import { getApiRequestService } from "../../../services/index.services";

interface QueryParams {
  limit: number;
  offset: number;
}

const defaultQueryParams: QueryParams = {
  limit: 10,
  offset: 0,
};

export async function getNotifications(params: QueryParams = defaultQueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/notification?limit=${params.limit ?? 10}&offset=${params.offset ?? 0}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
