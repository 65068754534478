import { useHistory } from "react-router-dom";
import { useLangContext } from "../domains/app/contexts/lang.context";
import { NotificationModel } from "../domains/app/app.types";
import { useUserConnection } from "../domains/user/hooks/useUserConnection";
import { pickUserImage } from "../domains/image/utils/pickUserImage";
import { VideoFormat } from "../domains/video/video.types";
import { Avatar } from "./global";
import { iosOpenNewTab, isNativeIOS } from "../tools/ios";
import { isAndroid } from "react-device-detect";
import { androidOpen } from "../tools/android";
import styled from "styled-components";
import JuisciLogo from "../assets/core/juisci-logo-circle.svg";
import { ReactComponent as CloseButtonGrey } from "../assets/icons/close-button-grey.svg";
import { ReactComponent as CheckButtonYellow } from "../assets/icons/check-button-yellow.svg";

export default function NotificationItem({
  notification,
  onClick,
  onDelete,
}: {
  notification: NotificationModel;
  onClick: Function;
  onDelete: Function;
}) {
  const history = useHistory();
  const { activeLang } = useLangContext();

  const notificationType = notification.action.notificationType;
  const notificationUser = notification.action.emitters[0];
  const { isConnected, acceptRequest, rejectRequest } = useUserConnection(
    notificationUser ?? null
  );
  let title = "";
  let text = "";

  if (notification.contents) {
    if (notification.contents[activeLang]) {
      title = notification.contents[activeLang].title;
      text = notification.contents[activeLang].text;
    }
  } else if (notification.title && notification.body) {
    title = notification.title;
    text = notification.body;
  }

  // Don't display empty notifications (multi-language notifications with missing language, etc.)
  if (!title || !text) return null;

  // Filter notifications by types
  if (
    ![
      "ContentNew",
      "ContentRecommended",
      "ContentShare",
      "ArticlePublished",
      "VideoPublished",
      "ExternalLink",
      "_old_FollowRequestSent",
      "FollowRequestReceived",
      "FollowRequestAccepted",
      "FollowUnfollow",
      "FollowActivity",
      "ConnectionRequest",
      "ConnectionRequestAccepted",
      "DirectMessage",
      "LemonCertifiedAnswered",
    ].includes(notificationType)
  ) {
    return null;
  }

  // Note: Certification notifications don't appear or
  // get read until the user is notified on the home page.
  if (notificationType === "LemonCertifiedAnswered" && !notification.read) {
    return null;
  }

  let avatar = JuisciLogo;
  if (notification.action.emitters.length) {
    avatar = pickUserImage(notificationUser);
  }
  if (notification.action?.journal) {
    avatar = notification.action?.journal?.image?.url;
  }

  let link: any = "";
  let externalLink: any = "";
  if (notificationType === "ContentNew") {
    link = "/discovery";
  }
  if (notificationType === "ContentRecommended") {
    link = "/foryou";
  }
  if (["ArticlePublished", "FollowActivity"].includes(notificationType)) {
    link = {
      pathname: `/post/${notification.action.article?.slug}`,
      state: {
        article: notification.action.article,
      },
    };
  }
  if (notificationType === "VideoPublished") {
    const content = notification?.action?.content;
    link = !content
      ? "/discovery/videos"
      : content.videoFormat === VideoFormat.STORY
        ? `/video/story/${content.slug}`
        : `/video/${content.slug}`;
  }

  if (
    [
      "_old_FollowRequestSent",
      "FollowRequestReceived",
      "FollowRequestAccepted",
      "FollowUnfollow",
      "ConnectionRequest",
      "ConnectionRequestAccepted",
    ].includes(notificationType)
  ) {
    link = `/profile/user/${notificationUser?.uid}`;
  }

  if (notificationType === "DirectMessage") {
    link = `/profile/messages/conversation/${notification?.action?.conversation?.id}`;
  }

  if (notificationType === "ExternalLink") {
    if (notification?.action?.url.includes("juisci.com")) {
      link = notification?.action?.url.split(".com")[1];
    } else {
      externalLink = notification?.action?.url;
    }
  }

  if (notificationType === "LemonCertifiedAnswered") {
    link = "/settings";
  }

  if (link === "/") link = "/discovery"

  const displayRequestsActions = [
    "FollowRequestReceived",
    "ConnectionRequest",
  ].includes(notificationType);

  return (
    <>
      <NotificationItemContainer
        onClick={() => {
          onClick && onClick();
          if (link) {
            history.push(link);
          } else if (externalLink) {
            if (isNativeIOS) {
              iosOpenNewTab(externalLink);
            } else if (isAndroid) {
              androidOpen(externalLink);
            } else {
              window.open(externalLink, "_blank");
            }
          }
        }}
      >
        <div className="icon-wrapper">
          <Avatar src={avatar} height={40} />
        </div>
        <div>
          <span className="title">{title}</span>
          <span className="body">{text}</span>
        </div>
        <div className="actions">
          {displayRequestsActions && !isConnected ? (
            <>
              <CloseButtonGrey
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(rejectRequest);
                }}
              />
              <CheckButtonYellow
                onClick={(e) => {
                  e.stopPropagation();
                  acceptRequest();
                }}
              />
            </>
          ) : !!notificationUser ? (
            <CloseButtonGrey
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            />
          ) : null}
        </div>
      </NotificationItemContainer>
      <hr />
    </>
  );
}

const NotificationItemContainer = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: flex-start;
  gap: 16px;

  .icon-wrapper {
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }

  .title {
    display: block;
    color: var(--JUISCI-Black-Matters, #212121);
    font-family: Roboto;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
  }

  .body {
    display: block;
    margin-top: 8px;
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-6, #4c5861);
    font-family: Roboto;
    font-size: 13px;
    font-weight: 300;
    line-height: 130%; /* 16.9px */
  }

  .actions {
    margin-left: auto;
    align-self: center;
    display: flex;
    align-items: center;
    gap: 12px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
`;
