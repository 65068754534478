import { SpecialtyModel } from "../../domains/app/app.types";
import { InfographicModel } from "../../interfaces/infographic.types";
import { AnalyticsItemCategory, AnalyticsItemVariant } from "./analytics.types";
import { gaEventSelectItem, gaEventViewItem } from "./analyticsEvents";

interface InfographicItemPayload {
  infographic: InfographicModel;
  listName: string;
}

function convertInfographicToItemData(payload: InfographicItemPayload) {
  const { infographic, listName } = payload;
  return {
    item_id: infographic._id,
    item_name: infographic.title,
    item_brand: infographic.company?.name ?? null,
    item_category: infographic.medical_specialties?.map((spe: SpecialtyModel) => {
      return spe.uid;
    }).join(","),
    item_category2: AnalyticsItemCategory.STANDARD,
    item_variant: AnalyticsItemVariant.INFOGRAPHIC,
    item_list_name: listName,
  };
}

export function gaEventSelectItemInfographic(payload: InfographicItemPayload) {
  gaEventSelectItem(convertInfographicToItemData(payload));
}

export function gaEventViewItemInfographic(payload: InfographicItemPayload) {
  gaEventViewItem(convertInfographicToItemData(payload));
}
