import { Component } from "react";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import { CustomButton, Spacer } from "../../components/global";
import styled from "styled-components";
import { t } from "i18next";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import CustomAvatarUploader from "../../components/profile/CustomAvatarUploader";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { defaultAvatarImages } from "../../domains/image/utils/pickUserImage";
import { uploadAvatar } from "../../domains/image/endpoints/uploadAvatar";
import { displayToast } from "../../components/app/AppToast";
import { getUserCertificationStatus } from "../../domains/user/endpoints/getUserCertificationStatus";
import { requestUserCertification } from "../../domains/user/endpoints/requestUserCertification";
import Loader from "../../components/Loader";

class RequestCertificationPage extends Component {
  state = {
    customAvatarFile: "",
    requireUpload: null,
    willNewImageUpload: false,
  };

  async componentDidMount() {
    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.default"),
    });
    if (isRedirected) return;

    // Check if user is already certified and redirect
    const { profilePicture, lemonCertified } = this.props.user.user;
    if (lemonCertified) {
      displayToast(t("error:default"));
      this.props.history.replace("/settings");
      return;
    } else {
      // Check if certification is pending or rejected and redirect
      const data = await getUserCertificationStatus();
      if (data?.status) {
        this.props.history.replace("/settings");
        return;
      }
    }

    if (profilePicture.url) {
      this.setState({ customAvatarFile: profilePicture.url });
      this.setState({ requireUpload: false });
    } else {
      this.setState({ requireUpload: true });
    }
  }

  handleChangeAvatarCustom = (url) => {
    this.setState({
      customAvatarFile: url,
      requireUpload: false,
      willNewImageUpload: true,
    });
  };

  handleSubmitImage = async (e) => {
    e.preventDefault();
    try {
      const { customAvatarFile, willNewImageUpload } = this.state;

      // Upload profile picture
      if (willNewImageUpload) {
        let file;
        await fetch(customAvatarFile)
          .then((res) => res.blob())
          .then(
            (blob) =>
              (file = new File([blob], "avatar", { type: "image/jpeg" }))
          );

        if (file) {
          const formData = new FormData();
          formData.set("file", file);

          uploadAvatar(formData)
            .then(() => {
              displayToast(t("toast:success.avatarUpdated"), "success");
              this.props.getUser();
              this.handleSubmitCertification();
            })
            .catch((error) => {
              displayToast(t("error:default"));
              console.error("Couldn't upload avatar.", error);
            });
        }
      } else {
        this.handleSubmitCertification();
      }
    } catch (error) {
      console.error("Couldn't upload profile picture.", error);
    }
  }

  async handleSubmitCertification(e) {
    e && e.preventDefault();

    try {
      // Request certification & redirect
      await requestUserCertification();
      displayToast(t("toast:certification.request"), "bg-yellow");
      this.props.history.replace("/settings");
    } catch (error) {
      console.error("Couldn't request certification.", error);
    }
  };

  render() {
    const { customAvatarFile, requireUpload, willNewImageUpload } = this.state;
    const selectedDefaultAvatar = this.props.user.user.profilePicture?.name;

    if (requireUpload === null) return <Loader />;

    return (
      <>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>{t("profile:certification.title")}</HeaderTitle>
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        <PageContainer>
          <Spacer height='90px' />

          <CustomAvatarUploader
            avatarUrl={
              customAvatarFile
                ? customAvatarFile
                : defaultAvatarImages[selectedDefaultAvatar]?.default
            }
            onChange={this.handleChangeAvatarCustom}
            disableUpload={!requireUpload}
          />

          <Spacer height='32px' />

          <CustomButton
            disabled={requireUpload}
            onClick={willNewImageUpload
              ? this.handleSubmitImage.bind(this)
              : this.handleSubmitCertification.bind(this)
            }>
            {t("profile:certification.requestCertification")}
          </CustomButton>
        </PageContainer>
      </>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(RequestCertificationPage);

const PageContainer = styled.div`
  margin: 22px 27px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
