import { SyntheticEvent } from "react";
import { Link, useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { renderDate } from "../../domains/app/utils/renderDate";
import SpecialtyBadges from "../app/SpecialtyBadges";
import ClampLines from "../app/ClampLines";
import ProfilePicture from "../ProfilePicture";
import UserConnectButton from "../app/UserConnectButton";
import styled from "styled-components";
import { ReactComponent as DefaultCompanyImage } from "../../assets/images/default-avatar/default-search-company.svg";

export default function PlaylistCard({
  playlist,
  onClick,
}: {
  playlist: PlaylistModel;
  onClick?: Function;
}) {
  const history = useHistory();
  const { t } = useLangContext();
  // NOTE: Search results use id.
  const playlistId = playlist?._id ?? playlist?.id;
  const medicalSpecialties = playlist.medical_specialties;
  const companyId = playlist?.company?.id ?? playlist?.company?._id;
  const companyImage = playlist?.company?.image ?? playlist?.company?.images?.[0]?.url;
  const path = {
    pathname: playlist.isPublic
      ? `/playlist/public/${playlistId}`
      : `/profile/playlist/${playlistId}`,
    state: {
      playlist,
    },
  };

  return (
    <ContainerLink
      className="playlist-card"
      to={path}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <CardHeader>
        <time>{renderDate(playlist.publicationDate as unknown as Date)}</time>
        <div className="tags">
          <SpecialtyBadges specialties={medicalSpecialties?.slice(0, 3)} />
        </div>
      </CardHeader>

      <CardBody>
        <ClampLines lines={4}>{playlist?.title}</ClampLines>
      </CardBody>

      <CardFooter>
        {playlist.user ? (
          <>
            <div className="row" onClick={(e: SyntheticEvent) => {
              e.preventDefault();
              history.push(`/profile/user/${playlist.user?.uid}`);
            }}>
              <ProfilePicture user={playlist.user} height={56} />
              <div>
                <span className="author-name">
                  {playlist?.user.fullname}
                </span>
                <span className="metrics">
                  {playlist?.metrics?.journals} {t("content:type.journals")} - {playlist?.metrics?.articles} {t("content:type.articles")}
                </span>
              </div>
            </div>
            <UserConnectButton user={playlist.user} />
          </>
        ) : playlist.company && (
          <div className="row" onClick={(e: SyntheticEvent) => {
            e.preventDefault();
            history.push(`/company/${companyId}`);
          }}>
            {!!companyImage
              ? <img src={companyImage} alt="Image" />
              : <DefaultCompanyImage />
            }
            <div>
              <span className="author-name">
                {playlist.company.name}
              </span>
              <span className="metrics">
                {playlist?.metrics?.journals} {t("content:type.journals")} - {playlist?.metrics?.articles} {t("content:type.articles")}
              </span>
            </div>
          </div>
        )}
      </CardFooter>
    </ContainerLink>
  );
}

const ContainerLink = styled(Link)`
  padding: 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`;

const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  
  time {
    text-align: left; /* Necessary for carousels, etc. */
    color: #212121;
    font-family: Inter;
    font-size: 12px;
    line-height: 120%; /* 14.4px */
    letter-spacing: 0.48px;
  }

  .tags {
    text-align: left; /* Necessary for carousels, etc. */
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }
`;

const CardBody = styled.div`
  min-height: 56px;
  display: flex;

  div {
    margin: auto 0;
    text-align: left; /* Necessary for carousels, etc. */
    color: #212121;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.28px;
  }
`;

const CardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;

  .row {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #212121;

    img, > svg {
      width: 30px !important; /* NOTE: Important needed for carousels. */
      height: 30px;
    }

    .author-name {
      display: block;
      text-align: left;
      color: var(--BRAND-CORE-Juisci-Black, #212121);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 16.8px */
      letter-spacing: 0.039px;
    }

    .metrics {
      display: block;
      text-align: left;
      text-transform: lowercase;
      color: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
      font-family: Inter;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%; /* 12px */
      letter-spacing: 0.028px;
    }
  }

  button {
    padding: 6px;
    font-size: 10px;
  }
`;
