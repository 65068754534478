import { Link } from "react-router-dom";
import { JournalModel } from "../domains/journal/journal.types";
import { useJournalFollow } from "../domains/journal/hooks/useJournalFollow";
import { CustomButton } from "./global";
import styled from "styled-components";
import { t } from "i18next";

export default function JournalCard({ journal }: {
  journal: JournalModel;
}) {
  const { isFollowed, toggleFollow } = useJournalFollow(journal);

  return (
    <StyledWrapper>
      <Link to={"/journal/" + journal.uid}>
        <div className='card-body'>
          <img src={journal.image.url} alt='' />
        </div>
        <h5>{journal.name}</h5>
      </Link>

      <div className='card-bottom'>
        <Link to={"/journal/" + journal.uid}>
          <div className='impact-factor'>
            <div className='label'>
              impact
              <br />
              factor
            </div>
            <div className='value'>{journal.impact_factor}</div>
          </div>
        </Link>

        <CustomButton
          onClick={toggleFollow}
          className={`xsmall ${isFollowed ? "active" : ""}`}
          style={{
            maxWidth: "100%",
            width: "fit-content",
          }}
        >
          {isFollowed ? t("common:action.unfollow") : t("common:action.follow")}
        </CustomButton>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  box-sizing: border-box;
  height: 100%;
  padding: 16px;
  border-radius: 10px;
  background: #ffffff;
  filter: drop-shadow(2px 2px 4px rgba(33, 33, 33, 0.1));

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .card-body {
    display: block;
    max-width: 130px;

    img {
      height: 40px;
      max-width: 100px;
      object-fit: contain;
      margin: auto;
    }
  }

  h5 {
    font-family: "Inter";
    font-weight: 700;
    font-size: 13px;
    line-height: 120%;
    color: #212121;
    text-align: center;
    margin: 21px 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-bottom {
    .impact-factor {
      display: flex;
      justify-content: space-between;
      padding: 8px 16px 16px;

      .label {
        font-family: "Inter";
        font-weight: 600;
        font-size: 8px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #90a4ae;
      }

      .value {
        font-family: "Oswald";
        font-weight: 700;
        font-size: 16px;
        line-height: 100%;
        color: #4c5861;
      }
    }
  }
`;
