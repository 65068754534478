import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { usePlaylistCache } from "../../../domains/playlist/hooks/usePlaylistCache";
import styled from "styled-components";
import { PlaylistModel } from "../../../domains/playlist/playlist.types";
import { ArticleModel } from "../../../domains/article/article.types";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { getPlaylistById } from "../../../domains/playlist/endpoints/getPlaylistById";
import { useRoomPreflight } from "../../../domains/room/hooks/useRoomPreflight";
import { gaEventViewItemListPodcast } from "../../../tools/analytics/audioRoomAnalytics";
import disableScroll from "disable-scroll";
import Loader from "../../../components/Loader";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavBack,
  NavLang,
} from "../../../components/app/headers/layout";
import { RoomPageWrapper } from "../../../components/room/layout";
import PodcastCard from "../../../components/cards/PodcastCard";
import { ReactComponent as AudioIcon } from "../../../assets/icons/audio-icon.svg";

const topicMap: { [key: string]: string } = {
  coughcold: "room:audio.sanofiOpella.topic.coughCold",
  allergy: "room:audio.sanofiOpella.topic.allergy",
};

export default function AudioRoomTopicPage() {
  const history = useHistory();
  const { roomId, topic } = useParams<{ roomId: string; topic: string }>();
  const { isInRoom, currentRoom } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const { isLoadingRoom, preflightRoom } = useRoomPreflight(roomId);
  const { roomPlaylists, setRoomPlaylists } = usePlaylistCache();

  // NOTE: Keep commented until backend is finished
  // const langPlaylists = roomPlaylists.filter((playlist: PlaylistModel) => {
  //   return playlist.language === activeLang;
  // });

  // NOTE: Temporary until the backend is finished
  const coughColdPlaylistIds = [
    // Production playlists
    "6712639045122b2eba27a12c", // Ep 1 - 💊 Mode of Action (MoA)
    "670820a2bae531179b08360e", // Ep 1 - 💊 Modo de acción (MoA)
    "671bb82d327626ec0e19e378", // Ep 2. - 🤧 Quick relief from cough
    "671bb856327626ec0e19e50f", // Ep 2. - 🤧 Alivio rápido de la tos
    "671bbbd3327626ec0e1a07c1", // Ep 3.1 - 🩺 Faster Recovery from cough - First part
    "671bbbf7327626ec0e1a0a26", // Ep 3.1 - 🩺 Recuperación más rápida de la tos - Primera parte
    "671bbfb7327626ec0e1a2c23", // Ep 3.2 - 🩺 Faster Recovery from cough - Second part
    "671bc00c327626ec0e1a2d5f", // Ep 3.2 - 🩺 Recuperación más rápida de la tos - Segunda parte
    "671bc5e7327626ec0e1a75a3", // Ep 4.1 - 🫁 Synergy with antibiotics - First part
    "671bc5bb327626ec0e1a753a", // Ep 4.1 - 🫁 Sinergia con antibióticos - Primera parte
    "671bcb5a327626ec0e1ac2a9", // Ep 4.2 - 🫁 Synergy with antibiotics - Second part
    "671bcb89327626ec0e1ac2ee", // Ep 4.2 - 🫁 Sinergia con antibióticos - Segunda parte
    "671be406327626ec0e1affbb", // Ep 4.3 - 🫁 Synergy with antibiotics - Third part
    "671be3de327626ec0e1aff72", // Ep 4.3 - 🫁 Sinergia con antibióticos - Tercera parte
    "671be6e4327626ec0e1b1cb9", // Ep 5 - ⚕️ Safety and Efficacy (Comparison vs. acetylcysteine)
    "671be7aa327626ec0e1b1ff5", // Ep 5 - ⚕️ Seguridad y eficacia (comparación frente a la acetilcisteína)
  ];
  const allergyPlaylistIds = [
    // Staging playlists
    "66a27b4e4be33add69d97223", // ️Climate Change and Impact on Allergic Rhinitis
    "669f83fb0f4f05c71c45eef2", // Cambio climático y su impacto en la rinitis alérgica

    // Production playlists
    "66ad02dea54fc354a610f3b2", // Ep 1 - 🌎🌡️Climate Change and Impact on Allergic Rhinitis
    "66b0ad0970dbbc5360770699", // Ep 1 - 🌎🌡️ Cambio climático y su impacto en la rinitis alérgica
    "66f18a9a1a5ddc2c7d59f091", // Ep 2 - 🏭🤧 Pollution and impact on Allergic Rhinitis
    "66f17bb11a5ddc2c7d5949d4", // Ep 2 - 🏭🤧 Contaminación e impacto en la rinitis alérgica
    "66f192421a5ddc2c7d5a0dee", // Ep 3 - 😴🤧 Safety & non-occupancy of Central Nervous System: No sedative effect
    "66f1950b1a5ddc2c7d5a1d26", // Ep 3 - 😴🤧 Seguridad y no ocupación del Sistema Nervioso Central: Sin efecto sedante
    "66f264ad1a5ddc2c7d5e8fc5", // Ep 4 - 🫀🤧 Safety & non-occupancy of Central Nervous System: No cardiotoxic effects
    "66f264781a5ddc2c7d5e8f65", // Ep 4 - 🫀🤧 Seguridad y no afectación del Sistema Nervioso Central: Sin efectos cardiotóxicos
    "67081db3bae531179b082381", // Ep 5 - 💊🫳 Safety & non-occupancy of Central Nervous System: Safety of dosage in patients with chronic urticaria
    "66f3e2db0c9e3dfed6e3bf1f", // Ep 5 - 💊🫳 Seguridad y no ocupación del Sistema Nervioso Central: Seguridad de la dosis en pacientes con urticaria crónica
  ];

  const topicPlaylistIds =
    topic === "coughcold" ? coughColdPlaylistIds : allergyPlaylistIds;

  const langPlaylists = roomPlaylists
    .filter((playlist: PlaylistModel) => {
      return (
        playlist.meta.status === "published" &&
        topicPlaylistIds.includes(playlist._id) &&
        playlist.language === activeLang
      );
    })
    .map((playlist: PlaylistModel) => ({
      ...playlist,
      playlist: playlist.playlist.filter(
        (podcast: ArticleModel) => podcast.meta?.status === "published"
      ),
    }));

  // console.log("roomPlaylists:", roomPlaylists);
  // console.log("langPlaylists:", langPlaylists);

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        await preflightRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (isInRoom) {
          scrollTo(0, 0);
          if (currentRoom?.playlists && !roomPlaylists.length) {
            const list = await Promise.all(
              currentRoom.playlists.map(async (playlistId: string) => {
                return await getPlaylistById(playlistId);
              })
            );
            setRoomPlaylists(list, 1000); // NOTE: Total doesn't apply here.
          }

          if (langPlaylists.length) {
            for (const playlist of langPlaylists) {
              gaEventViewItemListPodcast({
                podcasts: playlist.playlist,
                topicName: t(topicMap[topic]),
                sectionName: playlist.title,
                durationMin: 0, // TODO: Add podcast duration here.
              });
            }
          }
        }
      } catch (error) {
        console.error("Couldn't load room playlists.", error);
        history.push("/discovery");
      }
    })();
  }, [isInRoom, activeLang]);

  return (
    <RoomPageWrapper
      style={{ backgroundColor: currentRoom?.primaryColor ?? "#042B0B" }}
    >
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <HeaderTitle>{t(topicMap[topic])}</HeaderTitle>
        <HeaderLinks>
          <NavLang />
        </HeaderLinks>
      </HeaderBoxAutoSafe>

      {isLoadingRoom || !roomPlaylists.length ? (
        <Loader loaderOnly />
      ) : (
        <ContentWrapper>
          {langPlaylists.map((playlist: PlaylistModel, listIndex: number) => (
            <SectionWrapper key={playlist._id}>
              <SectionHeader>
                <div>
                  <AudioIcon />
                </div>
                <h1>{playlist.title}</h1>
              </SectionHeader>
              {playlist.playlist.map((podcast) => (
                <PodcastCard
                  key={podcast._id}
                  podcast={podcast}
                  listIndex={listIndex}
                  audioRoomContext={{
                    topicName: t(topicMap[topic]),
                    sectionName: playlist.title,
                  }}
                />
              ))}
              <hr />
            </SectionWrapper>
          ))}
        </ContentWrapper>
      )}
    </RoomPageWrapper>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 20px;
  padding-bottom: calc(16px + var(--safe-area-bottom));
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  hr {
    width: 100%;
    background: #6d7f6e;
  }

  &:last-child hr {
    display: none;
  }
`;

const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;

  h1 {
    color: #fff;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
`;
