import { JournalModel } from "../../domains/journal/journal.types";
import { AnalyticsItemCategory, AnalyticsItemVariant } from "./analytics.types";
import { gaEventViewItemList } from "./analyticsEvents";
import { gaPushEvent } from "./gaPushEvent";

enum Event {
  JOURNAL_FOLLOW = "journal_follow",
}

interface JournalItemPayload {
  journal: JournalModel;
  listName: string;
}

interface JournalItemListPayload {
  journals: JournalModel[];
  listName: string;
}

function convertJournalToItemData(payload: JournalItemPayload) {
  const { journal, listName } = payload;
  return {
    item_id: journal._id,
    item_name: journal.name,
    item_brand: journal.name, // NOTE: Journals are a brand themselves.
    item_category: null, // NOTE: Journals don't have specialties.
    item_category2: AnalyticsItemCategory.STANDARD,
    item_variant: AnalyticsItemVariant.JOURNALS,
    item_list_name: listName,
  };
}

export function gaEventViewItemListJournal(payload: JournalItemListPayload) {
  gaEventViewItemList(payload.journals.map((journal) => {
    return convertJournalToItemData({
      journal,
      listName: payload.listName,
    });
  }));
}

export function gaEventJournalFollow() {
  gaPushEvent(Event.JOURNAL_FOLLOW);
}
