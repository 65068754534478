import { getApiRequestService } from "../../../services/index.services";

export async function resetPassword(email: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/auth/resetpassword", { email });
    return res.data;
  } catch (error) {
    throw error;
  }
}
