import { useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled from "styled-components";
import { ArticleModel } from "../../../domains/article/article.types";
import { InfographicModel } from "../../../interfaces/infographic.types";
import { AudioRoomContext } from "../../../domains/room/room.types";
import { preflightUser } from "../../../domains/user/utils/preflightUser";
import { getContentById } from "../../../domains/content/endpoints/getContentById";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { AUDIO_PERCENTAGES } from "../../../tools/analytics/analytics.types";
import {
  gaEventAudioPodcastAccessOriginalPublication,
  gaEventPodcastAudioProgress,
  gaEventAudioRoomPlayPodcast,
  gaEventViewItemPodcast,
} from "../../../tools/analytics/audioRoomAnalytics";
import { renderDate } from "../../../domains/app/utils/renderDate";
import disableScroll from "disable-scroll";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  NavBack,
} from "../../../components/app/headers/layout";
import { RoomPageWrapper } from "../../../components/room/layout";
import { ReactComponent as InformationIcon } from "../../../assets/icons/information-icon.svg";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross.svg";
import { ReactComponent as LearnMoreIcon } from "../../../assets/icons/audioroom-learn-more.svg";
import { synthesizeArticleSpeech } from "../../../domains/article/endpoints/synthesizeArticleSpeech";
import { getArticleBySlug } from "../../../domains/article/endpoints/getArticleBySlug";
import { Skeleton } from "@mui/material";
import { getPodcastBackground } from "../../../domains/room/utils/getPodcastBackground";
import AudioRoomPodcastPlayer from "../../../components/media/AudioRoomPodcastPlayer";

export default function AudioRoomPlayerPage() {
  const history = useHistory();
  const location = useLocation<{
    podcast: ArticleModel;
    listIndex: number;
    audioRoomContext: AudioRoomContext;
  }>();
  const params = useParams<{
    roomId: string;
    slug: string;
    time?: string;
  }>();
  const { currentRoom } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const [podcast, setPodcast] = useState<ArticleModel | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [podcastCover, setPodcastCover] = useState<string | null>(null);
  const [associatedInfographic, setAssociatedInfographic] =
    useState<InfographicModel | null>(null);
  const [isInfoPanelVisible, setInfoPanelVisible] = useState(false);
  const [hasPlayedOnce, setPlayedOnce] = useState(false);
  const [debugMessages, setDebugMessages] = useState<string[]>([]);
  // const isDebugMode = process.env.NODE_ENV === "development" || window.location.host.startsWith("dev");
  const isDebugMode = false;
  // NOTE: These values are needed in real time for analytics or events out of
  // the React lifecycle. React state doesn't work here.
  const podcastRef = useRef<any>(null);
  const trackDurationRef = useRef<number>(0);

  useEffect(() => {
    loadPage();
  }, [params.slug]);

  async function loadPage() {
    try {
      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.default"),
      });
      if (isRedirected) return;

      disableScroll.off();
      setLoading(true);

      let loadedPodcast =
        location.state.podcast ?? (await getArticleBySlug(params.slug));

      if (loadedPodcast && !loadedPodcast.speech) {
        const { speech } = await synthesizeArticleSpeech(
          loadedPodcast.slug,
          activeLang
        );
        loadedPodcast = { ...loadedPodcast, speech };
      }

      setPodcast(loadedPodcast as ArticleModel);
      podcastRef.current = loadedPodcast;

      setPodcastCover(getPodcastBackground(location.state.listIndex));

      // Load associated infographic
      if (loadedPodcast && loadedPodcast.associatedContent.length > 0) {
        getContentById(loadedPodcast.associatedContent[0])
          .then((data) => {
            setAssociatedInfographic(data);
          })
          .catch((error) => {
            console.error("Couldn't load associated infographic.", error);
          });
      }

      setLoading(false);
    } catch (error) {
      console.error("Couldn't fetch article.", error);
    }
  }

  function processChunksOnExit(playedChunks: number[][]) {
    let totalPlayedDuration = 0;
    for (const [a, b] of playedChunks) {
      totalPlayedDuration += b - a;
    }

    let percentage = 0;

    if (trackDurationRef.current) {
      const playedRatio =
        (totalPlayedDuration / trackDurationRef.current) * 100;
      for (const step of AUDIO_PERCENTAGES) {
        if (playedRatio < step) break;
        percentage = step;
      }

      if (percentage) {
        if (location.state.audioRoomContext) {
          gaEventPodcastAudioProgress({
            podcast: podcastRef.current,
            topicName: location.state.audioRoomContext.topicName,
            sectionName: location.state.audioRoomContext.sectionName,
            durationMin: Math.floor(trackDurationRef.current / 60),
            percentage,
          });
        } else {
          console.error("Missing audio room context.");
        }
      }
    }
  }

  function saveTrack(time: number) {
    if (podcast) {
      localStorage.setItem(
        "lastPodcast",
        JSON.stringify({
          slug: podcast.slug,
          time,
          duration: trackDurationRef.current ?? 0,
          id: podcast._id,
          title: podcast.title,
          journal: { name: podcast.journal.name },
          medical_specialties: podcast.medical_specialties.map(({ uid }) => ({
            uid,
          })),
        })
      );
    }
  }

  function addDebugMessage(message: string) {
    setDebugMessages((prevMessages) => [...prevMessages, message]);
  }

  return (
    <RoomPageWrapper
      style={{ backgroundColor: currentRoom?.primaryColor ?? "#042B0B" }}
    >
      <HeaderBoxAutoSafe>
        <HeaderLinks>
          <NavBack />
        </HeaderLinks>
        <InformationIcon onClick={() => setInfoPanelVisible(true)} />
      </HeaderBoxAutoSafe>

      <ContentWrapper>
        {isLoading ? (
          <>
            <CoverImageWrapper>
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={"100%"}
                sx={{ backgroundColor: "#0b3813" }}
              />
            </CoverImageWrapper>
            <InfoWrapper>
              <Skeleton
                variant="rounded"
                width={"100px"}
                height={14}
                sx={{ backgroundColor: "#81516E" }}
              />
              <Skeleton
                variant="rounded"
                width={"100%"}
                height={22}
                sx={{ backgroundColor: "#B4B4B4" }}
              />
              <Skeleton
                variant="rounded"
                width={"80%"}
                height={22}
                sx={{ backgroundColor: "#B4B4B4" }}
              />
            </InfoWrapper>
          </>
        ) : (
          <>
            {!isDebugMode ? (
              <>
                <CoverImageWrapper>
                  {podcastCover && (
                    <img src={podcastCover} alt="Podcast cover" />
                  )}
                </CoverImageWrapper>

                <InfoWrapper>
                  <span>{podcast?.article_type}</span>
                  <h1>{podcast?.title}</h1>
                </InfoWrapper>
              </>
            ) : (
              <div
                style={{
                  minHeight: 500,
                  maxHeight: 500,
                  overflowY: "auto",
                  background: "white",
                  color: "black",
                }}
              >
                {debugMessages.map((message, index) => {
                  return <p key={index}>{message}</p>;
                })}
              </div>
            )}

            <AudioRoomPodcastPlayer
              onDebugLog={(text: string) => addDebugMessage(text)}
              trackUrl={podcast?.speech?.url ?? null}
              resumeLastTrackTime={parseFloat(params.time as string) ?? 0}
              onLoad={(duration: number) => {
                trackDurationRef.current = duration;
                gaEventViewItemPodcast({
                  podcast: podcastRef.current,
                  topicName: location.state.audioRoomContext.topicName,
                  sectionName: location.state.audioRoomContext.sectionName,
                  durationMin: Math.floor(trackDurationRef.current / 60),
                });
              }}
              onPlay={() => {
                if (!hasPlayedOnce) {
                  gaEventAudioRoomPlayPodcast({
                    podcast,
                    topicName: location.state.audioRoomContext.topicName,
                    sectionName: location.state.audioRoomContext.sectionName,
                    durationMin: Math.floor(trackDurationRef.current / 60),
                  });
                  setPlayedOnce(true);
                }
              }}
              onProgressTime={(time: number) => {
                saveTrack(time);
              }}
              onUnmount={({ playedChunks }: { playedChunks?: number[][] }) => {
                if (playedChunks) processChunksOnExit(playedChunks);
              }}
            />
          </>
        )}
      </ContentWrapper>

      {isInfoPanelVisible && (
        <InformationPanel
          onClose={() => setInfoPanelVisible(false)}
          title={podcast?.title}
          publicationDate={podcast?.publication_date as unknown as string}
          journalName={podcast?.journal?.name}
          primaryAthor={podcast?.primary_author}
          otherAuthors={podcast?.authors}
          doi={podcast?.doi}
          infographic={associatedInfographic}
          onClickExternal={() => {
            gaEventAudioPodcastAccessOriginalPublication({
              podcast,
              topicName: location.state.audioRoomContext.topicName,
              sectionName: location.state.audioRoomContext.sectionName,
              durationMin: Math.floor(trackDurationRef.current / 60),
              publicationUrl: podcast?.doi ?? "",
            });
          }}
        />
      )}
    </RoomPageWrapper>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding: 20px 20px calc(20px + var(--safe-area-bottom));

  .skeleton {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

    @keyframes pulse {
      0%,
      100% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
    }
  }
`;

const CoverImageWrapper = styled.div`
  width: 100%;
  aspect-ratio: 1/1;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  flex: 1;

  span {
    color: #ff78d2;
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }

  h1 {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 20px;
    font-weight: 500;
    line-height: 110%;
    letter-spacing: -0.2px;
    margin: 0;
  }
`;

function InformationPanel({
  onClose,
  title,
  publicationDate,
  journalName,
  primaryAthor,
  otherAuthors,
  doi,
  infographic,
  onClickExternal,
}: {
  onClose: () => void;
  title?: string;
  publicationDate?: string;
  journalName?: string;
  primaryAthor?: string;
  otherAuthors?: string[];
  doi?: string;
  infographic?: InfographicModel | null;
  onClickExternal: Function;
}) {
  const { t } = useLangContext();
  const dateObject =
    typeof publicationDate === "string" ? new Date(publicationDate) : null;

  useEffect(() => {
    disableScroll.off();
  }, []);

  return (
    <InformationPanelWrapper>
      <h2>
        {t("room:audio.infoPanel.title")}
        <CrossIcon onClick={onClose} />
      </h2>
      {title && (
        <>
          <h3>{t("content:field.title")}</h3>
          <p>{title}</p>
        </>
      )}
      {publicationDate && (
        <>
          <h3>{t("content:field.publicationDate")}</h3>
          <p>{renderDate(dateObject as Date)}</p>
        </>
      )}
      {journalName && (
        <>
          <h3>{t("content:field.journal")}</h3>
          <p>{journalName}</p>
        </>
      )}
      {primaryAthor && (
        <>
          <h3>{t("content:field.primaryAuthor")}</h3>
          <p>{primaryAthor}</p>
        </>
      )}
      {otherAuthors && otherAuthors.length > 0 && (
        <>
          <h3>{t("content:field.otherAuthors")}</h3>
          {otherAuthors.map((author, index) => (
            <p key={index}>{author}</p>
          ))}
        </>
      )}
      <h3>{t("content:field.promomatsCode")}</h3>
      <p>MAT-MX-2401677</p>
      {doi && (
        <>
          <h3>DOI</h3>
          <a
            href={`${doi}`}
            target="_blank"
            onClick={() => onClickExternal && onClickExternal()}
          >
            {doi}
          </a>
        </>
      )}

      {!!infographic && (
        <LearnMoreLink to={`/infographic/${infographic.slug}`}>
          <div>
            <h3>{t("room:audio.infoPanel.learnMore.title")}</h3>
            <span>{t("room:audio.infoPanel.learnMore.description")}</span>
          </div>
          <div>
            <LearnMoreIcon />
          </div>
        </LearnMoreLink>
      )}
    </InformationPanelWrapper>
  );
}

const InformationPanelWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  height: 100dvh;
  padding: calc(25px + var(--safe-area-top)) 20px
    calc(25px + var(--safe-area-bottom));
  overflow-y: auto;
  background-color: #f7efe6;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 400;
  line-height: 110%;
  color: #141414;

  h2,
  h3 {
    margin: 0;
    line-height: 1;
  }

  p {
    margin: 4px 0;
  }

  h2 {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 30px;
    font-weight: 700;
  }

  h3 {
    font-size: 16px;
    font-weight: 700;

    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }

  a {
    text-decoration: underline;
    color: inherit;
    display: block;
    margin-top: 4px;
  }
`;

const LearnMoreLink = styled(Link)`
  margin-top: 24px !important;
  background: #fff;
  display: flex;
  padding: 32px;
  justify-content: space-between;
  gap: 32px;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  text-decoration: none !important;

  div {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  h3 {
    color: #042b0b;
    font-family: "Inter";
    font-size: 16px;
    font-weight: 800;
    margin: 0;
  }

  span {
    color: #042b0b;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: 400;
  }
`;
