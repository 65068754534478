import { SpecialtyModel } from "../../domains/app/app.types";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { filterArrayDuplicates } from "../../domains/app/utils/filterArrayDuplicates";
import SpecialtyBadges from "../app/SpecialtyBadges";
import { renderDate } from "../../domains/app/utils/renderDate";
import { JuisciGPTBadge } from "./JuisciGPTBadge";
import styled from "styled-components";
import JuisciOriginalLogo from "../../assets/core/juisci-original.svg";

export default function NectarHero({
  nectar,
  styles = {},
  onClick,
  shortenSpecialties = false,
}: {
  nectar: NectarModel,
  styles: { container?: {}, title?: {} },
  onClick?: Function,
  shortenSpecialties?: boolean,
}) {
  // Eliminer les doublons
  let specialties = nectar?.medical_specialties
    ? filterArrayDuplicates(
      nectar?.medical_specialties,
      (a: SpecialtyModel, b: SpecialtyModel) => a.uid === b.uid,
    )
    : [];
  if (shortenSpecialties) specialties = specialties.slice(0, 4);

  return (
    <Container
      style={styles?.container}
      onClick={() => onClick && onClick(nectar)}
    >
      <span className="date">
        {renderDate(nectar.publication_date as Date)}
      </span>
      <div className="specialties">
        <SpecialtyBadges specialties={specialties} />
      </div>
      <div className="title">{nectar.question}</div>
      <div className="card-footer">
        <img src={JuisciOriginalLogo} alt="" />
        <JuisciGPTBadge />
      </div>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  background-color: #ffffff;
  box-shadow: 2px 2px 4px 0px #2121211a;

  .date {
    font-family: Inter;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .specialties {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
  }

  .title {
    margin: 22px 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
  }

  .card-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    img {
      object-fit: contain;
      width: 167px !important;
    }
  }
`;
