import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { SupportedLanguage } from "../interfaces";

// NOTE: yo is used for formal French.
const DETECTED_LANGUAGES = ["en", "fr", "es", "yo"];
export const PUBLIC_INAPP_LANGUAGES = [
  SupportedLanguage.EN,
  SupportedLanguage.FR,
];
export const LOGIN_SIGNUP_LANGUAGES = [
  SupportedLanguage.EN,
  SupportedLanguage.FR,
  SupportedLanguage.ES,
];
export const DEFAULT_LANGUAGE = SupportedLanguage.EN;

const namespaces = [
  "translation", // TODO: Legacy, remove at some point.
  "common",
  "content",
  "error",
  "toast",
  "modal",
  "login",
  "signup",
  "home",
  "juisciGpt", // NOTE: For some reason, 'gpt' will crash the app
  "forYou",
  "room",
  "profile",
  "settings",
  "search",
  "messaging",
  "share"
];

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    supportedLngs: DETECTED_LANGUAGES,
    preload: DETECTED_LANGUAGES, // NOTE: Absolutely necessary.
    fallbackLng: {
      "yo": ["fr", "en"],
      "default": ["en"],
    },
    load: "all",
    ns: namespaces,
    // debug: process.env.NODE_ENV === "development",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
