interface ContentFilters {
  specialtyIds: string[];
  specialtyUids: string[];
  subSpecialtyIds: string[];
  subSpecialtyUids: string[];
}

interface ContentFiltersPayload {
  specialtyIds?: string[];
  specialtyUids?: string[];
  subSpecialtyIds?: string[];
  subSpecialtyUids?: string[];
}

const initialContentFilters: ContentFilters = {
  specialtyIds: [],
  specialtyUids: [],
  subSpecialtyIds: [],
  subSpecialtyUids: [],
};

export function getStoredContentFilters(): ContentFilters {
  let filters: ContentFilters = initialContentFilters;

  const storedFilters = localStorage.getItem("contentFilters");
  if (storedFilters) {
    filters = JSON.parse(storedFilters)[window.location.pathname] ?? filters;
  }
  return filters;
}

export function storeContentFilters(payload: ContentFiltersPayload) {
  let filters: any = {};
  const storedFilters = localStorage.getItem("contentFilters");
  if (storedFilters) {
    filters = JSON.parse(storedFilters);
  }

  filters[window.location.pathname] = payload;

  localStorage.setItem("contentFilters", JSON.stringify(filters));
}

export function clearStoredContentFilters() {
  localStorage.removeItem("contentFilters");
}
