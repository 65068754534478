import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { ThemeProvider, createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      light: "#FFF3CE",
      main: "#ffc408",
      dark: "#FFA100",
      contrastText: "#fff",
    },
    primary: {
      light: "#FFF3CE",
      main: "#ffc408",
      dark: "#FFA100",
      contrastText: "#fff",
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-root": {
            backgroundColor: "#fff",
            color: "#212121",
            fontFamily: "Inter",
            width: "100%",
            borderRadius: "4px",

            "& .MuiInputBase-input": {
              fontFamily: "Inter",
              fontWeight: "500",
              transform: "translateY(5px)",
            },

            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#fff",
              fontFamily: "Inter",
            },

            "& .MuiInputLabel-shrink, .MuiFormLabel-filled ": {
              fontFamily: "Inter",
              fontWeight: "500",
              color: "#81939c",
              transform: "scale(0.7) translate(20px, 7px)",
            },
            "& .MuiFormLabel-root": {
              fontFamily: "Inter",
              fontWeight: "500",
              color: "#81939c",
            },
          },
        },
      },
    },
  },
});

export default function ComboBox({
  placeholder,
  value,
  options,
  groupBy,
  required,
  onChange,
}) {
  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        disablePortal
        id='combo-box-demo'
        options={options}
        groupBy={groupBy}
        sx={{
          minWidth: "100%",
        }}
        value={value}
        getOptionLabel={(value) => {
          return value.label ?? options.find((option) => option.value === value)?.label ?? "";
        }}
        renderInput={(params) => {
          return (
            <TextField
              required={required}
              {...params}
              label={placeholder ?? ""}
            />
          );
        }}
        onChange={(_event, newValue) => {
          onChange && onChange(newValue);
        }}
      />
    </ThemeProvider>
  );
}
