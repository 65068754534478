import { gaPushEvent, gaPushEventDelay } from "./gaPushEvent";

enum Event {
  SEARCH = "search",
  SEARCH_SECTION_SELECTED = "search_section_selected",
}

export function gaEventSearch(
  term: string,
  resultsCount: number,
  type: string, // global, articles, journals, users, playlists, videos, audio room, etc.
) {
  gaPushEvent(
    Event.SEARCH,
    {
      environment: {
        name: !window.location.hostname.includes("app.juisci") ? "dev" : "prod",
        entity: "web app",
      },
      search: {
        term,
        results_count: resultsCount,
        type,
      },
    }
  );
}

export function gaEventSearchSelectSection() {
  gaPushEventDelay(Event.SEARCH_SECTION_SELECTED);
}
