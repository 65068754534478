import { userApi } from "../../../redux/user/user.service";

const api = userApi.injectEndpoints({
  endpoints: (build) => ({
    getAcceptedConnectionRequests: build.query<any, void>({
      query: () => {
        return {
          url: "/user/connection/request/accepted",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getAcceptedConnectionRequests,
} = api.endpoints;

export const {
  useGetAcceptedConnectionRequestsQuery,
  useLazyGetAcceptedConnectionRequestsQuery,
} = api;
