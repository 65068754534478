import { getApiRequestService } from "../../../services/index.services";

interface QueryParams {
  limit: number;
}

const defaultQueryParams: QueryParams = {
  limit: 10,
};

export async function getRandomUsers(params: QueryParams = defaultQueryParams) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/user/random", { params });
    return res.data;
  } catch (error) {
    throw error;
  }
}
