import { Component } from "react";
import styled from "styled-components";

class CustomSwitch extends Component {
  render() {
    return (
      <StyledSwitch onClick={this.props.onClick}>
        <button className={!this.props.checked ? "off checked" : "off"}>
          {this.props.offText ?? "OFF"}
        </button>
        <button className={this.props.checked ? "on checked" : "on"}>
          {this.props.onText ?? "ON"}
        </button>
      </StyledSwitch>
    );
  }
}

export default CustomSwitch;

const StyledSwitch = styled.div`
  background: #313b42;
  padding: 3px;
  border-radius: 100px;

  button {
    padding: 5px;
    outline: none;
    border: none;
    box-shadow: none;
    font-family: "Inter";
    font-weight: 700;
    background: #313b42;
    color: #fff;

    &.off {
      border-radius: 100px 20px 20px 100px;
    }

    &.on {
      border-radius: 20px 100px 100px 20px;
    }

    &.checked {
      background: #fff;
      color: #212121;
    }
  }
`;
