import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useLazyGetNectarsListQuery } from "../../../domains/nectar/endpoints/getNectarsList";
import { useReadyState } from "../../../domains/app/hooks/useReadyState";
import { usePageScroll } from "../../../domains/app/hooks/usePageScroll";
import { memorizeNectarsList, memorizeNectarsListRoom, setSwipeIndex } from "../../../domains/nectar/nectar.reducer";
import { NectarModel } from "../../../domains/nectar/nectar.types";
import { getStoredContentFilters } from "../../../domains/app/utils/contentFilters";
import styled from "styled-components";
import { displayToast } from "../../../components/app/AppToast";
import Loader from "../../../components/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventViewItemListNectar } from "../../../tools/analytics/nectarAnalytics";
import { SupportedLanguage } from "../../../interfaces";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack, NavLang } from "../../../components/app/headers/layout";
import BottomNavbar from "../../../components/app/BottomNavbar";
import ContentFilterButton from "../../../components/filters/ContentFilterButton";
import SpecialtyFilterPanel from "../../../components/filters/SpecialtyFilterPanel";
import disableScroll from "disable-scroll";
import ItemList from "../../../components/app/ItemList";
import NectarCard from "../../../components/cards/NectarCard";
import FadeIn from "react-fade-in";

export default function HomeContentNectarsPage() {
  const dispatch = useAppDispatch();
  const { isInRoom } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const [getNectarsList] = useLazyGetNectarsListQuery();
  const { setAsReady, useReadyEffect } = useReadyState();
  const { savePageScrollY, restorePageScrollY } = usePageScroll();
  const [nectars, setNectars] = useState<NectarModel[]>([]);
  const [specialtyFilters, setSpecialtyFilters] = useState<string[]>(getStoredContentFilters().specialtyIds ?? []);
  const [isLoading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false);

  useEffect(() => {
    (async function () {
      disableScroll.off();

      // TODO: Add cache
      if (nectars.length > 0) {
        setLoading(false);
        restorePageScrollY();
      } else {
        scrollTo(0, 0);
        loadNectars(true);
      }
      setAsReady();
    })();
  }, [specialtyFilters]);

  useReadyEffect(() => {
    scrollTo(0, 0);
    loadNectars(true);
  }, [activeLang, specialtyFilters]);

  useEffect(() => {
    gaEventViewItemListNectar({
      nectars,
      listName: AnalyticsItemList.HOME,
    });
  }, [nectars]);

  async function loadNectars(resetList = false) {
    try {
      const params: {
        limit: number;
        offset: number;
        language: SupportedLanguage;
        medicalSpecialties?: string;
      } = {
        limit: 10,
        offset: resetList ? 0 : nectars.length,
        language: activeLang,
      };

      if (specialtyFilters.length > 0) {
        params.medicalSpecialties = specialtyFilters.join(",");
      }

      await getNectarsList(params)
        .unwrap()
        .then(({ docs, total }) => {
          const newList = resetList ? docs : [...nectars, ...docs];
          setNectars(newList);
          setTotal(total);
          dispatch(isInRoom
            ? memorizeNectarsListRoom({ list: newList, total })
            : memorizeNectarsList({ list: newList, total })
          );
        })
        .catch((error) => {
          throw error;
        });

      setLoading(false);
    } catch (error) {
      console.error("Couldn't fetch content.", error);
      displayToast(t("error:default"));
    }
  }

  function handleChangeFilters() {
    setSpecialtyFilters(getStoredContentFilters().specialtyIds ?? specialtyFilters);
  }

  return (
    <>
      <Wrapper>
        <HeaderBoxAutoSafe>
          <HeaderLinks><NavBack /></HeaderLinks>
          <HeaderTitle>{t("content:type.nectarsOfScience")}</HeaderTitle>
          <HeaderLinks><NavLang /></HeaderLinks>
        </HeaderBoxAutoSafe>

        <ContentFilterButton
          active={!!specialtyFilters?.length}
          onClick={() => setFilterPanelVisible(true)}
        />

        <div style={{ flex: 1 }}>
          {isLoading ? (
            <Loader loaderOnly />
          ) : !nectars.length ? (
            <NoResult>{t("common:noContent")}</NoResult>
          ) : (
            <div className='page-content'>
              <FadeIn key="content">
                <InfiniteScroll
                  dataLength={nectars.length}
                  next={loadNectars}
                  hasMore={nectars.length < total}
                  loader={null}
                >
                  <ItemList>
                    {nectars.map((nectar, index) => (
                      <NectarCard
                        key={nectar._id}
                        nectar={nectar}
                        analyticsListName={AnalyticsItemList.HOME}
                        onClick={() => {
                          savePageScrollY();
                          dispatch(setSwipeIndex(index));
                        }}
                      />
                    ))}
                  </ItemList>
                </InfiniteScroll>
              </FadeIn>
            </div>
          )}
        </div>

        <BottomNavbar />
      </Wrapper>

      <SpecialtyFilterPanel
        isOpen={isFilterPanelVisible}
        onClose={() => setFilterPanelVisible(false)}
        onSave={() => handleChangeFilters()}
      />
    </>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: rgba(238, 248, 34, 0.40);
`;

const NoResult = styled.div`
  padding: 8px 22px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 110%;
  color: #212121;
`;
