import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { RoomModel } from "../../domains/room/room.types";
import { ArticleModel } from "../../domains/article/article.types";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { VideoFormat, VideoModel } from "../../domains/video/video.types";
import { SpecialtyModel } from "../../domains/app/app.types";
import { InfographicModel } from "../../interfaces/infographic.types";
import { ContentFormat } from "../../interfaces/content.interface";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { getRoomTranslation } from "../../domains/room/utils/getRoomTranslation";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventSelectItemArticle } from "../../tools/analytics/articleAnalytics";
import { gaEventSelectItemNectar } from "../../tools/analytics/nectarAnalytics";
import { gaEventSelectItemVideo } from "../../tools/analytics/videoAnalytics";
import { gaEventSelectItemInfographic } from "../../tools/analytics/infographicAnalytics";
import { getArticleTypeText } from "../../domains/app/utils/getArticleTypeText";
import styled from "styled-components";
import {
  CardContainer,
  CardType,
  CardTitle,
  CondensedCardFooter,
  RoomBadge,
  ContentLabel,
} from "./layout";
import SpecialtyBadges from "../app/SpecialtyBadges";
import { ReactComponent as PlayIcon } from "../../assets/icons/play-icon.svg";
import { ReactComponent as InfographicIcon } from "../../assets/icons/image-icon.svg";

export default function RoomContentCard({
  content,
  analyticsListName,
}: {
  content: ArticleModel | NectarModel | VideoModel | InfographicModel;
  analyticsListName: AnalyticsItemList;
}) {
  const history = useHistory();
  const userRooms = useAppSelector((state) => state.user.rooms);
  const { t } = useLangContext();
  const isArticle = content.type === ContentFormat.ARTICLE;
  const isNectar = content.type === ContentFormat.NECTAR;
  const isVideo = content.type === ContentFormat.VIDEO;
  const isInfographic = content.type === ContentFormat.INFOGRAPHIC;
  const room = userRooms.find((room: RoomModel) => room._id === (content as unknown as { room: string }).room);
  const isDarkVariant = isVideo || isInfographic;
  let thumbnailUrl = "";
  let companyName = "";
  let companyLogo = "";
  let companyMetrics = "";

  if (isArticle) {
    const article = content as ArticleModel;
    companyName = article.journal?.name ?? "";
    companyLogo = article.journal?.image?.url ?? ""; ``
    companyMetrics = article.primary_author;
    if (article.authors?.length > 0) {
      companyMetrics += " " + t("common:numberOtherAuthors", { authorsLength: article.authors?.length ?? 0 })
    }
  }
  if (isNectar) {
    const nectar = content as NectarModel;
    companyName = nectar.organisations?.[0]?.name ?? "";
    companyLogo = nectar.organisations?.[0]?.logo?.url ?? "";
  }
  if (isVideo) {
    const video = content as VideoModel;
    thumbnailUrl = video?.apiVideo?.thumbnail ?? `https://i3.ytimg.com/vi/${video?.sourceURL?.split("v=")[1]
      }/mqdefault.jpg` ?? "";
    companyName = video.company?.name ?? video.congress?.name ?? "";
    companyLogo = video.company?.images ? video.company?.images[0]?.url : video.congress?.image?.url ?? "";
  }
  if (isInfographic) {
    const infographic = content as InfographicModel;
    thumbnailUrl = infographic.thumbnail?.url ?? "";
    companyName = infographic.company?.name ?? infographic.congress?.name ?? "";
    companyLogo = infographic.company?.images ? infographic.company?.images[0]?.url : infographic.congress?.image?.url ?? "";
  }

  function handleNavigate() {
    let path: any = "";

    if (isArticle) {
      const article = content as ArticleModel;
      path = `/post/${article.slug}`
      gaEventSelectItemArticle({ article, listName: analyticsListName });
    }

    if (isNectar) {
      const nectar = content as NectarModel;
      path = `/nectar/${nectar.slug}`;
      gaEventSelectItemNectar({ nectar, listName: analyticsListName });
    }

    if (isVideo) {
      const video = content as VideoModel;
      path = video.videoFormat === VideoFormat.STORY
        ? `/video/story/${video.slug}`
        : `/video/${video.slug}`;
      gaEventSelectItemVideo({ video, listName: analyticsListName });
    }

    if (isInfographic) {
      const infographic = content as InfographicModel;
      path = {
        pathname: `/infographic/${infographic.slug}`,
        state: { infographic },
      };
      gaEventSelectItemInfographic({
        infographic,
        listName: analyticsListName,
      });
    }

    if (path) history.push(path);
  }

  return (
    <>
      <CardContainer
        variant={isDarkVariant ? "dark" : "white"}
        style={{ minHeight: "unset" }}
        backgroundUrl={thumbnailUrl}
        onClick={handleNavigate}
      >
        <div style={{ display: "flex", marginBottom: 8 }}>
          {room && <RoomBadge>{getRoomTranslation(room)}</RoomBadge>}
        </div>
        <div style={{ display: "flex", flexWrap: "wrap", gap: "8px" }}>
          <ContentLabel type={(content.type as ContentFormat).toLowerCase()} />
          <SpecialtyBadges
            specialties={(content.medical_specialties as SpecialtyModel[])?.slice(0, 1)}
          />
        </div>
        <CardBody>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            {isArticle && <CardType>{t(getArticleTypeText((content as ArticleModel)))}</CardType>}
            <CardTitle lines={3}>{content.title}</CardTitle>
          </div>
          <div>
            {isVideo && <PlayIcon />}
            {isInfographic && <InfographicIcon />}
          </div>
        </CardBody>
        <CondensedCardFooter className={isDarkVariant ? "dark" : ""}
          companyName={companyName}
          companyLogo={companyLogo}
          companyMetrics={companyMetrics}
        />
      </CardContainer>
    </>
  );
}

const CardBody = styled.div`
  margin: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
