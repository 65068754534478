import { getApiRequestService } from "../../../services/index.services";

export async function patchUser(data: any) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch("/user/me", data);
    return res.data;
  } catch (error) {
    throw error;
  }
}
