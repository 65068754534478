import { atom, useAtom } from "jotai";
import { DebugLogger } from "../../app/utils/debugLogger";
import { ArticleModel } from "../article.types";
import { ArticleCacheList, useArticleCache } from "./useArticleCache";

const debug = new DebugLogger("Article Swipe");
debug.mute();

const indexAtom = atom(-1);

export function useArticleSwipe() {
  const { cacheLists, saveList } = useArticleCache();
  const [swipeIndex, setSwipeIndex] = useAtom(indexAtom);

  return {
    swipeList: cacheLists[ArticleCacheList.SWIPE],
    swipeIndex,
    setSwipeIndex: (index: number) => {
      debug.log("Swipe index changed", { index });
      setSwipeIndex(index);
    },
    setSwipeCache: (docs: ArticleModel[], index: number) => {
      debug.log("Swipe cache changed", docs, { index });
      saveList(ArticleCacheList.SWIPE, docs);
      setSwipeIndex(index);
    },
    clearSwipeCache: () => {
      debug.log("Swipe cache cleared");
      saveList(ArticleCacheList.SWIPE, []);
      setSwipeIndex(-1);
    },
  }
}
