import { ReactChildren, ReactNode, useRef, useState } from "react";

export default function GestureDetector({
  children,
  onDrag,
  onSwipeLeft,
  onSwipeRight,
  onSwipeUp,
  onSwipeDown,
  onRelease,
}: {
  children: ReactNode | ReactChildren;
  onDrag?: Function;
  onSwipeLeft?: Function;
  onSwipeRight?: Function;
  onSwipeUp?: Function;
  onSwipeDown?: Function;
  onRelease?: Function;
}) {
  const [startX, setStartX] = useState(0);
  const [endX, setEndX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [endY, setEndY] = useState(0);
  const containerRef = useRef<HTMLDivElement | null>(null);

  function handleTouchStart(e: any) {
    setStartX(e.targetTouches[0].clientX);
    setStartY(e.targetTouches[0].clientY);
    setEndX(e.targetTouches[0].clientX);
    setEndY(e.targetTouches[0].clientY);
  }

  function handleTouchMove(e: any) {
    setEndX(e.targetTouches[0].clientX);
    setEndY(e.targetTouches[0].clientY);
    if (onDrag) {
      onDrag(
        e.targetTouches[0].clientX - startX,
        e.targetTouches[0].clientY - startY,
      );
    }
  }

  function handleTouchEnd() {
    const diffX = endX - startX;
    const diffY = endY - startY;
    const clientWidth = containerRef.current?.clientWidth as number;
    const clientHeight = containerRef.current?.clientHeight as number;

    if (Math.abs(diffX) > Math.abs(diffY)) {
      if (onSwipeLeft && diffX < -clientWidth / 2) onSwipeLeft();
      if (onSwipeRight && diffX > clientWidth / 2) onSwipeRight();
    } else {
      if (onSwipeUp && diffY < -clientHeight / 2) onSwipeUp();
      if (onSwipeDown && diffY > clientHeight / 2) onSwipeDown();
    }

    if (onDrag) onDrag(0, 0);
    if (onRelease) onRelease(diffX, diffY);
  }

  return (
    <div
      ref={containerRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      {children}
    </div>
  );
}