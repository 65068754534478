import { useEffect, useRef, useState } from "react";
import { store, useAppDispatch, useAppSelector } from "../../../redux";
import { useLangContext } from "../../app/contexts/lang.context";
import { useLazyGetNectarsListQuery } from "../../nectar/endpoints/getNectarsList";
import { useLazySearchNectarsQuery } from "../endpoints/searchNectars";
import { memorizeSearchNectars, updateFacets } from "../search.reducer";
import { displayToast } from "../../../components/app/AppToast";

export function useSearchNectars(searchValue: string) {
  // Store
  const dispatch = useAppDispatch();
  const searchFilters = useAppSelector((state) => state.search.searchFilters);
  const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
  const { activeLang, t } = useLangContext();
  // State
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoadingResults, setLoadingResults] = useState(false);
  const [fallback, setFallback] = useState([]);
  // Query
  const [getNectarsList, getNectarsListResult] = useLazyGetNectarsListQuery();
  const [searchNectars] = useLazySearchNectarsQuery();
  // Custom
  const mountRef = useRef(false);
  const [isMounted, setMounted] = [!!mountRef.current, () => mountRef.current = true];

  useEffect(() => {
    // NOTE: Usual selector not reliable enough, using store state instead.
    const cache = store.getState().search.cache;
    if (!isMounted) {
      if (cache.nectars.results.length) {
        if (searchValue.length) {
          setResults(cache.nectars.results);
          setTotal(cache.nectars.total);
        } else {
          setFallback(cache.nectars.results);
        }
      } else if (searchValue.length) {
        searchForResults(true);
      } else {
        loadFallback();
        searchForFacets();
      }
      setMounted();
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length || filtersApplied) {
        searchForResults(true);
      } else {
        setResults([]);
        setTotal(0);
        if (!fallback.length) loadFallback();
      }
    }
  }, [searchValue, searchFilters]);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length) {
        searchForResults(true)
      } else {
        setFallback([]);
        setTimeout(() => {
          loadFallback();
        }, 0);
      }
    }
  }, [activeLang]);

  function getParamsWithFilters(params: any) {
    params.scoring = searchFilters.scoring;

    return params;
  }

  function loadFallback() {
    getNectarsList({
      limit: 20,
      offset: 0,
      language: activeLang,
    })
      .unwrap()
      .then((data) => {
        const results = data.docs as [];
        setFallback(results);
        dispatch(memorizeSearchNectars({ results, total: 20 }));
      })
      .catch((error) => {
        console.error("Couldn't load fallback nectars.", error);
      });
  }

  function searchForResults(resetResults: boolean) {
    if (resetResults) setLoadingResults(true);

    const params: any = getParamsWithFilters({
      search: searchValue,
      limit: 20,
      offset: resetResults ? 0 : results.length,
      language: activeLang,
    });

    searchNectars(params)
      .unwrap()
      .then(({ docs, meta: { total, facets } }) => {
        const newResults = resetResults ? docs : [...results, ...docs];
        setResults(newResults);
        setTotal(total);
        setLoadingResults(false);
        dispatch(memorizeSearchNectars({ results: newResults, total }));
        dispatch(updateFacets(facets));
      })
      .catch((error) => {
        console.error("Couldn't load nectar results.", error);
        displayToast(t("error:default"));
        setLoadingResults(false);
      });
  }

  function searchForFacets() {
    searchNectars(getParamsWithFilters({
      search: "",
      limit: 0,
      offset: 0,
      language: activeLang,
    }))
      .unwrap()
      .then(({ docs, meta: { total, facets } }) => {
        setTotal(total);
        dispatch(updateFacets(facets));
      })
      .catch((error) => {
        console.error("Couldn't search nectars to update facets.", error);
      });
  }

  function searchMore() {
    searchForResults(false);
  }

  return {
    results,
    fallback,
    total,
    isLoadingResults,
    isLoadingFallback: getNectarsListResult.isFetching,
    searchMore,
  };
}