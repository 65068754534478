import { ReactNode } from "react";
import CustomIcon from "../CustomIcon";
import styled from "styled-components";

export default function SettingButton({
  children,
  className,
  iconName,
  iconScale,
  icon,
  onClick,
  inputChild,
  rightIcon,
}: {
  children?: ReactNode;
  className?: string;
  iconName?: string;
  iconScale?: number;
  icon?: ReactNode;
  onClick?: Function;
  inputChild?: ReactNode;
  rightIcon?: ReactNode;
}) {
  return (
    <Container className={className} onClick={() => onClick && onClick()}>
      <div className="left-part">
        {iconName ? (
          <CustomIcon
            color="#000000"
            iconName={iconName}
            scale={iconScale ?? 1}
          />
        ) : icon ? (
          icon
        ) : null}
        <span>{children}</span>
      </div>
      {rightIcon || inputChild}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f9f9f9;
  padding: 12px 32px;
  box-sizing: border-box;
  width: 100%;

  .left-part {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  svg,
  svg path,
  svg circle {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
  }

  span {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    text-align: left;
    color: #212121;
  }

  &.lemon-certified-button {
    background-color: #fff3ce;
  }
`;
