import { getApiRequestService } from "../../../services/index.services";
import { GPTConversationModel, GPTMessageModel } from "../gpt.types";
import { removeDuplicateSources } from "../utils/removeDuplicateSources";

export async function getGptConversationById(conversationId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/conversation/${conversationId}`);

    const conversation: GPTConversationModel = res.data;
    conversation.messages = conversation.messages.map((message: GPTMessageModel) => {
      return removeDuplicateSources(message);
    });

    return conversation;
  } catch (error) {
    console.error("Couldn't get conversation.", error);
    throw error;
  }
};