import { getApiRequestService } from "../../../services/index.services";
import { NectarModel } from "../nectar.types";

export async function getNectarBySlug(nectarSlug: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res: { data: NectarModel } = await apiInstance.get(`/content/slug/${nectarSlug}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
