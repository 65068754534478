import { Component } from "react";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { t } from "i18next";
import FadeIn from "react-fade-in/lib/FadeIn";
import { connect } from "react-redux";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import SaveHeader from "../../components/app/headers/SaveHeader";
import Loader from "../../components/Loader";
import { userApi } from "../../redux/user/user.service";
import { patchUser } from "../../domains/user/endpoints/patchUser";
import { StyledOnboarding } from "../../components/onboarding/StyledOnboarding";
import { store } from "../../redux";
import { setUser } from "../../redux/user/user.reducer";
import { PreferredContent } from "../../interfaces";

export const FORMAT_PREFERENCES = [
  {
    key: "scientificPublications",
    value: PreferredContent.SCIENTIFIC_PUBLICATIONS,
  },
  {
    key: "medicalGuidelines",
    value: PreferredContent.MEDICAL_GUIDELINES,
  },
  {
    key: "editorialContent",
    value: PreferredContent.EDITORIAL_CONTENT,
  },
  {
    key: "videos",
    value: PreferredContent.VIDEOS
  },
  {
    key: "podcasts",
    value: PreferredContent.PODCASTS
  }
];

class EditPreferredContentPage extends Component {
  state = {
    isEdited: false,
    formats: [],
  };

  async componentDidMount() {
    const { isRedirected } = await preflightUser({ history: this.props.history, authOnly: true });
    if (isRedirected) return;

    const { user } = this.props.user;
    if (!user) this.props.getUser();

    this.setState({
      formats: user.preferredFormats,
    });
  }

  async handleSubmit() {
    try {
      const { formats } = this.state;
      const updatedUser = await patchUser({ preferredFormats: formats })
      store.dispatch(
        setUser({
          ...this.props.user.user,
          preferredFormats: updatedUser.preferredFormats,
        })
      );
      displayToast(t("toast:success.preferredContentUpdated"), "success");
      setTimeout(() => this.props.history.goBack(), 500);
    } catch (error) {
      console.error("Couldn't edit preferred content.", error);
      displayToast(t("error:default"));
    }
  }

  handleSelect(pref) {
    const { formats, isEdited } = this.state;
    if (!isEdited) this.setState({ isEdited: true });
    if (formats.includes(pref))
      this.setState({ formats: formats.filter((el) => el !== pref) });
    else this.setState({ formats: [...formats, pref] });
  }

  render() {
    const { isEdited, formats } = this.state;

    return (
      <>
        <SaveHeader
          title={t("profile:editProfile.preferredContent.title")}
          saveReady={isEdited}
          onSaveClick={this.handleSubmit.bind(this)}
        />
        {!!formats ? (
          <PageContainer>
            <h2>{t("profile:editProfile.preferredContent.heading")}</h2>
            <h5>{t("profile:editProfile.preferredContent.text")}</h5>
            <div className='selection-rows'>
              <FadeIn>
                {FORMAT_PREFERENCES.map((pref) => (
                  <div className='row' key={pref.value + "--key"}>
                    <p>{t(`settings:settingsPage.preferredContent.formats.${pref.key}`)}</p>
                    <button
                      type='button'
                      className={formats?.includes(pref.value) ? "selected" : ""}
                      onClick={() => this.handleSelect(pref.value)}
                    >
                      {t(formats?.includes(pref.value) ? "common:action.unselect" : "common:action.select")}
                    </button>
                  </div>
                ))}
              </FadeIn>
            </div>
          </PageContainer>
        ) : (
          <Loader />
        )}
      </>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}
const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(EditPreferredContentPage);

const PageContainer = styled(StyledOnboarding)`
  padding: 30px 10px;
  background: #ecf0f5;
`;
