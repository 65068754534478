import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { getGptConversationFromExternalId } from "../../domains/gpt/endpoints/getGptConversationFromExternalId";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavHome, NavLang } from "../../components/app/headers/layout";
import disableScroll from "disable-scroll";
import {
  GPTChatQuestion,
  GPTConversationWrapper,
  GPTPageLayout,
} from "../../components/gpt/layout";
import GPTNav from "../../components/gpt/GPTNav";
import GPTChatSuggestions from "../../components/gpt/chat/GPTChatSuggestions";
import GPTChatInput from "../../components/gpt/chat/GPTChatInput";
import { displayToast } from "../../components/app/AppToast";
import GPTChatAnswer from "../../components/gpt/chat/GPTChatAnswer";
import GPTChatLoader from "../../components/gpt/chat/GPTChatLoader";
import { t } from "i18next";
import { useFetchGPTStream } from "../../domains/gpt/hooks/useFetchGPTStream";
import { useTextReveal } from "../../domains/app/hooks/useTextReveal";
import styled from "styled-components";

export default function GPTNewChatPage() {
  const history = useHistory();
  const [inputQuestion, setInputQuestion] = useState("");
  const [isWaitingFullReveal, setWaitingFullReveal] = useState(false);
  const dummyScrollBottomRef = useRef<HTMLDivElement>(null);
  const stream = useFetchGPTStream();
  const textReveal = useTextReveal(stream.answer, () => {
    if (Math.floor(Math.random() * 5) === 0) {
      scrollToLastMessage(true);
    }
  });

  useEffect(() => {
    (async function () {
      disableScroll.off();

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.juisciGPT"),
      });
      if (isRedirected) return;
    })();
  }, []);

  useEffect(() => {
    if (textReveal.hasFinished() && stream.hasFullyLoaded) {
      setInputQuestion("");
      scrollToLastMessage(true);
      onAnswerRevealFinished();
    }
  }, [stream.hasFullyLoaded, textReveal.partialText]);

  useEffect(() => {
    if (stream.error) {
      displayToast(t("error:default"));
      setWaitingFullReveal(false);
    }
  }, [stream.error]);

  function scrollToLastMessage(smooth = false) {
    if (dummyScrollBottomRef.current) {
      dummyScrollBottomRef.current.scrollIntoView({ behavior: smooth ? "smooth" : "auto" });
    }
  }

  async function onAnswerRevealFinished() {
    const data = await getGptConversationFromExternalId(stream.conversationExternalId);
    history.replace({
      pathname: `/gpt/chat/conversation/${data.id}`,
      state: {
        history: stream.history,
        sources: stream.sources,
      },
    });
  }

  async function handleSubmit(question: string) {
    try {
      setWaitingFullReveal(true);
      const error = await stream.askQuestion(question);
      if (error) throw error;
    } catch (error) {
      console.error("Couldn't create conversation", error);
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        if ([
          "daily",
          "limit",
          "reach",
        ].some((term: string) => (error as Error).message.toLowerCase().includes(term))) {
          displayToast(t("juisciGpt:error.limitReached"));
        }
      } else {
        displayToast(t("error:default"));
      }
      setWaitingFullReveal(false);
    }
  };

  return (
    <GPTPageLayout>
      <HeaderBoxAutoSafe>
        <HeaderLinks><NavHome /></HeaderLinks>
        <HeaderTitle>{t("juisciGpt:chat.title")}</HeaderTitle>
        <HeaderLinks><NavLang /></HeaderLinks>
      </HeaderBoxAutoSafe>

      <div className="page-wrapper">
        <GPTConversationWrapper>
          {stream.question
            ? (
              <>
                <GPTChatQuestion>{stream.question}</GPTChatQuestion>
                {stream.isPreloading
                  ? <GPTChatLoader />
                  : stream.error ? (
                    <ChatError>{t("juisciGpt:error.default")}</ChatError>
                  ) : (
                    <GPTChatAnswer
                      isTyping={isWaitingFullReveal}
                    >
                      {textReveal.partialText}
                    </GPTChatAnswer>
                  )
                }
              </>
            )
            : <GPTChatSuggestions onSuggestionClick={handleSubmit} />
          }
          <div ref={dummyScrollBottomRef} />
        </GPTConversationWrapper>
      </div>

      <GPTChatInput
        style={{ bottom: 85 }}
        placeholder={t("juisciGpt:chat.action.askQuestion")}
        value={inputQuestion}
        onChange={(value: string) => setInputQuestion(value)}
        disabled={isWaitingFullReveal}
        onSubmit={handleSubmit}
        displayDisclaimer
      />

      {!isWaitingFullReveal && <GPTNav />}
    </GPTPageLayout>
  );
}

const ChatError = styled.p`
	margin: 0 16px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	color: #ce0868;
`;