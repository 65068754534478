import BG1 from "../../../assets/images/podcast-bg/podcast-bg-1.jpg";
import BG2 from "../../../assets/images/podcast-bg/podcast-bg-2.jpg";
import BG3 from "../../../assets/images/podcast-bg/podcast-bg-3.jpg";
import BG4 from "../../../assets/images/podcast-bg/podcast-bg-4.jpg";
import BG5 from "../../../assets/images/podcast-bg/podcast-bg-5.jpg";
import BG6 from "../../../assets/images/podcast-bg/podcast-bg-6.jpg";
import BG7 from "../../../assets/images/podcast-bg/podcast-bg-7.jpg";
import BG8 from "../../../assets/images/podcast-bg/podcast-bg-8.jpg";
import BG9 from "../../../assets/images/podcast-bg/podcast-bg-9.jpg";

export function getPodcastBackground(listIndex: number): string {
  const backgroundImages = [BG1, BG2, BG3, BG4, BG5, BG6, BG7, BG8, BG9];
  return backgroundImages[listIndex];
}
