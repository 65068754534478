import { getApiRequestService } from "../../../services/index.services";

export async function getPublicRooms() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/room/public");
    const { docs, meta } = res.data;
    return { docs, total: meta.total, meta };
  } catch (error) {
    throw error;
  }
}
