import { SpecialtyModel } from "../../domains/app/app.types";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { AnalyticsItemCategory, AnalyticsItemVariant } from "./analytics.types";
import { gaEventViewItemList } from "./analyticsEvents";

// TODO: Missing events: select item, view item, etc.

interface PlaylistItemPayload {
  playlist: PlaylistModel;
  listName: string;
}

interface PlaylistItemListPayload {
  playlists: PlaylistModel[];
  listName: string;
}

function convertPlaylistToItemData(payload: PlaylistItemPayload) {
  const { playlist, listName } = payload;
  return {
    item_id: playlist._id,
    item_name: playlist.title,
    item_brand: playlist.company?.name ?? "",
    item_category: playlist.medical_specialties?.map((spe: SpecialtyModel) => {
      return spe.uid;
    }).join(","),
    item_category2: playlist.sponsored ? AnalyticsItemCategory.SPONSORED : AnalyticsItemCategory.STANDARD,
    item_variant: AnalyticsItemVariant.PLAYLIST,
    item_list_name: listName,
  };
}

export function gaEventViewItemListPlaylist(payload: PlaylistItemListPayload) {
  gaEventViewItemList(payload.playlists.map((playlist) => {
    return convertPlaylistToItemData({
      playlist,
      listName: payload.listName,
    });
  }));
}
