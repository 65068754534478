import { store } from "../../../redux";
import { SearchCategory } from "../search.types";

export async function getTotalSearchResultsCount(): Promise<number> {
  // NOTE: Need to wait for store to update
  // before calculating total number of search results.
  return new Promise((resolve) => {
    setTimeout(() => {
      const { selectedContextTab, cache } = store.getState().search;
      if (selectedContextTab === SearchCategory.ALL) {
        let total = 0;
        for (const data of Object.values(cache)) {
          const typedData: { total: number } = data as unknown as { total: number };
          if (typedData.total) total += typedData.total;
        }
        return resolve(total);
      } else {
        return resolve(cache[selectedContextTab].total);
      }
    }, 1000);
  });
}
