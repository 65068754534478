import { getApiRequestService } from "../../../services/index.services";

export async function registerUserFirstLogin() {
  try {
    const apiInstance = await getApiRequestService();
    await apiInstance.post("/user/first-login");
  } catch (error) {
    throw error;
  }
}
