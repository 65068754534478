import styled from "styled-components";

const ItemList = styled.div`
  padding: 0 8px calc(16px + var(--safe-area-bottom));
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export default ItemList;
