import { ChangeEvent, Fragment, useContext, useEffect, useRef, useState } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { UserModel } from "../../domains/user/user.types";
import { ConversationModel, ConversationMessageModel } from "../../domains/messaging/messaging.types";
import { getUserConversations } from "../../domains/messaging/endpoints/getUserConversations";
import { getConversationData } from "../../domains/messaging/endpoints/getConversationData";
import { startConversationWithUser } from "../../domains/messaging/endpoints/startConversationWithUser";
import { addMessageToConversation } from "../../domains/messaging/endpoints/addMessageToConversation";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import Loader from "../../components/Loader";
import MessageHeader from "../../components/app/headers/MessageHeader";
import ProfilePicture from "../../components/ProfilePicture";
import TextareaAutosize from "react-textarea-autosize";
import CustomIcon from "../../components/CustomIcon";
import { MessagePageContent } from "../../components/messaging/layout";
import FadeIn from "react-fade-in";
import disableScroll from "disable-scroll";
import { gaEventUserSendMessage } from "../../tools/analytics/userAnalytics";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import { ReactComponent as MessagingCelebration } from "../../assets/images/messaging-celebration.svg";
import moment from "moment";
import "moment/locale/fr";
import { SocketContext } from "../../domains/app/contexts/socket.context";

// import {
//   deleteNotification,
//   getNotifications,
// } from "../../services/notifications.services";

export default function DirectMessagePage() {
  const history = useHistory();
  const location = useLocation<{ user?: UserModel }>();
  const params = useParams<{ id?: string }>();
  const { currentUser } = useCurrentUser();
  const [isLoading, setLoading] = useState(true);
  const [conversationId, setConversationId] = useState<string | null>(null);
  const [otherUser, setOtherUser] = useState<UserModel | null>(null);
  const [newMessageText, setNewMessageText] = useState("");
  const [messages, setMessages] = useState<ConversationMessageModel[]>([]);
  const dummyScrollBottomRef = useRef<HTMLDivElement>(null);
  const { socket } = useContext(SocketContext);

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({ history, authOnly: true });
        if (isRedirected) return;

        disableScroll.off();

        // Find conversation if ID wasn't provided
        if (!params.id && location.state.user) {
          const conversations = await getUserConversations();

          // Find existing conversation and redirect if it exists
          const existingConversation = conversations.find((conversation: ConversationModel) => {
            return conversation.participants.some((participant: UserModel) => {
              return participant._id === (location.state.user as UserModel)._id;
            })
          });
          if (existingConversation) {
            history.replace(`/profile/messages/conversation/${existingConversation.id}`);
            return;
          }
        } else if (!params.id) {
          throw new Error("No user or conversation ID was provided.")
        }

        setConversationId(params?.id as string);
        if (location.state?.user) {
          setOtherUser(location.state.user);
          setLoading(false);
        }
      } catch (error) {
        console.error("Couldn't start/load conversation.", error);
        displayToast(t("error:default"));
      }
    })();
  }, [params.id]);

  useEffect(() => {
    if (!!conversationId) loadConversation();
  }, [conversationId]);

  useEffect(() => {
    if (socket) socket.on("message/new", handleReceiveMessage);
    return () => {
      socket?.off("message/new");
    }
  }, [socket, conversationId]);

  useEffect(() => {
    setTimeout(() => scrollToLastMessage(true), 0);
  }, [messages]);

  async function loadConversation() {
    try {
      const data: ConversationModel = await getConversationData(conversationId as string);
      setMessages(data.messages);
      const otherParticipant = data.participants.find((participant: UserModel) => {
        return participant._id !== currentUser._id;
      });
      if (!otherParticipant) {
        throw new Error("Couldn't determine other participant user.")
      }
      setOtherUser(otherParticipant as UserModel);
      setLoading(false);
    } catch (error) {
      console.error("Couldn't load conversation.", error);
      displayToast(t("error:default"));
    }
  }

  //   async componentDidMount() {

  //     const queries = [getNotifications()];
  //     if (this.props.user.isUninitialized) {
  //       queries.push(this.props.getUser());
  //     }

  //     const [notifications] = await Promise.all(queries);

  //     // Mark all related notifications as read...
  //     notifications.forEach((notification) => {
  //       if (notification?.action?.notificationType === "DirectMessage")
  //         if (notification?.action?.conversation?._id === conversation_id)
  //           deleteNotification(notification?._id);
  //     });

  //     this.setState({
  //       notifications: notifications.filter((n) => !n.deleted),
  //     });

  function scrollToLastMessage(smooth = false) {
    if (dummyScrollBottomRef.current) {
      dummyScrollBottomRef.current.scrollIntoView({ behavior: smooth ? "smooth" : "auto" });
    }
  }

  async function handleSendMessage() {
    try {
      if (!newMessageText.length) return;

      if (!conversationId) {
        const data = await startConversationWithUser(otherUser?._id as string, newMessageText);
        gaEventUserSendMessage();
        setNewMessageText("");
        history.replace(`/profile/messages/conversation/${data.id}`);
      } else {
        await addMessageToConversation(conversationId, newMessageText);
        gaEventUserSendMessage();
        setNewMessageText("");
      }
    } catch (error) {
      console.error("Couldn't send message.", error);
      displayToast(t("error:default"));
    }
  }

  function handleReceiveMessage(payload: {
    conversationId: string;
    message: ConversationMessageModel;
  }) {
    if (payload.conversationId === conversationId) {
      setMessages(prevMessages => [...prevMessages, payload.message]);
    }
  }

  return (
    <>
      {isLoading
        ? <Loader />
        : (
          <LayoutContainer>
            <MessageHeader
              title={`${otherUser?.firstname} ${otherUser?.lastname}`}
              onTitleClick={() => history.push(`/profile/user/${otherUser?.uid}`)}
              customActions={
                <Link to={`/profile/user/${otherUser?.uid}`}>
                  <ProfilePicture user={otherUser as UserModel} height={32} withBorder withShadow />
                </Link>
              }
            />

            <ContentScroller>
              <ConversationContent>
                <WelcomeBox>
                  <MessagingCelebration />
                  <span className="welcome-text">
                    {t("messaging:directMessage.welcomeText")}
                    &nbsp;
                    <Link to={`/profile/user/${otherUser?.uid}`}>
                      <b>{otherUser?.firstname}&nbsp;{otherUser?.lastname}</b>
                    </Link>
                  </span>
                </WelcomeBox>

                {messages?.map((message: ConversationMessageModel, index: number) => {
                  let displayDate = true;
                  let previousIsSameUser = false;
                  let nextIsSameUser = false;
                  const previousMessage = messages[index - 1];
                  const nextMessage = messages[index + 1];
                  const date = new Date(message.createdAt);
                  if (previousMessage) {
                    const previousMessageDate = new Date(previousMessage.createdAt);
                    displayDate = date.toDateString() !== previousMessageDate.toDateString();
                    previousIsSameUser = (
                      previousMessage.sender.id == message.sender.id &&
                      date.toDateString() === previousMessageDate.toDateString()
                    );
                  }
                  if (nextMessage) {
                    const nextMessageDate = new Date(nextMessage.createdAt);
                    nextIsSameUser = (
                      nextMessage.sender.id == message.sender.id &&
                      date.toDateString() === nextMessageDate.toDateString()
                    );
                  }

                  const isMe = message?.sender?.id === currentUser?._id;

                  return (
                    <MessageEntry
                      key={index}
                      isMe={isMe}
                      message={message}
                      sender={isMe ? currentUser : otherUser}
                      displayDate={displayDate}
                      stickTop={previousIsSameUser}
                      stickBottom={nextIsSameUser}
                    />
                  );
                })}
                <div ref={dummyScrollBottomRef} />
              </ConversationContent>
            </ContentScroller>

            <MessageInputWrapper
            // isNative={isNativeIOS}
            >
              <MessageInput
                value={newMessageText}
                placeholder={t("messaging:directMessage.inputPlaceholder")}
                onInput={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  window.scroll({
                    top: 1e7,
                    left: 0,
                    behavior: "smooth",
                  });
                  setNewMessageText(e.target.value);
                }}
              />
              <CustomIcon
                iconName='send_message'
                color={newMessageText.length > 0 ? "#FFC408" : "#4C5861"}
                onClick={handleSendMessage}
              />
            </MessageInputWrapper>
          </LayoutContainer>
        )}
    </>
  );
}

//   socketConInterval = null;
//   socketConvInterval = null;

//   componentWillUnmount() {
//     const conversation_id = this.props.match.params.id;
//     this.props.socketHook.markConversationAsRead(conversation_id);
//   }

const LayoutContainer = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const ContentScroller = styled.div`
  flex: 1;
  overflow: auto;
`;

const ConversationContent = styled(MessagePageContent)`
  padding: 16px 12px;
`;

//   overflow-y: auto;
//   padding: 60px 20px;
//   font-family: "Inter";

//   h4 {
//     font-style: normal;
//     font-weight: 300;
//     font-size: 17px;
//     line-height: 100%;
//     color: #212121;
//     margin: 0;
//   }

//   h5 {
//     font-style: normal;
//     font-weight: 300;
//     font-size: 12px;
//     line-height: 15px;
//     text-align: center;
//     color: #81939c;
//     margin: 0;
//     margin-top: 6px;
//   }


const WelcomeBox = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  .welcome-text {
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-6, #4C5861);
    text-align: center;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    line-height: 15px; /* 125% */

    b {
      font-weight: 600;
    }

    a {
      color: #212121;
    }
  }
`;

const MessageInputWrapper = styled.div`
  padding: 20px 10px 25px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  background: #dfe6ec;
`;
/* padding-bottom: ${(props) => props.isNative && "40px"}; */

const MessageInput = styled(TextareaAutosize)`
  box-sizing: border-box;
  resize: none;
  width: 100%;
  max-height: 128px;
  margin: 0;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  overflow-y: scroll;
  font-weight: 300;
  font-family: Inter;
  font-size: 16px;
`;

function MessageEntry({ isMe, message, sender, displayDate, stickTop, stickBottom }: {
  isMe: boolean;
  message: ConversationMessageModel;
  sender: UserModel;
  displayDate: boolean;
  stickTop: boolean;
  stickBottom: boolean;
}) {
  return (
    <FadeIn
      onComplete={() => {
        window.scroll({
          top: 100000000,
          left: 0,
          behavior: "smooth",
        });
      }}
    >
      <div style={{
        display: "flex",
        alignItems: "flex-end",
        gap: 12,
      }}>
        {!isMe && (
          !stickBottom
            ? (
              <Link to={`/profile/user/${sender?.uid}`}>
                <ProfilePicture user={sender} height={30} />
              </Link>
            ) : <div style={{ width: 30 }}></div>
        )}
        <div style={{
          maxWidth: 220,
          marginLeft: isMe ? "auto" : 0,
          marginRight: isMe ? 0 : "auto",
        }}>
          {displayDate && <DateText>{moment(new Date(message.createdAt)).calendar()}</DateText>}
          <MessageBox style={{
            background: isMe ? "#FFC408" : "#D2DCE2",
            marginTop: (stickTop || stickBottom) ? 1 : displayDate ? 10 : 20,
            borderTopRightRadius: (stickTop && isMe) ? 4 : 12,
            borderBottomRightRadius: (stickBottom && isMe) ? 4 : 12,
            borderTopLeftRadius: (stickTop && !isMe) ? 4 : 12,
            borderBottomLeftRadius: (stickBottom && !isMe) ? 4 : 12,
          }}>
            {message.content.split("\n").map((entry: string, index: number) => {
              return (
                <Fragment key={index}><span>{entry}</span><br /></Fragment>
              );
            })}
          </MessageBox>
        </div>
      </div>
    </FadeIn>
  );
};

// TODO: adjacent messages have different margin and radius
const MessageBox = styled.div`
  padding: 10px;
  border-radius: 12px;
  /* border-radius: 12px 12px 4px 12px; */
  color: var(--BRAND-CORE-Juisci-Black, #212121);
  font-family: Inter;
  font-size: 12px;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.24px;
`;
// margin-top: 2px;
// width: auto;
// max-width: 50%;

// margin-left: ${(props) => props.isMe && "auto"};
// margin-right: ${(props) => !props.isMe && "auto"};

// display: table;

const DateText = styled.span`
  display: block;
  margin: 25px 0 10px;
  color: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 300;
  line-height: 15px; /* 125% */
  text-transform: capitalize;
`;
