import { Component } from "react";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import styled from "styled-components";
import { Spacer } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import disableScroll from "disable-scroll";
import { getPlaylistById } from "../../domains/playlist/endpoints/getPlaylistById";
import { renamePlaylist } from "../../domains/playlist/endpoints/renamePlaylist";
import { deletePlaylist } from "../../domains/playlist/endpoints/deletePlaylist";
import { sharePlaylist } from "../../domains/playlist/endpoints/sharePlaylist";
import { removeArticleFromPlaylist } from "../../domains/playlist/endpoints/removeArticleFromPlaylist";
import { removeContentFromPlaylist } from "../../domains/playlist/endpoints/removeContentFromPlaylist";
import { Row, Col } from "react-grid-system";
import CustomIcon from "../../components/CustomIcon";
import Loader from "../../components/Loader";
import { displayToast } from "../../components/app/AppToast";
import { connect } from "react-redux";
import { userApi } from "../../redux/user/user.service";
import i18n from "../../config/i18n";
import BottomPanel from "../../components/app/panels/BottomPanel";
import PreviewCard from "../../components/profile/PreviewCard";
import ProfileHeader from "../../components/app/headers/ProfileHeader";
import ShareBtn from "../../assets/icons/share-btn.svg";
import SaveHeader from "../../components/app/headers/SaveHeader";
import { t } from "i18next";
import { SupportedLanguage } from "../../interfaces";
import ShareSheet from "../../components/ShareSheet";
import { shareUrl } from "../../domains/app/utils/shareUrl";

class PlaylistPage extends Component {
  state = {
    playlist: undefined,
    showSheet: false,
    editMode: false,
  };

  async componentDidMount() {
    const playlistId = this.props.match.params.id;
    disableScroll.off();
    window.scrollTo(0, 0);

    const { isRedirected } = await preflightUser({ history: this.props.history, authOnly: true });
    if (isRedirected) return;

    // Get playlist informations
    getPlaylistById(playlistId)
      .then((playlist) => this.setState({ playlist }))
      .catch((error) => {
        displayToast(t("error:default"));
        console.error("Couldn't load playlist.", error);
        this.props.history.replace("/profile");
      });
  }

  componentDidUpdate(_prevProps, prevState) {
    const { userLoaded, showSheet } = this.state;

    // ???
    if (!!this.state.playlist && userLoaded) disableScroll.off();

    if (showSheet !== prevState.showSheet) {
      if (showSheet) {
        disableScroll.on();
      } else {
        disableScroll.off();
      }
    }
  }

  async deleteFromPlaylist(content) {
    const playlistId = this.props.match.params.id;

    if (
      content.content_format === "Video" ||
      content.content_format === "Nectar"
    )
      await removeContentFromPlaylist(playlistId, content._id);
    else await removeArticleFromPlaylist(playlistId, content.slug);

    this.setState({
      playlist: {
        ...this.state.playlist,
        playlistContent: this.state.playlist.playlistContent.filter(
          (el) => el._id !== content._id
        ),
      },
    });

    this.props.getPlaylists();
    getPlaylistById(playlistId).then((playlist) => {
      this.setState({ playlist });
    });

    displayToast(
      t(
        content.content_format === "Video"
          ? "toast:success.videoUnsaved"
          : content.content_format === "Nectar"
            ? "toast:success.nectarUnsaved"
            : "toast:success.articleUnsaved"
      ),
      "success"
    );
  }

  async handleSharePlaylist() {
    try {
      const { title } = this.state.playlist;
      const playlistId = this.props.match.params.id;
      const my_uid = this.props.user?.user?.uid;

      const { isRedirected } = await preflightUser({
        history: this.props.history,
        onboardingMessage: t("error:notOnboarded.sharePlaylist"),
      });
      if (isRedirected) return;

      await sharePlaylist(playlistId);

      // TODO: Localize.
      const text = i18n.resolvedLanguage === SupportedLanguage.FR
        ? `Découvrez ma playlist ${title} sur Juisci !`
        : `Discover my playlist ${title} on Juisci now!`;
      const url = `${window.location.origin}/profile/playlist/${playlistId}?shared=${my_uid ?? "null"
        }`;
      await shareUrl(document.title, text, url);

      displayToast(t("toast:success:playlistShared"), "success");
    } catch (error) {
      console.error("Couldn't share playlist.", error);
      displayToast(t("error:default"));
    }
  }

  async handleSubmit() {
    try {
      const playlist_id = this.props.match.params.id;
      const isMe = this.state.playlist?.user?._id === this.props?.user?.user?._id;

      if (isMe) {
        await renamePlaylist(playlist_id, this.state.playlist.title);
        await this.props.getPlaylists();
        displayToast(t("toast:success.playlistNameUpdated"), "success");
        this.setState({ editMode: false });
      }
    } catch (error) {
      console.error("Couldn't rename playlist.", error);
      displayToast(t("error:default"));
    }
  }

  handleDeletePlaylist() {
    const playlist_id = this.props.match.params.id;
    const isMe = this.state.playlist?.user?._id === this.props?.user?.user?._id;

    if (isMe) {
      this.setState({ isDeleting: true });
      deletePlaylist(playlist_id).then(() =>
        this.props.getPlaylists().then(() => {
          this.props.history.replace("/profile?mainTab=1");
          displayToast(t("toast:success.playlistDeleted"), "success");
        })
      );
    }
  }

  render() {
    const { showSheet, editMode, playlist } = this.state;

    if (!playlist) return <Loader />;

    const playlistContent = [
      ...this.state.playlist.playlist,
      ...this.state.playlist.playlistContent,
    ];

    const isMe = playlist?.user?._id === this.props?.user?.user?._id;

    return (
      <Container>
        {editMode ? (
          <SaveHeader
            title={t("profile:playlist.title")}
            saveReady={true}
            onSaveClick={this.handleSubmit.bind(this)}
          />
        ) : (
          <ProfileHeader user={isMe ? this.props.user.user : this.state.user} />
        )}
        <PageContent>
          {editMode ? (
            <CustomInput
              value={playlist.title}
              onInput={(e) =>
                this.setState({
                  playlist: {
                    ...playlist,
                    title: e.target.value,
                  },
                })
              }
              placeholder={t("profile:playlist.namePlaceholder")}
            />
          ) : (
            <PageHeader>
              <div className='col'>
                <CustomIcon iconName='playlist' />
                <h3>{this.state.playlist.title}</h3>
              </div>
              <div onClick={() => this.setState({ showSheet: true })}>
                <img src={ShareBtn} alt='' />
              </div>
            </PageHeader>
          )}

          {!editMode && playlistContent.length === 0 ? (
            <p style={{ margin: "16px auto", display: "block" }}>
              {t("profile:playlist.helpTextSaveContent")}
            </p>
          ) : (
            playlistContent?.map((content) => {
              return (
                <PreviewCard
                  key={content._id + "--profile-card-key"}
                  disabled={!isMe}
                  content={{ ...content }}
                  onRemove={() => this.deleteFromPlaylist(content)}
                />
              );
            })
          )}
        </PageContent>

        <ShareSheet type='playlist' />

        <BottomPanel
          isOpen={showSheet}
          onClose={() => this.setState({ showSheet: false })}
        >
          <SheetWrapper>
            {/* EDIT */}
            {isMe && (
              <Row
                style={{ margin: 0, opacity: this.state.isDeleting ? 0.5 : 1 }}
                gutterWidth={45}
                onClick={() =>
                  !this.state.isDeleting &&
                  this.setState({ editMode: true, showSheet: false })
                }
              >
                <Col xs={1}>
                  <CustomIcon iconName='edit_pen' color='#212121' />
                </Col>
                <Col xs={10}>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 600,
                      margin: 0,
                      marginTop: -5,
                    }}
                  >
                    {t("common:panel.playlist.label.edit")}
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {t("common:panel.playlist.text.edit")}
                  </p>
                </Col>
              </Row>
            )}

            <Spacer />

            {/* SHARE */}
            <Row
              style={{ margin: 0, opacity: this.state.isDeleting ? 0.5 : 1 }}
              gutterWidth={45}
              onClick={() =>
                !this.state.isDeleting && this.handleSharePlaylist()
              }
            >
              <Col xs={1}>
                <CustomIcon iconName='share-alt' scale={0.8} color='#212121' />
              </Col>
              <Col xs={10}>
                <p
                  style={{
                    fontSize: "17px",
                    fontWeight: 600,
                    margin: 0,
                    marginTop: -5,
                  }}
                >
                  {t("common:panel.playlist.label.share")}
                </p>
                <p
                  style={{
                    fontSize: "15px",
                    fontWeight: 400,
                    margin: 0,
                  }}
                >
                  {t("common:panel.playlist.text.share")}
                </p>
              </Col>
            </Row>

            <Spacer />

            {/* DELETE */}
            {isMe && (
              <Row
                style={{ margin: 0, opacity: this.state.isDeleting ? 0.5 : 1 }}
                gutterWidth={45}
                onClick={this.handleDeletePlaylist.bind(this)}
              >
                <Col xs={1}>
                  <CustomIcon iconName='trash' color='#212121' increase />
                </Col>
                <Col xs={10}>
                  <p
                    style={{
                      fontSize: "17px",
                      fontWeight: 600,
                      margin: 0,
                      marginTop: -5,
                    }}
                    className={this.state.isDeleting ? "loading" : ""}
                  >
                    {t("common:panel.playlist.label.delete")}
                  </p>
                  <p
                    style={{
                      fontSize: "15px",
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {t("common:panel.playlist.text.delete")}
                  </p>
                </Col>
              </Row>
            )}
          </SheetWrapper>
        </BottomPanel>
      </Container>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}
const mapDispatch = {
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(PlaylistPage);

const Container = styled.div`
min - height: 100dvh;
background: #F7F8FC;
`;

const PageContent = styled.div`
padding: 40px 20px 50px 20px;
`;

const PageHeader = styled.div`
display: flex;
align - items: start;
justify - content: space - between;
margin - bottom: 16px;
gap: 16px;

  .col {
  display: flex;
  align - items: start;
  gap: 16px;

    .--custom - icon {
    transform: translateY(5px)!important;
  }

    h3 {
    font - family: "Inter";
    font - style: normal;
    font - weight: 800;
    font - size: 20px;
    line - height: 110 %;
    letter - spacing: -0.00017em;
    color: #212121;
    margin: 0;
  }
}
`;

const SheetWrapper = styled.div`
padding: 55px 5px;
min - height: ${window.innerHeight / 3} px;
`;
