import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { JournalModel } from "./journal.types";

interface ReducerState {
  cache: {
    list: JournalModel[];
  };
}

const initialState: ReducerState = {
  cache: {
    list: [],
  },
};

export const slice = createSlice({
  name: "journals",
  initialState,
  reducers: {
    memorizeJournals: (state, action: PayloadAction<JournalModel[]>) => {
      state.cache.list = action.payload;
    },
  },
});

export const {
  memorizeJournals,
} = slice.actions;

export default slice.reducer;
