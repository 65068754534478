import { useCallback, useEffect, useRef, useState } from "react";

export function useCountdown(
  waitTime: number,
  onEnd: Function,
  loop?: boolean,
) {
  const [timeLeft, setTimeLeft] = useState(waitTime * 10);
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const start = useCallback(() => {
    if (intervalRef.current) return; // Prevent multiple intervals

    intervalRef.current = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 0) {
          stop();
          return 0;
        }
        return prevTime - 1;
      });
    }, 100);
  }, [onEnd]);

  useEffect(() => {
    if (timeLeft === 0) {
      onEnd();
      if (loop) {
        reset();
        start();
      }
    }
  }, [timeLeft])

  const stop = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const reset = useCallback(() => {
    stop();
    setTimeLeft(waitTime * 10);
  }, [waitTime, stop]);

  useEffect(() => {
    return () => stop();
  }, [stop]);

  return {
    timeLeft,
    timeLeftSec: timeLeft / 10,
    isRunning: !!intervalRef.current,
    start,
    stop,
    reset,
  };
}
