import { Suspense, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import theme from "./styles/theme";
import "./App.css";
import SplashPage from "./view/static/SplashPage";
import { Redirector } from "react-router-redirect";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { isUserAuthentified } from "./domains/user/utils/isUserAuthentified";
import { auth, initFirebaseCloudMessaging } from "./services/firebase";
import {
  iosGetSafeArea,
  iosRequestPushAuthorization,
  isNativeIOS,
} from "./tools/ios";
import { ThemeProvider } from "@mui/material";
import { getCurrentUser } from "./domains/user/endpoints/getCurrentUser";
import { gaEventPageView, gaInitializeTagManager } from "./tools/analytics/analyticsEvents";
import { useAppSelector } from "./redux";
import CustomRedirector from "./components/CustomRedirector";
import { SearchHistoryObserver } from "./domains/search/hooks/useSearchHistoryObserver";
import { AppToaster } from "./components/app/AppToast";
import { SocketProvider } from "./domains/app/contexts/socket.context";
import { useCurrentUser } from "./domains/user/hooks/useCurrentUser";
import { isAndroid, isIOS, isMobile } from "react-device-detect";
import AppRoutes from "./AppRoutes";
import BrowserPage from "./BrowserPage";
import { useLazyGetMedicalSpecialtiesQuery } from "./domains/specialty/endpoints/getMedicalSpecialties";
import { useLazyGetTagsQuery } from "./domains/specialty/endpoints/getTags";
import { RoomProvider } from "./domains/app/contexts/room.context";
import { LangProvider } from "./domains/app/contexts/lang.context";
import Loader from "./components/Loader";
import { clearStoredContentFilters } from "./domains/app/utils/contentFilters";

export default function App() {
  const specialties = useAppSelector((state) => state.specialties);
  const [isAppLoading, setAppLoading] = useState(true);
  const [getMedicalSpecialties] = useLazyGetMedicalSpecialtiesQuery();
  const [getTags] = useLazyGetTagsQuery();
  const isOnMobile = isMobile || (!isMobile && (isAndroid || isNativeIOS || isIOS));

  useEffect(() => {
    (async function () {
      const isAuthentified = await isUserAuthentified();
      if (isAuthentified) {
        try {
          await getCurrentUser();
        } catch (error) {
          console.error("Couldn't get authentified user data.", error);
          auth.signOut();
        }

        initFirebaseCloudMessaging();
      }

      if (specialties.isUninitialized) {
        getMedicalSpecialties();
        getTags();
      }

      resetArticleViews(); // If new day, reset article views

      if (isNativeIOS) {
        iosGetSafeArea(); // Getting iOS Device safeAreaTop
        iosRequestPushAuthorization(); // Request iOS notifications
      }

      // Google Analytics
      if (window.location.host.includes("juisci.com")) {
        gaInitializeTagManager();
      }

      clearStoredContentFilters();
    })();
  }, []);

  function resetArticleViews() {
    const viewNumber = new Date(
      Date.parse(localStorage.getItem("_nb_views_tm") ?? "")
    );
    const now = new Date();

    const _nb_views_exp =
      (now.getTime() - viewNumber.getTime()) / (1000 * 60 * 60 * 24) > 1;

    if (_nb_views_exp) localStorage.setItem("numberViews", "0");
  }

  if (!isOnMobile) {
    return (
      <Suspense fallback={<Loader />}>
        <LangProvider>
          <BrowserPage />;
        </LangProvider>
      </Suspense>
    );
  }

  // NOTE: Global splash screen already displayed on root page.
  if (isAppLoading && window.location.pathname !== "/") {
    return <SplashPage onComplete={() => setAppLoading(false)} />;
  }

  return (
    <>
      <AppToaster />
      <ThemeProvider theme={theme}>
        <Suspense fallback={<Loader />}>
          <Router>
            <SearchHistoryObserver />
            {/* NOTE: Replace with hook once this component becomes a functional component */}
            <PageViewerHook />
            <RoomProvider>
              <LangProvider>
                <SocketProvider>
                  <Redirector />
                  <CustomRedirector />
                  <AppRoutes onSplashComplete={() => setAppLoading(false)} />
                </SocketProvider>
              </LangProvider>
            </RoomProvider>
          </Router>
        </Suspense>
      </ThemeProvider>
    </>
  );
}

function PageViewerHook() {
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const roomOrganisationUid = useAppSelector((state) => state.user.cache.currentRoom.data?.organisation?.uid);
  const memPathname = useRef(location.pathname);

  // Trigger page view analytics event for most routes
  useEffect(() => {
    if (location.pathname !== memPathname.current) {
      let shouldTrigger = true;

      if (location.pathname === "/profile" && !currentUser.uid) {
        shouldTrigger = false;
      }

      const isDelayNeeded = isRoomTransitioning(location.pathname) && isRoomTransitioning(memPathname.current);
      if (isDelayNeeded) {
        shouldTrigger = false;
      }

      if (shouldTrigger) gaEventPageView();

      memPathname.current = location.pathname;
    }
  }, [location.pathname]);

  // NOTE: When entering/leaving a room, we need to wait until the room is loaded
  // before triggering the page view event.
  useEffect(() => {
    if (isRoomTransitioning(location.pathname)) {
      gaEventPageView();
    }
  }, [roomOrganisationUid]);

  function isRoomTransitioning(pathname: string) {
    return [
      "/discovery",
      "/room/discovery",
      "/room/audio/home",
    ].some((element) => pathname.startsWith(element));
  }

  return null;
}
