import { PlaylistModel } from "../../domains/playlist/playlist.types";
import HomeCarousel from "../home/home-blocks/HomeCarousel";
import CarouselIndicator from "../app/CarouselIndicator";
import PlaylistCard from "../playlists/PlaylistCard";
import Loader from "../Loader";
import styled from "styled-components";

interface PlaylistCarouselProps {
  title: string;
  linkTo: string;
  playlists: PlaylistModel[];
  backgroundColor?: string;
}

export default function PlaylistCarousel({
  title,
  linkTo,
  playlists,
  backgroundColor = "#FFF",
}: PlaylistCarouselProps) {
  return (
    <Container
      className="section-content primary padding"
      style={{ background: backgroundColor }}
    >
      <TopWrapper>
        <h3>{title}</h3>
        {/* <Link to={linkTo}>
          <span>{t("common:action.seeAll")}</span>
        </Link> */}
      </TopWrapper>
      {!playlists?.length ? (
        <Loader loaderOnly />
      ) : (
        <HomeCarousel
          centerMode={true}
          centerSlidePercentage={100}
          renderIndicator={(_onClickHandler: any, isSelected: boolean) => {
            return <CarouselIndicator active={isSelected} />;
          }}
        >
          {playlists.slice(0, 5).map((playlist) => (
            <SlideWrapper key={playlist._id}>
              <PlaylistCard playlist={playlist} />
            </SlideWrapper>
          ))}
        </HomeCarousel>
      )}
    </Container>
  );
}

const Container = styled.div`
  padding-bottom: 10px;
  .slider-wrapper {
    box-sizing: border-box;
    padding: 0 5px;
  }
`;

const SlideWrapper = styled.div`
  flex: 1;
  display: flex;

  .playlist-card {
    flex: 1;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21px 8px;

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;

    span {
      color: #ce0868;
    }
  }

  a {
    font-family: "Inter";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;
