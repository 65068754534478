import styled from "styled-components";
import Lottie from "lottie-react";
import BlenderOpenAnimation from "../../assets/animations/open.json";
import FadeIn from "react-fade-in/lib/FadeIn";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import { useLangContext } from "../../domains/app/contexts/lang.context";

export default function ForYouBlenderEmpty() {
  const { t } = useLangContext();

  return (
    <Container>
      <Lottie
        animationData={BlenderOpenAnimation}
        loop={true}
        autoplay={true}
      />
      <FlavorText className="big">
        <Trans t={t} i18nKey="forYou:blenderEmpty.title" components={{ span: <span className="highlight" /> }} />
      </FlavorText>
      <FlavorText>{
        <Trans t={t} i18nKey="forYou:blenderEmpty.text" components={{ b: <b /> }} />
      }</FlavorText>
      <CtaLink to="/discovery"><span>{t("Discovery")}</span></CtaLink>
    </Container>
  );
}

const Container = styled(FadeIn)`
  flex: 1;
  padding: calc(16px + var(--safe-area-top)) 44px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const FlavorText = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.002px;

  &.big {
    font-size: 25px;
    font-weight: 800;
    line-height: 115%; /* 28.75px */
  }

  .highlight {
    color: var(--secondarytangy-raspberry-1, #CE0868);
  }
`;

const CtaLink = styled(Link)`
  padding: 16px 60px;
  border-radius: 400px;
  background: var(--BRAND-CORE-Juisci-Yellow, #FFC408);
  color: var(--BRAND-CORE-Juisci-Black, #212121);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: -0.07px;
`;
