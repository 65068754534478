import { SpecialtyModel } from "../../domains/app/app.types";
import { ArticleModel } from "../../domains/article/article.types";
import { AnalyticsItemCategory, AnalyticsItemVariant } from "./analytics.types";
import { gaEventSelectItem, gaEventViewItem, gaEventViewItemList } from "./analyticsEvents";
import { gaPushEvent } from "./gaPushEvent";

enum Event {
  ARTICLE_LIKE = "article_like",
  ARTICLE_SAVE = "article_save",
  ARTICLE_SHARE = "article_share",
  AUDIO_PLAYED = "audio_played",
  AUDIO_PROGRESS = "audio_progress",
  AUDIO_COMPLETE = "audio_complete",
  VIEW_ORIGINAL_PAPER = "view_original_paper",
}

interface ArticleItemPayload {
  article: ArticleModel;
  listName: string;
  percentage?: number,
}

interface ArticleItemListPayload {
  articles: ArticleModel[];
  listName: string;
}

function convertArticleToItemData(payload: ArticleItemPayload) {
  const { article, listName } = payload;
  return {
    item_id: article._id,
    item_name: article.title,
    item_brand: article.journal?.name ?? "",
    item_category: article.medical_specialties?.map((spe: SpecialtyModel) => {
      return spe.uid;
    }).join(","),
    item_category2: AnalyticsItemCategory.STANDARD,
    item_variant: AnalyticsItemVariant.ARTICLE,
    item_list_name: listName,
  };
}

export function gaEventSelectItemArticle(payload: ArticleItemPayload) {
  gaEventSelectItem(convertArticleToItemData(payload));
}

export function gaEventViewItemArticle(payload: ArticleItemPayload) {
  gaEventViewItem(convertArticleToItemData(payload));
}

export function gaEventViewItemListArticle(payload: ArticleItemListPayload) {
  gaEventViewItemList(payload.articles.map((article) => {
    return convertArticleToItemData({
      article,
      listName: payload.listName,
    });
  }));
}

export function gaEventArticlePlayAudio(payload: ArticleItemPayload) {
  gaPushEvent(
    Event.AUDIO_PLAYED,
    {
      ecommerce: {
        items: [convertArticleToItemData(payload)],
      },
    },
  );
}

export function gaEventArticleAudioProgress(payload: ArticleItemPayload) {
  gaPushEvent(
    Event.AUDIO_PROGRESS,
    {
      percentage: payload.percentage,
      ecommerce: {
        items: [convertArticleToItemData(payload)],
      },
    },
  );
}

export function gaEventArticleAudioComplete(payload: ArticleItemPayload) {
  gaPushEvent(
    Event.AUDIO_COMPLETE,
    {
      ecommerce: {
        items: [convertArticleToItemData(payload)],
      },
    },
  );
}

export function gaEventArticleViewOriginalPaper(payload: ArticleItemPayload) {
  gaPushEvent(
    Event.VIEW_ORIGINAL_PAPER,
    {
      ecommerce: {
        items: [convertArticleToItemData(payload)],
      },
    },
  );
}

export function gaEventArticleLike(payload: ArticleItemPayload) {
  gaPushEvent(
    Event.ARTICLE_LIKE,
    {
      ecommerce: {
        items: [convertArticleToItemData(payload)],
      },
    },
  );
}

export function gaEventArticleSave(payload: ArticleItemPayload) {
  gaPushEvent(
    Event.ARTICLE_SAVE,
    {
      ecommerce: {
        items: [convertArticleToItemData(payload)],
      },
    },
  );
}

export function gaEventArticleShare(payload: ArticleItemPayload) {
  gaPushEvent(
    Event.ARTICLE_SHARE,
    {
      ecommerce: {
        items: [convertArticleToItemData(payload)],
      },
    },
  );
}
