import { atom, getDefaultStore, useAtom } from "jotai";
import { ArticleModel } from "../article.types";
import { DebugLogger } from "../../app/utils/debugLogger";

// Handles various article cache lists.
// Needs to be centralized to refresh articles in all caches after user actions.

const debug = new DebugLogger("Article Cache");
debug.mute();

function updateArticleInList(list: ArticleModel[], article: ArticleModel) {
  const slugs = list.map((article: ArticleModel) => article.slug);
  const index = slugs.findIndex((slug: string) => slug === article.slug);
  if (index !== -1) {
    debug.log("Update article in list", article.slug);

    const copyList = [...list];
    copyList.splice(index, 1, { ...article });

    return copyList;
  }
  return list;
}

export enum ArticleCacheList {
  ALL_SPECIALITIES = "allSpecialties",
  MAIN_SPECIALITY = "mainSpecialty",
  SECONDARY_SPECIALITY = "secondarySpecialty",
  ROOM = "room",
  // Temporary caches (see other hooks using them)
  SWIPE = "swipe",
  CONTENT_LIST = "content_list",
}

interface CacheLists {
  [ArticleCacheList.ALL_SPECIALITIES]: ArticleModel[];
  [ArticleCacheList.MAIN_SPECIALITY]: ArticleModel[];
  [ArticleCacheList.SECONDARY_SPECIALITY]: ArticleModel[];
  [ArticleCacheList.ROOM]: ArticleModel[];
  [ArticleCacheList.SWIPE]: ArticleModel[];
  [ArticleCacheList.CONTENT_LIST]: ArticleModel[];
}

const defaultCacheLists: CacheLists = {
  [ArticleCacheList.ALL_SPECIALITIES]: [],
  [ArticleCacheList.MAIN_SPECIALITY]: [],
  [ArticleCacheList.SECONDARY_SPECIALITY]: [],
  [ArticleCacheList.ROOM]: [],
  [ArticleCacheList.SWIPE]: [],
  [ArticleCacheList.CONTENT_LIST]: [],
};

const cacheListsAtom = atom<CacheLists>(defaultCacheLists);

export function useArticleCache() {
  const [cacheLists, setCacheLists] = useAtom(cacheListsAtom);

  function saveList(listType: ArticleCacheList, docs: ArticleModel[]) {
    debug.log("Save article list", { listType, docs });
    setCacheLists((lists) => ({ ...lists, [listType]: docs }));
  }

  function updateArticleInAllCaches(article: ArticleModel) {
    debug.log("Refresh article in all cache lists", article.slug);
    for (const key of Object.keys(cacheLists)) {
      const newList = updateArticleInList(cacheLists[key as ArticleCacheList], article);
      setCacheLists({ ...cacheLists, [key]: newList });
    }
  }

  return {
    cacheLists,
    saveList,
    // TODO: add getter/setter functions for each kind (see playlist cache).
    updateArticleInAllCaches,
    clearAllCaches: () => setCacheLists(defaultCacheLists),
  }
}

// NOTE: Temporary. Remove once the unique call from a class component is removed.
const store = getDefaultStore();
export const articleCacheStore = {
  saveList: (listType: ArticleCacheList, docs: ArticleModel[]) => {
    debug.log("Save article list", { listType, docs });
    store.set(cacheListsAtom, { ...store.get(cacheListsAtom), [listType]: docs });
  },
};
