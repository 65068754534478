import { Component } from "react";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import styled from "styled-components";
import { CustomButton } from "../../components/global";
import SaveHeader from "../../components/app/headers/SaveHeader";
import { t } from "i18next";
import { unfollowJournal } from "../../domains/journal/endpoints/unfollowJournal";
import { displayToast } from "../../components/app/AppToast";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loader from "../../components/Loader";

class ProfileJournals extends Component {
  state = {
    journals: [], // INFO: Contains journals '_id' to Unfollow
  };

  async componentDidMount() {
    window.scroll(0, 0);

    const { isRedirected } = await preflightUser({
      history: this.props.history,
      onboardingMessage: t("error:notOnboarded.default"),
    });
    if (isRedirected) return;
  }

  handleFollow = ({ _id }) => {
    if (this.state.journals.includes(_id))
      this.setState({
        journals: [...this.state.journals.filter((el) => el !== _id)],
      });
    else
      this.setState({
        journals: [...this.state.journals, _id],
      });
  };

  handleSubmit = async () => {
    try {
      const { journals } = this.state;

      for (const journalId of journals) {
        await unfollowJournal(journalId);
      }
      await this.props.getUser();

      displayToast(t("toast:success.profileUpdated"));
      this.props.history.goBack();
    } catch (error) {
      console.error("Couldn't edit journals.", error);
      displayToast(t("error:default"));
    }
  };

  render() {
    if (!this.props.location.state || !this.props.location.state.journals) return <Loader />;

    return (
      <Container>
        <SaveHeader
          title={t("profile:journal.title")}
          saveReady={true}
          onSaveClick={this.handleSubmit.bind(this)}
        />
        <PageContent>
          {!!this.props.location.state.journals &&
            this.props.location.state.journals?.map((journal) => (
              <JournalProfile
                onClick={() => this.props.history.push("/journal/" + journal.uid)}
                onUnfollow={() => this.handleFollow(journal)}
                active={!this.state.journals.includes(journal._id)}
                journal={journal}
                key={journal?._id}
              />
            ))}
        </PageContent>
      </Container>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(withRouter(ProfileJournals));

const JournalProfile = ({ journal, active, onUnfollow, onClick }) =>
  journal && (
    <div className={`--journal-profile-card`}>
      <div className='title-img' onClick={onClick}>
        <img src={journal?.image?.url} />
        <div className='title'>{journal?.name}</div>
      </div>
      <div className='btn'>
        <CustomButton
          onClick={onUnfollow}
          className={`xsmall ${active ? "active" : ""}`}
        >
          {t(!active ? "common:action.follow" : "common:action.unfollow")}
        </CustomButton>
      </div>
    </div>
  );


const Container = styled.div`
  min-height: 100dvh;
  background: #f7f8fc;
`;

const PageContent = styled.div`
  padding: 40px 20px 50px 20px;

  .--journal-profile-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* gap: 24px; */
    padding: 24px 16px;
    margin: 8px 0px;
    overflow: hidden;

    background: linear-gradient(
        180deg,
        rgba(210, 220, 226, 0) 52.08%,
        rgba(210, 220, 226, 0.5) 100%
      ),
      #ffffff;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    border-radius: 15px;

    .btn {
      width: max-content;
    }

    .title-img {
      display: flex;
      align-items: center;
      gap: 16px;

      img {
        max-width: 64px;
        max-height: 64px;
        object-fit: contain;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 120%;
        color: #212121;
        width: 100px;
      }
    }
  }
`;
