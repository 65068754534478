import { isAndroid } from "react-device-detect";
import { androidShare } from "../../../tools/android";
import { displayToast } from "../../../components/app/AppToast";
import { t } from "i18next";
import { iosPlaySound } from "../../../tools/ios";
import { SoundEffect } from "../../../interfaces";

export async function shareUrl(title: string, text: string, url: string): Promise<void> {
  try {
    if (isAndroid) {
      iosPlaySound(SoundEffect.SHARE);
      return androidShare(url, text);
    }

    if (!navigator.share) {
      displayToast(t("toast:fail.shareNoBrowserSupport"));
      return;
    }

    await navigator.share({
      title,
      text,
      url,
    });
    iosPlaySound(SoundEffect.SHARE);
  } catch (error) {
    console.error("Couldn't share playlist.", error);
    displayToast(t("error:default"));
  }
}
