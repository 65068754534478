import { ReactNode } from "react";
import SettingButton from "./SettingButton";
import CustomSwitch from "../CustomSwitch";

export default function SettingButtonSwitch({
  checked,
  children,
  iconName,
  iconScale,
  icon,
  onClick
}: {
  checked: boolean;
  children?: ReactNode;
  iconName?: string;
  iconScale?: number;
  icon?: ReactNode;
  onClick?: Function;
}) {
  return (
    <SettingButton
      iconName={iconName}
      iconScale={iconScale}
      icon={icon}
      onClick={onClick}
      inputChild={<CustomSwitch checked={checked} />}
    >
      {children}
    </SettingButton>
  );
};
