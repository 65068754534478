import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../domains/user/hooks/useCurrentUser";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useReadyState } from "../../../domains/app/hooks/useReadyState";
import { SpecialtyModel } from "../../../domains/app/app.types";
import { ArticleModel } from "../../../domains/article/article.types";
import { useLazyGetArticlesListQuery } from "../../../domains/article/endpoints/getArticlesList";
import { ArticleCacheList, useArticleCache } from "../../../domains/article/hooks/useArticleCache";
import { useArticleSwipe } from "../../../domains/article/hooks/useArticleSwipe";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import HomeCarousel from "./HomeCarousel";
import CarouselIndicator from "../../app/CarouselIndicator";
import { CustomButton } from "../../global";
import ArticleCard from "../../cards/ArticleCard";
import CustomIcon from "../../CustomIcon";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { ReactComponent as PublicationIcon } from "../../../assets/icons/home_publication_icon.svg";
import styled from "styled-components";
import SkeletonCard from "../../skeleton/SkeletonCard";

export default function HomeArticleCarousel({
  specialty,
  isMainSpecialty = false,
}: {
  specialty: SpecialtyModel;
  isMainSpecialty?: boolean;
}) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { activeLang, t } = useLangContext();
  const { isInRoom } = useRoomContext();
  const articleCache = useArticleCache();
  const { setSwipeCache } = useArticleSwipe();
  const [getArticlesList] = useLazyGetArticlesListQuery();
  const { setAsReady, useReadyEffect } = useReadyState();
  const [isLoading, setLoading] = useState(false);
  // NOTE: Used for testing purposes. Not connected to store.
  const [localArticles, setLocalArticles] = useState<ArticleModel[] | []>([]);
  // Derived
  const articles: ArticleModel[] = !specialty
    ? articleCache.cacheLists[ArticleCacheList.ALL_SPECIALITIES]
    : specialty?.uid === currentUser?.main_specialty?.uid
      ? articleCache.cacheLists[ArticleCacheList.MAIN_SPECIALITY]
      : specialty?.uid === currentUser.medical_specialties[0].uid
        ? articleCache.cacheLists[ArticleCacheList.SECONDARY_SPECIALITY]
        : localArticles;

  useEffect(() => {
    (async function () {
      if (!isInRoom) {
        if (articles.length === 0) await loadArticles();
        setAsReady();
      }
    })();
  }, [isInRoom]);

  useReadyEffect(() => {
    loadArticles();
  }, [activeLang, specialty]);

  async function loadArticles() {
    try {
      setLoading(true);
      const queryParams: any = {
        limit: 20,
        offset: 0,
        language: activeLang,
      };
      if (specialty) queryParams.medicalSpecialties = specialty._id;

      await getArticlesList(queryParams)
        .unwrap()
        .then(({ docs }) => {
          setLocalArticles(docs);
          refreshCache(docs);
        })
        .catch((error) => {
          throw error;
        });

      setLoading(false);
    } catch (error) {
      console.error("Couldn't load carousel articles.", error);
    }
  }

  function refreshCache(docs: ArticleModel[]) {
    if (!specialty) {
      articleCache.saveList(ArticleCacheList.ALL_SPECIALITIES, docs);
    }
    if (!!currentUser) {
      if (specialty?.uid === currentUser?.main_specialty?.uid) {
        articleCache.saveList(ArticleCacheList.MAIN_SPECIALITY, docs);
      }
      if (specialty?.uid === currentUser?.medical_specialties[0]?.uid) {
        articleCache.saveList(ArticleCacheList.SECONDARY_SPECIALITY, docs);
      }
    }
  }

  return (
    <Wrapper className={` ${isMainSpecialty ? " primary" : ""}`}>
      <Header>
        <div className='title'>
          <PublicationIcon />
          <h3>
            {isMainSpecialty
              ? t("home:articles.freshlySqueezed") + " "
              : t("home:articles.whatsNew") + " "}
            <br />
            <span>{specialty ? getItemTranslation(specialty) : t("common:allSpecialties")}</span>
          </h3>
        </div>
        <Link
          to={
            specialty
              ? {
                pathname: `/discovery/publications/${specialty.uid}`,
                state: { specialty },
              }
              : "/discovery/publications"
          }
        >
          <span>{t("common:action.seeAll")}</span>
        </Link>
      </Header>

      {(!articles.length && isLoading) ? (
        <SkeletonCard />
      ) : (
        <HomeCarousel
          renderIndicator={(_onClickHandler: any, isSelected: boolean) => {
            return <CarouselIndicator active={isSelected} />;
          }}
        >
          {articles.slice(0, 5).map((article, index) => {
            return (
              <ArticleCard
                key={article._id}
                article={article}
                variant='yellow'
                analyticsListName={
                  isMainSpecialty
                    ? AnalyticsItemList.DISCOVERY_MAIN_CAROUSEL
                    : AnalyticsItemList.DISCOVERY_SECOND_CAROUSEL
                }
                onClick={() => {
                  setSwipeCache(articles, index);
                }}
              />
            );
          })}
          <SeeMoreWrapper
            onClick={() =>
              history.push(
                specialty
                  ? {
                    pathname: `/discovery/publications/${specialty.uid}`,
                    state: { specialty },
                  }
                  : "/discovery/publications"
              )
            }
          >
            <CustomIcon iconName='invite_down_arrow' />
            <p>
              {t("home:articles.checkAllPublicationsIn")}{" "}
              <span>{specialty ? getItemTranslation(specialty) : t("common:allSpecialties")}</span>
            </p>
            <CustomButton className='pink'>{t("common:action.letsGo")}</CustomButton>
          </SeeMoreWrapper>
        </HomeCarousel>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding-bottom: 16px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21px 8px 21px;
  .title {
    display: flex;
    gap: 6px;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;
  }
  span {
    color: #ce0868;
  }
  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const SeeMoreWrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  background: #f7f6f2;
  border-radius: 15px;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
`;
