import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { LemonCertifiedRequestStatus } from "../../domains/user/user.types";
import { getUserCertificationStatus } from "../../domains/user/endpoints/getUserCertificationStatus";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import disableScroll from "disable-scroll";
import { useUserLogout } from "../../domains/user/hooks/useUserLogout";
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import ProfileHeader from "../../components/app/headers/ProfileHeader";
import Loader from "../../components/Loader";
import ProfileInfo from "../../components/settings/ProfileInfo";
import SettingButton from "../../components/settings/SettingButton";
import SettingButtonLink from "../../components/settings/SettingButtonLink";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as CertificationStatusPendingIcon } from "../../assets/icons/certification-status-pending.svg";
import { ReactComponent as LemonCertifiedIcon } from "../../assets/icons/lemon-certified.svg";
import { ReactComponent as LemonCertifiedApprovedIcon } from "../../assets/icons/lemon-certified-approved.svg";
import { ReactComponent as EditProfileIcon } from "../../assets/icons/pencil.svg";
import { ReactComponent as PreferredContentTypesIcon } from "../../assets/icons/favorite.svg";
import { ReactComponent as PasswordAndSecurityIcon } from "../../assets/icons/settings.svg";
import { ReactComponent as LanguageInterfaceIcon } from "../../assets/icons/language.svg";
import { ReactComponent as TermsOfUseIcon } from "../../assets/icons/check-circle.svg";
import { ReactComponent as PrivacyPolicyIcon } from "../../assets/icons/lock.svg";
import { ReactComponent as ManageAccountIcon } from "../../assets/icons/user-circle.svg";

export default function SettingsPage() {
  const history = useHistory();
  const logout = useUserLogout();
  const { currentUser } = useCurrentUser();
  const [certifiedStatus, setCertifiedStatus] =
    useState<LemonCertifiedRequestStatus | null>(null);
  const [isLoading, setLoading] = useState(true);
  const [isLoggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    (async function () {
      window.scrollTo(0, 0);
      disableScroll.off();

      if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");

      const { isRedirected } = await preflightUser({ history, authOnly: true });
      if (isRedirected) return;

      if (currentUser.lemonCertified) {
        setCertifiedStatus(LemonCertifiedRequestStatus.Approved);
      } else {
        const { status } = await getUserCertificationStatus();
        setCertifiedStatus(status ?? null);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <>
      <ProfileHeader user={currentUser} title={t("settings:settingsPage.title")} />
      {isLoading ? (
        <Loader />
      ) : (
        <Container>
          <HeadingContainer>
            <h2>{t("profile:profilePage.section.profile")}</h2>
            <Link to="/settings/edit/profile">
              <ProfileInfo />
            </Link>
          </HeadingContainer>

          <LinksContainer>
            {certifiedStatus === null && (
              <SettingButtonLink
                className="lemon-certified-button"
                icon={<LemonCertifiedIcon />}
                to="/settings/request/certification"
              >
                {t("settings:settingsPage.certificationOptions.default")}
              </SettingButtonLink>
            )}

            {certifiedStatus === LemonCertifiedRequestStatus.Approved && (
              <SettingButton
                className="lemon-certified-button"
                icon={<LemonCertifiedApprovedIcon />}
              >
                <span style={{ color: "#FF8800" }}>
                  {t("settings:settingsPage.certificationOptions.approved")}
                </span>
              </SettingButton>
            )}

            {certifiedStatus === LemonCertifiedRequestStatus.Waiting && (
              <SettingButton
                className="lemon-certified-button"
                icon={<LemonCertifiedIcon />}
                rightIcon={<CertificationStatusPendingIcon />}
              >
                <span style={{ color: "#FF8800" }}>
                  {t("settings:settingsPage.certificationOptions.waiting")}
                </span>
              </SettingButton>
            )}
            {/* 
            // NOTE: Not used anymore but needs to be checked. When a user's certification is removed, their status goes back to "Waiting" instead of the default or "Rejected".
            {certifiedStatus === LemonCertifiedRequestStatus.Rejected && (
              <SettingButton
                className="certified-rejected"
                icon={<CertificationStatusPendingIcon />}
              >
                {t("settings:settingsPage.certificationOptions.rejected")}
              </SettingButton>
            )} */}

            <SettingButtonLink
              icon={<EditProfileIcon />}
              to="/settings/edit/profile"
            >
              {t("settings:settingsPage.options.editProfile")}
            </SettingButtonLink>

            <SettingButtonLink
              icon={<PreferredContentTypesIcon />}
              to="/settings/edit/profile/preferredContent"
            >
              {t("settings:settingsPage.options.preferredContent")}
            </SettingButtonLink>

            <HeadingContainer>
              <h2>{t("profile:profilePage.section.settings")}</h2>
            </HeadingContainer>

            <SettingButtonLink
              icon={<PasswordAndSecurityIcon />}
              to="/settings/edit/security"
            >
              {t("settings:settingsPage.options.passwordSecurity")}
            </SettingButtonLink>

            <SettingButtonLink
              icon={<LanguageInterfaceIcon />}
              to="/settings/edit/language"
            >
              {t("settings:settingsPage.options.editLanguage")}
            </SettingButtonLink>

            <SettingButtonLink
              icon={<TermsOfUseIcon />}
              to={{ pathname: `/terms`, state: { isPush: true } }}
            >
              {t("settings:settingsPage.options.terms")}
            </SettingButtonLink>

            <SettingButtonLink
              icon={<PrivacyPolicyIcon />}
              to={{ pathname: `/policy`, state: { isPush: true } }}
            >
              {t("settings:settingsPage.options.policy")}
            </SettingButtonLink>

            <SettingButtonLink
              icon={<ManageAccountIcon />}
              to="/settings/account"
            >
              {t("settings:settingsPage.options.manageAccount")}
            </SettingButtonLink>
        
            <LogoutLink
              className={isLoggingOut ? "logging-out" : ""}
              onClick={() => {
                setLoggingOut(true);
                logout();
              }}
            >
              {t("common:action.logout")}
            </LogoutLink>
          </LinksContainer>
        </Container>
      )}
    </>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  background: #ffffff;
  min-height: 100dvh;

  h2 {
    margin: 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.025em;
  }
`;

const HeadingContainer = styled.div`
  padding: 30px 30px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const LogoutLink = styled.span`
  margin: 36px 0px;
  color: #ff4300;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: center;

  &.logging-out {
    color: #81939c;
  }
`;
