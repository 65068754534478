import { useState } from "react";
import { ArticleModel } from "../../article/article.types";
import { askGptQuestionStream } from "../endpoints/askGptQuestionStream";
import { createGptConversationStream } from "../endpoints/createGptConversationStream";

export function useFetchGPTStream() {
	const [isPreloading, setPreloading] = useState(false);
	const [hasFullyLoaded, setFullyLoaded] = useState(false);
	const [conversationExternalId, setConversationExternalId] = useState("");
	const [answer, setAnswer] = useState("");
	const [question, setQuestion] = useState("");
	const [sources, setSources] = useState<ArticleModel[]>([]);
	const [history, setHistory] = useState([]);
	const [error, setError] = useState<unknown | null>(null);

	async function askQuestion(question: string, conversationId?: string) {
		setQuestion(question);
		setPreloading(true);
		setFullyLoaded(false);
		setAnswer("");
		setSources([]);
		setHistory([]);
		setError(null);
		try {
			const reader = conversationId
				? await askGptQuestionStream(conversationId, question)
				: await createGptConversationStream(question);

			if (reader) readStream(reader);
			else throw new Error("No stream reader was returned.");
			return null;
		} catch (error) {
			console.error("Couldn't load stream reader.", error);
			reset();
			return error;
		}
	}

	function reset() {
		setPreloading(false);
		setFullyLoaded(false);
		setQuestion("");
		setAnswer("");
		setSources([]);
		setHistory([]);
		setError(null);
	}

	async function readStream(reader: ReadableStreamDefaultReader) {
		let isReading = true;
		let streamString = "";
		const trackData = {
			conversationExternalId: "",
			answer: "",
			sources: [],
			history,
		};

		while (isReading) {
			try {
				if (reader) {
					const { done, value } = await reader.read();

					// When no more data needs to be consumed, close the stream
					if (done) {
						isReading = false;
						setConversationExternalId(trackData.conversationExternalId);
						setPreloading(false);
						setAnswer(trackData.answer);
						setFullyLoaded(true);
						break;
					}

					const decoder = new TextDecoder("utf-8");
					const decoded = decoder.decode(value, { stream: true });
					streamString += decoded;
					const chunks = streamString.split("\n\n");

					let json;
					try {
						json = JSON.parse(chunks.at(-1) as string);
					} catch (error) {
						try {
							json = JSON.parse(chunks.at(-2) as string);
						} catch (error) { }
					}

					if (json?.conversation_id) {
						trackData.conversationExternalId = json.conversation_id;
						setConversationExternalId(json.conversation_id);
					}
					if (json?.query) {
						setQuestion(json.query);
					}
					if (json?.answer) {
						trackData.answer = json.answer;
						setPreloading(false);
						setAnswer(json.answer);
					}
					if (json?.source_documents) {
						trackData.sources = json.source_documents.map((doc: { metadata: { title: string; source: string } }) => {
							const source: any = {
								title: doc.metadata.title,
								slug: doc.metadata.source.split("/").at(-1) as string,
							}
							return source;
						});
						setSources(trackData.sources);
					}
					if (json?.history) {
						trackData.history = json.history;
						setHistory(json.history);
					}
				} else {
					throw new Error("No reader");
				}
			} catch (error) {
				console.error("Stream reading interrupted.", error);
				isReading = false;
				setPreloading(false);
				setError(error);
				setFullyLoaded(true);
				break;
			}
		}
	}

	return {
		askQuestion,
		reset,
		isPreloading,
		hasFullyLoaded,
		conversationExternalId,
		question,
		answer,
		sources,
		history,
		error,
	};
}