import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { firebaseLogout } from "../../../services/firebase";
import { androidSignOut } from "../../../tools/android";
import { iosAuthSignOut, iosDeleteCookies, isNativeIOS } from "../../../tools/ios";
import { unregisterFirebaseToken } from "../../notification/endpoints/unregisterFirebaseToken";
import { logoutUserBackend } from "../endpoints/logoutUserBackend";
import { updateActiveSession } from "../endpoints/updateActiveSession";
import { useArticleCache } from "../../article/hooks/useArticleCache";
import { useVideoCache } from "../../video/hooks/useVideoCache";
import { usePlaylistCache } from "../../playlist/hooks/usePlaylistCache";
import { setUser } from "../../../redux/user/user.reducer";
import { clearStoredContentFilters } from "../../app/utils/contentFilters";
// TODO: Add more caches to clear.

export function useUserLogout() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { clearAllCaches: clearArticleCache } = useArticleCache();
  const { clearAllCaches: clearPlaylistCache } = usePlaylistCache();
  const { clearCache: clearVideoCache } = useVideoCache();

  return async function () {
    if (isNativeIOS) {
      // TODO: missing catch.
      unregisterFirebaseToken(localStorage.getItem("ios_fb_token") as string);
      localStorage.removeItem("ios_fb_token");
    } else {
      // TODO: missing catch.
      unregisterFirebaseToken(localStorage.getItem("fb_token") as string);
      localStorage.removeItem("fb_token");
    }

    await updateActiveSession(false);
    await logoutUserBackend();

    firebaseLogout();
    iosAuthSignOut();
    androidSignOut();
    iosDeleteCookies(); // Deleting cookies on iOS for non-auth-persistance

    dispatch(setUser(false));

    localStorage.removeItem("authRedirectPath");
    localStorage.removeItem("ios_signin_callback");
    localStorage.removeItem("android_signin_callback");
    localStorage.removeItem("currentRoomId");
    clearStoredContentFilters();

    clearArticleCache();
    clearPlaylistCache();
    clearVideoCache();

    history.push("/login");
  };
}
