import { useEffect, useRef, useState } from "react";
import { store, useAppDispatch } from "../../../redux";
import { useLangContext } from "../../app/contexts/lang.context";
import { usePlaylistCache } from "../../playlist/hooks/usePlaylistCache";
import { usePublicPlaylistsWithCache } from "../../playlist/endpoints/getPublicPlaylists";
import { useLazySearchPlaylistsQuery } from "../endpoints/searchPlaylists";
import { memorizeSearchPlaylists, updateFacets } from "../search.reducer";
import { displayToast } from "../../../components/app/AppToast";

export function useSearchPlaylists(searchValue: string) {
  // Store
  const dispatch = useAppDispatch();
  const { activeLang, t } = useLangContext();
  const { publicPlaylists } = usePlaylistCache();
  // State
  const [results, setResults] = useState([]);
  const [total, setTotal] = useState(0);
  const [isLoadingResults, setLoadingResults] = useState(publicPlaylists.length === 0);
  const [fallback, setFallback] = useState(publicPlaylists);
  const [isLoadingFallback, setLoadingFallback] = useState(false);
  // Query
  const getPublicPlaylists = usePublicPlaylistsWithCache();
  const [searchPlaylists] = useLazySearchPlaylistsQuery();
  // Custom
  const mountRef = useRef(false);
  const [isMounted, setMounted] = [!!mountRef.current, () => mountRef.current = true];

  useEffect(() => {
    // NOTE: Usual selector not reliable enough, using store state instead.
    const cache = store.getState().search.cache;
    if (!isMounted) {
      if (cache.playlists.results.length) {
        if (searchValue.length) {
          setResults(cache.playlists.results);
          setTotal(cache.playlists.total);
        } else {
          setFallback(cache.playlists.results);
        }
      } else if (searchValue.length) {
        searchForResults(true);
      } else {
        loadFallback();
        searchForFacets();
      }
      setMounted();
    }
  }, []);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length) {
        searchForResults(true);
      } else {
        setResults([]);
        setTotal(0);
        if (!fallback.length) loadFallback();
      }
    }
  }, [searchValue]);

  useEffect(() => {
    if (isMounted) {
      if (searchValue.length) {
        searchForResults(true)
      } else {
        setFallback([]);
        setTimeout(() => {
          loadFallback();
        }, 0);
      }
    }
  }, [activeLang]);

  function getParamsWithFilters(params: any) {
    // NOTE: No filters for playlists. Change this part if filters are needed.
    return params;
  }

  async function loadFallback() {
    try {
      const { docs } = await getPublicPlaylists({
        limit: 20,
        offset: 0,
        language: activeLang,
      });
      const results = docs;
      setFallback(results);
      dispatch(memorizeSearchPlaylists({ results, total: 20 }));
      setLoadingFallback(false);
    } catch (error) {
      console.error("Couldn't load fallback playlists.", error);
      displayToast(t("error:default"));
      setLoadingResults(false);
    }
  }

  function searchForResults(resetResults: boolean) {
    if (resetResults) setLoadingResults(true);

    const params: any = getParamsWithFilters({
      search: searchValue,
      limit: 20,
      offset: resetResults ? 0 : results.length,
      language: activeLang,
    });

    searchPlaylists(params)
      .unwrap()
      .then(({ docs, meta: { total, facets } }) => {
        const newResults = resetResults ? docs : [...results, ...docs];
        setResults(newResults);
        setTotal(total);
        setLoadingResults(false);
        dispatch(memorizeSearchPlaylists({ results: newResults, total }));
        dispatch(updateFacets(facets));
      })
      .catch((error) => {
        console.error("Couldn't load playlist results.", error);
        displayToast(t("error:default"));
        setLoadingResults(false);
      });
  }

  function searchForFacets() {
    // NOTE: No filters for playlists. Change this part if filters are needed.
  }

  function searchMore() {
    searchForResults(false);
  }

  return {
    results,
    fallback,
    total,
    isLoadingResults,
    isLoadingFallback,
    searchMore,
  };
}