export class DebugLogger {
  name: string;
  private enabled: boolean;

  constructor(name?: string) {
    this.name = name ?? "Debug";
    this.enabled = true;
  }

  mute() {
    this.enabled = false;
  }

  unmute() {
    this.enabled = true;
  }

  log(...args: any[]) {
    this.displayTextInConsole(console.log, ...args);
  }

  warn(...args: any[]) {
    this.displayTextInConsole(console.warn, ...args);
  }

  error(...args: any[]) {
    this.displayTextInConsole(console.error, ...args);
  }

  private displayTextInConsole(func: Function, ...args: any[]) {
    if (this.enabled) {
      const isDebugMode = process.env.NODE_ENV === "development" || window.location.host.startsWith("dev");
      if (isDebugMode) func(`[${this.name}]`, ...args);
    }
  }
}
