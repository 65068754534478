import { getApiRequestService } from "../../../services/index.services";

export async function addArticleToPlaylist(playlistId: string, slug: any) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlistId}/article`, {
      slug,
    });
    return res.data;
  } catch (error) {
    console.error("Couldn't add article to playlist.", error);
    throw error;
  }
};