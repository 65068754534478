import { useEffect } from "react";
import { defaultAvatarImages } from "../../domains/image/utils/pickUserImage";
import styled from "styled-components";

export default function DefaultAvatarSelector({
  selected,
  onChange,
  disabled = false,
}: {
  selected: string;
  onChange: Function;
  disabled?: boolean;
}) {
  useEffect(() => {
    // Refresh on external change
    if (Object.keys(defaultAvatarImages).includes(selected)) {
      handleSelect(selected, true);
    }
  }, [selected]);

  async function handleSelect(slug: string, force = false) {
    if (slug !== selected || force) {
      onChange && onChange(slug);
    }
  }

  return (
    <Grid>
      {Object.entries(defaultAvatarImages).map(([slug, imgSet]) => {
        return (
          <SampleAvatar
            key={slug}
            className={selected === slug ? "selected" : ""}
            src={imgSet[disabled ? "disabled" : "default"]}
            alt=''
            onClick={() => !disabled && handleSelect(slug)}
          />
        );
      })}
    </Grid>
  );
}

const Grid = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 25px;
`;

const SampleAvatar = styled.img`
  box-sizing: border-box;
  margin: 0 auto;
  height: 81px;
  width: 81px;
  border-radius: 50%;
  border: 3px solid transparent;

  &.selected {
    border-color: #212121;
  }
`;
