import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

// TODO: Check if works and if necessary.

const CustomRedirector = () => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const authorizedPathsFromForYou = [
      "/foryou",
      "/post",
      "/profile",
      "/company",
      "/login",
    ];

    if (
      !!localStorage.getItem("foryou_carousel_index") &&
      !authorizedPathsFromForYou.some((path) =>
        location.pathname.includes(path)
      )
    )
      console.log("CUSTOM REDIRECTOR ATTEMPTED TO REDIRECT BACK AT FOR YOU");

    // history.replace("/foryou");
  }, [location]);

  return null;
};

export default CustomRedirector;
