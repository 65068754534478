import styled from "styled-components";
import { ReactComponent as SearchFilterIcon } from "../../assets/icons/filter-icon.svg";

export default function ContentFilterButton({ active, onClick }: {
  active: boolean;
  onClick?: Function;
}) {
  return (
    <HomeContentFilterWrapper className="filter-icon-wrapper">
      <StyledFilterIcon className={`filter-icon ${active ? "active" : ""}`} onClick={() => onClick && onClick()} />
    </HomeContentFilterWrapper>
  );
}

const HomeContentFilterWrapper = styled.div`
  margin: 16px 8px 10px;
  padding-right: 16px;
  text-align: right;
`;

const StyledFilterIcon = styled(SearchFilterIcon)`
  &.active {
    path {
      fill: #CE0868;
    }
  }
`;
