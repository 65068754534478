import { useEffect, useRef, useState } from "react";
import { useOnScreen } from "../../domains/app/hooks/useOnScreen";
import { useAnimationFrame } from "../../domains/app/hooks/useAnimationFrame";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { t } from "i18next";
import MasonryLayoutHorz from "../MasonryLayoutHorz";
import { CustomButton } from "../global";
import { iosGenerateHaptic } from "../../tools/ios";
import { HapticEffect } from "../../interfaces";
import { ReactComponent as NectarDropIcon } from "../../assets/icons/nectar-drop-green.svg";
import { Skeleton } from "@mui/material";

export default function NectarScroller({
  nectars,
  seeAllPath,
  isLoading,
  allLoaded,
  onNectarClick,
  onScrollNearEnd,
}: {
  nectars: NectarModel[];
  seeAllPath?: string;
  isLoading: boolean;
  allLoaded: boolean;
  onNectarClick?: Function;
  onScrollNearEnd?: Function;
}) {
  const [isAutoScrollOn, setAutoScrollOn] = useState(false);
  const autoScrollOffset = useRef(0); // NOTE: Mutable variable without rerender.
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const isScrollerOnScreen = useOnScreen(scrollRef, true);
  const scrollAnimationFrame = useAnimationFrame(autoScroll);

  useEffect(() => {
    let autoScrollStartTimeout: ReturnType<typeof setTimeout>;
    if (isScrollerOnScreen) {
      autoScrollStartTimeout = setTimeout(() => {
        if (!isAutoScrollOn && isScrollerOnScreen) {
          setAutoScrollOn(true);
        }
      }, 200);
    } else {
      setAutoScrollOn(false);
    }
    return () => clearTimeout(autoScrollStartTimeout);
  }, [isScrollerOnScreen]);

  // Autoscroll hook (animation frame)
  useEffect(() => {
    scrollAnimationFrame.play();
    return () => scrollAnimationFrame.stop();
  }, [isAutoScrollOn]);

  function autoScroll() {
    if (scrollRef.current && isAutoScrollOn && isScrollerOnScreen) {
      if (autoScrollOffset.current < scrollRef.current.scrollWidth) {
        autoScrollOffset.current += 0.25;
        scrollRef.current.scrollLeft = autoScrollOffset.current;
      }
    }
  }

  function handleScroll(e: any) {
    const { clientWidth, scrollWidth, scrollLeft } = e.target;

    // Update current scroll offset (animation frame)
    autoScrollOffset.current = scrollLeft;

    const remainingSpaceRight = scrollWidth - scrollLeft - clientWidth;
    if (allLoaded) {
      if (remainingSpaceRight === 0) {
        setTimeout(() => {
          autoScrollOffset.current = 0;
        }, 1000);
      }
    } else {
      if (remainingSpaceRight <= 2 * clientWidth) {
        onScrollNearEnd && onScrollNearEnd();
      }
    }
  }

  if (isLoading)
    return (
      <LoadingWrapper>
        <Header className="nectar-scroller-header">
          <NectarDropIcon className="drop-icon" />
          <span className="heading">{t("content:type.nectarsOfScience")}</span>
          {seeAllPath && (
            <Link to={seeAllPath} className="see-all">
              <span>{t("common:action.seeAll")}</span>
            </Link>
          )}
        </Header>
        <div className="flex-row">
          <Skeleton variant="rounded" width={"10%"} height={24} />
          <Skeleton variant="rounded" width={"85%"} height={24} />
          <Skeleton variant="rounded" width={"35%"} height={24} />
          <Skeleton variant="rounded" width={"60%"} height={24} />
          <Skeleton variant="rounded" width={"70%"} height={24} />
          <Skeleton variant="rounded" width={"25%"} height={24} />
        </div>
      </LoadingWrapper>
    );

  if (!nectars?.length) return null;

  return (
    <Container className="nectar-scroller-container">
      <Header className="nectar-scroller-header">
        <NectarDropIcon className="drop-icon" />
        <span className="heading">{t("content:type.nectarsOfScience")}</span>
        {seeAllPath && (
          <Link to={seeAllPath} className="see-all">
            <span>{t("common:action.seeAll")}</span>
          </Link>
        )}
      </Header>
      <ScrollContent
        ref={scrollRef}
        className="nectar-scroller-content scrollbar-hidden"
        onScroll={handleScroll}
      >
        <MasonryLayoutHorz rowCount={4}>
          {nectars.map((nectar: NectarModel, index: number) => {
            return (
              <Link
                key={nectar._id}
                to={`/nectar/${nectar.slug}`}
                className="nectar-scroller-nectar-link"
                onClick={() => {
                  iosGenerateHaptic(HapticEffect.SELECTION);
                  onNectarClick && onNectarClick(index);
                }}
              >
                {nectar.question}
              </Link>
            );
          })}
        </MasonryLayoutHorz>
        {seeAllPath && (
          <div className="see-all-card">
            <p>{t("content:nectarPage.checkOutAll")}</p>
            <Link to={seeAllPath}>
              <CustomButton className="pink">{t("common:action.letsGo")}</CustomButton>
            </Link>
          </div>
        )}
      </ScrollContent>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* background: linear-gradient(#ff7fbd, #ff8800); */
`;

const LoadingWrapper = styled.div`
  padding: 16px 0;

  .flex-row {
    padding: 24px 8px 12px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 8px;
  }
`;

const Header = styled.div`
  box-sizing: border-box;
  padding: 8px 21px 0px;
  display: flex;
  align-items: center;
  gap: 8px;

  .drop-icon {
    width: 24px;
    height: 24px;
    margin-bottom: -2px;
  }

  .heading {
    flex: 1;
    color: var(--BRAND-CORE-Juisci-Black, #212121);
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
  }

  a.see-all {
    color: var(--secondarytangy-raspberry-1, #ce0868);
    text-align: right;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
  }
`;

const ScrollContent = styled.div`
  padding: 0 22px;
  overflow-x: scroll;
  display: flex;
  gap: 8px;

  .masonry-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .masonry-row {
    display: flex;
    width: 100%;
    gap: 8px;
  }

  .masonry-cell,
  .masonry-filler {
    min-width: 22px !important;
    width: max-content;
    min-height: 14px;
    padding: 8px 10px;
    border-radius: 2px;
    background: var(--SECONDARY-SOFT-Soft-Yellow-Grey, #f7f6f2);
  }

  .masonry-cell {
    animation: fadeIn 0.5s ease-in-out;
  }

  .masonry-cell:last-child {
    flex: 1;
  }

  .masonry-filler {
    opacity: 0.5;
    animation: glow 2s ease-in-out infinite;
  }

  a {
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-6, #4c5861);
    font-family: Roboto;
    font-size: 12px;
    line-height: 100%; /* 12px */
    letter-spacing: 0.48px;
  }

  @keyframes glow {
    0% {
      opacity: 0.35;
    }
    50% {
      opacity: 0.5;
    }
    100% {
      opacity: 0.35;
    }
  }

  .see-all-card {
    min-width: 240px;
    flex: 1;
    padding: 22px;
    border-radius: 15px;
    box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
    text-align: center;
    background: #ffffff;
    opacity: 0;
    animation: fadeIn 0.25s 0.5s forwards;

    p {
      margin: 16px auto;
      line-height: 120%;
      font-weight: 700;
      font-size: 16px;
      color: #212121;
    }
  }
`;
