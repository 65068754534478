import { createSlice } from "@reduxjs/toolkit";
import { SpecialtyModel, TagModel } from "../../domains/app/app.types";
import { getMedicalSpecialties } from "./endpoints/getMedicalSpecialties";
import { getTags } from "./endpoints/getTags";

export interface ReducerState {
  medical_specialties: SpecialtyModel[];
  tags: TagModel[];
  isUninitialized: boolean;
}

const initialState: ReducerState = {
  medical_specialties: [],
  tags: [],
  isUninitialized: true,
};

export const slice = createSlice({
  name: "specialties",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        getMedicalSpecialties.matchFulfilled,
        (state, { payload }) => {
          state.medical_specialties = payload;
          state.isUninitialized = false;
        }
      )
      .addMatcher(
        getTags.matchFulfilled,
        (state, { payload }) => {
          state.tags = payload;
          state.isUninitialized = false;
        }
      );
  },
});

export default slice.reducer;
