import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import ContentMarkdown from "../../components/ContentMarkdown";
import disableScroll from "disable-scroll";
import { displayToast } from "../../components/app/AppToast";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { getNectarBySlug } from "../../domains/nectar/endpoints/getNectarBySlug";
import styled from "styled-components";
import SpecialtyBadges from "../../components/app/SpecialtyBadges";
import { GPTBottomScroller, GPTPageLayout, GPTSourceBadge } from "../../components/gpt/layout";
import GPTNav from "../../components/gpt/GPTNav";
import { ReactComponent as CloseIcon } from "../../assets/icons/gpt/nectar-close.svg";
import { ReactComponent as SourcesIcon } from "../../assets/icons/gpt/nectar-sources-icon.svg";
import { shortenText } from "../../tools/utils";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import { t } from "i18next";

export default function GPTNectarPage() {
	const history = useHistory();
	const params = useParams<{ slug: string }>();
	const [isLoading, setLoading] = useState(true);
	const [nectar, setNectar] = useState<NectarModel | null>(null);

	useEffect(() => {
		(async function () {
			try {
				disableScroll.off(); // Ensable Scroll

				const { isRedirected } = await preflightUser({
					history,
					onboardingMessage: t("error:notOnboarded.juisciGPT"),
				});
				if (isRedirected) return;

				setNectar(await getNectarBySlug(params.slug));
				setLoading(false);
			} catch (error) {
				console.error("Couldn't mount.", error);
				displayToast(t("error:default"));
				history.replace("/gpt/discover");
			}
		})()
	}, []);

	return (
		<GPTPageLayout>
			<HeaderBoxAutoSafe>
				<HeaderLinks><NavBack to="/gpt/discover" /></HeaderLinks>
				<HeaderTitle>{t("juisciGpt:discover.title")}</HeaderTitle>
				<HeaderLinks />
			</HeaderBoxAutoSafe>

			<div className="page-wrapper">
				{(!isLoading && nectar) &&
					<>
						<NectarSummary>
							<div className="summary-header">
								<SpecialtyBadges specialties={nectar.medical_specialties?.slice(0, 1) ?? []} />
								<button aria-roledescription="Close" onClick={() => history.goBack()}><CloseIcon /></button>
							</div>

							<div className="summary-body">
								<div className="scrollbar-hidden" style={{ overflow: "auto" }}>
									<span className="question">{nectar.question}</span>
									<ContentMarkdown
										className="answer"
										children={nectar.answer}
									/>
								</div>
							</div>
						</NectarSummary>

						<SourcesScroller>
							<div className="heading">
								<SourcesIcon />
								{t("common:sources")}
							</div>
							<GPTBottomScroller className="scrollbar-hidden">
								<div className="contents">
									{nectar.associatedArticles?.map((content: { title: string; slug: string }, index: number) => {
										return (
											<GPTSourceBadge key={index} onClick={() => history.push(`/post/${content.slug}`)}>
												{shortenText(content.title, 40)}
											</GPTSourceBadge>
										);
									})}
								</div>
							</GPTBottomScroller>
						</SourcesScroller>
					</>
				}
			</div>

			<GPTNav />
		</GPTPageLayout >
	);
}

const NectarSummary = styled.div`
    position: relative;
    flex: 1;
    margin: 20px 15px;
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #FFC408;
    animation: fadeIn ease-in 0.1s;

    .summary-header {
        display: flex;
        padding: 12px 21px 21px;

        button {
            position: absolute;
            top: 10px;
            right: 10px;
            padding: 0;
            background: none;
            border: none;

            svg {
                fill: #4C5861;
            }
        }
    }

    .summary-body {
        display: flex;
        flex-direction: column;
        gap: 13px;
        padding: 0 32px 32px;
        overflow-y: hidden;

        .question {
            font-family: Inter;
            font-size: 21px;
            font-weight: 600;
        }
    
        .answer {
						overflow-y: auto;
            line-height: 19px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 500;

						a {
							text-decoration: underline;
							color: #4C5861;
						}
        }
    }
`;

const SourcesScroller = styled.div`
    margin-bottom: 0;
    padding: 16px 0 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background-color: #FFFFFFF2;

    .heading {
				padding: 0 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        text-transform: uppercase;
        font-family: Inter;
        font-size: 10px;
        font-weight: 500;
        line-height: 7px;
    }
`;
