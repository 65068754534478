import { getApiRequestService } from "../../../services/index.services";

export async function getJournalByUid(journalUid: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/journal/${journalUid.toLowerCase()}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
