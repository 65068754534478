import { API_URL } from "../../../config";
import i18n from "../../../config/i18n";
import { getDefaultHttpHeaders } from "../../../services/index.services";

export async function createGptConversationStream(question: string) {
  try {
    const params = {
      question,
      language: i18n.resolvedLanguage,
    };

    const res = await fetch(`${API_URL}/conversation/stream`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: await getDefaultHttpHeaders(),
    });

    if (!res.body) throw new Error("No response body.");

    if (![200, 201].includes(res.status)) {
      const resJson = await res.json();
      const error = new Error(resJson.message);
      error.cause = res.status;
      throw error;
    }

    return res.body?.getReader();
  } catch (error) {
    console.error("Couldn't create conversation.", error);
    throw error;
  }
};