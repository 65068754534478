import { getApiRequestService } from "../../../services/index.services";

export async function unsavePlaylist(playlistId: string) {
  try {
    const data = { playlistId };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("/user/me/playlist", { data });
    return res.data;
  } catch (error) {
    throw error;
  }
};