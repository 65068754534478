import styled from "styled-components";

export const Spacer = styled.div`
  height: ${(props: { height: number }) => props.height ?? "30px"};
`;

// TODO: Remove (Deprecated).
export const CustomButton = styled.button`
  box-shadow: none;
  outline: none;
  border: 1px solid transparent;
  box-sizing: border-box;
  background-color: #ffc408;
  border-radius: 100px;
  padding: 16px 40px;
  font-size: 16px;
  font-weight: 700;
  color: #212121;
  font-family: "Inter";
  width: 100%;
  cursor: pointer;
  transition: all ease-out 0.2s;

  &.black {
    color: #fff;
    background: #212121;
    border-color: #212121;
  }

  &.secondary,
  &.white {
    background-color: #fff;
    color: #212121;
    border: 1px solid #d2dce2;
  }

  &.active {
    background-color: #fff3ce;
    color: #ffc408;
    border: 1px solid #ffc408;
  }

  &.disabled,
  :disabled {
    background-color: #d2dce2;
    border-color: #d2dce2;
    color: #81939c;
  }

  &.pink {
    background-color: #ce0868;
    border-color: #ce0868;
    color: #fff;
  }

  &.danger {
    background: #ff4300;
    border-color: #ff4300;
    color: #fff;
  }

  &.small {
    padding: 12px 24px;
  }

  &.xsmall {
    padding: 7px 12px;
    width: auto;
    font-size: 12px;
  }

  &.icon {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  &.no-border {
    border: none !important;
  }
`;

// TODO: Move/merge with ProfilePicture? Or own component.

export const Avatar = styled.img<{ height?: string | number; border?: string }>`
  box-sizing: border-box;
  height: ${(props) => props.height ?? "44px"};
  width: ${(props) => props.height ?? "44px"};
  background: lightgray;
  border-radius: 50%;
  border: ${(props) => props.border ?? "2px solid white"};
  box-sizing: border-box;
  border: ${(props) => props.border ?? "2px solid white"};
  box-sizing: border-box;
  object-fit: cover;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.05);
`;

// NOTE: Deprecated (see SpecialtyBadges).

export const SpecialtyTag = ({
  children,
  style,
}: {
  children: string;
  style: React.CSSProperties;
}) => {
  let backgroundColor = "#FFE5F0";
  let color = "#E6005A";

  switch (children) {
    case "COVID-19":
      backgroundColor = "#B7E3E4";
      color = "#076668";
      break;

    case "Cardiology":
      backgroundColor = "#FCF8D1";
      color = "#8F8621";
      break;

    case "Endocrinology":
      backgroundColor = "#FFF3E5";
      color = "#E67A00";
      break;

    case "Gastroenterology":
      backgroundColor = "#F2E6FE";
      color = "#5705AD";
      break;

    case "General Medicine":
      backgroundColor = "#E6EEFE";
      color = "#0550E0";
      break;

    case "Geriatrics":
      backgroundColor = "#E9FDE7";
      color = "#19A50E";
      break;

    case "Gyn & Obs":
      backgroundColor = "#EBF9F4";
      color = "#2A8969";
      break;

    case "Immunology":
      backgroundColor = "#FAFDE7";
      color = "#6E7C0F";
      break;

    case "Nephrology":
      backgroundColor = "#F8E7FD";
      color = "#830BA7";
      break;

    case "Neurology":
      backgroundColor = "#ECF8F9";
      color = "#2B8187";
      break;

    case "Oncology":
      backgroundColor = "#FEF9E7";
      color = "#806A0F";
      break;

    case "Oncology":
      backgroundColor = "#E6FDFF";
      color = "#02A6B0";
      break;

    case "Orthopedics":
      backgroundColor = "#EDEDF8";
      color = "#4045A5";
      break;

    case "Pediatrics":
      backgroundColor = "#F7EEF0";
      color = "#9C4A5D";
      break;

    case "Pulmonology":
      backgroundColor = "#F2F9EC";
      color = "#5D902B";
      break;

    case "Rheumatology":
      backgroundColor = "#FFF0F6";
      color = "#CE0868";
      break;

    case "Urology":
      backgroundColor = "#FEF7E6";
      color = "#E09906";
      break;

    default:
      backgroundColor = "#FFE5F0";
      color = "#E6005A";
      break;
  }

  return (
    <div
      style={{
        padding: "5px 5px 5px 0",
        margin: 0,
        display: children ? "inline-block" : "none",
        ...style,
      }}
    >
      <span
        style={{
          fontFamily: "Inter",
          backgroundColor: "#FFF3CE",
          color: "#FF8800",
          padding: "2px 8px",
          borderRadius: "50px",
          fontSize: "11px",
          fontWeight: 400,
          textAlign: "left",
        }}
        className='--specialty-tag'
      >
        {children}
      </span>
    </div>
  );
};

export const ArticleTypeHeading = styled.div`
  margin-bottom: 8px;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.36px;
  text-transform: uppercase;
  text-align: left;
  color: #ce0868;
`;

export const RoundIcon = styled.div`
  border-radius: 50%;
  overflow: hidden;
  background: #90a4ae;

  .--custom-icon {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: "all 0.3s ease-in-out";

    &.play-icon.playing {
      svg {
        margin-left: 4px;
      }
    }

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: #fff;
      }

      circle {
        fill: none;
      }
    }
  }
`;

export const Flex = (props: {
  justify?: "start" | "end" | "center" | "space-between" | "space-around";
  align?: "start" | "end" | "center";
  flexDirection?: "row" | "column";
  gap?: number;
  children: React.ReactNode;
  style?: React.CSSProperties;
}) => (
  <div
    style={{
      display: "flex",
      justifyContent: props.justify || "start",
      alignItems: props.align || "start",
      gap: props.gap || 0,
      flexDirection: props.flexDirection || "row",
      width: "100%",
      ...props.style,
    }}
  >
    {props.children}
  </div>
);
