import { useEffect, useState } from "react";

export function useTextReveal(fullText: string, onIncrement: Function) {
	const [partialText, setPartialText] = useState("");
	const [visibleChars, setVisibleChars] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			if (visibleChars < fullText.length) {
				setVisibleChars(prevLength => prevLength + 1 + Math.floor(Math.random() * 5));
				onIncrement && onIncrement();
			}
		}, 0);

		return () => clearInterval(intervalId);
	}, [fullText]);

	useEffect(() => {
		if (visibleChars > 0) {
			setPartialText(fullText.substring(0, visibleChars));
		}
	}, [fullText, visibleChars]);

	function hasFinished() {
		return fullText.length > 0 && visibleChars >= fullText.length;
	}

	function reset() {
		setPartialText("");
		setVisibleChars(0);
	}

	return { partialText, hasFinished, reset };
}