
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { flagAnnouncementAsRead } from "../../../redux/user/user.reducer";
import CustomIcon from "../../CustomIcon";
import { CustomButton } from "../../global";
import styled from "styled-components";
import { t } from "i18next";
import { deleteUserAnnouncement } from "../../../domains/user/endpoints/deleteUserAnnouncement";
import { ReactComponent as JuisciMainAvatar } from "../../../assets/icons/juisci-main-avatar.svg";
import { ReactComponent as CoolLemon } from "../../../assets/images/cool-lemon.svg";

function isInternalLink(link) {
    if (link.startsWith("/")) return true;
    return [
        "app.juisci.com",
        "dev.juisci.com",
        "pro.juisci.com",
        "pro-dev.juisci.com",
    ].some(str => link.includes(str));
}

export default function AnnouncementModal({ announcement, onClose }) {
    const history = useHistory();
    const dispatch = useAppDispatch();

    async function handleClose() {
        try {
            await deleteUserAnnouncement(announcement.organisationId);
        } catch (error) {
            console.error("Couldn't delete announcement.", error);
        } finally {
            dispatch(flagAnnouncementAsRead(announcement._id));
            onClose && onClose();
        }
    }

    return (
        <AnnouncementDialog open={!!announcement}>
            <JuisciMainAvatar />
            <ModalPanel>
                <CustomIcon
                    iconName='close_alt'
                    className='close-btn'
                    onClick={handleClose}
                />
                <CoolLemon style={{
                    width: 175,
                    height: 175,
                }} />
                <div className="modal-body">
                    {announcement?.title && <span>{announcement.title}</span>}
                    <p>{announcement?.content}</p>

                    {announcement.cta && isInternalLink(announcement.cta) ?
                        <CTAButton onClick={async () => {
                            handleClose();
                            history.push(announcement.cta.split(".com").at(-1));
                        }}>{t("common:action.discover")}</CTAButton>
                        : announcement.cta?.startsWith("http") ?
                            <a href={announcement.cta} target="_blank">
                                <CTAButton onClick={handleClose}>{t("common:action.discover")}</CTAButton>
                            </a>
                            :
                            <CTAButton onClick={handleClose}>
                                {announcement.cta ?? t("OK")}
                            </CTAButton>
                    }
                </div>
            </ModalPanel>
        </AnnouncementDialog>
    );
}

function CTAButton({ children, onClick }) {
    return (
        <CustomButton
            style={{
                width: "fit-content",
                wordBreak: "break-word",
                alignSelf: "center",
            }}
            onClick={() => onClick && onClick()}
        >
            {children}
        </CustomButton>
    );
}

const AnnouncementDialog = styled.dialog`
    position: fixed;
    top: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100dvh;
    z-index: 100;
    background-color: #212121E6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
`;

const ModalPanel = styled.div`
    margin: 0 22px;
    padding: 22px;
    background-color: #FFF9E6;
    border-radius: 20px;
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 27px;

    .close-btn {
        align-self: flex-end;
        svg circle { fill: #FF8800; }
    }

    .modal-body {
        max-height: 300px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        gap: 32px;
        font-family: Inter;
        text-align: center;
        color: #212121;

        span {
            font-size: 24px;
            font-weight: 700;
            line-height: 34px;
            word-break: break-word;
        }
    
        p {
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            word-break: break-word;
        }
    }

`;