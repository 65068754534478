import styled from "styled-components";
import { ChangeEvent, SyntheticEvent, useState } from "react";
import { TextareaAutosize } from "@mui/material";
import { t } from "i18next";
// import { ReactComponent as GreenMicIcon } from "../../../assets/icons/gpt/gpt-input-mic-green.svg";
import { ReactComponent as SendArrowIcon } from "../../../assets/icons/gpt/gpt-input-send-arrow.svg";

export default function GPTChatInput({
	placeholder,
	value,
	disabled,
	onChange,
	onSubmit,
	style,
	displayDisclaimer,
}: {
	placeholder: string;
	value: string;
	disabled: boolean;
	onChange: Function;
	onSubmit: Function;
	style?: {};
	displayDisclaimer?: boolean;
}) {
	const [isInputReady, setInputReady] = useState(false);

	function handleSubmit(e: SyntheticEvent) {
		e.preventDefault();
		setInputReady(false);
		if (value.length) onSubmit(value);
	}

	if (disabled) return null;

	return (
		<Container style={style ?? {}}>
			{!isInputReady
				? (
					<div style={{
						padding: "0 8px",
						paddingBottom: displayDisclaimer ? 0 : 32,
					}}>
						<ChatButton onClick={() => setInputReady(true)}>{placeholder}</ChatButton>
						{displayDisclaimer && <DisclaimerText>{t("juisciGpt:chat.disclaimer")}</DisclaimerText>}
					</div>
				)
				: (
					<InputContainer>
						<TextAreaWrapper>
							<TextareaAutosize
								placeholder={placeholder}
								value={value}
								onChange={(e: ChangeEvent<HTMLTextAreaElement>) => onChange(e.target.value)}
								autoFocus
							/>
						</TextAreaWrapper>
						{!value.length
							// ? <GreenMicIcon /> // NOTE: will be used in the future
							? <SendArrowIcon className="disabled" />
							: <SendArrowIcon onClick={handleSubmit} />
						}
					</InputContainer>
				)
			}
		</Container>
	);
}

const Container = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
`;

const ChatButton = styled.button`
	width: 100%;
	padding: 16px;
	border: none;
	border-radius: 100px;
	background: var(--BRAND-CORE-White, #FFF);
	color: var(--BRAND-CORE-Juisci-Black, #212121);
	font-family: Inter;
	font-size: 14px;
	font-weight: 500;
	line-height: 15px; /* 107.143% */
	letter-spacing: -0.14px;
`;

const DisclaimerText = styled.span`
	display: block;
	margin: 16px 12px;
	text-align: center;
	color: var(--BRAND-CORE-Juisci-Black, #212121);
	font-family: Inter;
	font-size: 10px;
	font-style: normal;
	font-weight: 300;
	line-height: 15px;
`;

const InputContainer = styled.div`
	padding: 16px 22px 8px;
	display: flex;
	flex-direction: column;
	gap: 22px;
	background: var(--BRAND-CORE-White, #FFF);

	svg {
		align-self: flex-end;
		width: 35px;
		height: 35px;
		transition: opacity 0.2s ease-in-out;

		&.disabled {
			opacity: 0.5;
		}
	}
`;

const TextAreaWrapper = styled.div`
	box-sizing: border-box;
	max-height: 192px;
	overflow-y: auto;

	textarea {
		box-sizing: border-box;
		resize: none;
		border: none;
		width: 100%;
		color: var(--BRAND-CORE-Juisci-Black, #212121);
		font-family: Inter;
		font-size: 16px;
		font-weight: 500;
		line-height: 120%;
		letter-spacing: 0.003px;
		
		::placeholder {
			color: var(--SECONDARY-GREY-SHADES-Bluegrey-4, #90A4AE);
			letter-spacing: -0.16px;
		}
	}
`;