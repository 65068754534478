import { userApi } from "../../../redux/user/user.service";

const api = userApi.injectEndpoints({
  endpoints: (build) => ({
    getPendingConnectionRequests: build.query<any, void>({
      query: () => {
        return {
          url: "/user/connection/request/pending",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getPendingConnectionRequests,
} = api.endpoints;

export const {
  useGetPendingConnectionRequestsQuery,
  useLazyGetPendingConnectionRequestsQuery,
} = api;
