import { getApiRequestService } from "../../../services/index.services";

export async function updateActiveSession(activesession: boolean) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(
      `/user/me/session?activesession=${activesession}`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
