import i18n from "../../../config/i18n";
import { SupportedLanguage } from "../../../interfaces";
import { store } from "../../../redux";
import { getUserAnnouncements } from "../endpoints/getUserAnnouncements";

export async function getUserUnreadAnnouncement() {
  const user = store.getState().user;
  const announcementData = await getUserAnnouncements();

  const unreadAnnouncement = announcementData.global?.contents
    .find((content: {
      _id: string;
      language: SupportedLanguage;
    }) => {
      return content.language === i18n.resolvedLanguage
        && !user.readAnnouncements.includes(content._id);
    });

  if (unreadAnnouncement) {
    unreadAnnouncement.organisationId = "global";
    return unreadAnnouncement;
  }

  return null;
}
