import { getApiRequestService } from "../../../services/index.services";

export async function deleteNotification(notificationId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(
      `/notification/${notificationId}/delete`
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
