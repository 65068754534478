import { ReactChildren, ReactNode, useEffect, useRef, useState } from "react";
import { usePageScroll } from "../../../domains/app/hooks/usePageScroll";
import HomeHeader from "../../app/headers/HomeHeader";
import BottomNavbar from "../../app/BottomNavbar";
import RoomsMenu from "../../app/RoomsMenu";
import AnimatedFooter from "../../app/AnimatedFooter";
import styled from "styled-components";
import FadeIn from "react-fade-in";

export default function HomeLayout({ children }: {
  children: ReactNode | ReactChildren;
}) {
  // Scroll memorization targets a specific component here, not the page
  const { pageScrollY, savePageScrollY } = usePageScroll();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      wrapperRef.current?.scrollTo({ top: pageScrollY });
    }, 10);
  }, []);

  function handleScroll() {
    savePageScrollY(wrapperRef.current?.scrollTop ?? 0);
  }

  return (
    <>
      <LayoutWrapper>
        <RoomsMenu isOpen={isMenuOpen} />
        <MainContainer ref={wrapperRef} isOpen={isMenuOpen} onScroll={handleScroll}>
          <BlockOverlay
            style={{ display: isMenuOpen ? "flex" : "none" }}
            onClick={() => setMenuOpen(!isMenuOpen)}
          />
          <HomeHeader onRoomsClick={() => setMenuOpen(!isMenuOpen)} />
          <FadeIn>
            {children}
          </FadeIn>
          <AnimatedFooter onClick={() => {
            wrapperRef?.current?.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }} />
          <BottomNavbar />
        </MainContainer>
      </LayoutWrapper>
    </>
  );
}

const LayoutWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 100dvh;
  overflow: hidden;
  overscroll-behavior: none;
`;

const MainContainer = styled.div<{ isOpen: boolean }>`
  box-sizing: border-box;
  position: relative;
  z-index: 1;
  overflow-y: ${props => props.isOpen ? "none" : "auto"};
  min-width: 100%;
  max-width: 100%;
  background: #FFF;
  box-shadow: -10px 0px 20px 0px rgba(33, 33, 33, 0.10);

  .safe-area-top-wrapper, .safe-area-bottom-wrapper {
    left: ${props => props.isOpen ? "80%" : 0};
    transition: left 0.2s ease-in, border-radius 0.2s ease-in;
  }
`;

const BlockOverlay = styled.div`
  position: fixed;
  z-index: 200;
  width: 100%;
  height: 100dvh;
`;
