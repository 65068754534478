import { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import styled from "styled-components";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavLang } from "../../components/app/headers/layout";
import BottomNavbar from "../../components/app/BottomNavbar";
import { NoticeBar } from "antd-mobile";
import { CustomButton } from "../../components/global";
import { CustomInput } from "../../components/CustomInput";
import {
  loginEmailPassword,
  loginWithApple,
  loginWithGoogle,
} from "../../services/firebase";
import { joinRoomByInviteCode } from "../../domains/user/endpoints/joinRoomByInviteCode";
import { joinRoomByQrCodeId } from "../../domains/user/endpoints/joinRoomByQrCodeId";
import NetworkLoginButton from "../../components/signup/NetworkLoginButton";
import disableScroll from "disable-scroll";
import { isAndroid } from "react-device-detect";
import { displayToast } from "../../components/app/AppToast";
import { Trans } from "react-i18next";
import { ReactComponent as EyeOpen } from "../../assets/icons/eye-open.svg";
import { ReactComponent as EyeClosed } from "../../assets/icons/eye-closed.svg";
import { getTranslation } from "../../config/fb-errors-translator/config";

export default function LoginPage() {
  const history = useHistory();
  const { t } = useLangContext();
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [loadingApple, setLoadingApple] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [showIndicator, setShowIndicator] = useState(false);
  const qrCodeId = localStorage.getItem("roomQrCodeId");
  const inviteCode = localStorage.getItem("roomInviteCode");

  useEffect(() => {
    disableScroll.off();
    window.scrollTo(0, 0);

    checkAuth();

    return () => {
      localStorage.removeItem("forceLogin");
      localStorage.removeItem("_cache_notification");
    };
  }, [history]);

  async function checkAuth() {
    // NOTE: Remove legacy field. Do NOT delete these line. Never.
    localStorage.removeItem("authData");
    localStorage.removeItem("rememberEmail");

    const isAuthentified = await isUserAuthentified();
    if (isAuthentified) history.replace("/discovery");

    localStorage.removeItem("foryou_carousel_index");

    // TODO: Check if this ever occurs.
    const forceLogin = localStorage.getItem("forceLogin");
    if (forceLogin) {
      setShowIndicator(true);
    }
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    try {
      e.preventDefault();

      setErrorMessage("");
      setBtnLoading(true);

      const { isSuccess, errorMessage } = await loginEmailPassword(email, password)
      if (!isSuccess) {
        setBtnLoading(false);
        const translationKey = getTranslation(errorMessage);
        displayToast(t(translationKey));
        return;
      }

      if (inviteCode) {
        await joinRoomByInviteCode(inviteCode);
        localStorage.removeItem("roomInviteCode");
        displayToast(t("room:joinedNewRoom"));
      }

      if (qrCodeId) {
        await joinRoomByQrCodeId(qrCodeId);
        localStorage.removeItem("roomQrCodeId");
        displayToast(t("room:joinedNewRoom"));
      }
    } catch (error) {
      console.error("Couldn't submit credentials.", error);
      displayToast(t("else"));
    }
  }

  async function handleOAuth(provider: "apple" | "google") {
    if (provider === "apple") {
      setLoadingApple(true);
      await loginWithApple();
    } else if (provider === "google") {
      setLoadingGoogle(true);
      loginWithGoogle();
    }

    setTimeout(() => {
      setLoadingApple(false);
      setLoadingGoogle(false);
    }, 5000);
  }

  return (
    <Wrapper>
      <HeaderBoxAutoSafe>
        <HeaderLinks />
        <HeaderLogo />
        <HeaderLinks><NavLang /></HeaderLinks>
      </HeaderBoxAutoSafe>

      <Content>
        {/* Error message on login */}
        {errorMessage !== "" && (
          <NoticeBar
            style={{ background: "#CE0868", color: "white" }}
            mode="closable"
            icon={null}
            onClick={() => setErrorMessage("")}
          >
            {errorMessage}
          </NoticeBar>
        )}

        {/* Title & Email input */}
        <form onSubmit={handleSubmit}>
          <div style={{ marginTop: showIndicator ? "0vh" : "3vh" }}>&nbsp;</div>
          {showIndicator && (
            <LoginSubtitle>
              You’ve reached your articles limit.
              <br />
              Log in to access more fresh scientific shots.
            </LoginSubtitle>
          )}
          <LoginTitle>{t("login:title")}</LoginTitle>

          <CustomInput
            name="email"
            placeholder={t("common:form.field.email")}
            type="email"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            required
            value={email}
          />

          {/* Password Input */}
          <CustomInput
            name="password"
            type={isPasswordVisible ? "text" : "password"}
            placeholder={t("common:form.field.password")}
            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
              setPassword(e.target.value)
            }
            style={{ margin: "30px 0 5px" }}
            value={password}
            required
            suffix={!isPasswordVisible ? <EyeOpen /> : <EyeClosed />}
            suffixClick={() => setPasswordVisible(!isPasswordVisible)}
          />

          {/* Agree checkbox & Forget password */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              margin: "5px 0 30px",
            }}
          >
            <ForgetLink to="/login/reset">
              <span>{t("login:forgotPassword")}</span>
            </ForgetLink>
          </div>

          {/* Login buttons */}
          <CustomButton
            className={btnLoading ? "loading disabled" : "pink"}
            type="submit"
          >
            {t("login:action.loginWithEmail")}
          </CustomButton>

          <hr style={{ margin: "30px 0" }} />

          {/* TODO: Replace these buttons with AuthProviderButton */}
          <NetworkLoginButton
            className={loadingGoogle ? "loading" : ""}
            text={t("login:action.loginWithGoogle")}
            icon="google"
            onClick={() => handleOAuth("google")}
          />
          {!isAndroid && (
            <NetworkLoginButton
              className={loadingApple ? "loading" : ""}
              backgroundColor="#000"
              color="white"
              text={t("login:action.loginWithApple")}
              icon="apple"
              onClick={() => handleOAuth("apple")}
            />
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "15px",
                textAlign: "center",
                marginBottom: 0,
              }}
            >
              {t("login:noAccountYet")}
            </p>
            <ForgetLink
              to={{
                pathname: "/signup/pre",
                state: {
                  email: "",
                },
              }}
              style={{
                fontSize: "15px",
                fontWeight: "800",
                fontFamily: "Inter",
              }}
            >
              <span>{t("login:action.registerNow")}</span>
            </ForgetLink>
          </div>
        </form>

        <Credits>
          <Trans
            i18nKey="signup:pre.disclaimer"
            t={t}
            className="credits"
            components={{
              b: <b />,
              termsLink: (
                <Link
                  to={{
                    pathname: "/terms",
                    state: { isPush: true, hideTitle: true },
                  }}
                ></Link>
              ),
              policyLink: (
                <Link
                  to={{
                    pathname: "/policy",
                    state: { isPush: true, hideTitle: true },
                  }}
                ></Link>
              ),
            }}
          />
        </Credits>
      </Content>
      <BottomNavbar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  background: #ffc408;
  display: flex;
  flex-direction: column;
`;

const Content = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 20px 25px;

  .credits {
    font-family: "Roboto" !important;
    font-weight: 600;
    font-size: 13px;
    line-height: 140%;
    text-align: center;
    color: #212121;
    padding: 0 30px;

    .link {
      color: #ce0868;
      text-decoration: underline;
    }
  }
`;

const ForgetLink = styled(Link)`
  font-size: 13px;
  color: #ce0868;
  margin: 0;
  font-weight: 600;
  font-family: Roboto;
  text-decoration: underline;
  margin-top: 5px;
`;

const LoginTitle = styled.div`
  color: #212121;
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
  font-family: "Inter";
`;

const LoginSubtitle = styled.div`
  color: #212121;
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 15px;
`;

const ShowHideButton = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;

  p {
    margin-right: 5px;
    color: #212121;
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
  }
`;

const TermsLink = styled.p`
  font-size: 13px;
  color: #212121;
  font-weight: 600;
  margin: 0;
  margin-top: 3px;
  margin-left: 0px;
  font-family: Roboto !important;
`;

const Credits = styled.div`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  line-height: 18.2px;
  text-align: center;
  color: #212121;
  padding: 13px 30px;

  a {
    text-decoration: underline;
    color: #ce0868;
  }
`;
