import { useHistory } from "react-router-dom";
import { ArticleModel } from "../../article/article.types";
import { useCurrentUser } from "./useCurrentUser";
import { shareArticle } from "../endpoints/shareArticle";
import { preflightUser } from "../utils/preflightUser";
import { useArticleCache } from "../../article/hooks/useArticleCache";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { displayToast } from "../../../components/app/AppToast";
import { gaEventArticleShare } from "../../../tools/analytics/articleAnalytics";
import { useLangContext } from "../../app/contexts/lang.context";
import { shareUrl } from "../../app/utils/shareUrl";

export function useShareArticle(article: ArticleModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { updateArticleInAllCaches } = useArticleCache();
  const { t } = useLangContext();

  return async function () {
    try {
      if (!article) throw new Error("Invalid article.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.shareArticle"),
      });
      if (isRedirected) return;

      await shareArticle(article.slug);
      // Refresh metrics if article was shared
      updateArticleInAllCaches(article);

      const url = `
        ${window.location.origin}/post/${article.slug}?shared=${currentUser?.uid ?? null}
      `;
      const text = t("share:article.message", { title: article.title });
      await shareUrl(document.title, text, url);

      gaEventArticleShare({
        article,
        listName: analyticsListName,
      });

      displayToast(t("toast:success.articleShared"), "success");
    } catch (error) {
      console.error("Couldn't share article.", error);
      displayToast(t("error:default"));
    }
  };
}