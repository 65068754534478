import { useHistory } from "react-router-dom";
import { NectarModel } from "../../nectar/nectar.types";
import { useCurrentUser } from "./useCurrentUser";
import { shareContent } from "../endpoints/shareContent";
import { preflightUser } from "../utils/preflightUser";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventNectarShare } from "../../../tools/analytics/nectarAnalytics";
import { displayToast } from "../../../components/app/AppToast";
import { useLangContext } from "../../app/contexts/lang.context";
import { shareUrl } from "../../app/utils/shareUrl";


export function useShareNectar(nectar: NectarModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { t } = useLangContext();

  return async function () {
    try {
      if (!nectar) throw new Error("Invalid nectar.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.shareContent"),
      });
      if (isRedirected) return;

      await shareContent(nectar.slug);

      const url = `
        ${window.location.origin}/nectar/${nectar.slug}?shared=${currentUser?.uid ?? null}
      `;
      const text = t("share:nectar.message", { title: nectar.question });
      await shareUrl(document.title, text, url);

      gaEventNectarShare({
        nectar,
        listName: analyticsListName,
      });

      displayToast(t("toast:success.nectarShared"), "success");
    } catch (error) {
      console.error("Couldn't share nectar.", error);
      displayToast(t("error:default"));
    }
  };
}