import { getApiRequestService } from "../../../services/index.services";

export async function getCompanyPlaylists(companyId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/playlist/company/${companyId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
