import { getApiRequestService } from "../../../services/index.services";

export async function createPlaylist(data: { title: string }) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/playlist", data);
    return res.data;
  } catch (error) {
    throw error;
  }
};