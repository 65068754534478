import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import styled from "styled-components";
import JuisciLogo from "../../assets/core/juisci-logo.svg";
import JuisciPattern from "../../assets/images/mobile-pattern.svg";
import { t } from "i18next";
import LogoHeader from "../../components/app/headers/LogoHeader";
import BottomNavbar from "../../components/app/BottomNavbar";
import CloseAlt from "../../assets/icons/close_alt.svg";
import disableScroll from "disable-scroll";

export default function PrevLoginPage() {
  const history = useHistory();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      disableScroll.on();
      window.scrollTo(0, 0);
      const isAuthentified = await isUserAuthentified();
      if (isAuthentified) {
        history.replace("/discovery");
      } else {
        setLoading(false);
      }
    };
    checkAuth();
  }, [history]);

  if (isLoading) return null;

  return (
    <Wrapper>
      <LogoHeader />

      <ImgPattern />
      <Container>
        <img
          src={CloseAlt}
          alt=""
          style={{ position: "absolute", right: "22px", top: "15px" }}
          onClick={() => {
            if (history.action === "PUSH") {
              history.goBack();
            } else {
              history.push("/discovery");
            }
          }}
        />
        <InnerContainer>
          <img src={JuisciLogo} alt="" />
          <SignupTitle>
            <h1>{t("login:loginPreview.title")}</h1>
            <p>
              <b>{t("login:loginPreview.subline")}</b>
              <br />
              {t("login:loginPreview.text")}
            </p>
          </SignupTitle>
          <div style={{ height: "30px" }} />
          <button onClick={() => history.replace("/signup/pre")}>
            {t("login:sheet.action.register")}
          </button>
          <button
            onClick={() => {
              history.replace("/login");
            }}
            style={{ background: "#FFC408" }}
          >
            {t("login:sheet.action.login")}
          </button>
        </InnerContainer>
      </Container>

      <BottomNavbar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const ImgPattern = styled.div`
  position: absolute;
  top: 0;
  z-index: -2;
  width: 100vw;
  height: 100dvh;
  background-image: url(${JuisciPattern});
  background-repeat: repeat;
  background-position: bottom;
`;

const Container = styled.div`
  position: relative;
  overscroll-behavior: contain;
  flex: 1;
`;

const InnerContainer = styled.div`
  padding: 70px 40px 22px;
  font-family: "Inter";
  background-color: #fff;
  text-align: center;

  button {
    font-family: "Inter";
    width: 100%;
    outline: none;
    border: none;
    box-shadow: none;
    align-items: center;
    padding: 16px 24px;
    background: #ecf0f5;
    border-radius: 100px;
    color: #212121;
    font-size: 16px;
    line-height: 16px;
    font-weight: 700;
    margin: 5px 0;
  }

  img {
    margin-bottom: 10px;
  }

  p {
    font-size: 16px;
    line-height: 22.4px;
    text-align: center;
  }

  b {
    font-weight: 700;
  }
`;

const SignupTitle = styled.div`
  color: #212121;
  text-align: center;

  h1 {
    font-family: "Inter";
    font-size: 24px;
    line-height: 33.6px;
    margin-bottom: 0px;
  }

  p {
    font-family: Roboto !important;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 35px;
  }
`;
