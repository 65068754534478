import { ChangeEvent, MouseEvent, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { ArticleModel } from "../../domains/article/article.types";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { getArticleBySlug } from "../../domains/article/endpoints/getArticleBySlug";
import { useLikeArticle } from "../../domains/user/hooks/useLikeArticle";
import { useSaveArticle } from "../../domains/user/hooks/useSaveArticle";
import { useShareArticle } from "../../domains/user/hooks/useShareArticle";
import { useCountdown } from "../../domains/app/hooks/useCountdown";
import disableScroll from "disable-scroll";
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import { t } from "i18next";
import { Trans } from "react-i18next";
import styled from "styled-components";
import Loader from "../../components/Loader";
import StepBars from "../../components/app/StepBars";
import {
  ScrollOverflowIndicatorWrapper,
  StoryActionsContainer,
  StorySlideContent,
  StorySlideScrollContainer,
  StorySlideTitle,
  StorySlideWrapper,
  StoryStepBarsWrapper,
  StoryTimerWrapper,
} from "../../components/forYou/layout";
import OptionsPanel from "../../components/app/OptionsPanel";
import PlaylistPanel from "../../components/app/PlaylistPanel";
import ImageViewer from "../../components/post/ImageViewer";
import ContentExternalLink from "../../components/ContentExternalLink";
import CircleProgress from "../../components/CircleProgress";
import StoryImageCarousel from "../../components/forYou/StoryImageCarousel";
import { ReactComponent as CloseRedIcon } from "../../assets/icons/cross-circle-red.svg";
import { ReactComponent as HeartBlackIcon } from "../../assets/icons/heart-circle-black.svg";
import { ReactComponent as HeartRedIcon } from "../../assets/icons/heart-circle-red.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/dots-circle-red.svg";
import { ReactComponent as ArrowDownCircleRedIcon } from "../../assets/icons/arrow-circle-red.svg";
// import { ReactComponent as StrawberryIcon } from "../../assets/icons/straw.svg";
import { ReactComponent as GrapesIcon } from "../../assets/icons/grapes.svg";
import { ReactComponent as AppleIcon } from "../../assets/icons/apple.svg";
import { ReactComponent as RaspIcon } from "../../assets/icons/rasp.svg";
import { ReactComponent as CherryIcon } from "../../assets/icons/cherry.svg";
import { ReactComponent as PineappleIcon } from "../../assets/icons/pineapple.svg";
import { ReactComponent as LemonIcon } from "../../assets/icons/lemon.svg";
import { ReactComponent as BlenderIcon } from "../../assets/icons/blender.svg";
import { ReactComponent as AvocadoIcon } from "../../assets/icons/avocado.svg";
import { ReactComponent as PeachIcon } from "../../assets/icons/peach.svg";

enum SlideCategory {
  CONCLUSION = "conclusion",
  BACKGROUND = "background",
  OBJECTIVES = "objectives",
  FIGURES = "figures",
  METHODOLOGY = "methodology",
  RESULTS = "results",
  SUGGESTION = "suggestion",
}

export default function ForYouArticleStoryPage() {
  const history = useHistory();
  const location = useLocation<{ content: any }>();
  const params = useParams<{ slug: string; roomId?: string }>();
  const rootPathname = params.roomId ? `/room/foryou/${params.roomId}` : "/foryou";
  const [article, setArticle] = useState<ArticleModel | null>(location.state?.content ?? null);
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const [isImageViewerOpen, setImageViewerOpen] = useState(false);
  const [slideCategories, setSlideCategories] = useState<string[]>([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [imageIndex, setImageIndex] = useState(0);
  const [isOverflowing, setOverflowing] = useState(false);
  const displayedCategory = slideCategories[slideIndex];
  const { isLiked, toggleLike } = useLikeArticle(article, AnalyticsItemList.FORYOU);
  const { isSaved, toggleSaveToPlaylists } = useSaveArticle(article, AnalyticsItemList.FORYOU);
  const shareArticle = useShareArticle(article, AnalyticsItemList.FORYOU);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const countdown = useCountdown(8, exitBack);

  useEffect(() => {
    (async function () {
      try {
        if (!location.state) {
          return history.replace(rootPathname)
        }

        disableScroll.off();

        // TODO: Check if proper way to do this.
        window.onbeforeunload = () => {
          localStorage.removeItem("foryou_carousel_index");
        };

        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.likeArticle"),
        });
        if (isRedirected) return;

        if (!article) {
          if (!params.slug) throw new Error("No article slug.");;
          setArticle(await getArticleBySlug(params.slug));
        }

        setupSlides();
        preloadFigures();
      } catch (error) {
        console.error("Couldn't load article.", error);
        history.replace(rootPathname);
      }
    })();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const slideIndex = parseInt(searchParams.get("slide") ?? "0") ?? 0;
    setSlideIndex(slideIndex);
  }, [location.search]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTo(0, 0);
      if (scrollContainer?.scrollHeight > scrollContainer?.clientHeight) {
        setOverflowing(true);
      } else {
        setOverflowing(false);
      }
    }

    // Start timer to move to the next content
    if (slideIndex === slideCategories.length - 1) {
      if (!countdown.isRunning) countdown.start();
    } else {
      countdown.reset();
    }
  }, [slideIndex]);

  useEffect(() => {
    if (isOptionsPanelVisible || isPlaylistsPanelVisible) {
      countdown.reset();
    }
  }, [isOptionsPanelVisible, isPlaylistsPanelVisible]);

  function setupSlides() {
    const categories = [];

    if (!!article?.conclusion?.length) categories.push(SlideCategory.CONCLUSION);
    if (!!article?.background?.length) categories.push(SlideCategory.BACKGROUND);
    if (!!article?.objectives?.length) categories.push(SlideCategory.OBJECTIVES);
    if (!!article?.images?.length) categories.push(SlideCategory.FIGURES);
    if (!!article?.methodology?.length) categories.push(SlideCategory.METHODOLOGY);
    if (!!article?.results?.length) categories.push(SlideCategory.RESULTS);
    categories.push(SlideCategory.SUGGESTION);

    setSlideCategories(categories);
  }

  function preloadFigures(index = 0) {
    if (article?.images && article?.images[index]) {
      const img = new Image();
      img.src = article?.images[index].url;
      img.onload = () => {
        preloadFigures(index + 1);
      };
    }
  }

  function exitBack() {
    const forYouContentIndex = localStorage.getItem("foryou_carousel_index");
    const search = forYouContentIndex !== null ? `?index=${parseInt(forYouContentIndex)}` : "";
    history.replace(`${rootPathname}${search}`);
  }

  function handleClickSlide(e: MouseEvent) {
    if (e.pageX < window.innerWidth * 0.35 && slideIndex > 0) {
      history.replace({
        pathname: location.pathname,
        search: `slide=${slideIndex - 1}`,
        state: { content: article },
      });
    }

    if (e.pageX > window.innerWidth * 0.65 && slideIndex < slideCategories.length - 1) {
      history.replace({
        pathname: location.pathname,
        search: `slide=${slideIndex + 1}`,
        state: { content: article },
      });
    }
  }

  function handleSlideScroll(e: ChangeEvent) {
    const scrollThreshold = e.target.scrollHeight - e.target.clientHeight;
    setOverflowing(e.target.scrollTop < scrollThreshold);
  }

  if (!article) return <Loader />;

  return (
    <>
      <StorySlideWrapper onClick={handleClickSlide}>
        <StoryStepBarsWrapper>
          <StepBars
            currentStep={slideIndex + 1}
            maxSteps={slideCategories.length}
          />
        </StoryStepBarsWrapper>

        <StoryActionsContainer>
          <CloseRedIcon onClick={(e) => {
            e.stopPropagation();
            exitBack();
          }} />

          <div style={{ display: "flex", gap: 16 }}>
            <div onClick={(e) => {
              e.stopPropagation();
              toggleLike();
            }}>
              {isLiked ? <HeartRedIcon /> : <HeartBlackIcon />}
            </div>

            <DotsIcon onClick={(e) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
            }} />
          </div>
        </StoryActionsContainer>

        <StorySlideTitle>
          {{
            conclusion: <LemonIcon />,
            background: <GrapesIcon />,
            objectives: <PineappleIcon />,
            figures: <AppleIcon />,
            methodology: <RaspIcon />,
            results: <CherryIcon />,
            // keywords: <StrawberryIcon />,
          }[displayedCategory] ?? null}

          {{
            conclusion: t("content:articlePage.section.conclusion"),
            background: t("content:articlePage.section.background"),
            objectives: t("content:articlePage.section.objectives"),
            figures: t("content:articlePage.section.figures"),
            methodology: t("content:articlePage.section.methodology"),
            results: t("content:articlePage.section.results"),
            // keywords: t("content:articlePage.section.keywords"),
          }[displayedCategory] ?? ""}
        </StorySlideTitle>

        <StorySlideScrollContainer
          ref={scrollContainerRef}
          className="scrollbar-hidden"
          onScroll={(e: any) => handleSlideScroll(e)}
        >
          <StorySlideContent>
          {displayedCategory === SlideCategory.CONCLUSION && (
              <ul>
                {article?.conclusion?.map((phrase, index) => {
                 return <li key={index}>{phrase}</li>
                })}
              </ul>
            )}
            {displayedCategory === SlideCategory.BACKGROUND && article?.background}
            {displayedCategory === SlideCategory.OBJECTIVES && (
              <ul>
                {article?.objectives?.map((phrase, index) => {
                  return <li key={index}>{phrase}</li>;
                })}
              </ul>
            )}

            {displayedCategory === SlideCategory.FIGURES && (
              <>
                <StoryImageCarousel
                  images={article?.images ?? []}
                  imageIndex={imageIndex}
                  onSlideChange={(index: number) => setImageIndex(index)}
                  onClick={() => {
                    setImageViewerOpen(true);
                    if (isNativeIOS) iosSetBackgroundColor("#191919");
                  }}
                />
              </>
            )}

            {displayedCategory === SlideCategory.METHODOLOGY && article?.methodology}

            {displayedCategory === SlideCategory.RESULTS && (
              <ul>
                {article?.results
                  ?.filter((phrase) => !!phrase.length)
                  .map((phrase, index) => {
                    return <li key={index}>{phrase}</li>;
                  })}
              </ul>
            )}

            {displayedCategory === SlideCategory.SUGGESTION && (
              <div style={{
                display: "flex",
                flexDirection: "column",
                gap: 80,
              }}>
                <ContentExternalLink
                  href={article?.doi ?? ""}
                  title={<Trans i18nKey="content:articlePage.externalLink.title" />}
                  text={<Trans i18nKey="content:articlePage.externalLink.text" />}
                  style={{
                    margin: "24px 8px 16px",
                    boxShadow: "0px 0px 10px rgba(33, 33, 33, 0.4)",
                    background: "white",
                  }}
                />

                {!!article?.authors?.length && (
                  <section>
                    <StorySlideTitle><BlenderIcon />{t("common:authors")}</StorySlideTitle>
                    {!!article.authors.length ? (
                      <AuthorList>
                        {article?.authors?.map((author, index) => {
                          return <AuthorRow key={index}><AvatarPlaceholder />{author}</AuthorRow>;
                        })}
                      </AuthorList>
                    ) : t("No authors for this article")}
                  </section>
                )}

                <section>
                  <StorySlideTitle><AvocadoIcon />{t("content:articlePage.section.limitations")}</StorySlideTitle>
                  <p>{article?.limitations ?? t("content:articlePage.section.noLimitations")}</p>
                </section>

                <section>
                  <StorySlideTitle><PeachIcon />{t("content:articlePage.section.disclosureStatement")}</StorySlideTitle>
                  <p>{article?.disclosure ?? t("content:articlePage.section.noDisclosureStatement")}</p>
                </section>
              </div>
            )}
          </StorySlideContent>
        </StorySlideScrollContainer>

        <ScrollOverflowIndicatorWrapper className={!isOverflowing ? "hide" : ""}>
          <ArrowDownCircleRedIcon />
        </ScrollOverflowIndicatorWrapper>

        <StoryTimerWrapper className={(countdown.isRunning && countdown.timeLeftSec <= 5) ? "visible" : ""}>
          <CircleProgress value={countdown.timeLeftSec * 20}>
            <span>{countdown.isRunning ? countdown.timeLeftSec.toFixed(0) : ""}</span>
          </CircleProgress>
        </StoryTimerWrapper>
      </StorySlideWrapper>

      <OptionsPanel
        isOpen={isOptionsPanelVisible}
        onClose={() => setOptionsPanelVisible(false)}
        isContentSaved={isSaved}
        isContentLiked={isLiked}
        onSaveContent={() => setPlaylistsPanelVisible(true)}
        onLikeContent={toggleLike}
        onShareContent={shareArticle}
      />

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={article}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({ add, remove }: { add: string[]; remove: string[] }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />

      <ImageViewer
        open={isImageViewerOpen}
        imgs={article?.images ?? []}
        imageIndex={imageIndex}
        updateIndex={(index: number) => setImageIndex(index)}
        onClose={() => setImageViewerOpen(false)}
      />
    </>
  );
}

const AuthorList = styled.div`
  margin: 24px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AuthorRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const AvatarPlaceholder = styled.div`
  height: 27px;
  width: 27px;
  border-radius: 50%;
  background: linear-gradient(180deg, #ffd3a6 0%, #ff9c96 51.56%, #fc6f89 100%);
`;
