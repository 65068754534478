import styled from "styled-components";
import ClampLines from "../app/ClampLines";

export const StoryCoverContainer = styled.div`
  box-sizing: border-box;
  scroll-snap-align: start;
  scroll-snap-stop: always;
  width: 100%;
  padding: calc(64px + var(--safe-area-top)) 22px 64px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  opacity: 0;
  animation: fadeIn 0.05s 0.1s ease-in forwards;
`;

export const StoryCoverHeader = styled.div`
  display: flex;
  gap: 8px;

  .date {
    color: #212121;
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 120%; /* 13.2px */
    letter-spacing: 0.44px;
  }

  .actions {
    display: flex;
    gap: 8px;
  }
`;

export const StoryCoverBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const StoryCoverType = styled.div`
  color: var(--secondarytangy-raspberry-1, #CE0868);
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 120%; /* 14.4px */
  letter-spacing: 0.034px;
  text-transform: uppercase;
`;

export const StoryCoverTitle = styled(ClampLines)`
  color: #212121;
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%; /* 28.8px */
  letter-spacing: 0.48px;
`;

export const StoryCoverFooter = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 32px;
`;

export const StorySlideWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 100dvh;
  max-height: 100dvh;
  padding: calc(42px + var(--safe-area-top)) 21px calc(42px + var(--safe-area-bottom));
  display: flex;
  flex-direction: column;
  gap: 24px;
  background-image: linear-gradient(180deg, #FFC408, #FFFFFF);
`;

export const StorySlideScrollContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  mask-image: linear-gradient(to top, transparent 5%, black 20%);
`;

export const StorySlideContent = styled.div`
  padding-bottom: 15dvh;
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 140%; /* 22.4px */

  p, li {
    margin: 16px 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      &:first-child {
        margin-top: 0;
      }

      ::before {
        content: "• ";
        color: #CE0868;
      }

      &.slide::before {
        display: none;
      }
    }
  }

  a {
    color: #CE0868;
  }
`;

export const StoryStepBarsWrapper = styled.div`
  .step-bars-container .bar {
    height: 2px;
    border-radius: 200px;
    background: rgba(33, 33, 33, 0.25);
    
    &::before {
      background: var(--secondarytangy-raspberry-1, #CE0868);
    }
  }
`;

export const StoryActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StorySlideTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  color: var(--JUISCI-Black-Matters, #212121);
  font-family: Inter;
  font-size: 30px;
  font-weight: 900;
  line-height: 100%; /* 30px */
  letter-spacing: -0.006px;

  svg {
    width: 30px;
    height: 30px;
  }
`;

export const StoryTimerWrapper = styled.div`
  position: absolute;
  bottom: 64px;
  right: 24px;
  opacity: 0;
  transition: opacity ease-in 0.3s;
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 17px;
  font-weight: 700;
  line-height: 150%; /* 25.5px */
  
  &.visible {
    opacity: 1;
  }
`;

export const ScrollOverflowIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  transition: opacity ease 0.3s;

  &.hide {
    opacity: 0;
  }
`;
