import { useEffect, useState } from "react";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import styled from "styled-components";
import Lottie from "lottie-react";
import BlenderAnimation from "../../assets/animations/new_foryou.json";
import FadeIn from "react-fade-in/lib/FadeIn";

const LINES_TIME = 2500;

export default function ForYouBlender() {
  const { t } = useLangContext();
  const [step, setStep] = useState(0);

  const lines = [
    t("forYou:blender.lines.preparing"),
    t("forYou:blender.lines.drop"),
    t("forYou:blender.lines.dash"),
    t("forYou:blender.lines.distilling"),
  ];

  useEffect(() => {
    const linesCount = lines.length;
    setTimeout(() => {
      setStep(prevStep => (prevStep + 1) % linesCount);
    }, step % linesCount === 0 ? LINES_TIME + 1000 : LINES_TIME);
  }, [step]);

  return (
    <Container className="fy-blender-container">
      <FlavorText dangerouslySetInnerHTML={{ __html: t("forYou:blender.title") }} />
      <Lottie
        animationData={BlenderAnimation}
        loop={true}
        autoplay={true}
      />
      <FadeIn key={step}>
        <FlavorText dangerouslySetInnerHTML={{ __html: lines[step] }} />
      </FadeIn>
    </Container>
  );
}

const Container = styled.div`
  flex: 1;
  padding: calc(64px + var(--safe-area-top)) 44px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`;

const FlavorText = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 140%; /* 22.4px */
  letter-spacing: -0.002px;
`;
