import { Component } from "react";
import { DefaultProfilePicture } from "../../domains/image/image.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { Spacer } from "../../components/global";
import styled from "styled-components";
import Loader from "../../components/Loader";
import disableScroll from "disable-scroll";
import SaveHeader from "../../components/app/headers/SaveHeader";
import { getCurrentUser } from "../../domains/user/endpoints/getCurrentUser";
import { defaultAvatarImages } from "../../domains/image/utils/pickUserImage";
import { uploadAvatar } from "../../domains/image/endpoints/uploadAvatar";
import { displayToast } from "../../components/app/AppToast";
import { connect } from "react-redux";
import { t } from "i18next";
import CustomAvatarUploader from "../../components/profile/CustomAvatarUploader";
import DefaultAvatarSelector from "../../components/profile/DefaultAvatarSelector";
import { store } from "../../redux";
import { setUser } from "../../redux/user/user.reducer";

class EditAvatarPage extends Component {
  state = {
    loading: true,
    customAvatarFile: "",
    selectedDefaultAvatar: "",
    isEdited: false,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    disableScroll.off(); // Enable Scroll

    const { isRedirected } = await preflightUser({ history: this.props.history, authOnly: true });
    if (isRedirected) return;

    getCurrentUser().then((res) => {
      if (res?.profilePicture?.name) {
        this.setState({
          selectedDefaultAvatar: res?.profilePicture?.name,
          loading: false,
        });
      } else if (res?.profilePicture?.url) {
        this.setState({
          customAvatarFile: res?.profilePicture?.url,
          loading: false,
        });
      }
    });
  }

  handleChangeAvatarCustom = (url) => {
    this.setState({
      selectedDefaultAvatar: "",
      customAvatarFile: url,
      isEdited: true,
    });
  };

  handleChangeAvatarDefault = (slug) => {
    this.setState({
      selectedDefaultAvatar: slug,
      customAvatarFile: null,
      isEdited: true,
    });
  };

  handleClearAvatar = () => {
    if (this.state.selectedDefaultAvatar != DefaultProfilePicture.Pineapple) {
      this.setState({
        customAvatarFile: "",
        selectedDefaultAvatar: DefaultProfilePicture.Pineapple,
      });
    }
  };

  handleSubmit = async () => {
    try {
      const { customAvatarFile, selectedDefaultAvatar } = this.state;

      const formData = new FormData();

      let file;
      if (selectedDefaultAvatar) {
        formData.set("pictureName", selectedDefaultAvatar);
      } else {
        await fetch(customAvatarFile)
          .then((res) => res.blob())
          .then(
            (blob) => (file = new File([blob], "avatar", { type: "image/jpeg" }))
          );
        formData.set("file", file);
      }

      if (selectedDefaultAvatar || file) {
        const updatedUser = await uploadAvatar(formData);
        store.dispatch(
          setUser({
            ...store.getState().user.user,
            profilePicture: updatedUser.profilePicture,
          })
        );
        displayToast(t("toast:success.avatarUpdated"), "success");
        this.props.history.goBack();
      }
    } catch (error) {
      displayToast(t("error:default"));
      console.error("Couldn't upload avatar", error);
    }
  };

  render() {
    const { loading, customAvatarFile, selectedDefaultAvatar, isEdited } =
      this.state;

    return (
      <>
        <SaveHeader
          title={t("profile:editAvatar.title")}
          saveReady={isEdited}
          onSaveClick={this.handleSubmit.bind(this)}
        />
        {loading ? (
          <Loader />
        ) : (
          <Container>
            <CustomAvatarUploader
              avatarUrl={
                customAvatarFile
                  ? customAvatarFile
                  : defaultAvatarImages[selectedDefaultAvatar]?.default
              }
              onClear={this.handleClearAvatar}
              onChange={this.handleChangeAvatarCustom}
            />

            <Spacer height='20px' />

            <SmallTitle>{t("profile:editAvatar.action.select")}</SmallTitle>

            <DefaultAvatarSelector
              selected={selectedDefaultAvatar}
              onChange={this.handleChangeAvatarDefault}
            />
          </Container>
        )}
      </>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const mapDispatch = {};

const connector = connect(mapState, mapDispatch);

export default connector(EditAvatarPage);

const Container = styled.div`
  padding: 80px 30px 30px;
`;

const SmallTitle = styled.h4`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.02em;
  color: #212121;
`;
