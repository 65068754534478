import { useRef } from "react";

export function useAnimationFrame(callback: Function) {
  // Use useRef for mutable variables that we want to persist
  // without triggering a re-render on their change
  const requestRef = useRef<number | null>(null);
  const previousTimeRef = useRef<number | null>(null);

  function animate(time: number | null) {
    if (time) {
      if (previousTimeRef.current != null) {
        const deltaTime = time - previousTimeRef.current;
        callback(deltaTime)
      }
      previousTimeRef.current = time;
    }
    play();
  }

  function play() {
    requestRef.current = requestAnimationFrame(animate);
  }

  function stop() {
    if (requestRef.current) cancelAnimationFrame(requestRef.current);
  }

  return {
    play,
    stop,
  }
}