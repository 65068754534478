import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { UserModel } from "../../../domains/user/user.types";
import { getRandomUsers } from "../../../domains/user/endpoints/getRandomUsers";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import Loader from "../../Loader";
import ProfilePicture from "../../ProfilePicture";
import styled from "styled-components";
import { ReactComponent as UserIcon } from "../../../assets/icons/home_user_icon.svg";

export default function HomeUserScroller() {
  const history = useHistory();
  const { t } = useLangContext();
  const [isLoading, setLoading] = useState(true);
  const [users, setUsers] = useState<UserModel[]>([]);

  useEffect(() => {
    (async function () {
      try {
        const data = await getRandomUsers({
          limit: 50, // NOTE: 50 users need to be displayed in the search suggestions
        });
        setUsers(data);
        setLoading(false);
      } catch (error) {
        console.error("Couldn't get random users.", error);
      }
    })();
  }, []);

  return (
    <div>
      <Header>
        <div className="title">
          <UserIcon />
          <h3>{t("home:users.title")}</h3>
        </div>
        <Link to="/search/users">
          <span>{t("common:action.seeAll")}</span>
        </Link>
      </Header>

      {isLoading ? (
        <Loader loaderOnly />
      ) : (
        <HScrollContainer className="scrollbar-hidden">
          {users
            ?.filter((el: UserModel) => !!el.firstname && !!el.lastname)
            .map((user) => (
              <UserItem key={user.uid} onClick={() => history.push("/profile/user/" + user.uid)}>
                <ProfilePicture user={user} height={80} withBorder withShadow />
                <span className="name">{user.firstname} {user.lastname}</span>
                <span>
                  {!!user?.profession.translations
                    ? getItemTranslation(user?.profession)
                    : t("common:other")
                  }
                </span>
              </UserItem>
            ))}
        </HScrollContainer>
      )}
    </div>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 21px 16px;
  .title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;
  }
  span {
    color: #ce0868;
  }
  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px 16px;
  display: flex;
  align-items: stretch;
  gap: 30px;
`;

const UserItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 105%;
    text-align: center;
    color: #212121;
  }

  .name {
    margin: 8px 0 4px;
    font-weight: 700;
  }
`;
