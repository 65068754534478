import { getApiRequestService } from "../../../services/index.services";

export async function sendConnectionRequest(toUserId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/user/connection/request", { toUserId });
    return res.data;
  } catch (error) {
    throw error;
  }
}
