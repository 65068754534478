import styled from "styled-components";

export const GPTPageLayout = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, rgba(255, 208, 0, 0.20) 0%, rgba(255, 161, 0, 0.20) 100%), #FFF;

  .page-wrapper {
    position: relative;
    flex: 1;
    overflow: hidden auto;
    display: flex;
    flex-direction: column;
  }
`;

export const GPTConversationWrapper = styled.div`
  flex: 1;
  padding: 32px 0 86px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const GPTChatQuestion = styled.div`
  padding: 0 16px;
  color: #212121;
  font-family: Inter;
  font-size: 21px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 123.81% */
  letter-spacing: -0.21px;
`;

export const GPTBottomScroller = styled.div`
  position: relative;
  max-width: 100%;
  overflow-x: auto;
  margin-bottom: 28px;

  &.floating {
    position: absolute;
    bottom: 28px;
    left: 0;
  }

  .contents {
    width: fit-content;
    padding: 0 15px;
    display: flex;
    gap: 8px;
  }
`;

export const GPTScrollBadge = styled.button`
  padding: 24px;
  display: flex;
  gap: 8px;
  border: 2px solid transparent;
  border-radius: 100px;
  background-color: white;
  color: #e2c5d3;
  line-height: 11px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 700;
  white-space: nowrap;

  &.active {
    color: #ce0868;
    border-color: #ce0868;
  }
`;

export const GPTSourceBadge = styled(GPTScrollBadge)`
    height: 59px;
    animation: fadeIn ease-in 0.1s;
    color: #212121;

    :nth-of-type(1n) {
        background-color: #FF7FBD;
    }
    :nth-of-type(2n) {
        background-color: #FF8D99;
    }
    :nth-of-type(3n) {
        background-color: #FF9B75;
    }
    :nth-of-type(4n) {
        background-color: #FFA850;
    }
    :nth-of-type(5n) {
        background-color: #FFB62C;
    }
    :nth-of-type(6n) {
        background-color: #FFC408;
    }
`;
