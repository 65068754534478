import { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

// TODO: Check if still necessary to have this component. Rename it in any case.
// TODO: Add a prop to get the analytics item list name.

class VideoCard extends Component {
  state = {
    showSheet: false,
  };

  render() {
    return (
      <StoryWrapper onClick={this.props.onClick}>
        <div className="image-container">
          <img src={this.props.video.apiVideo.thumbnail} alt="" />
        </div>
        <p>{this.props.video.title}</p>
      </StoryWrapper>
    );
  }
}

function mapState(state) {
  const user = state.user;
  return {
    user,
  };
}

const connector = connect(mapState);

export default connector(withRouter(VideoCard));

const StoryWrapper = styled.div`
  box-sizing: border-box;

  .image-container {
    width: 200px;
    height: 166px;
    border: 2px solid #ffc408;
    border-radius: 10px;
    background-size: contain;
    background-color: #e5e5e5;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  p {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: left;
    color: #212121;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
