import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ArticleModel } from "../../domains/article/article.types";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { GPTPageLayout } from "../../components/gpt/layout";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import ItemList from "../../components/app/ItemList";
import ArticleCard from "../../components/cards/ArticleCard";
import styled from "styled-components";
import disableScroll from "disable-scroll";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import { ReactComponent as PaperscrollIcon } from "../../assets/icons/gpt/gpt-paperscroll.svg";

export default function GPTChatSourcesPage() {
	const history = useHistory();
	const location = useLocation<{ question: string; sources: ArticleModel[] }>();
	const { question, sources } = location.state;

	useEffect(() => {
		if (!sources) {
			displayToast(t("error:default"));
			return history.goBack();
		}
		disableScroll.off(); // Enable Scroll
	}, []);

	return (
		<GPTPageLayout>
			<HeaderBoxAutoSafe>
				<HeaderLinks><NavBack /></HeaderLinks>
				<HeaderTitle>{t("juisciGpt:sources.title")}</HeaderTitle>
				<HeaderLinks />
			</HeaderBoxAutoSafe>

			<div className="page-wrapper" style={{ paddingTop: 32 }}>
				<Heading>{question}</Heading>

				<Heading style={{ marginTop: 20, marginBottom: 10 }}>
					<PaperscrollIcon />
					{t("common:sources")} ({sources.length})
				</Heading>

				<ItemList>
					{sources.map((article: ArticleModel, index: number) => {
						// TODO: Missing GPT analytics item list.
						return (
							<ArticleCard
								key={index}
								article={article}
								analyticsListName={AnalyticsItemList.NECTAR_PAGE}
							/>
						);
					})}
				</ItemList>
			</div>
		</GPTPageLayout>
	);
}

const Heading = styled.div`
  padding: 0 16px;
	display: inline-flex;
	align-items: center;
	gap: 8px;

	color: #212121;
	font-family: Inter;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 123.81% */
	letter-spacing: -0.21px;
`;
