import { gptApi } from "../gpt.api";

interface QueryParams {
  limit: number;
  offset: number;
}

const api = gptApi.injectEndpoints({
  endpoints: (build) => ({
    getGptConversationsList: build.query<any, QueryParams>({
      query: (params) => {
        return {
          url: "/conversation",
          params,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGptConversationsListQuery,
  useLazyGetGptConversationsListQuery,
} = api;