import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

const defaultProps = {
  showStatus: false,
  showThumbs: false,
  showArrows: false,
  centerMode: false,
  showIndicators: true,
  infiniteLoop: false,
  preventMovementUntilSwipeScrollTolerance: true,
  swipeScrollTolerance: 30,
}

export default function HomeCarousel(props: any) {
  return (
    <Wrapper>
      <Carousel
        {...defaultProps}
        {...props}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .slide {
    padding: 8px;
    display: flex;
  }

  .control-dots {
    box-sizing: border-box;
    margin: 16px 0 0 !important;
  }
`;