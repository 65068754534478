import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NectarModel } from "./nectar.types";

interface ReducerState {
  cache: {
    discovery: {
      list: NectarModel[];
      total: number;
    };
    room: {
      list: NectarModel[];
      total: number;
    };
  };
  currentNectarIndex: number;
}

const initialState: ReducerState = {
  cache: {
    discovery: {
      list: [],
      total: 0,
    },
    room: {
      list: [],
      total: 0,
    },
  },
  currentNectarIndex: -1,
};

export const slice = createSlice({
  name: "nectars",
  initialState,
  reducers: {
    memorizeNectarsList: (state, action: PayloadAction<{ list: NectarModel[]; total: number }>) => {
      state.cache.discovery = action.payload;
    },
    memorizeNectarsListRoom: (state, action: PayloadAction<{ list: NectarModel[]; total: number }>) => {
      state.cache.room = action.payload;
    },
    setSwipeIndex: (state, action: PayloadAction<number>) => {
      state.currentNectarIndex = action.payload;
    },
    clearSwipeIndex: (state) => {
      state.currentNectarIndex = -1;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  memorizeNectarsList,
  memorizeNectarsListRoom,
  setSwipeIndex,
  clearSwipeIndex,
} = slice.actions;

export default slice.reducer;
