import { DEFAULT_LANGUAGE, PUBLIC_INAPP_LANGUAGES } from "../../../config/i18n";
import { SupportedLanguage } from "../../../interfaces";
import { RoomModel } from "../../room/room.types";
import { DebugLogger } from "./debugLogger";

const debug = new DebugLogger("LangUtils");
debug.mute();

export function getStoredAppLang() {
  return localStorage.getItem("appLang") as SupportedLanguage ?? null;
}

export function getStoredRoomLang(roomId: string): SupportedLanguage | null {
  const roomLangs = JSON.parse(localStorage.getItem("roomLangs") ?? "{}") ?? {};
  return roomLangs[roomId] ?? null;
}

export function determineInitialPublicAppLang() {
  const storedLang = localStorage.getItem("appLang") as SupportedLanguage ?? null;
  if (storedLang && PUBLIC_INAPP_LANGUAGES.includes(storedLang)) {
    return storedLang;
  }

  const navigatorLang = navigator.language.slice(0, 2) as SupportedLanguage;
  if (navigatorLang && PUBLIC_INAPP_LANGUAGES.includes(navigatorLang)) {
    return navigatorLang;
  }

  return DEFAULT_LANGUAGE;
}

export function determineInitialRoomLang(room: RoomModel) {
  const navigatorLang = navigator.language.slice(0, 2) as SupportedLanguage;
  if (navigatorLang && room.allowedLanguages.includes(navigatorLang)) {
    debug.log("User locale is accepted in this room and set as the language", { navigatorLang });
    return navigatorLang;
  }
  debug.log("User locale not accepted in this room, use default", { defaultRoomLang: room.allowedLanguages[0] });
  return room.allowedLanguages[0];
}

export function storeRoomLang(roomId: string, lang: SupportedLanguage) {
  debug.log("Storing room lang", { lang });
  const roomLangs = JSON.parse(localStorage.getItem("roomLangs") ?? "{}") ?? {};
  roomLangs[roomId] = lang;
  localStorage.setItem("roomLangs", JSON.stringify(roomLangs));
}
