import { HeaderBoxAutoSafe, HeaderLinks, HeaderLogo, NavLang, NavRooms } from "./layout";

export default function HomeHeader({ onRoomsClick }: {
  onRoomsClick: Function;
}) {
  return (
    <HeaderBoxAutoSafe>
      <HeaderLinks>
        <NavRooms onClick={onRoomsClick} />
      </HeaderLinks>
      <HeaderLogo />
      <HeaderLinks>
        <NavLang />
      </HeaderLinks>
    </HeaderBoxAutoSafe>
  );
}
