import { getDiscoveryNotifications } from "../endpoints/getDiscoveryNotifications";
import { readNotification } from "../endpoints/readNotification";

export async function checkCertificationAnswer() {
  // TODO: missing catch.
  const notifications = await getDiscoveryNotifications();

  // Get all unread certification answers (approved/rejected)
  const certificationNotifications = notifications.filter((notification: {
    action: {
      notificationType: string,
    };
    read: boolean;
  }) => {
    return (
      notification.action.notificationType == "LemonCertifiedAnswered" &&
      !notification.read
    );
  });

  // Mark all certification answer notifications as read
  for (const notification of certificationNotifications) {
    // TODO: missing catch.
    readNotification(notification._id);
  }

  return certificationNotifications.length > 0;
};
