import { getApiRequestService } from "../../../services/index.services";

export async function unfollowUser(connectionRequestId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`/user/connection/${connectionRequestId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
