import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { useLangContext } from "../../app/contexts/lang.context";
import { useRoomContext } from "../../app/contexts/room.context";
import { RoomModel } from "../room.types";
import { preflightUser } from "../../user/utils/preflightUser";
import { getRoomById } from "../endpoints/getRoomById";
import { useCurrentUser } from "../../user/hooks/useCurrentUser";
import { useLazyGetUserRoomsQuery } from "../../user/endpoints/getUserRooms";
import { enterRoom, exitRoom } from "../../../redux/user/user.reducer";
import { SpecialtyModel, TagModel } from "../../app/app.types";

export function useRoomPreflight(roomId: string) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const storeSpecialties = useAppSelector((state) => state.specialties.medical_specialties);
  const storeTags = useAppSelector((state) => state.specialties.tags);
  const { rooms } = useCurrentUser();
  const { t } = useLangContext();
  const { isInRoom, currentRoomId, setCurrentRoom } = useRoomContext();
  const activeRoom = rooms.find((room: RoomModel) => room._id === currentRoomId);
  const [roomData, setRoomData] = useState<RoomModel | null>(activeRoom ?? null);
  const [isLoadingRoom, setLoadingRoom] = useState(!activeRoom);
  const [getUserRooms] = useLazyGetUserRoomsQuery();

  useEffect(() => {
    return () => {
      if (!history.location.pathname.includes(roomId)) {
        dispatch(exitRoom());
      }
    }
  }, []);

  useEffect(() => {
    if (isInRoom) setLoadingRoom(false);
  }, [isInRoom]);

  async function preflightRoom() {
    try {
      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.default"),
      });

      if (!isRedirected) {
        let data = roomData ?? await getRoomById(roomId);
        const userRooms = await getUserRooms().unwrap();

        const canAccess = data.isPublic || userRooms.some((room: RoomModel) => room._id === roomId);
        if (!canAccess) {
          throw new Error("Room not in user's rooms.");
        }

        if (typeof data.mainSpecialty === "string") {
          // NOTE: Quick fix until proper populate on user rooms query.
          // TODO: Check if still relevant.
          data = {
            ...data,
            mainSpecialty: storeSpecialties.find((spe: SpecialtyModel) => spe._id === data.mainSpecialty),
          };
        }
        if (data.tags.length > 0 && typeof data.tags[0] === "string") {
          // NOTE: Quick fix until proper populate on user rooms query.
          // TODO: Check if still relevant.
          data = {
            ...data,
            tags: data.tags.map((tagId: string) => storeTags.find((tag: TagModel) => tag._id === tagId)),
          };
        }

        setRoomData(data);
        dispatch(enterRoom(data as RoomModel));
        setCurrentRoom(data);
      }

      return { isRedirected };
    } catch (error) {
      throw error;
    }
  }

  return {
    isLoadingRoom,
    roomData,
    preflightRoom,
  };
}
