import { getApiRequestService } from "../../../services/index.services";

export async function joinRoomByQrCodeId(qrCodeId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/user/room/qr-code/${qrCodeId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
