import { useEffect } from "react";
import { iosSetBackgroundColor, isNativeIOS } from "../../../tools/ios";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "./layout";
import AppButton from "../AppButton";
import { t } from "i18next";

export default function SaveHeader({ title, saveReady, onSaveClick, onGoBack }: {
  title: string;
  saveReady: boolean;
  onSaveClick: Function;
  onGoBack?: Function;
}) {
  useEffect(() => {
    if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");
  }, []);

  return (
    <HeaderBoxAutoSafe>
      <HeaderLinks><NavBack onClick={onGoBack} /></HeaderLinks>
      <HeaderTitle>{title}</HeaderTitle>
      <HeaderLinks>
        <AppButton
          type="submit"
          className="s"
          disabled={!saveReady}
          onClick={async () => {
            await onSaveClick();
          }}
        >
          {t("common:action.save")}
        </AppButton>
      </HeaderLinks>
    </HeaderBoxAutoSafe>
  );
}
