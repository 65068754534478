export function isUserOnboarded(
  user: {
    firstname: string;
    lastname: string;
    country: string;
    profession: unknown;
    main_specialty?: unknown;
    profilePicture?: {
      name: string;
      url: string;
    };
  },
) {
  return (
    !!user.firstname &&
    !!user.lastname &&
    !!user.country &&
    (!!user.profession && typeof user.profession === "object") &&
    (!!user.main_specialty && typeof user.main_specialty === "object") &&
    (user.profilePicture && (!!user.profilePicture?.name || !!user.profilePicture?.url))
  );
}