import { ReactChildren, ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";

export default function SafeAreaTopWrapper({ children, float }: {
  children: ReactNode | ReactChildren;
  float?: boolean;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState(
    parseInt(localStorage.getItem("safeAreaTopWrapperHeight") ?? "0") ?? 0
  );

  useEffect(() => {
    const wrapperHeight = containerRef.current?.clientHeight;
    if (wrapperHeight) {
      setContainerHeight(wrapperHeight);
      localStorage.setItem(
        "safeAreaTopWrapperHeight",
        JSON.stringify(wrapperHeight),
      )
    }
  }, [containerRef.current, containerRef.current?.clientHeight]);

  return (
    <>
      <Container ref={containerRef} className="safe-area-top-wrapper">
        {children}
      </Container>
      {!float && <div style={{ height: containerHeight }} />}
    </>
  );
}

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  padding-top: var(--safe-area-top);
  background: inherit;
  background-color: inherit;
  background-image: inherit;
  box-shadow: inherit;
`;
