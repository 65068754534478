import Lottie from "lottie-react";
import { SupportedLanguage } from "../../interfaces";
import i18n from "../../config/i18n";
import styled from "styled-components";
import MotionTitleEN from "../../assets/animations/science-in-motion-en.json";
import MotionTitleFR from "../../assets/animations/science-in-motion-fr.json";

export default function AnimatedFooter({ onClick }: { onClick: Function }) {
  return (
    <Container onClick={() => onClick()}>
      <Lottie
        animationData={
          i18n?.resolvedLanguage === SupportedLanguage.FR
            ? MotionTitleFR
            : MotionTitleEN
        }
        className="animation-lottie"
        loop={true}
        autoplay={true}
      />
    </Container>
  );
}

const Container = styled.footer`
  background-color: #fff;
  background: linear-gradient(180deg, #fff 0%, #f5f5f5 70%, #ffa100 250%);
  padding: 21px 24px 48px;

  .animation-lottie {
    width: 100%;
    max-width: 210px;
    height: 100%;
    margin: auto;
  }
`;
