import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { SupportedLanguage } from "../../interfaces";
import { t } from "i18next";
import i18n from "../../config/i18n";
import { ReactComponent as Glass } from "../../assets/icons/gpt/nav-glass.svg";
import { ReactComponent as GlassActive } from "../../assets/icons/gpt/nav-glass-active.svg";
import { ReactComponent as NewChat } from "../../assets/icons/gpt/nav-new-chat.svg";
import { ReactComponent as NewChatActive } from "../../assets/icons/gpt/nav-new-chat-active.svg";
import { ReactComponent as RecentChats } from "../../assets/icons/gpt/nav-recent-chats.svg";
import { ReactComponent as RecentChatsActive } from "../../assets/icons/gpt/nav-recent-chats-active.svg";

export default function GPTNav() {
  const location = useLocation();

  return (
    <MenuContainer>
      <Link
        className={`side-link ${location.pathname.startsWith("/gpt/discover") ? "active" : ""}`}
        to='/gpt/discover'
      >
        {location.pathname.startsWith("/gpt/discover") ? <GlassActive /> : <Glass />}
        <span> {t("juisciGpt:nav.discover")}</span>
      </Link>

      <Link
        className={`chat-link ${location.pathname.startsWith("/gpt/chat/") ? "active" : ""}`}
        to='/gpt/chat/new'
      >
        {location.pathname.startsWith("/gpt/chat/") ? <NewChatActive /> : <NewChat />}
        <span className={i18n.resolvedLanguage === SupportedLanguage.FR ? "squeezed" : ""}>
          {t("juisciGpt:nav.newChat")}
        </span>
      </Link>

      <Link
        className={`side-link ${location.pathname === "/gpt/chat/history" ? "active" : ""}`}
        to='/gpt/chat/history'
      >
        {location.pathname === "/gpt/chat/history" ? <RecentChatsActive /> : <RecentChats />}
        <span>{t("juisciGpt:nav.recent")}</span>
      </Link>
    </MenuContainer>
  );
}

const MenuContainer = styled.nav`
  padding: 12px 24px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ff8800;
  box-shadow: -4px -4px 10px 0px #2121211a;

  .side-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    opacity: 0.4;
    font-family: Inter;
    font-size: 10px;
    font-weight: 700;
    color: #313b42;
    fill: #313b42;

    &.active {
      opacity: 1;
    }
  }

  .chat-link {
    padding: 8px 24px;
    border-radius: 100px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 15.2px;
    color: #212121;
    background-color: #ffc408;

    svg {
      width: 33px;
      height: 33px;
      fill: #ff8800;
    }

    span.squeezed {
      width: min-content; // NOTE: Hack for FR text
    }

    &.active {
      background-color: #ffffff;
    }
  }
`;
