import React from "react";
import styled from "styled-components";

import { ReactComponent as LemonCertified } from "../../assets/icons/lemon-certified-welcomescreen.svg";
import person1 from "../../assets/images/welcome-pics/4.0/lemon-certified/person-1.png";
import person2 from "../../assets/images/welcome-pics/4.0/lemon-certified/person-2.png";
import person3 from "../../assets/images/welcome-pics/4.0/lemon-certified/person-3.png";
import person4 from "../../assets/images/welcome-pics/4.0/lemon-certified/person-4.png";
import person5 from "../../assets/images/welcome-pics/4.0/lemon-certified/person-5.png";
import person6 from "../../assets/images/welcome-pics/4.0/lemon-certified/person-6.png";

const Container = styled.div`
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    animation: fade-in 4s ease-in-out forwards;
  }
`;

const SpinningContainer = styled.div`
  position: relative;
  width: 280px;
  height: 280px;
  animation: spin 100s linear infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

const ProfileImage = styled.div<{ x: number; y: number; delay: number }>`
  width: 75px;
  height: 75px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: calc(50% + ${(props) => props.y}px);
  left: calc(50% + ${(props) => props.x}px);
  transform: translate(-50%, -50%);
  animation: counter-spin 100s linear infinite,
    fade-in 1s ease-in-out ${(props) => props.delay}s forwards;
  opacity: 0;

  @keyframes counter-spin {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(-360deg);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const OrbitingProfilePictures: React.FC = () => {
  const profiles = [person1, person2, person3, person4, person5, person6];
  const radius = 90;

  return (
    <Container>
      <LemonCertified />
      <SpinningContainer>
        {profiles.map((profile, index) => {
          const angle = (index / profiles.length) * 2 * Math.PI;
          const x = Math.cos(angle) * radius;
          const y = Math.sin(angle) * radius;
          const delay = index * 0.2;
          return (
            <ProfileImage
              key={index}
              style={{
                backgroundImage: `url(${profile})`,
              }}
              x={x}
              y={y}
              delay={delay}
            />
          );
        })}
      </SpinningContainer>
    </Container>
  );
};

export default OrbitingProfilePictures;
