import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as DoubleLemonIcon } from "../../assets/icons/nectar/double-lemon.svg";

export function JuisciGPTBadge() {
  return (
    <Container>
      {t("juisciGpt:poweredBy")}
      <DoubleLemonIcon className="lemon-icon" />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
  padding: 4px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: Inter;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  background-color: #eef822;
  color: #212121;

  &.small {
    padding: 4px 12px;
    font-size: 10px;
    line-height: 10px;

    .lemon-icon {
      width: 18px;
      height: 18px;
    }
  }
`;
