import { getApiRequestService } from "../../../services/index.services";

export async function likeArticle(articleId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/user/me/like/${articleId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
