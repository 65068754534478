import { Component } from "react";
import styled from "styled-components";
import Hexagon from "../../assets/icons/gamification/hexagon.svg";
import HexagonDisabled from "../../assets/icons/gamification/hexagon_disabled.svg";
import i18n from "../../config/i18n";
import { SupportedLanguage } from "../../interfaces";

const HexagonActive = ({ color }) => (
  <svg
    width='97'
    height='114'
    viewBox='0 0 97 114'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M40.2649 5.99957L7.68282 24.2068C2.93906 26.8576 0 31.8668 0 37.301V78.5348C0 84.0561 3.0332 89.131 7.89606 91.7459L40.4735 109.263C44.8235 111.603 50.0461 111.65 54.4382 109.391L88.8608 91.6863C93.8585 89.1158 97 83.9673 97 78.3473V37.4906C97 31.9559 93.9522 26.8709 89.071 24.2618L54.6531 5.86499C50.1471 3.45649 44.7251 3.5072 40.2649 5.99957Z'
      fill={color}
    />
    <g filter='url(#filter0_d_1_1743)'>
      <path
        d='M40.9061 11.0066L13.0661 26.5795C8.3255 29.2312 5.38889 34.2387 5.38889 39.6706V75.066C5.38889 80.5851 8.41965 85.6584 13.2794 88.2742L41.1147 103.257C45.4676 105.6 50.6954 105.648 55.0904 103.385L84.5551 88.2148C89.5498 85.6432 92.6889 80.4963 92.6889 74.8785V39.8603C92.6889 34.3278 89.6435 29.2444 84.7654 26.6344L55.3054 10.8719C50.7964 8.45938 45.3691 8.51017 40.9061 11.0066Z'
        fill={color}
      />
      <path
        d='M13.7984 27.8886L41.6384 12.3157C45.6551 10.0689 50.5397 10.0232 54.5977 12.1945L84.0577 27.957C88.448 30.306 91.1889 34.881 91.1889 39.8603V74.8785C91.1889 79.9346 88.3637 84.5667 83.8685 86.8811L54.4038 102.051C50.4483 104.088 45.7433 104.045 41.8257 101.936L13.9904 86.9534C9.61658 84.5991 6.88889 80.0332 6.88889 75.066V39.6706C6.88889 34.7819 9.53184 30.2752 13.7984 27.8886Z'
        stroke='white'
        strokeWidth='3'
      />
    </g>
    <g opacity='0.2'>
      <path
        d='M55.0121 29.6076C51.7402 29.6076 48.4936 30.4162 45.5931 31.9833L45.5289 32.0173C36.1925 37.0616 28.5666 44.6132 23.4765 53.8615L23.4421 53.925C21.241 57.9246 20.5233 62.585 21.4221 67.0524L23.144 75.6147C23.4169 76.9729 23.1669 78.3719 22.4378 79.5552L21.2639 81.4607C20.6746 82.4169 20.8214 83.6365 21.6216 84.4291C22.4218 85.2218 23.653 85.3671 24.6183 84.7834L26.542 83.6206C27.7343 82.8984 29.1489 82.6508 30.5201 82.9211L39.1732 84.629C43.674 85.517 48.3721 84.8107 52.4029 82.6394C52.4074 82.6372 52.4097 82.6349 52.4143 82.6326C61.7668 77.5906 69.4088 70.0322 74.5081 60.7726L74.5631 60.6727C76.7642 56.6754 77.4842 52.0127 76.5854 47.5453L74.8657 38.9944C74.5929 37.6362 74.8428 36.2372 75.5719 35.0539L76.7459 33.1484C77.3351 32.1922 77.1884 30.9726 76.3882 30.1822C75.588 29.3896 74.3567 29.2442 73.3915 29.8279L71.4678 30.9908C70.2755 31.713 68.8608 31.9606 67.4897 31.6903L58.8457 29.9846C57.5755 29.7325 56.2915 29.6076 55.0121 29.6076Z'
        fill={color}
      />
      <path
        d='M23.3386 89C21.7038 89 20.0874 88.3709 18.8607 87.1581C16.7834 85.1004 16.4028 81.9299 17.9344 79.4475L19.1083 77.542C19.3262 77.1899 19.3995 76.7743 19.3193 76.37L17.5974 67.8077C16.522 62.4569 17.3818 56.8698 20.0186 52.0776L20.053 52.014C25.503 42.114 33.6678 34.0264 43.66 28.6278L43.7242 28.5937C48.5621 25.9796 54.2024 25.1302 59.6044 26.1954L68.2483 27.901C68.6565 27.9828 69.076 27.9078 69.4314 27.6921L71.3551 26.527C73.8612 25.0098 77.062 25.3891 79.1393 27.4445C81.2166 29.5022 81.5972 32.6727 80.0656 35.1551L78.8917 37.0606C78.6738 37.4127 78.6005 37.8283 78.6807 38.2326L80.4003 46.7835C81.478 52.1367 80.6159 57.7237 77.9768 62.5159L77.9218 62.6158C72.4603 72.5318 64.2771 80.6239 54.2598 86.0225C54.2575 86.0248 54.2529 86.0271 54.2506 86.0271C49.4219 88.6253 43.7953 89.4701 38.4048 88.4072L29.7517 86.6993C29.3435 86.6176 28.924 86.6925 28.5686 86.9083L26.6449 88.0734C25.6177 88.6979 24.4736 89 23.3386 89ZM55.0118 29.6089C51.7399 29.6089 48.4933 30.4175 45.5929 31.9846L45.5287 32.0186C36.1922 37.0629 28.5663 44.6145 23.4762 53.8627L23.4418 53.9263C21.2407 57.9259 20.523 62.5863 21.4218 67.0537L23.1437 75.616C23.4166 76.9742 23.1666 78.3732 22.4375 79.5565L21.2636 81.462C20.6743 82.4182 20.8211 83.6378 21.6213 84.4304C22.4215 85.2231 23.6527 85.3684 24.618 84.7847L26.5417 83.6219C27.734 82.8997 29.1487 82.6521 30.5198 82.9224L39.1729 84.6303C43.6737 85.5183 48.3718 84.812 52.4026 82.6407C52.4071 82.6385 52.4094 82.6362 52.414 82.6339C61.7665 77.5919 69.4085 70.0335 74.5078 60.7739L74.5628 60.674C76.7639 56.6767 77.4839 52.014 76.5851 47.5466L74.8655 38.9957C74.5926 37.6375 74.8425 36.2385 75.5716 35.0552L76.7456 33.1497C77.3348 32.1935 77.1881 30.9739 76.3879 30.1835C75.5877 29.3909 74.3564 29.2455 73.3912 29.8292L71.4675 30.9921C70.2752 31.7143 68.8605 31.9619 67.4894 31.6916L58.8454 29.9859C57.5752 29.7338 56.2912 29.6089 55.0118 29.6089Z'
        fill='#212121'
      />
      <path
        d='M37.3658 47.672C36.8682 47.672 36.3707 47.4835 35.9901 47.1087C35.2266 46.357 35.2243 45.1351 35.9832 44.3788C39.4637 40.9152 43.451 37.9627 47.8326 35.6007L47.883 35.5735C50.2951 34.2721 53.0006 33.5907 55.7222 33.5907C56.8021 33.5907 57.8798 33.6975 58.9253 33.9064C59.98 34.1176 60.661 35.1374 60.4477 36.1821C60.2345 37.2269 59.2073 37.9037 58.1503 37.6902C57.3593 37.5312 56.543 37.4517 55.7222 37.4517C53.6495 37.4517 51.5882 37.9718 49.7631 38.9575L49.7127 38.9848C45.6475 41.1764 41.9629 43.9064 38.7484 47.1042C38.3678 47.4812 37.8656 47.672 37.3658 47.672Z'
        fill='#212121'
      />
      <path
        d='M46.6553 79.3865C45.9629 79.3865 45.3347 78.9096 45.1811 78.2123C45.0962 77.8217 45.1673 77.422 45.3851 77.0859C45.6029 76.7497 45.94 76.5181 46.3343 76.4318C46.7287 76.3477 47.1323 76.4181 47.4693 76.6339C47.8086 76.8497 48.0425 77.1835 48.1296 77.5742C48.3062 78.3804 47.788 79.1776 46.9763 79.3525C46.8686 79.3752 46.7608 79.3865 46.6553 79.3865Z'
        fill='#212121'
      />
      <path
        d='M45.4333 73.8368C45.1467 73.8368 44.8669 73.755 44.6193 73.5983C44.28 73.3826 44.0461 73.0487 43.9613 72.6603C43.7847 71.8563 44.3006 71.0592 45.11 70.882C45.5089 70.7957 45.9125 70.8684 46.2518 71.0842C46.5911 71.2999 46.825 71.6338 46.9098 72.0244C46.9947 72.4151 46.9236 72.8148 46.7058 73.1509C46.488 73.487 46.1509 73.7187 45.7565 73.805C45.6488 73.8255 45.541 73.8368 45.4333 73.8368Z'
        fill='#212121'
      />
      <path
        d='M51.4474 72.2685C51.1631 72.2685 50.8833 72.189 50.638 72.0323C50.2987 71.8165 50.0625 71.4826 49.9777 71.092C49.8928 70.7014 49.9639 70.3016 50.1817 69.9655C50.3996 69.6294 50.7366 69.3977 51.131 69.3114C51.5253 69.2251 51.9289 69.2978 52.2682 69.5135C52.6076 69.7293 52.8414 70.0632 52.9263 70.4538C53.0111 70.8444 52.94 71.2442 52.7222 71.5803C52.5044 71.9164 52.1673 72.1481 51.773 72.2321C51.6629 72.2571 51.5551 72.2685 51.4474 72.2685Z'
        fill='#212121'
      />
      <path
        d='M39.3011 74.8935C39.0145 74.8935 38.7348 74.8118 38.4894 74.6551C38.1501 74.4393 37.9162 74.1055 37.8314 73.7148C37.7465 73.3242 37.8176 72.9244 38.0354 72.5883C38.2533 72.2522 38.5903 72.0205 38.9847 71.9365C39.3745 71.8502 39.778 71.9229 40.1173 72.1363C40.4567 72.3521 40.6928 72.686 40.78 73.0743C40.9565 73.8829 40.4406 74.6801 39.6267 74.8549C39.5189 74.8822 39.4089 74.8935 39.3011 74.8935Z'
        fill='#212121'
      />
      <path
        d='M67.6617 45.05C69.3079 45.05 70.6424 44.4907 70.6424 43.8008C70.6424 43.1109 69.3079 42.5517 67.6617 42.5517C66.0156 42.5517 64.6811 43.1109 64.6811 43.8008C64.6811 44.4907 66.0156 45.05 67.6617 45.05Z'
        fill='#212121'
      />
      <path
        d='M67.6609 44.3673C66.5856 44.3673 65.712 43.502 65.712 42.4368V10.8834C65.712 10.3724 65.9184 9.87959 66.283 9.51847L75.3213 0.565521C76.0825 -0.188507 77.3161 -0.188507 78.0773 0.565521C78.8385 1.31955 78.8385 2.54144 78.0773 3.29547L69.6099 11.6829V42.4368C69.6099 43.5043 68.7363 44.3673 67.6609 44.3673Z'
        fill='#212121'
      />
    </g>
    <path
      d='M67.5 13C66.4247 13 65.75 12.7481 65.75 11.6829V10.6875C65.75 10.1765 66.1354 9.86112 66.5 9.5L75.6093 0.565521C76.3705 -0.188507 77.604 -0.188507 78.3652 0.565521C79.1265 1.31955 79.1265 2.54144 78.3652 3.29547L69.8978 11.6829V14C69.8978 15.0674 68.5753 13 67.5 13Z'
      fill='#212121'
    />
    <line x1='57' y1='30.5' x2='80' y2='30.5' stroke='white' />
    <path
      d='M49.3893 33.9999C49.2704 33.9999 49.1721 33.9471 49.0958 33.842C49.0287 33.7494 49.0274 33.6216 49.0927 33.5277L49.102 33.5128C49.1148 33.4918 49.1324 33.463 49.1573 33.4334C49.6989 32.4737 49.3476 32.1066 48.7687 31.5016C48.3276 31.0407 47.7973 30.4864 47.6267 29.5485C46.6967 30.9101 47.3613 32.2281 48.0809 33.2522C48.2604 33.5076 48.3505 33.6357 48.2584 33.8091C48.102 34.104 47.7773 33.9763 47.6386 33.9217C47.605 33.9085 47.5723 33.8951 47.5431 33.8871C46.3379 33.5565 45.4042 32.6562 45.1061 31.5373C44.7683 30.2692 45.2434 28.8563 46.4438 27.559C47.5844 26.3267 47.5893 25.1413 47.4907 24.5446C47.4604 24.3608 47.533 24.1825 47.6802 24.0791C47.827 23.976 48.0114 23.9735 48.1615 24.0726C48.5103 24.303 48.8237 24.6119 49.0926 24.9908C49.6685 25.8018 49.9605 26.7222 49.9605 27.7262C49.9605 28.9501 50.2173 29.0897 50.22 29.0906C50.4712 28.8783 50.3854 28.3976 50.2764 27.9231C50.2331 27.7376 50.3152 27.5527 50.4754 27.4699C50.6337 27.388 50.8213 27.4318 50.9318 27.5763C51.0327 27.7082 51.1134 27.8404 51.1914 27.968L51.226 28.0245C51.9769 29.247 52.1788 30.4566 51.8432 31.7224C51.5337 32.8901 50.6057 33.796 49.534 33.9772L49.5195 33.9811C49.4737 33.9938 49.4304 33.9999 49.3893 33.9999ZM47.8222 28.6425C47.8545 28.6425 47.8871 28.649 47.9182 28.6625C48.012 28.7032 48.0731 28.7993 48.0731 28.9057C48.0731 30.0316 48.607 30.5895 49.1232 31.1289C49.6662 31.6964 50.2261 32.2815 49.7481 33.3843C50.5025 33.1354 51.1308 32.4447 51.3596 31.5815C51.6435 30.5104 51.4857 29.4785 50.8769 28.4317C50.9107 28.8159 50.8594 29.2272 50.5327 29.4989C50.3783 29.6272 50.1743 29.6521 50 29.5638C49.6357 29.3791 49.4586 28.778 49.4586 27.7262C49.4586 26.8379 49.2 26.0233 48.6901 25.3051C48.4877 25.0202 48.258 24.7821 48.0057 24.5961C48.095 25.326 48.0169 26.6153 46.8044 27.9256C45.7333 29.083 45.302 30.3154 45.5898 31.3957C45.8273 32.2877 46.5582 33.0175 47.5159 33.3314C46.939 32.4718 45.8859 30.5635 47.6446 28.7196C47.6925 28.6691 47.7568 28.6425 47.8222 28.6425Z'
      fill='white'
    />
    <path
      d='M47.8222 28.6425C47.8545 28.6425 47.8871 28.649 47.9182 28.6625C48.012 28.7032 48.0731 28.7993 48.0731 28.9057C48.0731 30.0316 48.607 30.5895 49.1232 31.1289C49.6662 31.6964 50.2261 32.2815 49.7481 33.3843C50.5025 33.1354 51.1308 32.4447 51.3596 31.5815C51.6435 30.5104 51.4857 29.4785 50.8769 28.4317C50.9107 28.8159 50.8594 29.2272 50.5327 29.4989C50.3783 29.6272 50.1743 29.6521 50 29.5638C49.6357 29.3791 49.4586 28.778 49.4586 27.7262C49.4586 26.8379 49.2 26.0233 48.6901 25.3051C48.4877 25.0202 48.258 24.7821 48.0057 24.5961C48.095 25.326 48.0169 26.6153 46.8044 27.9256C45.7333 29.083 45.302 30.3154 45.5898 31.3957C45.8273 32.2877 46.5582 33.0175 47.5159 33.3314C46.939 32.4718 45.8859 30.5635 47.6446 28.7196C47.6925 28.6691 47.7568 28.6425 47.8222 28.6425Z'
      fill='white'
    />
    <line x1='17' y1='30.5' x2='40' y2='30.5' stroke='white' />
    <defs>
      <filter
        id='filter0_d_1_1743'
        x='1.38889'
        y='9.09775'
        width='95.3'
        height='103.951'
        filterUnits='userSpaceOnUse'
        colorInterpolationFilters='sRGB'
      >
        <feFlood floodOpacity='0' result='BackgroundImageFix' />
        <feColorMatrix
          in='SourceAlpha'
          type='matrix'
          values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
          result='hardAlpha'
        />
        <feOffset dy='4' />
        <feGaussianBlur stdDeviation='2' />
        <feComposite in2='hardAlpha' operator='out' />
        <feColorMatrix
          type='matrix'
          values='0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0 0.129412 0 0 0 0.25 0'
        />
        <feBlend
          mode='normal'
          in2='BackgroundImageFix'
          result='effect1_dropShadow_1_1743'
        />
        <feBlend
          mode='normal'
          in='SourceGraphic'
          in2='effect1_dropShadow_1_1743'
          result='shape'
        />
      </filter>
    </defs>
  </svg>
);

const COLORS_PALETTES = [
  "#FFC408",
  "#FFBD07",
  "#FFB706",
  "#FFB005",
  "#FFA904",
  "#FFA304",
  "#FF9C03",
  "#FF9502",
  "#FF8F01",
  "#FF8800",
  "#FF862A",
  "#FF853F",
  "#FF8454",
  "#FF8369",
  "#FF827E",
  "#FF8193",
  "#FF80A8",
  "#FF7FBD",
];

class GamificationBadge extends Component {
  render() {
    return (
      <StyledWrapper {...this.props}>
        {this.props.disabled ? (
          <img src={HexagonDisabled} alt='' />
        ) : (
          <HexagonActive
            color={COLORS_PALETTES[parseInt(this.props.count / 5) - 1]}
          />
        )}
        {i18n.resolvedLanguage === SupportedLanguage.FR ? (
          <div className='count-text'>
            <span className='small-text'>Série de</span>
            {this.props.count ?? this.props.children}
            <span className='small-text'>jours</span>
          </div>
        ) : (
          <div className='count-text'>
            {this.props.count ?? this.props.children}
            <span className='small-text'>day streak</span>
          </div>
        )}
      </StyledWrapper>
    );
  }
}

export default GamificationBadge;

const StyledWrapper = styled.div`
  position: relative;
  width: fit-content;

  .count-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -40%);
    display: block;
    margin: auto;
    width: 100%;

    /** Font style */
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 100%;
    text-align: center;
    color: ${(props) => (props.disabled ? "#fff" : "#212121")};

    .small-text {
      display: block;
      font-weight: 400;
      font-size: 11px;
      line-height: 100%;
    }
  }
`;
