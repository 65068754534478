import { useEffect, useState } from "react";

// A hook to separate mount and subsequent effects and avoid conflicts.

export function useReadyState() {
  const [isReady, setReady] = useState(false);

  const useMount = (cb: Function) => useEffect(() => cb(), []);

  const useReadyEffect = (cb: Function, dependencies: any[]) => useEffect(() => {
    if (isReady) cb();
  }, dependencies);

  return {
    isReady,
    setAsReady: () => setTimeout(() => setReady(true), 0),
    useMount,
    useReadyEffect,
  }
}
