import { useEffect } from "react";
import { useLangContext } from "./domains/app/contexts/lang.context";
import { SupportedLanguage } from "./interfaces";
import { InlineWidget } from "react-calendly";
import disableScroll from "disable-scroll";
import styled from "styled-components";
import i18n from "./config/i18n";
import { Trans } from "react-i18next";
import BrowserBG from "./assets/images/desktop-page/juisci-pattern.svg";
import JuisciLogo from "./assets/core/logo-with-tagline.svg";
import MobileJuisciEN from "./assets/images/desktop-page/juisci-phone-eng.svg";
import MobileJuisciFR from "./assets/images/desktop-page/juisci-phone-fr.svg";
import DashboardJuisci from "./assets/images/browser-page-dashboard.png";

export default function BrowserPage() {
  const { t } = useLangContext();

  useEffect(() => {
    disableScroll.off();
  }, []);

  return (
    <PageWrapper>
      <div className="background" />
      <InnerContent>
        <img className="logo-brand" src={JuisciLogo} />
        <div className="oopsy-message">{t("common:browserPage.oopsMessage")}</div>

        <h1>
          <Trans
            i18nKey="common:browserPage.title"
            components={{ span: <span /> }}
          />
        </h1>
        <img
          className="mobile-screenshot"
          src={{
            [SupportedLanguage.EN]: MobileJuisciEN,
            [SupportedLanguage.FR]: MobileJuisciFR,
          }[i18n.resolvedLanguage] ?? MobileJuisciEN}
          alt=""
        />

        <span className="text">
          {t("common:browserPage.needBiggerScreen")}
          <br />
        </span>
        <span className="text">
          <Trans
            i18nKey="common:browserPage.discoverCompanyVersion"
            components={{ a: <a /> }}
          />
        </span>
        <img className="dashboard-screenshot" src={DashboardJuisci} alt="Juisci Dashboard" />

        <span className="text">{t("common:browserPage.bookDemoNow")}</span>

        <InlineWidget
          styles={{
            height: "800px",
            overflow: "hidden",
          }}
          pageSettings={{
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
          }}
          url='https://calendly.com/juisci-demo/juisci-demo'
        />

        <div className="footer">
          <div className="left">
            <a href="https://www.juisci.com/terms-of-use">
              {t("common:termsOfUse")}
            </a>
            <a href="https://www.juisci.com/privacy-policy">
              {t("common:privacyPolicy")}
            </a>
          </div>
          <div className="right">
            {`© 2024, Juisci SAS. ${t("common:allRightsReserved")}.`}
          </div>
        </div>
      </InnerContent>
    </PageWrapper>
  );
}

const PageWrapper = styled.div`
  position: relative;
  min-height: 100dvh;
  overflow: hidden;

  ::before {
    content: "";
    position: absolute;
    overflow: hidden;
    width: 200%;
    height: 200%;
    top: 0;
    left: -50%;
    z-index: -1;
    background: #f7f8fc;
    background-image: url(${BrowserBG});
    transform:
      rotate(-10deg)
      scale(
        ${window.innerWidth > 900 ? 1.32 : window.innerWidth > 700 ? 1.45 : 1.6}
      );
  }
`;

const InnerContent = styled.div`
  box-sizing: border-box;
  background: #fff;
  width: 60%;
  min-width: 414px;
  min-height: 100dvh;
  margin: 0 auto;
  padding: 21px 80px;
  text-align: center;
  color: #212121;

  img {
    display: block;
    margin: 0 auto;

    &.logo-brand {
      height: 88px;
      margin: 48px auto;
    }
  
    &.mobile-screenshot {
      width: 20%;
      margin: 48px auto;
    }

    &.dashboard-screenshot {
      width: 90%;
    }
  }

  .oopsy-message {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: center;
  }

  h1 {
    font-family: "Inter";
    font-size: 32px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: center;

    span {
      color: #ff8800;
    }
  }

  .text {
    font-family: Inter;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    white-space: pre-line;
    
    a {
      color: #ff8800;
      font-weight: 700;
      text-decoration: none;
    }
  }

  .footer {
    padding: 32px 0px;
    border-top: 0.5px solid #212121;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 24px;

    .right {
      font-family: Inter;
      font-size: 12px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.04em;
      text-align: left;
      color: #212121;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 8px;

      a {
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.04em;
        text-align: left;
        text-decoration: none;
        color: #212121;
      }
    }
  }
`;
