import { gaPushEvent, gaPushEventDelay } from "./gaPushEvent"

enum Event {
  AUTH_LOGIN = "login",
  AUTH_SIGNUP = "sign_up",
  VIEW_SIGN_UP = "view_sign_up",
  START_SIGN_UP = "start_sign_up",
  VIEW_ONBOARDING = "view_onboarding",
  COMPLETE_ONBOARDING = "complete_onboarding",
}

export function gaEventAuthLogin(userUid: string) {
  gaPushEvent(
    Event.AUTH_LOGIN,
    {
      user: {
        account_id: userUid,
      },
    },
  );
}

export function gaEventAuthSignup(userUid: string) {
  gaPushEvent(
    Event.AUTH_SIGNUP,
    {
      user: {
        account_id: userUid,
      },
      // TODO: Check missing lead_profile field.
    },
  );
}

export function gaEventViewSignUp() {
  gaPushEvent(Event.VIEW_SIGN_UP);
}

export function gaEventStartSignUp() {
  gaPushEvent(Event.START_SIGN_UP);
}

export function gaEventViewOnboarding() {
  gaPushEventDelay(Event.VIEW_ONBOARDING);
}

export function gaEventCompleteOnboarding() {
  gaPushEvent(Event.COMPLETE_ONBOARDING);
}
