import { getApiRequestService } from "../../../services/index.services";

// CHECK: messageLimit?

export async function getConversationData(conversationId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res: { data: any } = await apiInstance.get(`/messaging/${conversationId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
