import { useHistory } from "react-router-dom";
import { VideoFormat, VideoModel } from "../../video/video.types";
import { useCurrentUser } from "./useCurrentUser";
import { shareContent } from "../endpoints/shareContent";
import { preflightUser } from "../utils/preflightUser";
import { displayToast } from "../../../components/app/AppToast";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { gaEventVideoShare } from "../../../tools/analytics/videoAnalytics";
import { useLangContext } from "../../app/contexts/lang.context";
import { shareUrl } from "../../app/utils/shareUrl";

export function useShareVideo(video: VideoModel | null, analyticsListName: AnalyticsItemList) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const { t } = useLangContext();

  return async function () {
    try {
      if (!video) throw new Error("Invalid video.");

      const { isRedirected } = await preflightUser({
        history,
        onboardingMessage: t("error:notOnboarded.shareContent"),
      });
      if (isRedirected) return;

      await shareContent(video.slug);

      const url = `
        ${window.location.origin}/${video.videoFormat === VideoFormat.STORY
          ? "video/story"
          : "video"
        }/${video.slug}?shared=${currentUser?.uid ?? null}
      `;
      const publisherName =
        video.publisher === "journal"
          ? video.journal?.name
          : video.publisher === "congress"
            ? video.congress?.name
            : video.company?.name;
      const text = t("share:video.message", {
        title: video.title,
        publisherName
      });
      await shareUrl(document.title, text, url);

      gaEventVideoShare({
        video,
        listName: analyticsListName,
      });

      displayToast(t("toast:success.videoShared"), "success");
    } catch (error) {
      console.error("Couldn't share video.", error);
      displayToast(t("error:default"));
    }
  };
}