import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { Link } from "react-router-dom";

export default function ContentMarkdown({ className = "", children }) {
  return (
    <ReactMarkdown
      className={"markdown-content " + className}
      children={children}
      remarkPlugins={[remarkGfm]}
      rehypePlugins={[rehypeRaw]}
      skipHtml={false}
      components={{
        a(props) {
          const { node, children, href, ...otherProps } = props;
          if (href.includes("juisci.com")) {
            return (
              <Link to={href.split("juisci.com")[1]} {...otherProps}>
                {children}
              </Link>
            );
          } else {
            return (
              <a href={href} {...otherProps}>
                {children}
              </a>
            );
          }
        },
      }}
    />
  );
}
