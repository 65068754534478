import { useState } from "react";
import styled from "styled-components";
import { ReactComponent as JuisciLogoSVG } from "../../assets/core/icon-square.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/close.svg";
import image from "../../assets/images/upload-avatar-modal-image.png";
import { Link } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";

export default function AddProfilePictureModal({
  isVisible,
  onClose,
}: {
  isVisible: boolean;
  onClose: () => void;
}) {
  const { t } = useLangContext();
  const [isClosing, setIsClosing] = useState(false);

  function handleClose() {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300);
  }

  function handleOutsideClick(e: React.MouseEvent<HTMLDivElement>) {
    if (e.target === e.currentTarget) {
      handleClose();
    }
  }

  if (!isVisible) return null;

  return (
    <Wrapper onClick={handleOutsideClick} $isClosing={isClosing}>
      <ContentWrapper $isClosing={isClosing}>
        <JuisciLogo>
          <JuisciLogoSVG />
        </JuisciLogo>
        <ModalWrapper>
          <CloseIcon onClick={handleClose} />
          <img src={image} />
          <h1>{t("profile:uploadProfilePicture.title")}</h1>
          <p>{t("profile:uploadProfilePicture.text")}</p>
          <Link to="/settings/edit/profile/avatar">
            <button>{t("profile:uploadProfilePicture.uploadButton")}</button>
          </Link>
        </ModalWrapper>
      </ContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ $isClosing: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  animation: ${(props) => (props.$isClosing ? "fadeOut" : "fadeIn")} 0.3s
    ease-out forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const ContentWrapper = styled.div<{ $isClosing: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  animation: ${(props) => (props.$isClosing ? "scaleOut" : "scaleIn")} 0.2s
    ease-out forwards;

  @keyframes scaleIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }

  @keyframes scaleOut {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.9);
    }
  }
`;

const JuisciLogo = styled.div`
  overflow: hidden;
  border-radius: 100%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 22px;
  width: 75vw;
  background: #fff9e6;
  border-radius: 20px;
  position: relative;
  align-items: center;

  svg {
    color: #ff8800;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  img {
    width: 175px;
    height: 175px;
    margin-top: 42px;
  }

  h1 {
    color: #212121;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 140%;
    margin: 0;
  }

  p {
    color: #212121;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
  }

  button {
    padding: 16px 24px;
    border: none;
    border-radius: 25px;
    background-color: #ffc408;
    color: #212121;
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
  }
`;
