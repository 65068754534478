import { atom, useAtom } from "jotai";

// Memorizes scroll position based on the URL path.
// Just add the hook and call the getters/setters when needed.

const pageScrollAtom = atom({} as { [key: string]: any });

export function usePageScroll() {
  const [pageScroll, setPageScroll] = useAtom(pageScrollAtom);

  return {
    pageScrollY: pageScroll[window.location.pathname] ?? 0,
    setPageScrollY: (value: number) => {
      setPageScroll(prevState => ({ ...prevState, [window.location.pathname]: value }));
    },
    restorePageScrollY: () => {
      setTimeout(() => {
        scrollTo({ top: pageScroll[window.location.pathname] ?? 0 });
      }, 1);
    },
    savePageScrollY: (value = window.scrollY) => {
      setPageScroll(prevState => ({ ...prevState, [window.location.pathname]: value }));
    },
  };
}
