import { useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { store, useAppDispatch, useAppSelector } from "../../redux";
import { SearchCategory } from "../../domains/search/search.types";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import {
  setSearchPageScrollPosition,
  resetFilters,
  selectContextTab,
  clearContextTab,
} from "../../domains/search/search.reducer";
import { searchWithDebounce } from "../../domains/search/utils/searchWithDebounce";
import { getTotalSearchResultsCount } from "../../domains/search/utils/getTotalSearchResultsCount";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import { smoothScrollToTop } from "../../tools/utils";
import { gaEventSearch } from "../../tools/analytics/searchAnalytics";
import { HeaderBoxAutoSafe, HeaderLinks, NavHome, NavLang } from "../../components/app/headers/layout";
import BottomNavbar from "../../components/app/BottomNavbar";
import SearchCategoryList from "../../components/search/SearchCategoryList";
import {
  SearchPageHeader,
  SearchPageInput,
  SearchPageContent,
  SearchPageWrapper,
} from "../../components/search/layout";
import SearchCategoryTabs from "../../components/search/SearchCategoryTabs";
import SearchResultsAll from "../../components/search/SearchResultsAll";
import disableScroll from "disable-scroll";

export default function GlobalSearchPage() {
  const history = useHistory();
  const location = useLocation();
  const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user);
  const selectedContextTab: SearchCategory = useAppSelector((state) => state.search.selectedContextTab);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    (async function () {
      try {
        // NOTE: Necessary post login (no onboarding check to load data).
        if (user.isUninitialized && await isUserAuthentified()) {
          await getCurrentUser();
        }

        disableScroll.off();

        dispatch(resetFilters());

        setTimeout(() => { // NOTE: delay to let results load first
          // NOTE: Usual selector not reliable enough, using store state instead.
          const searchPageScrollPosition = store.getState().search.searchPageScrollPosition;
          contentRef.current?.scrollTo({ top: searchPageScrollPosition });
        }, 0);
      } catch (error) {
        console.error("Couldn't mount.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    if (!searchValueParam.length) {
      dispatch(clearContextTab());
    }
  }, [searchValueParam]);

  function handleScrollPage() {
    if (contentRef.current) {
      dispatch(setSearchPageScrollPosition(contentRef.current.scrollTop));
    }
  }

  function handleClickTab(tabContext: SearchCategory) {
    smoothScrollToTop(contentRef.current);
    if (tabContext === selectedContextTab) {
      dispatch(clearContextTab());
    } else {
      dispatch(selectContextTab(tabContext));
    }
  }

  return (
    <SearchPageWrapper>
      <HeaderBoxAutoSafe>
        <HeaderLinks><NavHome /></HeaderLinks>
        <HeaderLinks><NavLang /></HeaderLinks>
      </HeaderBoxAutoSafe>

      <SearchPageHeader>
        <div className="search-input-wrapper" onTouchMove={(e) => e.preventDefault()}>
          <SearchPageInput
            inputRef={inputRef}
            autoFocus
            value={searchValueParam}
            onClear={() => {
              searchWithDebounce("", () => {
                history.push(`/search`);
                smoothScrollToTop(contentRef.current);
              });
              inputRef.current?.focus();
            }}
            onSearch={(value: string) => {
              searchWithDebounce(value, async () => {
                history.push(`/search?value=${value}`);
                smoothScrollToTop(contentRef.current);

                const totalResultsCount = await getTotalSearchResultsCount();
                gaEventSearch(
                  value,
                  totalResultsCount,
                  selectedContextTab,
                );
              });
            }}
          />
        </div>
        {!!searchValueParam.length && <SearchCategoryTabs onTabClick={handleClickTab} />}
      </SearchPageHeader>

      <SearchPageContent>
        {!searchValueParam.length ? (
          <SearchCategoryList />
        ) : (
          <div
            ref={contentRef}
            id="content-ref"
            onScroll={handleScrollPage}
          >
            <SearchResultsAll />
          </div>
        )}
      </SearchPageContent>

      <BottomNavbar />
    </SearchPageWrapper>
  );
}
