import { useEffect, useRef, useState } from "react";
import { InternalAudioPlayer } from "../../objects/audio";
import { iosGenerateHaptic, isNativeIOS } from "../../tools/ios";
import { HapticEffect } from "../../interfaces";
import { formatTime } from "../../tools/utils";
import { Skeleton, Slider } from "@mui/material";
import styled from "styled-components";
import { ReactComponent as SkipBackIcon } from "../../assets/icons/skip-backward.svg";
import { ReactComponent as SkipForwardIcon } from "../../assets/icons/skip-forward.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause_audio.svg";
import { ReactComponent as PlayIcon } from "../../assets/icons/play_audio.svg";

// NOTE: This is a core complex component in the app.
// Do NOT touch without advising your lead first.

export default function AudioRoomPodcastPlayer({
  trackUrl,
  resumeLastTrackTime,
  onLoad,
  onPlay,
  onPause,
  onProgressTime,
  onProgressRate,
  onEndReached,
  onUnmount,
  onDebugLog,
}: {
  trackUrl: string | null;
  resumeLastTrackTime?: number;
  onLoad?: Function;
  onPlay?: Function;
  onPause?: Function;
  onProgressTime?: Function;
  onProgressRate?: Function;
  onEndReached?: Function;
  onUnmount?: Function;
  onDebugLog?: Function;
}) {
  const [trackDuration, setTrackDuration] = useState(-1);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPlaying, setPlaying] = useState(false);
  const [spinningButton, setSpinningButton] = useState<
    "backward" | "forward" | null
  >(null);
  const internalPlayerRef = useRef<InternalAudioPlayer | null>(null);

  useEffect(() => {
    return () => {
      handleStop();
      onUnmount && onUnmount({
        playedChunks: internalPlayerRef.current?._playedChunks,
      });
    };
  }, []);

  useEffect(() => {
    if (trackUrl) loadTrack(trackUrl);

    return () => handleStop();
  }, [trackUrl]);

  useEffect(() => {
    if (trackDuration && resumeLastTrackTime) {
      log("RESUME PODCAST?", { internalPlayer: internalPlayerRef.current });
      // Load previously listened track time
      handleSeek(resumeLastTrackTime);
    }
  }, [trackDuration]);

  useEffect(() => {
    log("INTERNAL PLAYER EXISTS? ", !!internalPlayerRef.current);
    log("IS PLAYING? ", { isPlaying });
    log("INTERNAL PLAYER IS NATIVE?", { isNative: internalPlayerRef.current?.isNativeIOS });

    if (isPlaying) {
      internalPlayerRef.current?.play()
      onPlay && onPlay();
    } else {
      internalPlayerRef.current?.pause();
      onPause && onPause();
    }
  }, [isPlaying]);

  useEffect(() => {
    internalPlayerRef.current?.setPlaybackRate(playbackRate);
  }, [playbackRate]);

  function log(event: string, payload: any = "") {
    let text = event;
    if (payload) text += JSON.stringify(payload);
    onDebugLog && onDebugLog(text);
  }

  async function loadTrack(url: string) {
    if (internalPlayerRef.current) {
      await internalPlayerRef.current.stop();
      internalPlayerRef.current = null;
    }

    const internalPlayer = new InternalAudioPlayer(isNativeIOS, (duration) => {
      setTrackDuration(duration);
      onLoad && onLoad(duration);
    });
    log("INTERNAL SHOULD BE SET NOW");
    log("SET INTERNAL PLAYER IS NATIVE?", { isNative: (internalPlayerRef.current as unknown as InternalAudioPlayer)?.isNativeIOS });

    internalPlayer.setTrack(url);
    internalPlayer.onProgress = (time: number) => {
      setCurrentTime(time);
      onProgressTime && onProgressTime(time);

      const progressRate = Math.ceil((time / trackDuration) * 100);
      onProgressRate && onProgressRate(progressRate);
    };
    internalPlayer.onEnd = () => {
      setPlaying(false);
      onEndReached && onEndReached();
    };
    internalPlayerRef.current = internalPlayer;
  }

  function handlePlayPause() {
    log("PLAY/PAUSE");
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    setPlaying(!isPlaying);
  }

  function handleSeek(value: number) {
    log("SEEK ", { value });
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    setCurrentTime(value);
    internalPlayerRef.current?.seek(value);
  }

  function handleSkipForward() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);

    const value = Math.min(currentTime + 10, trackDuration as number);
    setCurrentTime(value);
    internalPlayerRef.current?.seek(value);

    setSpinningButton("forward");
    setTimeout(() => setSpinningButton(null), 300);
  }

  function handleSkipBackward() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);

    const value = Math.max(0, currentTime - 10);
    setCurrentTime(value);
    internalPlayerRef.current?.seek(value);

    setSpinningButton("backward");
    setTimeout(() => setSpinningButton(null), 300);
  }

  function handleStop() {
    log("STOP ", { internalPlayer: internalPlayerRef.current });
    internalPlayerRef.current?.stop();
    setCurrentTime(0);
  }

  if (!trackUrl) {
    return (
      <>
        <Skeleton
          variant='rounded'
          width={"100%"}
          height={5}
          sx={{ backgroundColor: "#B4B4B4", marginBottom: 2 }}
        />
        <CustomFlex>
          {[0, 1, 2].map((index) => (
            <Skeleton
              key={index}
              variant='circular'
              width={64}
              height={64}
              sx={{ backgroundColor: "#B4B4B4" }}
            />
          ))}
        </CustomFlex>
      </>
    )
  }

  return (
    <>
      <ControllerWrapper isNative={isNativeIOS}>
        <SectionWrapper>
          <Slider
            min={0}
            value={currentTime / trackDuration * 100}
            onChangeCommitted={(_, percentage) => {
              handleSeek((percentage as number) * trackDuration / 100)
            }}
            sx={{
              color: "#FF78D2",
              borderRadius: 5,
              margin: "auto",
              display: "block",
            }}
          />
          <TimeDisplay>
            <span>{formatTime(currentTime)}</span>
            <span>-{formatTime(trackDuration - currentTime)}</span>
          </TimeDisplay>
        </SectionWrapper>

        <SectionWrapper>
          <ControlsWrapper>
            <SkipButtonWrapper
              onClick={handleSkipBackward}
              className={spinningButton === "backward" ? "spin-backward" : ""}
            >
              <span>10</span>
              <SkipBackIcon />
            </SkipButtonWrapper>
            {isPlaying ? (
              <PauseIcon
                onClick={handlePlayPause}
                style={{ height: "58px", width: "58px" }}
              />
            ) : (
              <PlayIcon
                onClick={handlePlayPause}
                style={{ height: "58px", width: "58px" }}
              />
            )}
            <SkipButtonWrapper
              onClick={handleSkipForward}
              className={spinningButton === "forward" ? "spin-forward" : ""}
            >
              <span>10</span>
              <SkipForwardIcon />
            </SkipButtonWrapper>
          </ControlsWrapper>

          <ControlsWrapper>
            {[0.9, 1, 1.2].map((speed, index) => {
              return (
                <SpeedControlButton
                  key={index}
                  isSelected={playbackRate === speed}
                  onClick={() => {
                    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
                    setPlaybackRate(speed);
                  }}
                >
                  <span>x{speed}</span>
                </SpeedControlButton>
              );
            })}
          </ControlsWrapper>
        </SectionWrapper>
      </ControllerWrapper>
    </>
  );
}

const ControllerWrapper = styled.div<{ isNative: boolean }>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: slideIn 0.2s ease-in;

  &.hide {
    opacity: 0;
    display: none;
    animation: slideOut 0.2s ease-out;
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      bottom: -10px;
    }
    100% {
      opacity: 1;
      bottom: ${(props) => (props.isNative ? "70px" : "15px")};
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      bottom: ${(props) => (props.isNative ? "70px" : "15px")};
    }
    100% {
      opacity: 0;
      bottom: -10px;
    }
  }

  color: white;
  font-weight: 800;
`;

const CustomFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  .MuiSlider-rail {
    background: #ffffff;
  }

  .MuiSlider-track {
    background: #859486;
    border: none;
  }

  .MuiSlider-thumb {
    background: #ff78d2;
  }
`;

const TimeDisplay = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 12px;
`;

const ControlsWrapper = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
`;

const SkipButtonWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  span {
    position: absolute;
    left: 0;
    right: 0;
    margin: 2px auto 0;
    color: white;
    text-align: center;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
  }

  svg {
    transition: transform 0.2s ease;
  }

  &.spin-backward svg {
    animation: spinBackward 0.2s ease-out;
  }

  &.spin-forward svg {
    animation: spinForward 0.2s ease-out;
  }

  @keyframes spinBackward {
    from {
      transform: rotate(0deg) scale(1);
    }
    to {
      transform: rotate(-360deg) scale(0.92);
    }
  }

  @keyframes spinForward {
    from {
      transform: rotate(0deg) scale(1);
    }
    to {
      transform: rotate(360deg) scale(0.92);
    }
  }
`;

const SpeedControlButton = styled.div<{ isSelected: boolean }>`
  background: ${(props) => (props.isSelected ? "#FF78D2" : "#fff")};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  border-radius: 100%;

  span {
    color: #042b0b;
    text-align: center;
    font-family: "Poppins";
    font-size: 12px;
    font-weight: 400;
  }
`;
