import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { GPTConversationModel, GPTMessageModel, GPTMessageStates } from "../../domains/gpt/gpt.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import disableScroll from "disable-scroll";
import { t } from "i18next";
import { useLazyGetGptConversationsListQuery } from "../../domains/gpt/endpoints/getGptConversationsList";
import { GPTBottomScroller, GPTChatQuestion, GPTConversationWrapper, GPTPageLayout } from "../../components/gpt/layout";
import GPTNav from "../../components/gpt/GPTNav";
import GPTChatAnswer from "../../components/gpt/chat/GPTChatAnswer";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import Loader from "../../components/Loader";
import { ReactComponent as NewChatIcon } from "../../assets/icons/gpt/nav-new-chat.svg";

export default function GPTChatHistoryPage() {
	const history = useHistory();
	const [getGptConversationsList, { isFetching, data }] = useLazyGetGptConversationsListQuery();
	const totalConversations = data?.meta.total ?? 0;
	const [conversations, setConversations] = useState<GPTConversationModel[]>([]);
	const [selectedConversationIdx, setSelectedConversationIdx] = useState(-1);
	const [isLoading, setLoading] = useState(true); // NOTE: Using custom loading state to prevent glitch.
	const [previewMessages, setPreviewMessages] = useState<GPTMessageModel[]>([]);

	useEffect(() => {
		(async function () {
			disableScroll.off(); // Enable Scroll

			const { isRedirected } = await preflightUser({
				history,
				onboardingMessage: t("error:notOnboarded.juisciGPT"),
			});
			if (isRedirected) return;

			fetchConversations();
		})();
	}, []);

	useEffect(() => {
		if (conversations.length) {
			const conversation: GPTConversationModel = conversations[selectedConversationIdx];
			if (conversation) {
				setPreviewMessages(conversation.messages);
			}
		}
	}, [selectedConversationIdx]);

	async function fetchConversations() {
		await getGptConversationsList({ limit: 10, offset: conversations.length })
			.unwrap()
			.then(({ docs, meta }) => {
				setConversations([...conversations, ...docs]);
				if (selectedConversationIdx === -1) {
					setSelectedConversationIdx(0);
				}
				setLoading(false);
			})
			.catch((error) => {
				console.error("Couldn't get conversations.", error);
				displayToast(t("error:default"));
			});
	}

	async function handleScrollConversations(e: any) {
		if (conversations.length < totalConversations && !isFetching) {
			if (e.target.scrollLeft >= (e.target.scrollWidth - e.target.clientWidth - 64)) {
				fetchConversations();
			}
		}
	}

	return (
		<GPTPageLayout>
			<HeaderBoxAutoSafe>
				<HeaderLinks><NavBack to="/gpt/discover" /></HeaderLinks>
				<HeaderTitle>{t("juisciGpt:history.title")}</HeaderTitle>
				<HeaderLinks />
			</HeaderBoxAutoSafe>

			<div className="page-wrapper">
				{isLoading
					? <Loader loaderOnly />
					: conversations.length === 0
						? (
							<NoResults>
								{t("juisciGpt:history.noResults")}
								<Link to='/gpt/chat/new'>
									<NewChatButton className="white">
										<NewChatIcon />
										{t("juisciGpt:history.action.newChat")}
									</NewChatButton>
								</Link>
							</NoResults>
						)
						: (
							<>
								<BlockOverlay />
								<GPTConversationWrapper style={{ overflow: "hidden" }}>
									{previewMessages.map((message: GPTMessageModel, index: number) => {
										return (
											message.fromUser
												? <GPTChatQuestion key={index}>{message.content}</GPTChatQuestion>
												: message.status === GPTMessageStates.Done
												&& <GPTChatAnswer key={index}>{message.content}</GPTChatAnswer>
										);
									})}
								</GPTConversationWrapper>
							</>
						)
				}

				<GPTBottomScroller
					className="scrollbar-hidden floating"
					style={{ zIndex: 1, marginBottom: 0, bottom: 16 }}
					onScroll={handleScrollConversations}
				>
					<div className="contents">
						{conversations.map((conversation: GPTConversationModel, index: number) => {
							return (
								<ConversationLink
									key={conversation.id}
									className={selectedConversationIdx === index ? "active" : ""}
									onClick={() => setSelectedConversationIdx(index)}
								>
									{conversation.messages[0].content.split(" ").slice(0, 4).join(" ")}
									<span className="continue" onClick={() => {
										history.push(`/gpt/chat/conversation/${conversation.id}`)
									}}>{t("common:action.continue")}</span>
								</ConversationLink>
							);
						})}
					</div>
				</GPTBottomScroller>
			</div>

			<GPTNav />
		</GPTPageLayout>
	);
}

// An invisible layer to block all clicks on conversations
const BlockOverlay = styled.div`
	position:absolute;
	z-index: 1;
	width: 100vw;
	height: 100%;
	background-image: linear-gradient(#F9EFCB22, #F9E8CADD);
`;

const ConversationLink = styled.div`
	width: max-content;
	padding: 16px;
	border: 2px solid transparent;
	border-radius: 8px;
	display: flex;
	gap: 16px;
	background: #FFFFFF;
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	box-shadow: 2px 2px 4px 0px #2121211A;
	opacity: 0.5;
	animation: fadeIn 0.2s forwards;

	&.active {
		border-color: #212121;

		.continue {
			display: flex;
		}
	}

	.continue {
		display: none;
		color: #CE0868;
		font-weight: 700;
	}
`;

const NoResults = styled.div`
	margin: auto;
	padding: 0 32px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 22px;
	text-align: center;
	font-family: Inter;
	font-size: 16px;
	font-weight: 500;
`;

const NewChatButton = styled.button`
	width: fit-content;
	padding: 8px 24px;
	border: none;
	border-radius: 100px;
	display: flex;
	align-items: center;
	gap: 8px;
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
	line-height: 15.2px;
	color: #212121;
	background-color: #ffffff;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);

	svg {
		width: 33px;
		height: 33px;
		fill: #ff8800;
	}
`;