import { getApiRequestService } from "../../../services/index.services";

export async function addMessageToConversation(conversationId: string, message: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res: { data: any } = await apiInstance.post("/messaging/send-message", {
      conversationId,
      message,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};
