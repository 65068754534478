import { Skeleton } from "@mui/material";
import styled from "styled-components";

const SkeletonContentCard = ({ contentFormat = "" }) => {
  return (
    <StyledCard>
      <Skeleton variant='rectangle' className='badge' width={75} height={20} />
      <Skeleton
        variant='rectangle'
        className='meta-title'
        width={120}
        height={16}
      />

      <Skeleton variant='rectangle' width='90%' height={10} />
      <Skeleton variant='rectangle' width='65%' height={10} />
      <Skeleton variant='rectangle' width='80%' height={10} />

      {contentFormat !== "playlist" ? (
        <div className='skeleton-footer'>
          <div className='skeleton-footer-content'>
            <Skeleton variant='rectangle' width={75} height={8} />
            <Skeleton variant='rectangle' width={100} height={16} />
          </div>
          <div className='skeleton-footer-content'>
            <Skeleton variant='rectangle' width={60} height={8} />
            <Skeleton variant='rectangle' width={140} height={16} />
          </div>
        </div>
      ) : (
        <div className='skeleton-footer'>
          <Skeleton variant='rectangle' width={64} height={40} />
          <Skeleton variant='circular' width={40} height={40} />
          <Skeleton variant='circular' width={40} height={40} />
        </div>
      )}
    </StyledCard>
  );
};

export default SkeletonContentCard;

const StyledCard = styled.div`
  background: #ecf0f5;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 24px 16px;
  width: 100%;
  max-height: 200px;
  gap: 8px;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);

  .badge {
    border-radius: 100px;
    margin-bottom: 8px;
  }

  .meta-title {
    margin-bottom: 8px;
  }

  .skeleton-footer {
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    gap: 64px;

    margin-top: 16px;

    .skeleton-footer-content {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: start;
      gap: 4px;
    }
  }
`;
