import { getApiRequestService } from "../../../services/index.services";

export async function registerFirebaseToken(registrationId: string) {
  try {
    const data = { registrationId };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/notification/registration`, data);
    return res.data;
  } catch (error) {
    throw error;
  }
}
