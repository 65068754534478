import { Fragment, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { ArticleModel } from "../../domains/article/article.types";
import { GPTMessageModel, GPTMessageStates } from "../../domains/gpt/gpt.types";
import { removeDuplicateSources } from "../../domains/gpt/utils/removeDuplicateSources";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { getGptConversationById } from "../../domains/gpt/endpoints/getGptConversationById";
import { useFetchGPTStream } from "../../domains/gpt/hooks/useFetchGPTStream";
import { useTextReveal } from "../../domains/app/hooks/useTextReveal";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import disableScroll from "disable-scroll";
import { GPTChatQuestion, GPTConversationWrapper, GPTPageLayout } from "../../components/gpt/layout";
import GPTChatAnswer from "../../components/gpt/chat/GPTChatAnswer";
import GPTChatSources from "../../components/gpt/chat/GPTChatSources";
import GPTChatLoader from "../../components/gpt/chat/GPTChatLoader";
import GPTChatInput from "../../components/gpt/chat/GPTChatInput";
import { t } from "i18next";
import { displayToast } from "../../components/app/AppToast";
import Loader from "../../components/Loader";
import styled from "styled-components";

export default function GPTConversationPage() {
	const history = useHistory();
	const location = useLocation<{ history?: [], sources?: ArticleModel[] }>();
	const params = useParams<{ id: string }>();
	const [isLoading, setLoading] = useState(true);
	const [inputQuestion, setInputQuestion] = useState("");
	const [messages, setMessages] = useState<GPTMessageModel[]>([]);
	const [isWaitingFullReveal, setWaitingFullReveal] = useState(false);
	const dummyScrollBottomRef = useRef<HTMLDivElement>(null);
	const stream = useFetchGPTStream();
	const textReveal = useTextReveal(stream.answer, () => {
		if (Math.floor(Math.random() * 5) === 0) {
			scrollToLastMessage(true);
		}
	});

	useEffect(() => {
		(async function () {
			disableScroll.off(); // Enable Scroll

			const { isRedirected } = await preflightUser({
				history,
				onboardingMessage: t("error:notOnboarded.juisciGPT"),
			});
			if (isRedirected) return;

			if (location.state && location.state.history) {
				const preloadMessages: GPTMessageModel[] = [];
				location.state.history.forEach((message: { human?: string, ai?: string }) => {
					if (message.human) {
						const question: GPTMessageModel = {
							fromUser: true,
							content: message.human,
							createdAt: new Date(),
							status: GPTMessageStates.Done,
							sources: null,
						};
						preloadMessages.push(question);
					}
					if (message.ai) {
						const answer: GPTMessageModel = {
							fromUser: false,
							content: message.ai,
							createdAt: new Date(),
							status: GPTMessageStates.Done,
							// sources: location.state.sources ?? null,
							sources: null,
						};
						preloadMessages.push(answer);
					}
				});
				setMessages(preloadMessages);
				scrollToLastMessage();
			}

			setLoading(true); // NOTE: Only on first loading.
			setMessages(await refreshMessages());
			setLoading(false);
			scrollToLastMessage();
		})();
	}, []);

	useEffect(() => {
		if (textReveal.hasFinished() && stream.hasFullyLoaded) {
			setWaitingFullReveal(false);
			setInputQuestion("");
			scrollToLastMessage(true);
			onAnswerRevealFinished();
		}
	}, [stream.hasFullyLoaded, textReveal.partialText]);

	useEffect(() => {
		if (stream.error) {
			displayToast(t("error:default"));
			setWaitingFullReveal(false);
		}
	}, [stream.error]);

	async function onAnswerRevealFinished() {
		// Create message objects from stream data and store them in the current message list
		const question: GPTMessageModel = {
			fromUser: true,
			content: stream.question,
			createdAt: new Date(),
			status: GPTMessageStates.Done,
			sources: null,
		};
		const answer: GPTMessageModel = removeDuplicateSources({
			fromUser: false,
			content: stream.answer,
			createdAt: new Date(),
			status: GPTMessageStates.Done,
			sources: stream.sources,
		});
		stream.reset();
		textReveal.reset();
		setMessages([...messages, question, answer]);

		// Refresh messages from server
		setMessages(await refreshMessages());
		scrollToLastMessage(true);
	}

	function scrollToLastMessage(smooth = false) {
		if (dummyScrollBottomRef.current) {
			dummyScrollBottomRef.current.scrollIntoView({ behavior: smooth ? "smooth" : "auto" });
		}
	}

	async function refreshMessages() {
		try {
			const data = await getGptConversationById(params.id);
			return data.messages;
		} catch (error) {
			console.error("Couldn't get conversation messages.", error);
			displayToast(t("error:default"));
			return messages;
		}
	}

	function handleCopyAnswer(question: string, text: string) {
		const copyText = question
			+ "\n\n"
			+ text
			+ `\n\n${t("juisciGpt:poweredBy")} - https://app.juisci.com`;
		navigator.clipboard.writeText(copyText);
		displayToast(t("juisciGpt:chat.copyDone"));
	}

	async function handleSubmit(question: string) {
		try {
			setWaitingFullReveal(true);
			setTimeout(() => scrollToLastMessage(true), 100);
			const error = await stream.askQuestion(question, params.id);
			if (error) throw error;
		} catch (error) {
			console.error("Couldn't ask question.", error);
			if (error instanceof Error) {
				// NOTE: Fix for some errors sent as array
				if (Array.isArray(error.message)) {
					error.message = error.message[0];
				}
			}
			if (axios.isAxiosError(error) && error.response?.status === 400) {
				if ([
					"daily",
					"limit",
					"reach",
				].some((term: string) => (error as Error).message.toLowerCase().includes(term))) {
					displayToast(t("juisciGpt:error.limitReached"));
				}
			} else {
				displayToast(t("error:default"));
			}
			setWaitingFullReveal(false);
		}
	}

	return (
		<GPTPageLayout>
			<HeaderBoxAutoSafe>
				<HeaderLinks><NavBack to="/gpt/chat/new" /></HeaderLinks>
				<HeaderTitle>{t("juisciGpt:chat.title")}</HeaderTitle>
				<HeaderLinks />
			</HeaderBoxAutoSafe>

			<div className="page-wrapper">
				{(isLoading && !messages.length)
					? <Loader loaderOnly />
					: (
						<GPTConversationWrapper>
							{messages.map((message: GPTMessageModel, index) => {
								return (
									message.fromUser
										? <GPTChatQuestion key={index}>{message.content}</GPTChatQuestion>
										: message.status === GPTMessageStates.Done
											? (
												<Fragment key={index}>
													<GPTChatAnswer
														onCopy={() => handleCopyAnswer(
															messages[index - 1].content,
															message.content,
														)}
													>
														{message.content}
													</GPTChatAnswer>
													{message.sources &&
														<GPTChatSources
															sources={message.sources}
															onClickSeeAll={() => history.push({
																pathname: `/gpt/chat/sources`,
																state: {
																	question: messages[index - 1].content,
																	sources: message.sources,
																},
															})}
														/>
													}
												</Fragment>
											)
											: <GPTChatLoader key={index} />
								);
							})}
							{stream.question && (
								<>
									<GPTChatQuestion>{stream.question}</GPTChatQuestion>
									{stream.isPreloading
										? <GPTChatLoader />
										: stream.error ? (
											<ChatError>{t("juisciGpt:error.default")}</ChatError>
										) : (
											<GPTChatAnswer
												isTyping={isWaitingFullReveal}
											>
												{textReveal.partialText}
											</GPTChatAnswer>
										)
									}
								</>
							)}
							<div ref={dummyScrollBottomRef} />
						</GPTConversationWrapper>
					)
				}
			</div>

			<GPTChatInput
				placeholder={t("juisciGpt:chat.action.keepAsking")}
				value={inputQuestion}
				onChange={(value: string) => setInputQuestion(value)}
				disabled={isLoading || isWaitingFullReveal}
				onSubmit={handleSubmit}
			/>
		</GPTPageLayout>
	);
}

const ChatError = styled.p`
	margin: 0 16px;
	font-family: Inter;
	font-size: 14px;
	font-weight: 400;
	line-height: 26px;
	color: #ce0868;
`;