import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../../redux";
import { clearCachedResults, clearContextTab } from "../search.reducer";

export function useSearchHistoryObserver() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  // Handle React navigation
  useEffect(() => handlePathChange(location.pathname), [location.pathname]);

  function handlePathChange(nextPath: string) {
    if (nextPath !== currentPath) {
      // Clear cache when switching between search pages
      if (currentPath === "/search" && nextPath.startsWith("/search/")) {
        dispatch(clearCachedResults());
      }
      if (currentPath.startsWith("/search/") && nextPath === "/search") {
        dispatch(clearCachedResults());
      }

      // Clear cache when leaving search cycle
      if (!currentPath.startsWith("/search") && !nextPath.startsWith("/search")) {
        dispatch(clearCachedResults());
        dispatch(clearContextTab());
      }

      setCurrentPath(nextPath);
    }
  }
}

// Use this with a class component App
export function SearchHistoryObserver() {
  useSearchHistoryObserver();
  return null;
}