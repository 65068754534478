import { getApiRequestService } from "../../../services/index.services";
import { ArticleModel } from "../../article/article.types";
import { sortArticleImages } from "../../article/utils/sortArticleImages";

export async function getContentRelatedArticles(contentId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("content/relatedArticles/" + contentId);
    const docs = res.data.docs.map((article: ArticleModel) => sortArticleImages(article));
    return docs;
  } catch (error) {
    console.error("Couldn't get content related articles.", error);
    throw error;
  }
};