import { specialtiesApi } from "../specialty.api";

const api = specialtiesApi.injectEndpoints({
  endpoints: (build) => ({
    getMedicalSpecialties: build.query<any, void>({
      query: () => {
        return "/tag/parents";
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getMedicalSpecialties,
} = api.endpoints;

export const {
  useLazyGetMedicalSpecialtiesQuery,
} = api;
