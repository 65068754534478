import { getApiRequestService } from "../../../services/index.services";

export async function uploadAvatar(data: FormData) {
  try {
    const apiInstance = await getApiRequestService({
      "Content-Type": "multipart/form-data",
    });
    const res = await apiInstance.post("/user/image", data);
    return res.data;
  } catch (error) {
    throw error;
  }
}
