import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { getUserUnreadAnnouncement } from "../../domains/user/utils/getUserUnreadAnnouncement";
import { checkCertificationAnswer } from "../../domains/notification/utils/checkCertificationAnswer";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import DisclaimerModal from "../../components/home/modals/DisclaimerModal";
import AnnouncementModal from "../../components/home/modals/AnnouncementModal";
import HomeLayout from "../../components/home/layout/HomeLayout";
import HomeSpecialtyScroller from "../../components/home/home-blocks/HomeSpecialtyScroller";
import HomeArticleCarousel from "../../components/home/home-blocks/HomeArticleCarousel";
import HomeNectarScroller from "../../components/home/home-blocks/HomeNectarScroller";
import RoomsContentCarousel from "../../components/home/home-blocks/RoomsContentCarousel";
// import HomeButtonLinks from "../../components/home/home-blocks/HomeButtonLinks";
import RecommendedPlaylistCarousel from "../../components/home/home-blocks/RecommendedPlaylistCarousel";
import GPTInviteBlock from "../../components/home/home-blocks/GPTInviteBlock";
import HomeVideoStories from "../../components/home/home-blocks/HomeVideoStories";
import CertifiedPlaylistCarousel from "../../components/home/home-blocks/CertifiedPlaylistCarousel";
import HomeUserScroller from "../../components/home/home-blocks/HomeUserScroller";
import HomeJournalScroller from "../../components/home/home-blocks/HomeJournalScroller";
import styled from "styled-components";
import disableScroll from "disable-scroll";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import "./home.css";
import { iOSAudioHandler } from "../../tools/ios/audio";
import ConfirmModal from "../../components/app/ConfirmModal";
import { isNativeIOS } from "../../tools/ios";

export default function HomePage() {
  const history = useHistory();
  const location = useLocation();
  const { currentUser } = useCurrentUser();
  const [isAuthentified, setAuthentified] = useState(false);
  const [isDisclaimerVisible, setDisclaimerVisible] = useState(false);
  const [displayedAnnouncement, setDisplayedAnnouncement] = useState(null);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();

        const isAuthentified = await isUserAuthentified();
        setAuthentified(isAuthentified);
        if (isAuthentified && !currentUser) await getCurrentUser();

        const searchParams = new URLSearchParams(location.search);
        const validateAccount = searchParams.get("validate_account");
        if (validateAccount === "success") {
          displayToast(t("toast:success.accountValidated"));
          return history.replace("/discovery");
        }

        checkModals();
      } catch (error) {
        console.error("Couldn't mount home page.", error);
      }
    })();
  }, []);

  async function checkModals() {
    // Check disclaimer modal
    const acceptedRules = localStorage.getItem("acceptedRules");
    if (acceptedRules === null) {
      disableScroll.off();
      setDisclaimerVisible(true);
      return;
    }

    if (isAuthentified) {
      // Check certification notification
      const notificationExists = await checkCertificationAnswer();
      if (notificationExists) {
        await getCurrentUser();
        if (currentUser.lemonCertified) {
          displayToast(t("toast:certification.accept"), "bg-green");
        } else {
          displayToast(t("toast:certification.reject"), "bg-orange");
        }
        return;
      }

      // Check announcement
      const announcement = await getUserUnreadAnnouncement();
      setDisplayedAnnouncement(announcement);
    }
  }

  function handleCloseDisclaimerModal() {
    setDisclaimerVisible(false);
    localStorage.setItem("acceptedRules", "true");
    disableScroll.off();

    checkModals();
  }

  // TODO: Temporary force app store update app
  const showUpdateAppModal = isNativeIOS && !iOSAudioHandler;

  return (
    <>
      <DisclaimerModal
        visible={isDisclaimerVisible}
        onPress={handleCloseDisclaimerModal}
      />

      {!!displayedAnnouncement && (
        <AnnouncementModal
          announcement={displayedAnnouncement}
          onClose={() => setDisplayedAnnouncement(null)}
        />
      )}

      {showUpdateAppModal && (
        <ConfirmModal
          isVisible={showUpdateAppModal}
          promptText={t("modal:updateApp.text")}
          onConfirm={() => {
            window.location.href =
              "https://apps.apple.com/us/app/juisci/id1588725828?l=en&ls=1";
          }}
          cancelText={null}
          confirmText={t("modal:updateApp.title")}
        />
      )}

      <HomeLayout>
        <PageContainer>
          {!!currentUser && currentUser.main_specialty && (
            <>
              <HomeSpecialtyScroller />
              <hr />
            </>
          )}
          <HomeArticleCarousel
            key='main'
            isMainSpecialty
            specialty={currentUser?.main_specialty ?? null}
          />
          <hr />
          <HomeNectarScroller />
          {isAuthentified && (
            <RoomsContentCarousel type="user" title={t("home:roomContent.userRooms.title")} />
          )}
          {/* <HomeButtonLinks /> */}
          <RecommendedPlaylistCarousel />
          <GPTInviteBlock />
          {!!currentUser?.medical_specialties?.length && (
            <HomeArticleCarousel
              key='secondary'
              specialty={currentUser.medical_specialties[0]}
            />
          )}
          <hr />
          <HomeVideoStories />
          <hr />
          <CertifiedPlaylistCarousel />
          <hr />
          {isAuthentified && (
            <HomeUserScroller />
          )}
          <HomeJournalScroller />
        </PageContainer>
      </HomeLayout>
    </>
  );
}

const PageContainer = styled.div`
  background: #ffffff;

  hr {
    border-color: #ecf0f5;
  }

  .section-content {
    padding: 0px;
    background: #ffffff;

    &.primary {
      background: #fff3ce;
    }

    &.padding {
      padding: 16px 0px;
    }

    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: -0.00011em;
        color: #313b42;
        margin: 16px 21px;

        span {
          color: #ce0868;
        }
      }

      a {
        font-family: "Roboto";
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        letter-spacing: 0.02em;
        text-decoration-line: underline;
        color: #ce0868;
        margin-right: 21px;
      }
    }

    .invite-card-small {
      box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
      border-radius: 15px;
      padding: 16px 22px;
      margin: 16px 8px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-weight: 700;

      span {
        color: #ce0868;
      }
    }

    .invite-card {
      flex: 1;
      box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
      border-radius: 15px;
      padding: 60px 22px;
      background: #ffffff;
      min-height: 330px;

      &.primary {
        background: #fff3ce;
      }

      p {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #212121;
        margin: 24px auto;

        span {
          color: #ce0868;
        }
      }
    }

    .content-boxes {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 16px;
      grid-row-gap: 0px;
      padding: 24px 8px;
      align-items: center;

      .box {
        background: linear-gradient(180deg, #ffd000 0%, #ffa100 100%);
        border-radius: 10px;
        padding: 12px 24px;
        text-align: center;
        position: relative;
        height: 60px;

        .content {
          position: absolute;
          top: 53%;
          left: 50%;
          transform: translate(-50%, -50%);

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 13px;
            line-height: 100%;
            color: #313b42;
            margin: 4px 0;
          }
        }

        &.videos {
          background: linear-gradient(
            180deg,
            #ffd3a6 0%,
            #ffd3a6 0.01%,
            #ff9c96 51.56%,
            #fc6f89 100%
          );
        }
        &.guidelines {
          background: linear-gradient(180deg, #fdb955 0%, #ff8a00 100%);
        }
      }
    }
  }
`;
