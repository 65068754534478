import { filterArrayDuplicates } from "../../app/utils/filterArrayDuplicates";
import { ArticleModel } from "../../article/article.types";
import { sortArticleImages } from "../../article/utils/sortArticleImages";
import { GPTMessageModel } from "../gpt.types";

export function removeDuplicateSources(message: GPTMessageModel) {
	if (message.sources) {
		// Remove duplicate sources that may happen to be sent by the LLM
		message.sources = filterArrayDuplicates(
			message.sources,
			(a: { slug: string }, b: { slug: string }) => a.slug === b.slug,
		)
			.map((source: ArticleModel) => sortArticleImages(source));
	}

	return message;
}
