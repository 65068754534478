import { useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { Widget } from "@typeform/embed-react";
import styled from "styled-components";
import AppButton from "../../components/app/AppButton";
import { NavBack } from "../../components/app/headers/layout";

export default function RoomQuizSlidesPage() {
  const history = useHistory();
  const { quizId } = useParams<{ quizId: string }>();
  const { t } = useLangContext();
  const [isFinished, setFinished] = useState(false);
  const footerCacheRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      {!isFinished ? (
        <>
          <div style={{
            position: "absolute",
            top: "calc(16px + var(--safe-area-bottom))",
            left: 16,
            zIndex: 10010,
          }}>
            <NavBack />
          </div>

          <Widget
            id={quizId}
            className="widget-container"
            // opacity={0}
            fullScreen
            onReady={() => {
              const footerCache = footerCacheRef.current;
              if (footerCache) footerCache.style.display = "none";
              setFinished(false);
            }}
            onStarted={() => {
              const footerCache = footerCacheRef.current;
              if (footerCache) footerCache.style.display = "block";
            }}
            onSubmit={() => {
              setFinished(true);
            }}
          />
        </>
      ) : (
        <EndSlide>
          <span className="heading">{t("room:quiz:endSlide.heading")}</span>
          <span className="text">{t("room:quiz:endSlide.text")}</span>
          <AppButton onClick={() => history.goBack()}>{t("common:action.back")}</AppButton>
        </EndSlide>
      )}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .widget-container {
    position: relative;
    top: 0;
    z-index: 0;
    box-sizing: border-box;
    width: 100%;
  }
`;

const EndSlide = styled.div`
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;

  .heading {
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    text-align: center;
  }

  .text {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
  }
`;
