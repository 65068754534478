// A component that takes its children and spreads them across rows.
// Use the masonry-container, masonry-row, masonry-cell and masonry-filler
// classes to add or overwrite styles.
import { ReactChildren, ReactNode, useEffect, useState } from "react";

export default function MasonryLayoutHorz({ children, rowCount, hideFillers = false }: {
  children: any;
  rowCount: number;
  hideFillers?: boolean;
}) {
  const [itemRows, setItemRows] = useState<any[]>([]);

  useEffect(() => {
    const rows: any[] = [];
    for (let i = 0; i < rowCount; i++) {
      rows[i] = [];
    }

    children.forEach((child: ReactNode, index: number) => {
      rows[index % rowCount].push(child);
    });

    setItemRows(rows);
  }, [children, rowCount]);

  return (
    <div className="masonry-container">
      {[...Array(rowCount)].map((_, index) => {
        return (
          <div key={index} className="masonry-row" style={{ display: "flex" }}>
            {itemRows[index]?.map((child: ReactNode | ReactChildren, index: number) => (
              <div key={index} className="masonry-cell">{child}</div>
            ))}
            {!hideFillers && <div className="masonry-filler" style={{ flex: 1, minWidth: 10 }}></div>}
          </div>
        );
      })}
    </div>
  );
}