import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { RoomModel, RoomType } from "../../domains/room/room.types";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useLazyGetUserRoomsQuery } from "../../domains/user/endpoints/getUserRooms";
import { getPublicRooms } from "../../domains/room/endpoints/getPublicRooms";
import { filterArrayDuplicates } from "../../domains/app/utils/filterArrayDuplicates";
import { getRoomTranslation } from "../../domains/room/utils/getRoomTranslation";
import SafeAreaTopWrapper from "./SafeAreaTopWrapper";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as AudioIcon } from "../../assets/icons/audio-black.svg";
import { ReactComponent as ArrowCircleYellow } from "../../assets/icons/arrow-circle-yellow.svg";

const MAX_ITEMS_DISPLAYED = 8;

export default function RoomsMenu({ isOpen }: { isOpen: boolean }) {
  const { rooms: privateRooms } = useCurrentUser();
  const [getUserRooms] = useLazyGetUserRoomsQuery();
  const uniquePrivateRooms = filterArrayDuplicates(
    privateRooms,
    (a: RoomModel, b: RoomModel) => a._id === b._id,
  );
  const [publicRooms, setPublicRooms] = useState([]);
  const [hasExpandedPrivateList, setExpandedPrivateList] = useState(false);
  const [hasExpandedPublicList, setExpandedPublicList] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        getUserRooms();
        const { docs } = await getPublicRooms();
        const uniquePrivateRoomsIds = uniquePrivateRooms.map((room: RoomModel) => room._id);
        const filteredPublicRooms = docs.filter((room: RoomModel) => !uniquePrivateRoomsIds.includes(room._id));
        setPublicRooms(filteredPublicRooms);
      } catch (error) {
        console.error("Couldn't load user rooms.", error);
      }
    })();
  }, []);

  return (
    <SlideContainer className={isOpen ? "open" : ""}>
      <FixedContainer>
        <SafeAreaTopWrapper>
          <InnerContainer>
            {uniquePrivateRooms.length > 0 && (
              <>
                <span className="heading">{t("Your Rooms")}</span>
                <RoomsMenuList>
                  {uniquePrivateRooms
                    .slice(0, hasExpandedPrivateList ? uniquePrivateRooms.length : MAX_ITEMS_DISPLAYED)
                    .filter((room: RoomModel) => room.articles.length >= MAX_ITEMS_DISPLAYED)
                    .map((room: RoomModel) => {
                      return <RoomsMenuItem key={room._id} room={room} />;
                    })
                  }
                  <ExpandToggleButton
                    list={uniquePrivateRooms}
                    isExpanded={hasExpandedPrivateList}
                    onToggleSetter={setExpandedPrivateList}
                  />
                </RoomsMenuList>
                <hr />
              </>
            )}
            <span className="heading">{t("Suggested Other Rooms")}</span>
            <RoomsMenuList>
              {publicRooms
                .slice(0, hasExpandedPublicList ? publicRooms.length : MAX_ITEMS_DISPLAYED)
                .map((room: RoomModel) => {
                  return <RoomsMenuItem key={room._id} room={room} />;
                })
              }
              <ExpandToggleButton
                list={publicRooms}
                isExpanded={hasExpandedPublicList}
                onToggleSetter={setExpandedPublicList}
              />
            </RoomsMenuList>
          </InnerContainer>
        </SafeAreaTopWrapper>
      </FixedContainer>
    </SlideContainer>
  );
}

function RoomsMenuItem({ room }: { room: RoomModel }) {
  const pathname = room.type === RoomType.AUDIO
    ? `/room/audio/home/${room._id}`
    : `/room/discovery/${room._id}`;
  const logoUrl = room?.ico?.url ?? room?.logo?.url ?? room?.organisation?.logo?.url ?? "";

  return (
    <Link to={pathname}>
      {logoUrl ? (
        <img
          className="logo"
          src={logoUrl ?? ""}
          alt={getRoomTranslation(room)}
        />
      ) : (
        <LogoPlaceholder />
      )}
      <div className="room-info">
        <div className="room-name-container">
          <span className="room-name">{getRoomTranslation(room)}</span>
          {room.type === RoomType.AUDIO && (
            <AudioIcon className="audio-icon" />
          )}
        </div>
        <span className="room-org">
          {t("common:by")} {room.organisation?.name}
        </span>
      </div>
      <ArrowCircleYellow className="link-icon" />
    </Link>
  );
}

function ExpandToggleButton({ list, isExpanded, onToggleSetter }: {
  list: any[];
  isExpanded: boolean;
  onToggleSetter: Function;
}) {
  return (
    <>
      {(list.length > MAX_ITEMS_DISPLAYED) && (
        <ExpandToggle onClick={() => onToggleSetter(!isExpanded)}>
          {!isExpanded ? t("common:action.seeMore") : t("common:action.seeLess")}
        </ExpandToggle>
      )}
    </>
  );
}

const SlideContainer = styled.div`
  top: 0;
  min-width: 0%;
  transition: min-width 0.2s ease-in;

  &.open {
    min-width: 80%;
  }
`;

const FixedContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  width: 80%;
  max-height: 100dvh;
  overflow-y: auto;
`;

const InnerContainer = styled.div`
  box-sizing: border-box;
  width: 80%;
  max-height: 100dvh;
  overflow-y: auto;
  padding: 16px;

  .heading {
    display: block;
    margin-bottom: 24px;
    color: var(--BRAND-CORE-Juisci-Black, #212121);
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 110%; /* 17.6px */
  }

  hr {
    margin: 24px 0;
    border-color: #d2dce2;
  }
`;

const RoomsMenuList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  a {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #212121;
    width: 100%;

    .logo {
      object-fit: contain;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border: 1px solid #d2dce2;
      border-radius: 50%;
    }

    svg {
      width: 30px;
      height: 30px;
    }

    .room-info {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      min-width: 0;
    }

    .room-name-container {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      min-width: 0;
    }

    .room-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 13px;
      font-weight: 400;
    }

    .room-org {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Inter;
      font-size: 9px;
      font-weight: 400;
    }

    .audio-icon {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }

    .link-icon {
      margin-left: auto;
      width: 30px;
      min-width: 30px;
      height: 30px;
    }
  }
`;

const LogoPlaceholder = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: #e5e5e5;
`;

const ExpandToggle = styled.span`
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  text-decoration: underline;
  color: #CE0868;
`;
