import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { useCurrentUser } from "../../../domains/user/hooks/useCurrentUser";
import { SpecialtyModel } from "../../../domains/app/app.types";
import { PlaylistModel } from "../../../domains/playlist/playlist.types";
import { usePublicPlaylistsWithCache } from "../../../domains/playlist/endpoints/getPublicPlaylists";
import { usePlaylistCache } from "../../../domains/playlist/hooks/usePlaylistCache";
import { useReadyState } from "../../../domains/app/hooks/useReadyState";
import HomeCarousel from "./HomeCarousel";
import CarouselIndicator from "../../app/CarouselIndicator";
import PlaylistCard from "../../playlists/PlaylistCard";
import styled from "styled-components";
import { ReactComponent as PlaylistIcon } from "../../../assets/icons/home_playlist_icon.svg";
import SkeletonContentCard from "../../profile/SkeletonContentCard";

export default function RecommendedPlaylistCarousel() {
  const { currentUser } = useCurrentUser();
  const { activeLang, t } = useLangContext();
  const { isInRoom } = useRoomContext();
  const { publicPlaylists: playlists } = usePlaylistCache();
  const getPublicPlaylists = usePublicPlaylistsWithCache();
  const { setAsReady, useReadyEffect } = useReadyState();
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isInRoom) {
        if (playlists.length === 0) await loadPlaylists();
        setAsReady();
      }
    })();
  }, [isInRoom]);

  useReadyEffect(() => {
    loadPlaylists();
  }, [activeLang]);

  async function loadPlaylists() {
    try {
      setLoading(true);

      const queryParams: any = {
        limit: 5,
        offset: 0,
        language: activeLang,
      };

      // NOTE: Deactivated, but may be used later.
      // if (currentUser?.main_specialty) {
      //   queryParams.medicalSpecialties = [
      //     currentUser?.main_specialty?._id,
      //     ...currentUser?.medical_specialties.map(
      //       (s: SpecialtyModel) => s._id
      //     ),
      //   ].toString();
      // }

      const { docs } = await getPublicPlaylists(queryParams);

      // If there is no recommended playlist, make new request ignoring specialties
      if (!docs.length) {
        await getPublicPlaylists({
          limit: 20,
          offset: 0,
          language: activeLang,
        });
      }

      setLoading(false);
    } catch (error) {
      console.error("Couldn't get public playlists.", error);
    }
  }

  return (
    <Container className=' primary ' style={{ background: "#FAE6F0" }}>
      <Header>
        <div className='title'>
          <PlaylistIcon />
          <h3>{t("home:playlists.recommended")}</h3>
        </div>
        <Link to='/discovery/playlists'>
          <span>{t("common:action.seeAll")}</span>
        </Link>
      </Header>

      <HomeCarousel
        swipeable={playlists.length}
        centerMode={true}
        centerSlidePercentage={100}
        renderIndicator={(_onClickHandler: any, isSelected: boolean) => {
          return <CarouselIndicator active={isSelected} />;
        }}
      >
        {isLoading
          ? [
            <SkeletonContentCard key={0} contentFormat='playlist' />,
            <SkeletonContentCard key={1} contentFormat='playlist' />,
          ]
          :
          playlists.slice(0, 5).map((playlist: PlaylistModel) => (
            <SlideWrapper key={playlist._id + "carouselplaylist"}>
              <PlaylistCard playlist={playlist} />
            </SlideWrapper>
          ))
        }
      </HomeCarousel>
    </Container>
  );
}

const Container = styled.div`
  padding: 16px 0px;

  .slider-wrapper {
    box-sizing: border-box;
    padding: 0 5px;
  }

  .skeleton-wrapper {
    padding: 16px 16px 8px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 21px 8px 21px;

  .title {
    display: flex;
    gap: 6px;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;
  }

  span {
    color: #ce0868;
  }

  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const SlideWrapper = styled.div`
  flex: 1;
  display: flex;

  .playlist-card {
    flex: 1;
  }
`;
