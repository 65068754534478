import { RefObject, useEffect, useMemo, useState } from "react";

export function useOnScreen(
  ref: RefObject<HTMLElement> | null,
  initialValue?: boolean,
) {
  const [isIntersecting, setIntersecting] = useState(initialValue);
  const observer = useMemo(() => new IntersectionObserver(
    ([entry]) => setIntersecting(entry.isIntersecting)
  ), [ref]);

  useEffect(() => {
    if (ref?.current) {
      observer.observe(ref.current)
    }
    return () => observer.disconnect();
  }, [ref?.current])

  return isIntersecting;
}