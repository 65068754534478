import { useEffect } from "react";
import { useAppSelector } from "../../redux";
import { useSearchArticles } from "../../domains/search/hooks/useSearchArticles";
import { gaEventViewItemListArticle } from "../../tools/analytics/articleAnalytics";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import Loader from "../Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonContentCard from "../profile/SkeletonContentCard";
import ArticleCard from "../cards/ArticleCard";
import { NoResultText, SearchResultsListWrapper } from "./layout";
import { t } from "i18next";

export default function SearchResultsArticles() {
	const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
	const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
	const searcher = useSearchArticles(searchValueParam);

	useEffect(() => {
		for (const list of [searcher.fallback, searcher.results]) {
			if (list.length) {
				gaEventViewItemListArticle({
					articles: list,
					listName: AnalyticsItemList.SEARCH,
				});
			}
		}
	}, [searcher.fallback, searcher.results]);

	if (!searchValueParam && !filtersApplied) {
		if (searcher.isLoadingFallback) {
			return (
				<FadeIn>
					<SearchResultsListWrapper>
						{[...Array(10)].map((_, index) => <SkeletonContentCard key={index} />)}
					</SearchResultsListWrapper>
				</FadeIn>
			);
		}

		return (
			<FadeIn key="fallback">
				<SearchResultsListWrapper>
					{searcher.fallback.map((article: any) => {
						return (
							<ArticleCard
								key={article.id}
								article={article}
								analyticsListName={AnalyticsItemList.SEARCH}
							/>
						);
					})}
				</SearchResultsListWrapper>
			</FadeIn>
		);
	}

	if (searcher.isLoadingResults) {
		return (
			<FadeIn>
				<SearchResultsListWrapper>
					{[...Array(10)].map((_, index) => <SkeletonContentCard key={index} />)}
				</SearchResultsListWrapper>
			</FadeIn>
		);
	}

	if (!searcher.results.length) {
		return <NoResultText>{t("common:noSearchResults")}</NoResultText >;
	}

	return (
		// NOTE: specific key to force fade when switching results
		<FadeIn key="results">
			<InfiniteScroll
				dataLength={searcher.results.length}
				next={() => searcher.searchMore()}
				hasMore={searcher.results.length < searcher.total}
				pullDownToRefresh={false}
				scrollableTarget="content-ref"
				loader={null}
			>
				<SearchResultsListWrapper>
					{searcher.results.map((article, index) => {
						return (
							<ArticleCard
								key={index}
								article={article}
								analyticsListName={AnalyticsItemList.SEARCH}
							/>
						);
					})}
					{searcher.isLoadingResults && <Loader loaderOnly />}
				</SearchResultsListWrapper>
			</InfiniteScroll>
		</FadeIn>
	);
}
