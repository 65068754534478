import { DefaultProfilePicture } from "../image.types";
import { UserModel } from "../../user/user.types";
import AvatarApple from "../../../assets/images/default-avatar/default-apple.svg";
import AvatarPineapple from "../../../assets/images/default-avatar/default-pineapple.svg";
import AvatarPear from "../../../assets/images/default-avatar/default-pear.svg";
import AvatarLemon from "../../../assets/images/default-avatar/default-lemon.svg";
import AvatarBanana from "../../../assets/images/default-avatar/default-banana.svg";
import AvatarAvocado from "../../../assets/images/default-avatar/default-avocado.svg";
import AvatarAppleGrey from "../../../assets/images/default-avatar/default-apple-grey.svg";
import AvatarPineappleGrey from "../../../assets/images/default-avatar/default-pineapple-grey.svg";
import AvatarPearGrey from "../../../assets/images/default-avatar/default-pear-grey.svg";
import AvatarLemonGrey from "../../../assets/images/default-avatar/default-lemon-grey.svg";
import AvatarBananaGrey from "../../../assets/images/default-avatar/default-banana-grey.svg";
import AvatarAvocadoGrey from "../../../assets/images/default-avatar/default-avocado-grey.svg";

export const defaultAvatarImages = {
  [DefaultProfilePicture.Pear]: {
    default: AvatarPear,
    disabled: AvatarPearGrey,
  },
  [DefaultProfilePicture.Apple]: {
    default: AvatarApple,
    disabled: AvatarAppleGrey,
  },
  [DefaultProfilePicture.Pineapple]: {
    default: AvatarPineapple,
    disabled: AvatarPineappleGrey,
  },
  [DefaultProfilePicture.Lemon]: {
    default: AvatarLemon,
    disabled: AvatarLemonGrey,
  },
  [DefaultProfilePicture.Banana]: {
    default: AvatarBanana,
    disabled: AvatarBananaGrey,
  },
  [DefaultProfilePicture.Avocado]: {
    default: AvatarAvocado,
    disabled: AvatarAvocadoGrey,
  },
};

export function pickUserImage(user: UserModel) {
  if (user?.profilePicture?.url) {
    return user.profilePicture.url;
  }
  if (user?.profilePicture?.name) {
    return defaultAvatarImages[user.profilePicture.name as DefaultProfilePicture].default;
  }
  // TEMP: Legacy profile pictures on some pages
  if (user?.image?.url) {
    return user?.image.url;
  }

  // Select a picture based on the user's name length
  const randomImage = Object.values(DefaultProfilePicture)[
    (user?.fullname?.length || 0) % 6
  ];

  return defaultAvatarImages[randomImage].default;
}
