import { userApi } from "../../../redux/user/user.service";
import { UserModel } from "../user.types";

const api = userApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query<UserModel, void>({
      query: () => {
        return "/user/me";
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getCurrentUser,
} = api.endpoints;

export const {
  useLazyGetCurrentUserQuery,
} = api;
