import { ReactChildren, ReactNode, useRef } from "react";
import styled from "styled-components";
import ClampLines from "../../components/app/ClampLines";
import { t } from "i18next";

export default function ExpandableText({
  children,
  isExpanded,
  setExpand,
}: {
  children: ReactNode | ReactChildren;
  isExpanded: boolean;
  setExpand: Function;
}) {
  const clampRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      <ClampLines ref={clampRef} lines={!isExpanded ? 10 : 10000}>
        <Text>{children}</Text>
      </ClampLines>
      {((clampRef.current &&
        clampRef.current?.scrollHeight > clampRef.current?.clientHeight) ||
        isExpanded) && (
        <ExpandButton
          className={isExpanded ? "expanded" : ""}
          onClick={() => setExpand(!isExpanded)}
        >
          {isExpanded ? t("common:action.close") : t("common:action.readMore")}
        </ExpandButton>
      )}
    </>
  );
}

const Text = styled.p`
  margin-bottom: 0;
  white-space: pre-wrap;
`;

const ExpandButton = styled.button`
  margin: 10px 0 0 10px;
  padding: 0;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: underline;
  font-family: "Inter";
  font-size: 16px;
  font-weight: 300;
  font-style: italic;
  color: "#212121";

  &::before {
    content: "";
    display: block;
    width: 0.4em;
    height: 0.4em;
    border-width: 0 0.2em 0.2em 0;
    border-style: solid;
    border-color: #f42cab !important;
    transform: rotate(45deg);
  }

  &.expanded::before {
    transform: rotate(225deg);
  }
`;
