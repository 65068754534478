import { useState, useEffect, useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import { AudioRoomContext } from "../../domains/room/room.types";
import styled from "styled-components";
import {
  CardContainer,
  CardActions,
  CardDate,
  CardTags,
  CardType,
  CardTitle,
  CondensedCardFooter,
} from "./layout";
import { gaEventSelectItemPodcast } from "../../tools/analytics/audioRoomAnalytics";
import { getPodcastBackground } from "../../domains/room/utils/getPodcastBackground";
// import video likes and such
import OptionsPanel from "../app/OptionsPanel";
import PlaylistPanel from "../app/PlaylistPanel";
import { ReactComponent as PlayIcon } from "../../assets/icons/podcast-play-icon.svg";

export default function PodcastCard({
  podcast,
  listIndex,
  audioRoomContext,
}: {
  podcast: any; // TODO: add correct model
  listIndex: number;
  audioRoomContext?: AudioRoomContext;
}) {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const [duration, setDuration] = useState<number | null>(null);

  const loadAudioDuration = useCallback(() => {
    if (podcast.speech && podcast.speech.url) {
      const audio = new Audio(podcast.speech.url);
      audio.addEventListener("loadedmetadata", () => {
        setDuration(audio.duration);
      });
      audio.load();
    }
  }, [podcast.speech]);

  useEffect(() => {
    loadAudioDuration();
  }, [loadAudioDuration]);

  function formatDuration(seconds: number) {
    if (!seconds) return "";
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  }

  function handleNavigate() {
    if (audioRoomContext) {
      const payload = {
        podcast,
        topicName: audioRoomContext?.topicName,
        sectionName: audioRoomContext?.sectionName,
        durationMin: Math.floor((duration as number) / 60),
      };
      gaEventSelectItemPodcast(payload);
    }

    history.push({
      pathname: `/room/audio/${roomId}/player/${podcast.slug}`,
      state: { podcast, listIndex, audioRoomContext },
    });
  }

  const thumbnailUrl = getPodcastBackground(listIndex);

  return (
    <>
      <CardContainer
        variant="dark"
        backgroundUrl={thumbnailUrl}
        onClick={handleNavigate}
      >
        <CardHeader>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: "8px",
            }}
          >
            {/* <CardDate date={podcast.publication_date} /> */}
            <CardTags
              contentLabel="podcast"
              tags={podcast.medical_specialties}
            />
          </div>
          {/* <CardActions
            isLiked={isLiked}
            onLike={handleLike}
            onOptions={handleOptions}
          /> */}
        </CardHeader>
        <CardBody>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <CardType>{podcast.article_type ?? "Podcast"}</CardType>
            <CardTitle lines={4}>{podcast?.title}</CardTitle>
          </div>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <PlayIcon />
            {duration !== null ? (
              <span>{formatDuration(duration)}</span>
            ) : (
              <span>0:00</span>
            )}
          </div>
        </CardBody>
        <CondensedCardFooter
          className="dark"
          companyName={podcast.journal?.name}
          companyLogo={podcast.journal?.image?.url}
        />
      </CardContainer>
    </>
  );
}

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;

  span {
    color: #fff;
    font-family: "Inter";
    font-size: 11px;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
  }
`;
