import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  SearchCategory,
  SearchFacetsModel,
  SearchFiltersModel,
  defaultSearchFilters,
  defaultSearchFacets,
} from "./search.types";

interface ReducerState {
  cache: {
    articles: { results: []; total: number };
    journals: { results: []; total: number };
    playlists: { results: []; total: number };
    users: { results: []; total: number };
    videos: { results: []; total: number };
    nectars: { results: []; total: number };
  };
  selectedContextTab: SearchCategory;
  searchFilters: SearchFiltersModel;
  searchFacets: SearchFacetsModel;
  previousSearchFacets: SearchFacetsModel;
  filtersApplied: boolean;
  searchPageScrollPosition: number;
  contextScrollerPosition: number;
}

const initialState: ReducerState = {
  cache: {
    articles: { results: [], total: 0 },
    journals: { results: [], total: 0 },
    playlists: { results: [], total: 0 },
    users: { results: [], total: 0 },
    videos: { results: [], total: 0 },
    nectars: { results: [], total: 0 },
  },
  selectedContextTab: SearchCategory.ALL,
  searchFilters: defaultSearchFilters,
  searchFacets: defaultSearchFacets,
  previousSearchFacets: {},
  filtersApplied: false,
  searchPageScrollPosition: 0,
  contextScrollerPosition: 0,
};

export const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    memorizeSearchArticles: (state, action: PayloadAction<{ results: []; total: number }>) => {
      state.cache.articles = action.payload;
    },
    memorizeSearchJournals: (state, action: PayloadAction<{ results: []; total: number }>) => {
      state.cache.journals = action.payload;
    },
    memorizeSearchPlaylists: (state, action: PayloadAction<{ results: []; total: number }>) => {
      state.cache.playlists = action.payload;
    },
    memorizeSearchUsers: (state, action: PayloadAction<{ results: []; total: number }>) => {
      state.cache.users = action.payload;
    },
    memorizeSearchVideos: (state, action: PayloadAction<{ results: []; total: number }>) => {
      state.cache.videos = action.payload;
    },
    memorizeSearchNectars: (state, action: PayloadAction<{ results: []; total: number }>) => {
      state.cache.nectars = action.payload;
    },
    clearCachedResults: (state) => {
      state.cache.articles = { results: [], total: 0 };
      state.cache.journals = { results: [], total: 0 };
      state.cache.playlists = { results: [], total: 0 };
      state.cache.users = { results: [], total: 0 };
      state.cache.videos = { results: [], total: 0 };
      state.cache.nectars = { results: [], total: 0 };
      // Reset page scroll position eveytime cache results are cleared      
      state.searchPageScrollPosition = 0;
    },
    selectContextTab: (state, action: PayloadAction<SearchCategory>) => {
      state.selectedContextTab = action.payload;
    },
    clearContextTab: (state) => {
      state.selectedContextTab = SearchCategory.ALL;
    },
    applyFilters: (state, action: PayloadAction<SearchFiltersModel>) => {
      state.searchFilters = action.payload;
      state.filtersApplied =
        JSON.stringify(state.searchFilters) !==
        JSON.stringify(defaultSearchFilters);
    },
    resetFilters: (state) => {
      state.searchFilters = defaultSearchFilters;
      state.filtersApplied = false;
      state.previousSearchFacets = {};
    },
    updateFacets: (state, action: PayloadAction<{
      age_facet: any[];
      article_type: any[];
      impact_factor_facet: any[];
    }>) => {
      const newFacets = action.payload;

      if (!state.previousSearchFacets.publicationDate) {
        const ageFacet: any = {};
        newFacets.age_facet?.forEach(
          ({ key, doc_count }: { key: string; doc_count: number }) => {
            ageFacet[key] = doc_count;
          }
        );
        state.searchFacets.publicationDate = ageFacet;
      }

      if (!state.previousSearchFacets.publicationType) {
        const articleTypeFacet: any = {};
        newFacets.article_type?.forEach(
          ({ key, doc_count }: { key: string; doc_count: number }) => {
            articleTypeFacet[key] = doc_count;
          }
        );
        state.searchFacets.publicationType = articleTypeFacet;
      }

      if (!state.previousSearchFacets.impactFactor) {
        const impactFactorFacet: any = {};
        newFacets.impact_factor_facet?.forEach(
          ({ key, doc_count }: { key: string; doc_count: number }) => {
            impactFactorFacet[key] = doc_count;
          }
        );
        state.searchFacets.impactFactor = impactFactorFacet;
      }
    },
    memorizeFacets: (state, action: PayloadAction<SearchFacetsModel>) => {
      state.previousSearchFacets = action.payload;
    },
    setSearchPageScrollPosition: (state, action: PayloadAction<number>) => {
      state.searchPageScrollPosition = action.payload;
    },
    setContextScrollerPosition: (state, action: PayloadAction<number>) => {
      state.contextScrollerPosition = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  memorizeSearchArticles,
  memorizeSearchJournals,
  memorizeSearchPlaylists,
  memorizeSearchUsers,
  memorizeSearchVideos,
  memorizeSearchNectars,
  clearCachedResults,
  selectContextTab,
  clearContextTab,
  applyFilters,
  resetFilters,
  updateFacets,
  memorizeFacets,
  setSearchPageScrollPosition,
  setContextScrollerPosition,
} = slice.actions;

export default slice.reducer;
