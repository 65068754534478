import { getApiRequestService } from "../../../services/index.services";
import { sortArticleImages } from "../utils/sortArticleImages";

export async function getArticleBySlug(articleSlug: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/article/${articleSlug}`);
    return sortArticleImages(res.data);
  } catch (error) {
    throw error;
  }
};