import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux";
import { UserModel } from "../../domains/user/user.types";
import { useSearchUsers } from "../../domains/search/hooks/useSearchUsers";
import Loader from "../Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import UserButton from "../app/UserButton";
import { NoResultText } from "./layout";
import { t } from "i18next";

export default function SearchResultsUsers() {
	const history = useHistory();
	const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
	const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
	const searcher = useSearchUsers(searchValueParam);

	if (!searchValueParam && !filtersApplied) {
		if (searcher.isLoadingFallback) {
			return <Loader loaderOnly />;
		}

		return (
			<FadeIn key="fallback">
				{searcher.fallback.map((listUser: UserModel) => {
					return (
						<UserButton
							key={listUser.uid}
							user={listUser}
							onClick={() => {
								history.push(`/profile/user/${listUser?.uid}`);
							}}
						/>
					);
				})}
			</FadeIn>
		);
	}

	if (searcher.isLoadingResults) {
		return <Loader loaderOnly />;
	}

	if (!searcher.results.length) {
		return <NoResultText>{t("common:noSearchResults")}</NoResultText >;
	}

	return (
		// NOTE: specific key to force fade when switching results
		<FadeIn key="results">
			<InfiniteScroll
				dataLength={searcher.results.length}
				next={() => searcher.searchMore()}
				hasMore={searcher.results.length < searcher.total}
				pullDownToRefresh={false}
				scrollableTarget="content-ref"
				loader={null}
			>
				{searcher.results.map((listUser: UserModel) => {
					return (
						<UserButton
							key={listUser.uid}
							user={listUser}
							onClick={() => {
								history.push(`/profile/user/${listUser?.uid}`);
							}}
						/>
					);
				})}
				{searcher.isLoadingResults && <Loader loaderOnly />}
			</InfiniteScroll>
		</FadeIn>
	);
}
