import { SupportedLanguage } from "../../interfaces";
import { SpecialtyModel, TagModel } from "../app/app.types";
import { OrganisationModel } from "../organisation/organisation.types";

// NOTE: Values are shared with the backend. Do not change.
export enum RoomType {
  DEFAULT = "Default",
  AUDIO = "Audio",
}

export interface RoomModel {
  _id: string;
  type: RoomType;
  isPublic: boolean;
  name: string;
  translations: { [key in SupportedLanguage]: any };
  organisation: OrganisationModel;
  primaryColor: string;
  articles: any[]; // TODO: Check type.
  contents: any[]; // TODO: Check type.
  playlists: string[];
  allowedLanguages: SupportedLanguage[];
  logo: {
    path: string;
    url: string;
  };
  ico: {
    path: string;
    url: string;
  };
  mainSpecialty: SpecialtyModel;
  medicalSpecialties: SpecialtyModel[];
  tags: TagModel[];
}

export interface RoomQuizModel {
  label: string;
  title: string;
  subtitle: string;
  eventName: string;
  template: string;
  displayAt: Date;
  endDisplayAt: Date;
}

export interface AudioRoomContext {
  topicName: string;
  sectionName: string;
}
