import { ChangeEvent, useEffect, useState } from "react";
import { SupportedLanguage } from "../../interfaces";
import { SpecialtyModel, TagModel } from "../../domains/app/app.types";
import { getSpecialtyTags } from "../../domains/app/endpoints/getSpecialtyTags";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { UNAVAILABLE_SPECIALTIES } from "../../tools/utils";
import SaveHeader from "../../components/app/headers/SaveHeader";
import Loader from "../../components/Loader";
import CustomSelect from "../../components/customSelect";
import FadeIn from "react-fade-in/lib/FadeIn";
import { CustomButton } from "../../components/global";
import CustomIcon from "../../components/CustomIcon";
import ConfirmModal from "../../components/app/ConfirmModal";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";

export default function EditSpecialtyPanel({
  editedSpecialty,
  availableSpecialties,
  preselectedTags,
  onSubmit,
  onDelete,
  onClose,
  isMain = false,
  isNew = false,
}: {
  editedSpecialty: SpecialtyModel;
  availableSpecialties: SpecialtyModel[];
  preselectedTags: any[];
  onSubmit: Function;
  onDelete: Function;
  onClose: Function;
  isMain?: boolean;
  isNew?: boolean;
}) {
  const [isLoading, setLoading] = useState(true);
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const [availableTags, setAvailableTags] = useState([]);
  const [selectedSpecialty, setSelectedSpecialty] =
    useState<SpecialtyModel>(editedSpecialty);
  const [selectedTags, setSelectedTags] = useState<TagModel[]>([]);

  useEffect(() => {
    (async function () {
      try {
        window.scrollTo(0, 0);
        setLoading(true);
        const speTags = await getSpecialtyTags(selectedSpecialty.uid);
        setAvailableTags(speTags);
        setSelectedTags(
          preselectedTags?.filter((tag: TagModel) => {
            return speTags.find((speTag: TagModel) => speTag.uid === tag.uid);
          })
        );
        setLoading(false);
      } catch (error) {
        console.error("Couldn't mount edit specialty panel.", error);
        displayToast(t("error:default"));
        onClose();
      }
    })();
  }, [selectedSpecialty]);

  function handleChangeSpecialty(e: ChangeEvent<HTMLSelectElement>) {
    const specialty = availableSpecialties.find(
      (el: SpecialtyModel) => el.uid === e.target.value
    );
    setSelectedSpecialty(specialty as SpecialtyModel);
  }

  function handleToggleTag(tag: TagModel) {
    const tagAlreadyAdded = selectedTags.find(
      (el: TagModel) => el.uid === tag.uid
    );
    if (tagAlreadyAdded) {
      setSelectedTags(
        selectedTags.filter((el: TagModel) => el.uid !== tag.uid)
      );
    } else {
      setSelectedTags([...selectedTags, tag]);
    }
  }

  function handleToggleAllTags() {
    setSelectedTags(
      selectedTags.length === availableTags.length ? [] : availableTags
    );
  }

  async function handleSubmit() {
    try {
      onSubmit({
        isMain,
        isNew,
        selectedSpecialty,
        selectedTags,
      });
    } catch (error) {
      console.error("Couldn't edit specialty.", error);
      displayToast(t("error:default"));
    }
  }

  async function handleDeleteSpecialty() {
    try {
      onDelete(selectedSpecialty);
    } catch (error) {
      console.error("Couldn't delete specialty.", error);
      displayToast(t("error:default"));
    }
  }

  return (
    <PanelContainer>
      <SaveHeader
        title={t(isMain ? "common:mainSpecialty" : "common:secondarySpecialty")}
        saveReady={true}
        onSaveClick={handleSubmit}
        onGoBack={onClose}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <ContentContainer>
          {isMain || isNew ? (
            <>
              <CustomSelect
                noEmptyOption
                label={isMain ? t("common:mainSpecialty") : t("common:secondarySpecialty")}
                renderOptions={availableSpecialties
                  .filter((el) => {
                    return !UNAVAILABLE_SPECIALTIES.includes(
                      getItemTranslation(el, SupportedLanguage.EN)
                    );
                  })
                  // Sort specialties by alphabetical order
                  .sort((a, b) => {
                    return getItemTranslation(a).localeCompare(getItemTranslation(b));
                  })
                  .map((el, index) => (
                    <option key={el.uid + index} value={el.uid}>
                      {getItemTranslation(el)}
                    </option>
                  ))}
                value={selectedSpecialty.uid}
                onChange={handleChangeSpecialty}
              />
              <div className='description'>
                {t("profile:editProfile.specialty.helpText.changeMainSpecialty")}
              </div>
            </>
          ) : (
            <>
              <h2>{getItemTranslation(selectedSpecialty)}</h2>
              <div className='description'>
                {t("profile:editProfile.specialty.helpText.changeSecondarySpecialty")}
              </div>
            </>
          )}

          <div style={{ padding: "10px 0" }}>
            <FadeIn>
              <div className='tags-flex'>
                {!!availableTags.length ? (
                  availableTags?.map((tag: TagModel, index: number) => (
                    <div
                      key={index}
                      className={`tag ${selectedTags?.find(
                        (el: { uid: string }) => el.uid === tag.uid
                      )
                        ? "selected"
                        : ""
                        }`}
                      onClick={() => handleToggleTag(tag)}
                    >
                      {getItemTranslation(tag)}
                    </div>
                  ))
                ) : (
                  <p className='description'>
                    {t("profile:editProfile.specialty.noResults")}
                  </p>
                )}
              </div>

              {!!availableTags.length && (
                <CustomButton
                  type='button'
                  className='secondary small'
                  style={{ fontWeight: 600, marginTop: 80 }}
                  onClick={handleToggleAllTags}
                >
                  {selectedTags.length === availableTags.length
                    ? t("common:action.unselectAll")
                    : t("common:action.selectAll")}
                </CustomButton>
              )}

              {!(isMain || isNew) && (
                <>
                  <hr style={{ margin: "30px 0" }} />
                  <div className='description'>
                    {t("profile:editProfile.specialty.action.deleteSpecialty")}
                  </div>
                  <CustomButton
                    type='button'
                    className={"danger small icon"}
                    onClick={() => setConfirmModalVisible(true)}
                  >
                    <CustomIcon iconName='trash' color='white' />
                    {t("common:action.delete")}
                  </CustomButton>
                </>
              )}
            </FadeIn>
          </div>

          <ConfirmModal
            isVisible={isConfirmModalVisible}
            promptText={t("profile:editProfile.specialty.confirmPrompt")}
            confirmDanger={true}
            onConfirm={handleDeleteSpecialty}
            onCancel={() => setConfirmModalVisible(false)}
          />
        </ContentContainer>
      )}
    </PanelContainer>
  );
}

const PanelContainer = styled.div`
  background: #ecf0f5;
  min-height: 100dvh;
`;

const ContentContainer = styled.div`
  padding: 50px 30px;

  .description {
    margin: 16px 0;
    font-family: "Roboto";
    font-size: 16px;
    line-height: 140%;
    text-align: center;
  }

  .tags-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: start;
    align-items: center;
  }

  .tag {
    border: 1px solid #d2dce2;
    padding: 10px;
    border-radius: 100px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 100%;
    color: #212121;
    background-color: #fff;
    transition: all ease-out 0.3s;

    &.selected {
      border-color: #fff3ce;
      background-color: #fff3ce;
      color: #ff8800;
    }
  }
`;
