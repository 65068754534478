import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { iosGenerateHaptic, isNativeIOS } from "../../tools/ios";
import CustomIcon from "../CustomIcon";
import { RoundIcon } from "../global";
import Lottie from "lottie-react";
import Spinner from "../../assets/animations/spinner.json";
import {
  HapticEffect,
} from "../../interfaces";
import { Slider } from "@mui/material";
import { formatTime } from "../../tools/utils";
import { InternalAudioPlayer } from "../../objects/audio";

// NOTE: This is a core complex component in the app.
// Do NOT touch without advising your lead first.

export default function PostAudioPlayer({
  trackUrl,
  onLoad,
  onPlay,
  onPause,
  onProgressTime,
  onProgressRate,
  onEndReached,
  onUnmount,
  numberOfTracks,
  trackIndex,
  onPrevious,
  onNext,
  resumeOnTrackChange,
}: {
  trackUrl: string | null;
  onLoad?: Function;
  onPlay?: Function;
  onPause?: Function;
  onProgressTime?: Function;
  onProgressRate?: Function;
  onEndReached?: Function;
  onUnmount?: Function;
  numberOfTracks?: number;
  trackIndex?: number;
  onPrevious?: Function;
  onNext?: Function;
  resumeOnTrackChange?: boolean;
}) {
  const [trackDuration, setTrackDuration] = useState(-1);
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [isPlaying, setPlaying] = useState(false);
  const internalPlayerRef = useRef<InternalAudioPlayer | null>(null);

  useEffect(() => {
    return () => {
      handleStop();
      onUnmount && onUnmount({
        playedChunks: internalPlayerRef.current?._playedChunks,
      });
    };
  }, []);

  useEffect(() => {
    if (trackUrl) loadTrack(trackUrl);

    return () => handleStop();
  }, [trackUrl]);

  useEffect(() => {
    if (resumeOnTrackChange) {
      // NOTE: For some reason both these instructions need to be set separatedly.
      setPlaying(true);
      internalPlayerRef.current?.play();
    }
  }, [trackDuration]);

  useEffect(() => {
    if (isPlaying) {
      internalPlayerRef.current?.play()
      onPlay && onPlay();
    } else {
      internalPlayerRef.current?.pause();
      onPause && onPause();
    }
  }, [isPlaying]);

  useEffect(() => {
    internalPlayerRef.current?.setPlaybackRate(playbackRate);
  }, [playbackRate]);

  async function loadTrack(url: string) {
    if (internalPlayerRef.current) {
      await internalPlayerRef.current.stop();
      internalPlayerRef.current = null;
    }

    const internalPlayer = new InternalAudioPlayer(isNativeIOS, (duration) => {
      setTrackDuration(duration);
      onLoad && onLoad(duration);
    });
    internalPlayer.setTrack(url);
    internalPlayer.onProgress = (time: number) => {
      setCurrentTime(time);
      onProgressTime && onProgressTime(time);

      const progressRate = Math.ceil((time / trackDuration) * 100);
      onProgressRate && onProgressRate(progressRate);
    };
    internalPlayer.onEnd = () => {
      setPlaying(false);
      onEndReached && onEndReached();
    };
    internalPlayerRef.current = internalPlayer;
  }

  function canGoPreviousTrack() {
    if (numberOfTracks === undefined || trackIndex === undefined) {
      return false;
    }
    return numberOfTracks > 0 && trackIndex > 0;
  }

  function canGoNextTrack() {
    if (numberOfTracks === undefined || trackIndex === undefined) {
      return false;
    }
    return trackIndex > -1 && trackIndex < numberOfTracks - 1;
  }

  function handlePlayPause() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    setPlaying(!isPlaying);
  }

  function handleSeek(value: number) {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    setCurrentTime(value);
    internalPlayerRef.current?.seek(value);
  }

  function handleSkipBackward() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    const value = Math.max(0, currentTime - 10);
    setCurrentTime(value);
    internalPlayerRef.current?.seek(value);
  }

  function handleStop() {
    internalPlayerRef.current?.stop();
    setCurrentTime(0);
  }

  function handleSwitchPlaybackRate() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    const newRate = {
      1: 1.5,
      1.5: 2,
      2: 1,
    }[playbackRate] ?? 1;
    setPlaybackRate(newRate);
  }

  function handleGoPrevTrack() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    if (canGoPreviousTrack() && onPrevious) onPrevious();
  }

  function handleGoNextTrack() {
    if (isNativeIOS) iosGenerateHaptic(HapticEffect.SELECTION);
    if (canGoNextTrack() && onNext) onNext();
  }

  return (
    <>
      <PlayIcon
        onClick={handlePlayPause}
        isPlaying={isPlaying}
        isLoading={!trackUrl}
      />

      <ControllerWrapper
        className={isPlaying ? "" : "hide"}
        isNative={isNativeIOS}
      >
        <Controls>
          {onPrevious && (
            <CustomIcon
              iconName='prev_audio'
              className={!canGoPreviousTrack() ? "disabled" : ""}
              onClick={handleGoPrevTrack}
            />
          )}

          <CustomIcon iconName='back_audio' onClick={handleSkipBackward} />

          <CustomIcon
            iconName={isPlaying ? "pause_audio" : "play_audio"}
            onClick={handlePlayPause}
          />

          <SpeedButton onClick={handleSwitchPlaybackRate}>
            x{playbackRate ?? 1}
          </SpeedButton>

          {onNext && (
            <CustomIcon
              className={!canGoNextTrack() ? "disabled" : ""}
              iconName='next_audio'
              onClick={handleGoNextTrack}
            />
          )}
        </Controls>

        <SliderControls>
          <div style={{ width: 50 }}>{formatTime(currentTime)}</div>
          <Slider
            min={0}
            max={trackDuration}
            value={currentTime}
            onChangeCommitted={(_, value) => handleSeek(value as number)}
            sx={{
              "& .MuiSlider-track": {
                backgroundColor: "white",
                height: 5,
                borderRadius: 30,
              },
              "& .MuiSlider-rail": {
                backgroundColor: "#212121",
                height: 5,
                borderRadius: 30,
              },
              "& .MuiSlider-thumb": {
                height: 15,
                width: 15,
                backgroundColor: "white",
                "&:focus, &:hover, &.Mui-active": {
                  boxShadow: "none",
                },
              },
            }}
          />
          <div style={{ width: 50 }}>
            {trackDuration > 0 ? formatTime(trackDuration) : "..."}
          </div>
        </SliderControls>
      </ControllerWrapper>
    </>
  );
}

function PlayIcon({
  isPlaying,
  isLoading,
  onClick,
}: {
  isPlaying: boolean;
  isLoading: boolean;
  onClick: Function;
}) {
  return (
    <RoundIcon>
      {isLoading ? (
        <div
          style={{
            width: 60,
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "linear-gradient(180deg, #F42CAC 0%, #F1353B 100%)",
          }}
        >
          <Lottie
            animationData={Spinner}
            style={{ height: 30 }}
            loop={true}
            autoplay={true}
          />
        </div>
      ) : (
        <CustomIcon
          iconName={!isPlaying ? "play_audio" : "pause_audio"}
          className={`play-icon ${!isPlaying ? "playing" : ""}`}
          style={{
            background: "linear-gradient(180deg, #F42CAC 0%, #F1353B 100%)",
          }}
          onClick={onClick}
        />
      )}
    </RoundIcon>
  );
}

const ControllerWrapper = styled.div<{ isNative: boolean }>`
  box-sizing: border-box;
  position: fixed;
  left: 0;
  right: 0;
  bottom: calc(50px + var(--safe-area-bottom));
  z-index: 100;
  margin: 20px;
  padding: 30px 20px;
  border-radius: 8px;
  background: linear-gradient(180deg, #f42cab 0%, #f1353a 100%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  animation: slideIn 0.2s ease-in;

  &.hide {
    opacity: 0;
    display: none;
    animation: slideOut 0.2s ease-out;
  }

  @keyframes slideIn {
    0% {
      opacity: 0;
      bottom: -10px;
    }
    100% {
      opacity: 1;
      bottom: ${(props) => (props.isNative ? "70px" : "15px")};
    }
  }

  @keyframes slideOut {
    0% {
      opacity: 1;
      bottom: ${(props) => (props.isNative ? "70px" : "15px")};
    }
    100% {
      opacity: 0;
      bottom: -10px;
    }
  }

  color: white;
  font-weight: 800;
`;

const Controls = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-around;

  svg {
    height: 27px;
  }

  .--custom-icon.disabled {
    opacity: 0.5;
  }
`;

const SliderControls = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;

  .--custom-icon.disabled {
    opacity: 0.5;
  }
`;

const SpeedButton = styled.button`
  outline: none;
  border: none;
  background: white;
  width: 27px;
  height: 27px;
  line-height: 27px;
  border-radius: 50%;
  font-size: 10px;
  color: #f42cab;
  font-weight: 500;
  font-family: "Inter";
  padding: 0;
`;
