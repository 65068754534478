import { getApiRequestService } from "../../../services/index.services";

export async function deletePlaylist(playlistId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`/playlist/${playlistId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};