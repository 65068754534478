import { journalsApi } from "../journals.api";

interface QueryParams {
  limit: number;
  offset: number;
  sortImpactFactor?: "asc" | "desc";
}

const api = journalsApi.injectEndpoints({
  endpoints: (build) => ({
    getJournalsList: build.query<any, QueryParams>({
      query: (params) => {
        return {
          url: `/journal?offset=${params.offset ?? 0}&limit=${params.limit ?? 100}&sortImpactFactor=${params?.sortImpactFactor ?? "desc"}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetJournalsListQuery,
  useLazyGetJournalsListQuery,
} = api;
