import { SyntheticEvent } from "react";
import { Modal } from "antd-mobile";
import CustomIcon from "../CustomIcon";
import { t } from "i18next";
import { CustomButton } from "../global";

export default function ConfirmModal({
  isVisible,
  onConfirm,
  onCancel,
  promptText,
  cancelText = t("common:action.cancel"),
  confirmText = t("common:yes"),
  confirmDanger = false,
}: {
  isVisible: boolean;
  onConfirm: Function;
  onCancel?: Function;
  promptText: string;
  cancelText?: string | null;
  confirmText?: string;
  confirmDanger?: boolean;
}) {
  return (
    <Modal
      wrapClassName='app-confirm-modal'
      visible={isVisible}
      transparent
      maskClosable={false}
      onClose={() => onCancel}
    >
      <div
        style={{
          position: "absolute",
          right: 40,
          top: 10,
          transform: "translateX(30px)",
          zIndex: 99,
          opacity: 0.6,
        }}
      >
        {onCancel && (
          <CustomIcon
            iconName='close_alt'
            onClick={(e: SyntheticEvent) => {
              e.preventDefault();
              onCancel();
            }}
          />
        )}
      </div>
      <div
        style={{
          margin: onCancel ? "50px 0 20px" : "20px 0",
          fontFamily: "Roboto",
          fontWeight: 600,
          fontSize: "16px",
          lineHeight: "140%",
          textAlign: "center",
          color: "#212121",
        }}
      >
        {promptText}
      </div>

      {!!cancelText && (
        <CustomButton
          className='small'
          style={{ marginBottom: 10, whiteSpace: "pre-line" }}
          onClick={(e) => {
            e.preventDefault();
            onCancel && onCancel();
          }}
        >
          {cancelText}
        </CustomButton>
      )}
      <CustomButton
        className={`${confirmDanger ? "danger" : ""} small`}
        style={{
          whiteSpace: "pre-line",
        }}
        onClick={(e) => {
          e.preventDefault();
          onConfirm();
        }}
      >
        {confirmText}
      </CustomButton>
    </Modal>
  );
}
