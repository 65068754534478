import { displayToast } from "../components/app/AppToast";
import { t } from "i18next";

export const getCurrentOS = () => {
  if (window) {
    let agent = window.navigator.userAgent;
    if (agent?.includes("iPhone")) return "ios";
    if (agent?.includes("Android")) return "android";
    return "error";
  }
};

export const preventScroll = (on) => {
  if (on) {
    document.addEventListener("touchmove", preventTouchMove, {
      passive: false,
    });
    document.addEventListener("scroll", preventTouchMove, {
      passive: false,
    });
  } else {
    document.removeEventListener("touchmove", preventTouchMove, {
      passive: false,
    });
    document.removeEventListener("scroll", preventTouchMove, {
      passive: false,
    });
  }
};

export const preventTouchMove = (e) => {
  if (
    e.target.className !== "story-text" &&
    e.target.className !== "bullet-text" &&
    e.target.className !== "story-content" &&
    e.target.className !== "sc-fcdeBU jokdJK"
  ) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export function smoothScrollToTop(element) {
  if (element && typeof element.scrollTo === "function") {
    element.scrollTo({ top: 0, behavior: "smooth" });
  }
}

export const pickGradient = (type) => {
  switch (type) {
    case 0:
      return "linear-gradient(#FFD000, #FFA100)";

    case 1:
      return "linear-gradient(180deg, #FFD3A6 0%, #FF9C96 51.56%, #FC6F89 100%)";

    case 2:
      return "linear-gradient(180deg, #FFC408 0%, #FF734B 51.04%, #FF306E 100%)";

    case 3:
      return "linear-gradient(180deg, #FDB955 0%, #FF8A00 100%)";

    case 4:
      return "linear-gradient(180deg, #FF699C 0%, #FE5763 100%)";

    default:
      return "linear-gradient(180deg, #F42CAB 0%, #F1353A 100%)";
  }
};

export const maxVideoViewed = (format) => {
  let videosFormatViewed = JSON.parse(
    localStorage.getItem("videosFormatViewed") ?? null
  );

  if (!videosFormatViewed) {
    videosFormatViewed = { story: false, article: false };
    localStorage.setItem(
      "videosFormatViewed",
      JSON.stringify(videosFormatViewed)
    );
  }

  if (format === "story" && !videosFormatViewed.story) {
    videosFormatViewed.story = true;
    localStorage.setItem(
      "videosFormatViewed",
      JSON.stringify(videosFormatViewed)
    );
    return false;
  } else if (format === "article" && !videosFormatViewed.article) {
    videosFormatViewed.article = true;
    localStorage.setItem(
      "videosFormatViewed",
      JSON.stringify(videosFormatViewed)
    );

    return false;
  }

  return true;
};

export const redirectToStore = () => {
  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const isAndroid = /Android/.test(navigator.userAgent) && !window.MSStream;

  if (isIOS)
    window.location.href = "https://apps.apple.com/fr/app/juisci/id1588725828";
  else if (isAndroid)
    window.location.href = `https://play.google.com/store/apps/details?id=com.juisci.app&hl=us&gl=US`;
  else displayToast(t("error:default"));
};

// NOTE: These specialties have been deemed to not have sufficient data yet
// and do not appear in the app for user. The list may change in the future.
export const UNAVAILABLE_SPECIALTIES = [
  "Correctional Health",
  "Cosmetic Dentistry", // Available in September
  "Endodontics", // Available in September
  "General Dentistry", // Available in September
  "Geriatric Dentistry", // Available in September
  "Implant Dentistry", // Available in September
  "Infusion Therapy",
  "Integrative Medicine", // Available in September
  "Non-Clinical",
  "Nutrition", // Available in September
  "Oral and Maxillofacial Pathology", // Available in September
  "Oral and Maxillofacial Radiology", // Available in September
  "Oral and Maxillofacial Surgery", // Available in September
  "Oral Microbiology", // Available in September
  "Orthodontics", // Available in September
  "Other Clinical",
  "Periodontics", // Available in September
  "Prosthodontics", // Available in September
  "Public Health Dentistry", // Available in September
  "Reconstructive Dentistry", // Available in September
  "School & College Health",
  "Speech-Language", // Available in September
  "Telemetry", // Available in September
];

export function shortenText(str, max) {
  return str.length > max
    ? str.substring(0, str.lastIndexOf(" ", max)) + "…"
    : str;
}

export function formatTime(timeInSeconds) {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = Math.floor(timeInSeconds % 60);
  return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}
