import { getApiRequestService } from "../../../services/index.services";

export async function removeContentFromPlaylist(
  playlistId: string,
  contentId: string
) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`/playlist/${playlistId}/content`, {
      data: { contentId },
    });
    return res.data;
  } catch (error) {
    console.error("Couldn't remove content from playlist.", error);
    throw error;
  }
};