import { getApiRequestService } from "../../../services/index.services";

export async function getGptConversationFromExternalId(externalId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/conversation/externalId/${externalId}`);

    return res.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}