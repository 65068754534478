import { SpecialtyModel } from "../../domains/app/app.types";
import { gaPushEvent, gaPushEventDelay } from "./gaPushEvent";

enum Event {
  VIEW_PROFILE = "view_profile",
  PROFILE_FOLLOW = "profile_follow",
  MESSAGE_SENT = "message_sent",
  UPDATE_USER_INTERESTS = "update_user_interests",
}

export function gaEventUserViewProfile() {
  gaPushEventDelay(Event.VIEW_PROFILE);
}

export function gaEventUserFollowProfile() {
  gaPushEvent(Event.PROFILE_FOLLOW);
}

export function gaEventUserSendMessage() {
  gaPushEvent(Event.MESSAGE_SENT);
}

export function gaEventUserUpdateInterests(medicalSpecialties: SpecialtyModel[]) {
  gaPushEvent(
    Event.UPDATE_USER_INTERESTS,
    {
      user: {
        interests: medicalSpecialties.map((spe: SpecialtyModel) => {
          return spe.uid;
        }).join(","),
      }
    }
  );
}
