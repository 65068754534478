// All iOS TypeScript Functions here...

import { SoundEffect } from "../interfaces";

declare const window: Window & typeof globalThis & { webkit: any };
const servicesHandler = window.webkit?.messageHandlers.services;

export const iosSetBackgroundColor = (color: string): void => {
  const request = {
    method: "setBackgroundColor",
    color: color,
  };
  servicesHandler.postMessage(request);
};

export let isNativeIOS = window.webkit != null && servicesHandler != null;

export const iosRequestPushAuthorization = (): void => {
  const request = {
    method: "requestPushAuthorization",
    callback: "iosRequestPushAuthorizationCallback",
  };
  if (isNativeIOS) servicesHandler.postMessage(request);
};

export function iosRequestPushAuthorizationCallback(granted: boolean): void {
  localStorage.setItem("ios_granted", granted.toString());
}

export const iosGetFirebaseToken = (): void => {
  const request = {
    method: "getFirebaseToken",
    callback: "iosGetFirebaseTokenCallback",
  };
  if (isNativeIOS) servicesHandler.postMessage(request);
};

export const iosDeleteCookies = (): void => {
  const request = {
    method: "deleteCookies",
  };
  if (isNativeIOS) servicesHandler.postMessage(request);
};

export const iosOpenNewTab = (url: string): void => {
  const request = {
    method: "open",
    url,
  };
  if (isNativeIOS) servicesHandler.postMessage(request);
};

export const iosAppleSignIn = async (): Promise<void> => {
  const request = {
    method: "startSignInFlow",
    authProvider: "apple",
    callback: "iosSignInCallback",
  };
  if (isNativeIOS) await servicesHandler.postMessage(request);
};

export const iosGoogleSignIn = async (): Promise<void> => {
  const request = {
    method: "startSignInFlow",
    authProvider: "google",
    callback: "iosSignInCallback",
  };
  if (isNativeIOS) await servicesHandler.postMessage(request);
};

export const iosAuthSignOut = async (): Promise<void> => {
  const request = {
    method: "signOut",
  };
  if (isNativeIOS) await servicesHandler.postMessage(request);
};

export const iosGetSafeArea = async (): Promise<void> => {
  const request = {
    method: "getSafeArea",
    callback: "testGetSafeAreaCallback",
  };

  if (isNativeIOS) servicesHandler.postMessage(request);
};

export const iosDismissKeyboard = async (): Promise<void> => {
  const request = {
    method: "dismissKeyboard",
  };
  if (isNativeIOS) await servicesHandler.postMessage(request);
};

export const iosRequestReview = async (): Promise<void> => {
  let request = {
    method: "requestReview",
  };

  if (isNativeIOS) await servicesHandler.postMessage(request);
};

export const iosSetAppUrl = async (url: string): Promise<void> => {
  const request = {
    method: "setAppUrl",
    url,
  };
  if (isNativeIOS) await servicesHandler.postMessage(request);
};

export const iosGetAppUrl = async (): Promise<void> => {
  const request = {
    method: "getAppUrl",
    callback: "testGetAppUrlCallback",
  };
  servicesHandler.postMessage(request);
};

export const iosResetAppUrl = async (): Promise<void> => {
  const request = {
    method: "resetAppUrl",
  };
  servicesHandler.postMessage(request);
};

export const iosGenerateHaptic = (name = "selection"): void => {
  const request = {
    method: "generateHapticFeedback",
    name, // selection, success, error, blender
  };
  if (isNativeIOS && localStorage.getItem("disableHaptic") !== "true")
    servicesHandler.postMessage(request);
};

export const iosStopHaptic = (): void => {
  const request = {
    method: "stopHapticFeedback",
  };
  if (isNativeIOS) servicesHandler.postMessage(request);
};

export const iosPlaySound = (name = SoundEffect.LIKE): void => {
  const request = {
    method: "playSoundEffect",
    name,
  };
  if (isNativeIOS && localStorage.getItem("disableSound") !== "true")
    servicesHandler.postMessage(request);
};
