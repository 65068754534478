import styled from "styled-components";

export const OnboardingHero = styled.div`
  padding: 16px 21px 32px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const OnboardingHeading = styled.div`
  margin: 0 55px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  h1 {
    margin: 0;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 28.8px;
    letter-spacing: -0.025em;
    text-align: center;
    color: #CE0868;
  }

  .subtitle {
    display: block;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 17.6px;
    letter-spacing: 0.02em;
    text-align: center;
    color: #212121;
  }
`;

export const OnboardingSelectionWrapper = styled.div`
  padding: 32px 22px;
  background: #FFFFFF;

  &.popular {
    background: #FFF9E6;
  }

  h2 {
    margin: 0 0 16px;
    font-family: Roboto;
    font-size: 21px;
    font-weight: 700;
    line-height: 21px;
    text-align: left;
    color: #CE0868;
  }

  .help, .no-results {
    display: block;
    margin-bottom: 16px;
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.02em;
    text-align: left;
    color: #000000;
  }

  .no-results {
    margin: 30px 0;
  }
`;

export const OnboardingSelectionRow = styled.div`
  margin-bottom: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #212121;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:last-child {
    border-bottom: none;
    margin-bottom: 120px;
  }

  span, button {
    font-family: Inter;
    font-size: 16px;
    font-weight: 700;
    line-height: 17.6px;
    text-align: left;
    color: #212121;
  }

  button {
    padding: 12px 16px;
    border-radius: 100px;
    border: 1px solid #D2DCE2;
    background: #FFFFFF;

    &.selected {
      background: #FFF3CE;
      border-color: #FF8800;
      color: #FF8800;
    }
  }
`;

export const OnboardingBottomPanel = styled.div`
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 32px 30px 64px;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px -4px 6px 0px #2121211A;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: #F9F9F9;
  transform: translateY(0);
  transition: transform ease-out 0.3s;

  &.hide {
    transform: translateY(160px);
  }
`;

export const OnboardingSuccessWrapper = styled.div`
  min-height: 100dvh;
  padding: 120px 0 77px;
  background: #FFFFFF;

  h1 {
    font-family: Inter;
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
  }
  
  .lottie-animation {
    height: 400px;
    margin: -150px auto 24px;
  }

  h2 {
    margin: 30px 40px 9px;
    font-family: Inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: center;
    color: #212121;

    .highlight {
      color: #FF8800;
    }
  }

  p {
    margin: 0 22px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #212121;

    b {
      font-weight: 700;
    }
  }
`;
