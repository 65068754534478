import React, { Component } from "react";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import slugify from "slugify";

class CustomRadio extends Component {
  render() {
    return (
      <div className='--custom-checkbox'>
        <FormControl>
          <RadioGroup>
            <FormControlLabel
              checked={this.props.checked}
              className={this.props.className}
              value={slugify(this.props.title)}
              control={
                <Radio
                  onClick={this.props.onChange}
                  sx={{
                    // color: "#D2DCE2",
                    "&.Mui-checked": {
                      color: this.props.selectColor ?? "#CE0868",
                    },
                    "& .MuiSvgIcon-root": {
                      background: "#fff",
                      borderRadius: 1000,
                      border: "1px solid #D2DCE2",
                    },
                    "& .MuiSvgIcon-root path": {
                      stroke: this.props.selectColor ?? "#CE0868",
                      strokeWidth: 8,
                    },
                    "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) path":
                      {
                        stroke: "#fff",
                        strokeWidth: 3,
                      },
                  }}
                />
              }
              label={
                <p style={{ ...this.props.textStyle }}> {this.props.title}</p>
              }
            />
          </RadioGroup>
        </FormControl>
      </div>
    );
  }
}

export default CustomRadio;
