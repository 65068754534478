import styled from "styled-components";
import { UserModel } from "../../domains/user/user.types";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import ProfilePicture from "../ProfilePicture";
import UserConnectButton from "./UserConnectButton";
import { t } from "i18next";

export default function UserButton({
  user,
  onClick,
}: {
  user: UserModel;
  onClick: Function;
}) {
  const { currentUser } = useCurrentUser();

  return (!!user?.firstname && !!user?.lastname) ? (
      <>
        <RowWrapper style={{ padding: "20px 0" }}>
          <div className='user-row'>
            <div className='user-infos' onClick={() => onClick()}>
              <ProfilePicture
                user={user}
                height={30}
              />
              <div>
                <p className='fullname'>
                  {user?.firstname + " " + user?.lastname}
                </p>
                <span>
                  {!!user.profession?.translations
                    ? getItemTranslation(user.profession)
                    : t("common:other")}
                </span>
              </div>
            </div>
            {user.uid !== currentUser.uid && <UserConnectButton user={user} />}
          </div>
        </RowWrapper>
        <hr />
      </>
    ) : null;
}

const RowWrapper = styled.div`
  background: transparent;
  margin: 0;
  padding: 0px;
  border: none;

  .user-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-infos {
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  p {
    margin: 0;
    margin-left: 10px;
    color: #212121;
    font-weight: 500;
    font-family: "Inter";
    font-size: 14px;
    line-height: 21px;

    &.fullname {
      margin-left: 0px;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }

  span {
    margin: 0;
    color: #212121;
    font-weight: 300;
    font-size: 10px;
    line-height: 15px;
    font-family: "Inter";
    text-transform: uppercase;
  }
`;