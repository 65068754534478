// Need to use the React-specific entry point to import createApi
import { createApi } from "@reduxjs/toolkit/query/react";
import { PlaylistModel } from "../../domains/playlist/playlist.types";
import { UserModel } from "../../domains/user/user.types";
import { baseQuery } from "../base-query";
import { IContent } from "../../interfaces/content.interface";

export const userApi = createApi({
  reducerPath: "userApi",
  refetchOnMountOrArgChange: true,
  baseQuery,
  endpoints: (builder) => ({
    // NOTE: Deprecated, see getCurrentUser.
    getUser: builder.query<UserModel, any>({
      query: (params?) => {
        return "/user/me";
      },
    }),

    getPlaylists: builder.query<
      {
        docs: PlaylistModel[];
        meta: {
          total: number;
          limit: number;
          offset: number;
        };
      },
      any
    >({
      query: (params?) => {
        return { url: "/playlist/me", method: "GET", params };
      },
    }),

    patchUserContent: builder.query<IContent[], any>({
      query: (body) => ({
        url: "/user/me/content",
        params: { ...body },
        method: "PATCH",
      }),
    }),
  }),
});

export const {
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetPlaylistsQuery,
  useLazyGetPlaylistsQuery,
} = userApi;
