import { SupportedLanguage } from "../../../interfaces";
import { VideoFormat } from "../../video/video.types";
import { forYouApi } from "../forYou.api";

interface QueryParams {
  limit: number;
  offset: number;
  language: SupportedLanguage;
}

const api = forYouApi.injectEndpoints({
  endpoints: (build) => ({
    getForYouContent: build.query<any, QueryParams>({
      query: (params: QueryParams) => {
        return {
          method: "POST",
          url: "/recommendations/forYouMultiple",
          body: {
            n: params.limit,
            offset: params.offset,
            language: params.language,
          },
        };
      },
      transformResponse: (docs: any[]) => {
        // NOTE: This fixes video articles appearing from the recommander
        return docs.filter((doc: any) => {
          if (doc?.videoFormat && doc?.videoFormat !== VideoFormat.STORY) {
            return false;
          }
          return true;
        });
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getForYouContent,
} = api.endpoints;

export const {
  useLazyGetForYouContentQuery,
} = api;
