import { getApiRequestService } from "../../../services/index.services";

export async function getUserConversations() {
  try {
    const apiInstance = await getApiRequestService();
    const res: { data: any } = await apiInstance.get("/messaging");
    return res.data;
  } catch (error) {
    throw error;
  }
};
