import { getApiRequestService } from "../../../services/index.services";

export async function unregisterFirebaseToken(registrationId: string) {
  try {
    const data = { registrationId };
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(
      `/notification/registration/delete`,
      data
    );
    return res.data;
  } catch (error) {
    throw error;
  }
}
