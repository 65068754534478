import { getApiRequestService } from "../../../services/index.services";

export async function deleteUserAnnouncement(organisationId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("/user/announcement", { data: { organisationId } });
    return res.data;
  } catch (error) {
    throw error;
  }
};
