import { OrganisationModel } from "../../domains/organisation/organisation.types";
import { AnalyticsItemCategory, AnalyticsItemVariant } from "./analytics.types";
import { gaPushEvent } from "./gaPushEvent";

enum Event {
  ORGANISATION_SHARE = "organisation_share",
  ORGANISATION_REDIRECT = "organisation_redirect",
}

interface OrganisationItemPayload {
  organisation: OrganisationModel;
  listName: string;
}

function convertOrganisationToItemData(payload: OrganisationItemPayload) {
  const { organisation, listName } = payload;
  return {
    item_id: organisation._id,
    item_name: organisation.name,
    item_brand: organisation.name, // NOTE: Organisations are a brand themselves.
    item_category: "organisation",
    item_category2: AnalyticsItemCategory.STANDARD,
    item_variant: AnalyticsItemVariant.ORGANISATION,
    item_list_name: listName,
  };
}

export function gaEventOrganisationShare(payload: OrganisationItemPayload) {
  gaPushEvent(
    Event.ORGANISATION_SHARE,
    {
      ecommerce: {
        items: [convertOrganisationToItemData(payload)],
      },
    },
  );
}

export function gaEventOrganisationRedirect(payload: OrganisationItemPayload) {
  gaPushEvent(
    Event.ORGANISATION_REDIRECT,
    {
      ecommerce: {
        items: [convertOrganisationToItemData(payload)],
      },
    },
  );
}
