import { CSSProperties } from "react";
import styled from "styled-components";

export default function StepBars({ maxSteps, currentStep, style }: {
  maxSteps: number;
  currentStep: number;
  style?: {
    container?: CSSProperties;
    bar?: CSSProperties;
  };
}) {
  return (
    <Container className="step-bars-container" style={style?.container}>
      {[...Array(maxSteps)].map((_el, index) => {
        return (
          <Bar
            key={index}
            className={`bar 
               ${index < currentStep - 1
                ? "filled"
                : index === currentStep - 1
                  ? "filled half"
                  : ""}
            `}
            style={style?.bar}
          />
        );
      })}
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  gap: 6px;
`;

const Bar = styled.div`
  position: relative;
  flex: 1;
  height: 8px;
  border-radius: 6px;
  overflow: hidden;
  background: #FFFFFF;
  transition: background 0.3s ease-in-out;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    background: #FFC408;
    transition: width 0.05s ease-in;
  }

  &.filled {
    &::before {
      width: 100%;
    }
  }

  &.half {
    &::before {
      width: 50%;
    }
  }
`;
