import { ReactNode } from "react";
import styled from "styled-components";
import { Drawer } from "@mui/material";

export function LeftDrawer({
  children,
  isOpen,
}: {
  children: ReactNode;
  isOpen: boolean;
}) {
  return (
    <StyledDrawer anchor="left" open={isOpen}>
      {children}
    </StyledDrawer>
  );
}

const StyledDrawer = styled(Drawer)`
  box-sizing: border-box;

  .MuiDrawer-paper {
    width: 100%;
    min-height: 100dvh;
    box-sizing: border-box;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
`;

export const FilterPanelContent = styled.div`
  box-sizing: border-box;
  position: relative;
  padding: 11px 21px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .info {
    margin: 16px 0;
    font-family: Inter;
    font-size: 16px;
    color: #212121;
  }

  .searchbar {
    margin: 16px 0;
  }

  .heading {
    display: block;
    margin: 16px 0;
    font-family: Inter;
    font-size: 21px;
    font-weight: 700;
    color: #ce0868;
  }
`;

export const FilterPanelHeader = styled.div`
  box-sizing: border-box;
  padding: calc(11px + var(--safe-area-top)) 16px;
  display: flex;
  background-color: white;

  .close-button {
    margin-left: auto;
  }
`;

export const FilterPanelFooter = styled.div`
  box-sizing: border-box;
  padding: 21px 22px calc(21px + var(--safe-area-bottom));
  background-color: #fff;
  transform: translateY(0px);
  transition: transform 0.3s ease-out;
`;

export const FilterPanelActionBox = styled.div`
  display: flex;
  justify-content: space-between;

  .clear-all {
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 700;
    color: #D2DCE2;

    .--custom-icon svg rect {
      fill: #D2DCE2;
    }

    &.active {
      color: #FF4300;

      .--custom-icon svg rect {
        fill: #FF4300;
      }
    }
  }
`;

export const SelectableItem = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;

  span {
    line-height: 130%;
    font-family: Inter;
    font-weight: 700;
    font-size: 15px;
    letter-spacing: 0.02em;
    color: #212121;
  }

  button {
    outline: none;
    padding: 12px 16px;
    border: 1px solid #d2dce2;
    border-radius: 100px;
    background-color: #ffffff;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    letter-spacing: 0.02em;
    color: #212121;
    transition: 0.3s ease-out;
  }

  &.selected {
    span {
      color: #ff8800;
    }

    button {
      background-color: #fff3ce;
      color: #ff8800;
      border-color: #ff8800;
    }
  }
`;