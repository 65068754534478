import styled from "styled-components";

export const MessagePageHeader = styled.div`
  padding: 20px 21px;
  background: #ecf0f5;
  color: #212121;
  font-family: "Inter";

  /* h3 {
    line-height: 17px;
    font-size: 17px;
    font-weight: 300;
    margin: 10px 0;
  }

  h3 b {
    font-weight: 700;
  }

  h4 {
    line-height: 15px;
    font-size: 11px;
    font-weight: 300;
    margin: 0;
    margin-right: 20px;
  }

  h4 b {
    font-weight: 600;
    font-size: 14px;
  }

  h5 {
    margin: 0;
    text-transform: capitalize;
    font-size: 11px;
    line-height: 15.4px;
    font-weight: 300;
  }

  h5 span {
    text-transform: uppercase;
  }

  p {
    font-size: 14px;
    line-height: 22.4px;
    font-weight: 400;
    margin: 0;
  } */
`;



export const MessagePageContent = styled.div`
  padding: 30px 20px;
  background: #f7f8fc;
  color: #212121;

  /* h3 {
    font-size: 21px;
    line-height: 25.2px;
    font-weight: 900;
    margin: 0;
    font-family: "Work Sans" !important;
    text-transform: capitalize;
  }

  h4 {
    font-size: 16px;
    line-height: 20.8px;
    font-weight: 400;
    margin-top: 5px;
  }

  h4 b {
    font-weight: 600;
  } */
`;

export const MessageRow = styled.div`
  padding: 20px 22px;
  display: flex;
  align-items: center;
  gap: 16px;

  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 6px;

    .username {
      color: var(--BRAND-CORE-Juisci-Black, #212121);
      font-family: Roboto;
      font-size: 14px;
      font-weight: 600;
    }

    .date {
      color: var(--SECONDARY-GREY-SHADES-Bluegrey-4, #90A4AE);
      font-family: Roboto;
      font-size: 11px;
      font-weight: 300;
    }

    .subline {
      color: var(--SECONDARY-GREY-SHADES-Bluegrey-6, #4C5861);
      font-family: Roboto;
      font-size: 13px;
      font-weight: 300;
      line-height: 130%; /* 16.9px */
    }
  }

  .actions {
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 12px;
  }
`;