import { SearchFilterPublicationDate, SearchFiltersModel } from "../search.types";

function dateToIso(d: Date) {
  return d.toISOString().split("T")[0];
}

export function getDateParamsFromSearchFilters(filters: SearchFiltersModel) {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setUTCDate(tomorrow.getUTCDate() + 1);
  const oneYearAgo = new Date(tomorrow);
  const twoYearsAgo = new Date(tomorrow);
  const threeYearsAgo = new Date(tomorrow);

  oneYearAgo.setFullYear(today.getFullYear() - 1);
  twoYearsAgo.setFullYear(today.getFullYear() - 2);
  threeYearsAgo.setFullYear(today.getFullYear() - 3);

  let afterDate, beforeDate;

  // NOTE: Set next day as limit to avoid content published in the future.
  beforeDate = dateToIso(tomorrow);

  if (filters.publicationDate === SearchFilterPublicationDate.LESS_THAN_1_YEAR) {
    beforeDate = dateToIso(today);
    afterDate = dateToIso(oneYearAgo);
  } else if (filters.publicationDate === SearchFilterPublicationDate.LESS_THAN_2_YEARS) {
    beforeDate = dateToIso(today);
    afterDate = dateToIso(twoYearsAgo);
  } else if (filters.publicationDate === SearchFilterPublicationDate.LESS_THAN_3_YEARS) {
    beforeDate = dateToIso(today);
    afterDate = dateToIso(threeYearsAgo);
  } else if (filters.publicationDate === SearchFilterPublicationDate.MORE_THAN_3_YEARS) {
    beforeDate = dateToIso(threeYearsAgo);
  }

  return { afterDate, beforeDate };
}