import { useEffect, useState, SyntheticEvent } from "react";
import { Modal } from "antd-mobile";
import styled from "styled-components";
import disableScroll from "disable-scroll";
import Hand from "../../assets/images/hand.svg";
import { t } from "i18next";
import { CustomButton } from "../../components/global";

const disclaimerTakedaContent = {
  en: "Attention, the data presented may not be validated by French health authorities and therefore should not be implemented (except under marketing authorization).\n\nThis content is the sole responsibility of JUISCI and its Medical Team, who are responsible for its objectivity.",
  fr: "Attention, les données présentées sont susceptibles de ne pas être validées par les autorités de santé françaises et ne doivent donc pas être mises en pratique (hors AMM).\n\nCe contenu est sous la seule responsabilité de JUISCI et de son Équipe Médicale qui sont garants de son objectivité.",
};

const topTextTakeda = {
  fr: "Réalisé avec le soutien institutionnel de Takeda France.",
  en: "Developed with the institutional support of Takeda France.",
};

const getLanguageFromLocalStorage = (roomLang: string): string => {
  const roomLangs = JSON.parse(localStorage.getItem("roomLangs") || "{}");
  return roomLangs[roomLang] || "en";
};

function closest(el: any, selector: any) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

const TakedaDisclaimerModal = ({
  onPress,
  visible,
  roomLang,
}: {
  onPress: () => void;
  visible: boolean;
  roomLang: string;
}) => {
  const [language, setLanguage] = useState<keyof typeof topTextTakeda>("en");

  useEffect(() => {
    const lang = getLanguageFromLocalStorage(roomLang);
    setLanguage(lang as keyof typeof topTextTakeda);

    if (visible) disableScroll.on();
    return () => disableScroll.off();
  }, [visible]);

  const onWrapTouchStart = (e: SyntheticEvent) => {
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) return;
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) e.preventDefault();
  };

  return (
    <StyledModal>
      {visible && <div className="top-text">{topTextTakeda[language]}</div>}
      <Modal
        className="scrollbar-hidden"
        visible={visible}
        transparent
        maskClosable={true}
        onClose={() => onPress()}
        title={
          <StyledHeader>
            <h3>
              {language === "fr"
                ? "Clause de non-responsabilité"
                : "Disclaimer"}
            </h3>
            <img className="disclaimer-hand-image" src={Hand} alt="" />
          </StyledHeader>
        }
        footer={[
          {
            text: (
              <CustomButton
                className="white small no-border"
                style={{ width: "fit-content" }}
              >
                {t("common:action.accept")}
              </CustomButton>
            ) as any,
            onPress: onPress,
          },
        ]}
        wrapProps={{ onTouchStart: onWrapTouchStart }}
      >
        <StyledContent className="scrollbar-hidden">
          {disclaimerTakedaContent[language]}
        </StyledContent>
      </Modal>
    </StyledModal>
  );
};

export default TakedaDisclaimerModal;

const StyledModal = styled.div`
  .top-text {
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: center;
    z-index: 9999;
    text-align: center;
    position: absolute;
    margin: 0 24px;
    top: calc(10dvh);
  }
`;

const StyledHeader = styled.div`
  h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    white-space: pre-line;
  }
`;

const StyledContent = styled.div`
  max-height: 60dvh;
  overflow: scroll;
  width: 100%;
  color: #212121;
  padding: 16px 0;
  white-space: pre-line;
`;
