import React, { Component } from "react";
import { Box, CircularProgress } from "@mui/material";

class CircleProgress extends Component {
  render() {
    return (
      <Box sx={{ position: "relative", display: "inline-flex" }}>
        <CircularProgress
          thickness={4}
          size={44}
          sx={{
            color: "#000",
          }}
          variant='determinate'
          value={this.props.value}
        />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {this.props.children}
        </Box>
      </Box>
    );
  }
}

export default CircleProgress;
