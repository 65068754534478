import { createSlice } from "@reduxjs/toolkit";
import { VideoModel } from "./video.types";
import { getVideoArticlesList } from "./endpoints/getVideoArticlesList";

interface ReducerState {
  cache: {
    videoArticles: VideoModel[];
    videoStories: VideoModel[];
  };
}

const initialState: ReducerState = {
  cache: {
    videoArticles: [],
    videoStories: [],
  },
};

export const slice = createSlice({
  name: "videos",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      getVideoArticlesList.matchFulfilled,
      (state, { payload }) => {
        state.cache.videoArticles = payload.docs;
      },
    );
  },
});

export default slice.reducer;
