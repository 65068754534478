import { CSSProperties, SyntheticEvent, useState } from "react";
import { UserModel } from "../../domains/user/user.types";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useUserConnection } from "../../domains/user/hooks/useUserConnection";
import ConfirmModal from "./ConfirmModal";
import { CustomButton } from "../global";
import styled from "styled-components";
import { t } from "i18next";

export default function UserConnectButton({
  user,
  style = {},
}: {
  user: UserModel;
  style?: CSSProperties;
}) {
  const { currentUser } = useCurrentUser();
  const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
  const {
    isConnected,
    isPendingSent,
    isPendingReceived,
    sendRequest,
    cancelRequest,
    unfollow,
  } = useUserConnection(user);

  function handleClick() {
    if (isPendingReceived) return;

    if (isConnected) {
      setConfirmModalVisible(true);
      return;
    }

    if (isPendingSent) {
      setConfirmModalVisible(true);
      return;
    }

    sendRequest();
  }

  function handleConfirm() {
    setConfirmModalVisible(false);

    if (isConnected) {
      unfollow();
      return;
    }

    if (isPendingSent) {
      cancelRequest();
      return;
    }
  }

  if (user._id === currentUser._id) return null;

  return (
    <>
      <ConnectButton
        style={{
          background:
            (isConnected || isPendingSent || isPendingReceived) && "#fff3ce",
          color:
            (isConnected || isPendingSent || isPendingReceived) && "#ffc408",
          ...style,
        }}
        onClick={(e: SyntheticEvent) => {
          e.preventDefault();
          handleClick();
        }}
      >
        {isConnected
          ? t("common:action.unfollow")
          : isPendingSent
          ? t("common:action.cancel")
          : isPendingReceived
          ? t("common:action.disconnect")
          : t("common:action.connect")}
      </ConnectButton>

      <ConfirmModal
        isVisible={isConfirmModalVisible}
        promptText={
          isConnected
            ? t("profile:userConnect.confirmUnfollow")
            : t("profile:userConnect.confirmCancelRequest")
        }
        onConfirm={handleConfirm}
        confirmDanger={true}
        onCancel={() => setConfirmModalVisible(false)}
      />
    </>
  );
}

const ConnectButton = styled(CustomButton)`
  width: fit-content;
  padding: 5px 16px;
  font-size: 15px;
  white-space: nowrap;
`;
