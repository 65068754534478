import { auth } from "../../../services/firebase";
import { getApiRequestService } from "../../../services/index.services";
import { UserModel } from "../user.types";

// TODO: move and rename
export async function getCurrentUser() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get<UserModel>(`/user/me`);
    return res.data;
  } catch (error: any) {
    console.error("Couldn't get current user data.", error);
    auth.signOut();
    throw error;
  }
}
