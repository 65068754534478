import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { ArticleModel } from "../../domains/article/article.types";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { getNectarBySlug } from "../../domains/nectar/endpoints/getNectarBySlug";
import { useShareNectar } from "../../domains/user/hooks/useShareNectar";
import { useSaveNectar } from "../../domains/user/hooks/useSaveNectar";
import { useLikeNectar } from "../../domains/user/hooks/useLikeNectar";
import { useCountdown } from "../../domains/app/hooks/useCountdown";
import disableScroll from "disable-scroll";
import { t } from "i18next";
import styled from "styled-components";
import Loader from "../../components/Loader";
import StepBars from "../../components/app/StepBars";
import {
  ScrollOverflowIndicatorWrapper,
  StoryActionsContainer,
  StorySlideContent,
  StorySlideScrollContainer,
  StorySlideTitle,
  StorySlideWrapper,
  StoryStepBarsWrapper,
  StoryTimerWrapper,
} from "../../components/forYou/layout";
import ContentMarkdown from "../../components/ContentMarkdown";
import ArticleCard from "../../components/cards/ArticleCard";
import OptionsPanel from "../../components/app/OptionsPanel";
import PlaylistPanel from "../../components/app/PlaylistPanel";
import CircleProgress from "../../components/CircleProgress";
import { ReactComponent as CloseRedIcon } from "../../assets/icons/cross-circle-red.svg";
import { ReactComponent as HeartBlackIcon } from "../../assets/icons/heart-circle-black.svg";
import { ReactComponent as HeartRedIcon } from "../../assets/icons/heart-circle-red.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/dots-circle-red.svg";
import { ReactComponent as ArrowDownCircleRedIcon } from "../../assets/icons/arrow-circle-red.svg";
import { ReactComponent as NectarPastebinIcon } from "../../assets/icons/nectar/pastebin.svg";
import { ReactComponent as NectarPaperscrollIcon } from "../../assets/icons/nectar/paperscroll.svg";

enum SlideCategory {
  ANSWER = "answer",
  SOURCES = "sources",
}

export default function ForYouNectarStoryPage() {
  const history = useHistory();
  const location = useLocation<{ content: any }>();
  const params = useParams<{ slug: string, roomId?: string }>();
  const rootPathname = params.roomId ? `/room/foryou/${params.roomId}` : "/foryou";
  const [nectar, setNectar] = useState<NectarModel | null>(location.state?.content ?? null);
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);
  const [isPlaylistsPanelVisible, setPlaylistsPanelVisible] = useState(false);
  const [slideCategories, setSlideCategories] = useState<string[]>([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isOverflowing, setOverflowing] = useState(false);
  const displayedCategory = slideCategories[slideIndex];
  const { isLiked, toggleLike } = useLikeNectar(nectar, AnalyticsItemList.FORYOU);
  const { isSaved, toggleSaveToPlaylists } = useSaveNectar(nectar, AnalyticsItemList.FORYOU);
  const shareNectar = useShareNectar(nectar, AnalyticsItemList.FORYOU);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const countdown = useCountdown(8, exitBack);

  useEffect(() => {
    (async function () {
      try {
        if (!location.state) {
          return history.replace(rootPathname);
        }

        disableScroll.off();

        // TODO: Check if proper way to do this.
        window.onbeforeunload = () => {
          localStorage.removeItem("foryou_carousel_index");
        };

        const { isRedirected } = await preflightUser({
          history,
          onboardingMessage: t("error:notOnboarded.likeArticle"),
        });
        if (isRedirected) return;

        if (!nectar) {
          if (!params.slug) throw new Error("No nectar slug.");;
          setNectar(await getNectarBySlug(params.slug));
        }

        setupSlides();
      } catch (error) {
        console.error("Couldn't load nectar.", error);
        history.replace(rootPathname);
      }
    })();
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const slideIndex = parseInt(searchParams.get("slide") ?? "0") ?? 0;
    setSlideIndex(slideIndex);
  }, [location.search]);

  useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      scrollContainer.scrollTo(0, 0);
      if (scrollContainer?.scrollHeight > scrollContainer?.clientHeight) {
        setOverflowing(true);
      } else {
        setOverflowing(false);
      }
    }

    // Start timer to move to the next content
    if (slideIndex === slideCategories.length - 1) {
      if (!countdown.isRunning) countdown.start();
    } else {
      countdown.reset();
    }
  }, [slideIndex]);

  useEffect(() => {
    if (isOptionsPanelVisible || isPlaylistsPanelVisible) {
      countdown.reset();
    }
  }, [isOptionsPanelVisible, isPlaylistsPanelVisible]);

  function setupSlides() {
    const categories = [SlideCategory.ANSWER];
    if (!!nectar?.associatedArticles?.length) categories.push(SlideCategory.SOURCES);
    setSlideCategories(categories);
  }

  function exitBack() {
    const forYouNectarIndex = localStorage.getItem("nectarStoryIndex");
    const search = forYouNectarIndex !== null ? `?index=${parseInt(forYouNectarIndex)}` : "";
    history.replace(`${rootPathname}${search}`);
  }

  function handleClickSlide(e: MouseEvent | any) {
    if (e.pageX < window.innerWidth * 0.35 && slideIndex > 0) {
      history.replace({
        pathname: location.pathname,
        search: `slide=${slideIndex - 1}`,
        state: { content: nectar },
      });
    }

    if (e.pageX > window.innerWidth * 0.65 && slideIndex < slideCategories.length - 1) {
      history.replace({
        pathname: location.pathname,
        search: `slide=${slideIndex + 1}`,
        state: { content: nectar },
      });
    }
  }

  function handleSlideScroll(e: ChangeEvent) {
    const scrollThreshold = e.target.scrollHeight - e.target.clientHeight;
    setOverflowing(e.target.scrollTop < scrollThreshold);
  }

  if (!nectar) return <Loader />;

  return (
    <>
      <NectarStorySlideWrapper onClick={handleClickSlide}>
        <StoryStepBarsWrapper>
          <StepBars
            currentStep={slideIndex + 1}
            maxSteps={slideCategories.length}
          />
        </StoryStepBarsWrapper>

        <StoryActionsContainer>
          <CloseRedIcon onClick={(e) => {
            e.stopPropagation();
            exitBack();
          }} />

          <div style={{ display: "flex", gap: 16 }}>
            <div onClick={(e) => {
              e.stopPropagation();
              toggleLike();
            }}>
              {isLiked ? <HeartRedIcon /> : <HeartBlackIcon />}
            </div>

            <DotsIcon onClick={(e) => {
              e.stopPropagation();
              setOptionsPanelVisible(true);
            }} />
          </div>
        </StoryActionsContainer>

        <StorySlideTitle>
          {{
            answer: <NectarPastebinIcon />,
            sources: <NectarPaperscrollIcon />,
          }[displayedCategory] ?? null}

          {{
            answer: t("common:answer"),
            sources: t("commmon:sources"),
          }[displayedCategory] ?? ""}
        </StorySlideTitle>

        <StorySlideScrollContainer
          ref={scrollContainerRef}
          className="scrollbar-hidden"
          onScroll={(e: any) => handleSlideScroll(e)}
        >
          <StorySlideContent>
            {displayedCategory === SlideCategory.ANSWER && (
              <ContentMarkdown>{nectar?.answer}</ContentMarkdown>
            )}

            {displayedCategory === SlideCategory.SOURCES && (
              <>
                {nectar?.associatedArticles?.map((article: ArticleModel) => {
                  return (
                    <ArticleCard
                      key={article?._id}
                      article={article}
                      analyticsListName={AnalyticsItemList.FORYOU}
                      onClick={() => {
                        localStorage.setItem("nectarStoryIndex", slideIndex.toString());
                      }}
                    />
                  );
                })}
              </>
            )}
          </StorySlideContent>
        </StorySlideScrollContainer>

        <ScrollOverflowIndicatorWrapper className={!isOverflowing ? "hide" : ""}>
          <ArrowDownCircleRedIcon />
        </ScrollOverflowIndicatorWrapper>

        <StoryTimerWrapper className={(countdown.isRunning && countdown.timeLeftSec <= 5) ? "visible" : ""}>
          <CircleProgress value={countdown.timeLeftSec * 20}>
            <span>{countdown.isRunning ? countdown.timeLeftSec.toFixed(0) : ""}</span>
          </CircleProgress>
        </StoryTimerWrapper>
      </NectarStorySlideWrapper>

      <OptionsPanel
        isOpen={isOptionsPanelVisible}
        onClose={() => setOptionsPanelVisible(false)}
        isContentSaved={isSaved}
        isContentLiked={isLiked}
        onSaveContent={() => setPlaylistsPanelVisible(true)}
        onLikeContent={toggleLike}
        onShareContent={shareNectar}
      />

      <PlaylistPanel
        isVisible={isPlaylistsPanelVisible}
        contentToAdd={nectar}
        onClose={() => setPlaylistsPanelVisible(false)}
        onSave={async ({ add, remove }: { add: string[]; remove: string[] }) => {
          await toggleSaveToPlaylists({ add, remove });
        }}
      />
    </>
  );
}

const NectarStorySlideWrapper = styled(StorySlideWrapper)`
  background-image: linear-gradient(
      180deg,
      #EEF822F9,
      #FFFFFFF2 60%
    );
`;
