import { SupportedLanguage } from "../../../interfaces";
import { getApiRequestService } from "../../../services/index.services";

export async function getCongressList(language?: SupportedLanguage) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/congress", {
      params: { language },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
