import { getApiRequestService } from "../../../services/index.services";

export async function readNotification(notificationId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.patch(`/notification/${notificationId}/read`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
