import { ReactChildren, ReactNode } from "react";
import { t } from "i18next";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "./layout";

export default function MessageHeader({ title, onTitleClick, customActions }: {
  title?: string;
  onTitleClick?: Function;
  customActions?: ReactNode | ReactChildren;
}) {
  return (
    <HeaderBoxAutoSafe>
      <HeaderLinks>
        <NavBack />
      </HeaderLinks>
      <HeaderTitle onClick={() => onTitleClick && onTitleClick()}>
        {title ?? t("messaging:title")}
      </HeaderTitle>
      <HeaderLinks>{customActions}</HeaderLinks>
    </HeaderBoxAutoSafe>
  );
}
