import { useEffect, useState } from "react";
import { store, useAppDispatch } from "../../../redux";
import { useLangContext } from "../../app/contexts/lang.context";
import { useLazySearchArticlesQuery } from "../endpoints/searchArticles";
import { useLazySearchJournalsQuery } from "../endpoints/searchJournals";
import { useLazySearchPlaylistsQuery } from "../endpoints/searchPlaylists";
import { useLazySearchUsersQuery } from "../endpoints/searchUsers";
import { useLazySearchVideosQuery } from "../endpoints/searchVideos";
import { useLazySearchNectarsQuery } from "../endpoints/searchNectars";
import {
  memorizeSearchArticles,
  memorizeSearchJournals,
  memorizeSearchNectars,
  memorizeSearchPlaylists,
  memorizeSearchUsers,
  memorizeSearchVideos,
} from "../search.reducer";

export function useSearchAll(searchValue: string) {
  // Store
  const dispatch = useAppDispatch();
  const { activeLang } = useLangContext();
  // State
  const [articles, setArticles] = useState([]);
  const [journals, setJournals] = useState([]);
  const [playlists, setPlaylists] = useState([]);
  const [users, setUsers] = useState([]);
  const [videos, setVideos] = useState([]);
  const [nectars, setNectars] = useState([]);
  const [totalArticles, setTotalArticles] = useState(0);
  const [totalJournals, setTotalJournals] = useState(0);
  const [totalPlaylists, setTotalPlaylists] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalVideos, setTotalVideos] = useState(0);
  const [totalNectars, setTotalNectars] = useState(0);
  const totalAll = totalArticles
    + totalJournals
    + totalPlaylists
    + totalUsers
    + totalVideos
    + totalNectars;
  // Query
  const [searchArticles, searchArticlesResult] = useLazySearchArticlesQuery();
  const [searchJournals, searchJournalsResult] = useLazySearchJournalsQuery();
  const [searchPlaylists, searchPlaylistsResult] = useLazySearchPlaylistsQuery();
  const [searchUsers, searchUsersResult] = useLazySearchUsersQuery();
  const [searchVideos, searchVideosResult] = useLazySearchVideosQuery();
  const [searchNectars, searchNectarsResult] = useLazySearchNectarsQuery();
  // NOTE: Check query params limit to not display a loader during dummy queries.
  const isLoadingArticles = searchArticlesResult.isFetching && !(searchArticlesResult.originalArgs?.limit === 0);
  const isLoadingJournals = searchJournalsResult.isFetching && !(searchJournalsResult.originalArgs?.limit !== 0);
  const isLoadingPlaylists = searchPlaylistsResult.isFetching && !(searchPlaylistsResult.originalArgs?.limit !== 0);
  const isLoadingUsers = searchUsersResult.isFetching && !(searchUsersResult.originalArgs?.limit !== 0);
  const isLoadingVideos = searchVideosResult.isFetching && !(searchVideosResult.originalArgs?.limit !== 0);
  const isLoadingNectars = searchNectarsResult.isFetching && !(searchNectarsResult.originalArgs?.limit !== 0);
  const isAnyLoading = isLoadingArticles
    || isLoadingJournals
    || isLoadingPlaylists
    || isLoadingUsers
    || isLoadingVideos
    || isLoadingNectars;

  useEffect(() => {
    // NOTE: Usual selector not reliable enough, using store state instead.
    const cache = store.getState().search.cache;

    if (searchValue.length) {
      const isMounting = searchArticlesResult.isUninitialized;
      if (isMounting && Object.values(cache).some((value: any) => value.total > 0)) {
        setArticles(cache.articles.results);
        setJournals(cache.journals.results);
        setPlaylists(cache.playlists.results);
        setUsers(cache.users.results);
        setVideos(cache.videos.results);
        setNectars(cache.nectars.results);
        setTotalArticles(cache.articles.total);
        setTotalJournals(cache.journals.total);
        setTotalPlaylists(cache.playlists.total);
        setTotalUsers(cache.users.total);
        setTotalVideos(cache.videos.total);
        setTotalNectars(cache.nectars.total);
        // NOTE: dummy query execution to uninitialize query and fix mount detection.
        searchArticles({ search: "", limit: 0, offset: 0, language: activeLang });
      } else {
        searchForResults();
      }
    } else {
      setArticles([]);
      setJournals([]);
      setPlaylists([]);
      setUsers([]);
      setVideos([]);
      setNectars([]);
      setTotalArticles(0);
      setTotalJournals(0);
      setTotalPlaylists(0);
      setTotalUsers(0);
      setTotalVideos(0);
      setTotalNectars(0);
    }
  }, [activeLang, searchValue]);

  function searchForResults() {
    searchArticles({
      search: searchValue,
      limit: 5,
      offset: 0,
      language: activeLang,
    })
      .unwrap()
      .then(({ docs, meta: { total } }) => {
        setArticles(docs);
        setTotalArticles(total);
        dispatch(memorizeSearchArticles({ results: docs, total }));
      })
      .catch((error) => {
        console.error("Couldn't load article results.", error);
      });

    searchJournals({
      search: searchValue,
      limit: 5,
      offset: 0,
      language: activeLang,
    })
      .unwrap()
      .then(({ docs, meta: { total } }) => {
        setJournals(docs);
        setTotalJournals(total);
        dispatch(memorizeSearchJournals({ results: docs, total }));
      })
      .catch((error) => {
        console.error("Couldn't load journal results.", error);
      });

    searchPlaylists({
      search: searchValue,
      limit: 5,
      offset: 0,
      language: activeLang,
    })
      .unwrap()
      .then(({ docs, meta: { total } }) => {
        setPlaylists(docs);
        setTotalPlaylists(total);
        dispatch(memorizeSearchPlaylists({ results: docs, total }));
      })
      .catch((error) => {
        console.error("Couldn't load playlist results.", error);
      });

    searchUsers({
      search: searchValue,
      limit: 5,
      offset: 0,
    })
      .unwrap()
      .then(({ docs, meta: { total } }) => {
        setUsers(docs);
        setTotalUsers(total);
        dispatch(memorizeSearchUsers({ results: docs, total }));
      })
      .catch((error) => {
        console.error("Couldn't load user results.", error);
      });

    searchVideos({
      search: searchValue,
      limit: 5,
      offset: 0,
      language: activeLang,
    })
      .unwrap()
      .then(({ docs, meta: { total } }) => {
        setVideos(docs);
        setTotalVideos(total);
        dispatch(memorizeSearchVideos({ results: docs, total }));
      })
      .catch((error) => {
        console.error("Couldn't load video results.", error);
      });

    searchNectars({
      search: searchValue,
      limit: 5,
      offset: 0,
      language: activeLang,
    })
      .unwrap()
      .then(({ docs, meta: { total } }) => {
        setNectars(docs);
        setTotalNectars(total);
        dispatch(memorizeSearchNectars({ results: docs, total }));
      })
      .catch((error) => {
        console.error("Couldn't load nectar results.", error);
      });
  }

  return {
    articles,
    journals,
    playlists,
    users,
    videos,
    nectars,
    totalArticles,
    totalJournals,
    totalPlaylists,
    totalUsers,
    totalVideos,
    totalNectars,
    totalAll,
    isLoadingArticles,
    isLoadingJournals,
    isLoadingPlaylists,
    isLoadingUsers,
    isLoadingVideos,
    isLoadingNectars,
    isAnyLoading,
  };
}