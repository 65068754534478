import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ReducerState {
  cache: {
    recommendedContent: any[];
  };
}

const initialState: ReducerState = {
  cache: {
    recommendedContent: [],
  },
};

export const slice = createSlice({
  name: "forYou",
  initialState,
  reducers: {
    memorizeRecommendedContent: (state, action: PayloadAction<any[]>) => {
      state.cache.recommendedContent = action.payload;
    },
    clearRecommendedContent: (state) => {
      state.cache.recommendedContent = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  memorizeRecommendedContent,
  clearRecommendedContent,
} = slice.actions;

export default slice.reducer;
