import moment from "moment"
import { SupportedLanguage } from "../../../interfaces"
import i18n from "../../../config/i18n"

export function renderDate(date: Date, shorten?: boolean) {
  const lang = i18n.resolvedLanguage as SupportedLanguage;
  const format = getLocaleDateFormat(lang, shorten);
  return moment(date).locale(lang).format(format);
}

function getLocaleDateFormat(locale: SupportedLanguage, shorten?: boolean) {
  switch (locale) {
    case SupportedLanguage.FR:
    case SupportedLanguage.IT:
      return shorten ? "DD/MM/YYYY" : "D MMMM YYYY";
    default:
      return shorten ? "MM/DD/YYYY" : "MMMM D, YYYY";
  }
}
