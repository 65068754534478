import styled from "styled-components";

const AppButton = styled.button`
  box-sizing: border-box;
  width: 100%;
  padding: 16px 40px;
  border: none;
  /* border: 1px solid transparent; */
  border-radius: 400px;
  outline: none;
  background: var(--BRAND-CORE-Juisci-Yellow, #FFC408);
  color: var(--BRAND-CORE-Juisci-Black, #212121);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 100%; /* 14px */
  letter-spacing: -0.07px;
  cursor: pointer;
  transition: all ease-out 0.2s;

  &.xl {
    font-size: 16px;
    font-weight: 700;
  }

  &.m {
    padding: 12px 53px;
    font-size: 13px;
    letter-spacing: -0.065px;
  }

  &.s {
    padding: 11px 15px;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: -0.055px;
  }

  &.xs {
    padding: 6px 8px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.2px;
  }

  &:disabled {
    background: var(--SECONDARY-GREY-SHADES-Bluegrey-3, #D2DCE2);
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
  }

  /*

  &.black {
    color: #fff;
    background: #212121;
    border-color: #212121;
  }

  &.secondary,
  &.white {
    background-color: #fff;
    color: #212121;
  }

  &.active {
    background-color: #fff3ce;
    color: #ffc408;
    border: 1px solid #ffc408;
  }

  &.disabled,
  

  &.pink {
    background-color: #ce0868;
    color: #fff;
  }

  &.danger {
    background: #ff4300;
    color: #fff;
  }

  &.small {
    padding: 12px 24px;
  }

  &.xsmall {
    padding: 7px 12px;
    width: auto;
    font-size: 12px;
  }

  &.icon {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
  }

  &.no-border {
    border: none !important;
  } */
`;

export default AppButton;