import { getApiRequestService } from "../../../services/index.services";

export async function joinRoomByInviteCode(inviteCode: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/user/room/invite/${inviteCode}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
