import { t } from "i18next";
import { displayToast } from "../../app/AppToast";
import ConfirmModal from "../../app/ConfirmModal";
import { useEffect, useState } from "react";
import { isNativeIOS } from "../../../tools/ios";
import { isNativeAndroid } from "../../../tools/android";

export default function DownloadAppModal({ onClose }: {
  onClose?: Function;
}) {
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const seenDownloadAppModal = localStorage.getItem("seenDownloadAppModal");
    if (!seenDownloadAppModal && !isNativeIOS && !isNativeAndroid) {
      setVisible(true);
      localStorage.setItem("seenDownloadAppModal", "true");
    } else {
      handleClose();
    }
  }, []);

  function handleConfirm() {
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as unknown as { MSStream: any }).MSStream;
    const isAndroid = /Android/.test(navigator.userAgent) && !(window as unknown as { MSStream: any }).MSStream;

    if (isIOS)
      window.location.href =
        "https://apps.apple.com/fr/app/juisci/id1588725828";
    else if (isAndroid)
      window.location.href = `https://play.google.com/store/apps/details?id=com.juisci.app&hl=us&gl=US`;
    else displayToast(t("error:default"));

    handleClose();
  }

  function handleClose() {
    setVisible(false);
    onClose && onClose();
  }

  return (
    <ConfirmModal
      isVisible={isVisible}
      promptText={t("modal:downloadApp.text")}
      cancelText={t("modal:downloadApp.later")}
      confirmText={t("modal:downloadApp.download")}
      onConfirm={handleConfirm}
      onCancel={handleClose}
    />
  );
}
