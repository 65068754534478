import { Component } from "react";
import styled from "styled-components";
import Lottie from "lottie-react";
import Spinner from "../assets/animations/spinner.json";
import { Spacer } from "./global";

class Loader extends Component {
  state = {
    showNone: false,
  };

  componentDidMount() {
    if (this.props.emptyContent)
      this.timer = setInterval(() => this.setState({ showNone: true }), 1500);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    const { loaderOnly, emptyContent } = this.props;

    return loaderOnly ? (
      <center>
        <Spacer />
        <Lottie
          animationData={Spinner}
          style={{ height: "30px" }}
          loop={true}
          autoplay={true}
        />
        <Spacer />
      </center>
    ) : emptyContent ? (
      <center style={{ paddingTop: "30px" }}>
        {this.state.showNone ? (
          <p
            style={{
              color: "white",
              fontWeight: 600,
              fontSize: "18px",
            }}
          >
            Aucun article trouvé
          </p>
        ) : (
          <center>
            <Lottie
              animationData={Spinner}
              style={{ height: "30px" }}
              loop={true}
              autoplay={true}
            />
          </center>
        )}
      </center>
    ) : (
      <StyledLoader style={{ ...this.props.style }}>
        <center>
          <Lottie
            animationData={Spinner}
            style={{ height: "30px" }}
            loop={true}
            autoplay={true}
          />
        </center>
      </StyledLoader>
    );
  }
}

export default Loader;

export const SingleLoader = () => {
  return (
    <Lottie
      animationData={Spinner}
      style={{ height: "30px" }}
      loop={true}
      autoplay={true}
    />
  );
};

const StyledLoader = styled.div`
  background: #f7f8fc;
  min-height: 68vh;
  max-height: 68vh;
  width: 100%;
  top: 0;
  overflow-y: hidden;
  padding-top: 33vh;
`;
