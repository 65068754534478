import styled from "styled-components";

const CarouselIndicator = styled.div<{ active: boolean }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  background: ${props => !props.active ? "#d2dce2" : "#ffc408"};

  &.dark {
    background: ${props => !props.active ? "#90A4AE" : "#ffc408"};
  }

  &.red {
    background: ${props => !props.active ? "#F9F9F9" : "#CE0868"};
  }
`;

export default CarouselIndicator;