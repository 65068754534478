import { useAppSelector } from "../../../redux";

export function useCurrentUser() {
  const currentUser = useAppSelector((state) => state.user.user);
  const playlists = useAppSelector((state) => state.user.playlists);
  const rooms = useAppSelector((state) => state.user.rooms);

  return {
    currentUser,
    playlists,
    rooms,
  };
}
