import { DEFAULT_LANGUAGE } from "../../../config/i18n";
import { SupportedLanguage } from "../../../interfaces";
import countries from "i18n-iso-countries";

// Import countries for all supported languages
for (const language of Object.keys(SupportedLanguage)) {
  countries.registerLocale(require(`i18n-iso-countries/langs/${language.toLowerCase()}.json`));
}

export const POPULAR_COUNTRY_CODES = [
  "FR",
  "GB",
  "US",
  "DE",
  "DZ",
  "ES",
  "MA",
  "BE",
  "SN",
  "CA",
  "CI",
  "NG",
  "CH",
  "SG",
  "ZA",
  "IN",
  "PK",
  "TN",
  "NO",
  "NL",
  "AU",
];

export function getCountriesList(language: SupportedLanguage = DEFAULT_LANGUAGE) {
  return countries.getNames(language, { select: "official" });
}

export function getCountriesListSortedByPopularity(language: SupportedLanguage = DEFAULT_LANGUAGE) {
  const countriesList = getCountriesList(language);
  const countryOptions = Object.keys(countriesList)
    .map((key) => ({
      value: key,
      label: countriesList[key],
    }))
    .sort((a, b) => {
      // If a favourite, comes before b
      if (
        POPULAR_COUNTRY_CODES.includes(a.value) &&
        !POPULAR_COUNTRY_CODES.includes(b.value)
      ) {
        return -1;
      }
      // If b favourite, comes before a
      if (
        !POPULAR_COUNTRY_CODES.includes(a.value) &&
        POPULAR_COUNTRY_CODES.includes(b.value)
      ) {
        return 1;
      }
      // Sort non favorites alphabetically
      if (
        !POPULAR_COUNTRY_CODES.includes(a.value) &&
        !POPULAR_COUNTRY_CODES.includes(b.value)
      ) {
        return a.label.localeCompare(b.label);
      }
      // If both favorites, leave them in their original order in POPULAR_COUNTRY_CODES
      return (
        POPULAR_COUNTRY_CODES.indexOf(a.value) -
        POPULAR_COUNTRY_CODES.indexOf(b.value)
      );
    });
  return countryOptions;
}