import { getApiRequestService } from "../../../services/index.services";

export async function getUserLastArticle(userId: string = "") {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`history/${userId || "me"}/lastArticle`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
