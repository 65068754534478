// Need to use the React-specific entry point to import createApi
import { createApi } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../../redux/base-query";

export const searchApi = createApi({
  reducerPath: "searchApi",
  refetchOnMountOrArgChange: true,
  baseQuery,
  endpoints: () => ({}),
});
