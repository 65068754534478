import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "../../redux";
import {
  UserConnectionRequestModel,
  UserModel,
} from "../../domains/user/user.types";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useLazyGetAcceptedConnectionRequestsQuery } from "../../domains/user/endpoints/getAcceptedConnectionRequests";
import UserButton from "../../components/app/UserButton";
import Loader from "../../components/Loader";
import {
  MessagePageContent,
  MessagePageHeader,
} from "../../components/messaging/layout";
import MessageHeader from "../../components/app/headers/MessageHeader";
import Fuse from "fuse.js";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";
import { t } from "i18next";
import CustomIcon from "../../components/CustomIcon";

export default function NewMessagePage() {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const acceptedRequests = useAppSelector(
    (state) => state.user.connections.accepted
  );
  const [isLoading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState<UserModel[] | []>([]);
  const [getAcceptedConnectionRequests] =
    useLazyGetAcceptedConnectionRequestsQuery();

  useEffect(() => {
    (async function () {
      try {
        const { isRedirected } = await preflightUser({
          history,
          authOnly: true,
        });
        if (isRedirected) return;

        await getAcceptedConnectionRequests().unwrap();
        setLoading(false);
      } catch (error) {
        console.error("Couldn't load contacts.", error);
        displayToast(t("error:default"));
        history.replace("/profile/messages");
      }
    })();
  }, []);

  useEffect(() => {
    if (!searchValue.length) {
      setSearchResults([]);
      return;
    }

    const otherUsers = acceptedRequests.map(
      (request: UserConnectionRequestModel) => {
        return [request.fromUser, request.toUser].find((user: UserModel) => {
          return user.uid !== currentUser.uid;
        });
      }
    );

    const fuse = new Fuse(otherUsers, {
      keys: [
        "fullname",
        "firstname",
        "lastname",
        "username",
        "primary_author",
        "profession",
        "main_specialty",
      ],
      includeScore: true,
      findAllMatches: true,
      threshold: 0.3,
    });

    const results: any = fuse.search(searchValue).map((result) => result.item);
    setSearchResults(results);
  }, [searchValue]);

  async function handleMoveToConversation(user: UserModel) {
    history.push({
      pathname: "/profile/messages/conversation",
      state: { user },
    });
  }

  return (
    <>
      <MessageHeader />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <MessagePageHeader>
            <SearchBar>
              <CustomIcon iconName="search" color="#90A4AE" />
              <input
                type="search"
                maxLength={50}
                placeholder={t("messaging:searchUsers")}
                onInput={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearchValue(e.target.value)
                }
              />
            </SearchBar>
          </MessagePageHeader>
          <MessagePageContent style={{ padding: "0 20px" }}>
            {searchValue.length > 0 ? (
              <>
                {searchResults.length > 0 ? (
                  searchResults.map((user) => {
                    return (
                      <UserButton
                        user={user}
                        onClick={() => handleMoveToConversation(user)}
                      />
                    );
                  })
                ) : (
                  <NoResultText>{t("messaging:noUsers")}</NoResultText>
                )}
              </>
            ) : (
              <>
                {acceptedRequests.map((request: UserConnectionRequestModel) => {
                  const otherUser: any = [
                    request.fromUser,
                    request.toUser,
                  ].find((user: any) => {
                    return user.uid !== currentUser.uid;
                  });
                  return (
                    <UserButton
                      key={otherUser.id}
                      user={otherUser}
                      onClick={() => handleMoveToConversation(otherUser)}
                    />
                  );
                })}
              </>
            )}
          </MessagePageContent>
        </>
      )}
    </>
  );
}

const SearchBar = styled.div`
  padding: 11px 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 40px;
  background: var(--SECONDARY-GREY-SHADES-Bluegrey-3, #d2dce2);

  svg path {
    fill: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
  }

  input {
    flex: 1;
    border: none;
    background: none;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 400;
    color: var(--SECONDARY-GREY-SHADES-Bluegrey-5, #617985);
  }
`;

const NoResultText = styled.div`
  margin: 15px 0;
  color: #212121;
  font-family: "Inter";
  font-weight: 400;
  font-size: 15px;
  text-align: center;
`;
