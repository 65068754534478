import { useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { usePlaylistCache } from "../../../domains/playlist/hooks/usePlaylistCache";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { getPlaylistById } from "../../../domains/playlist/endpoints/getPlaylistById";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { RoomModel } from "../../../domains/room/room.types";
import { getStoredRoomLang } from "../../../domains/app/utils/langUtils";
import { useRoomPreflight } from "../../../domains/room/hooks/useRoomPreflight";
import { gaEventAudioRoomResumePodcast, gaEventAudioRoomSelectTopic } from "../../../tools/analytics/audioRoomAnalytics";
import styled from "styled-components";
import disableScroll from "disable-scroll";
import Loader from "../../../components/Loader";
import LanguagePanel from "../../../components/app/LanguagePanel";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  NavHome,
  NavLang,
  NavOptions,
} from "../../../components/app/headers/layout";
import { RoomHeader, RoomPageWrapper } from "../../../components/room/layout";
import ClampLines from "../../../components/app/ClampLines";
import { getPodcastBackground } from "../../../domains/room/utils/getPodcastBackground";
import { Trans } from "react-i18next";
import RoomOptionsPanel from "../../../components/room/RoomOptionsPanel";
import DownloadAppModal from "../../../components/home/modals/DownloadAppModal";
import { ReactComponent as CoughColdIcon } from "../../../assets/icons/cough-cold.svg";
import { ReactComponent as AllergyIcon } from "../../../assets/icons/allergy.svg";
import { ReactComponent as PlayIcon } from "../../../assets/icons/play-pink.svg";

export default function AudioRoomHomePage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { isInRoom, currentRoom } = useRoomContext();
  const { activeLang, t } = useLangContext();
  const { isLoadingRoom, preflightRoom } = useRoomPreflight(roomId);
  const { roomPlaylists, setRoomPlaylists } = usePlaylistCache();
  const [isLangSelected, setLangSelected] = useState(getStoredRoomLang(roomId) !== null);
  const [isOptionsPanelVisible, setOptionsPanelVisible] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        await preflightRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  useEffect(() => {
    (async function () {
      try {
        if (isInRoom) {
          scrollTo(0, 0);

          if (roomPlaylists.length === 0) {
            if (currentRoom?.playlists) {
              const list = await Promise.all(
                currentRoom.playlists.map(async (playlistId: string) => {
                  return await getPlaylistById(playlistId);
                })
              );
              setRoomPlaylists(list, 1000); // NOTE: Total doesn't apply here.
            }
          }
        }
      } catch (error) {
        console.error("Couldn't load room playlists.", error);
        history.push("/discovery");
      }
    })();
  }, [isInRoom, activeLang]);

  if (isLoadingRoom) return <Loader />;

  if (!isLangSelected) {
    return (
      <LanguagePanel
        isVisible={!isLangSelected}
        onClose={() => setLangSelected(true)}
        displayConfirmButton={true}
      />
    );
  }

  return (
    <>
      <RoomPageWrapper
        style={{ backgroundColor: currentRoom?.primaryColor ?? "#042B0B" }}
      >
        <HeaderBoxAutoSafe>
          <HeaderLinks>
            <NavHome />
          </HeaderLinks>
          <RoomHeader room={currentRoom as RoomModel} />
          <HeaderLinks>
            <NavLang />
            <NavOptions onClick={() => setOptionsPanelVisible(true)} />
          </HeaderLinks>
        </HeaderBoxAutoSafe>

        <ContentWrapper>
          <h1>{t("room:audio.sanofiOpella.welcome")}</h1>
          <ResumeListening onClick={(podcast: LastPlayedPodcast) => {
            gaEventAudioRoomResumePodcast({
              podcast,
              topicName: AnalyticsItemList.AUDIO_ROOM_RESUME_PODCAST,
              sectionName: AnalyticsItemList.AUDIO_ROOM_RESUME_PODCAST,
              durationMin: Math.floor(podcast.duration / 60),
            });
          }} />

          <hr />

          <h3>{t("room:audio.sanofiOpella.pickTopic")}</h3>

          {/* TODO: Refactor (list). */}
          <TopicButton to={`/room/audio/${roomId}/topic/coughcold`} onClick={() => {
            gaEventAudioRoomSelectTopic(t("room:audio.sanofiOpella.topic.coughCold"));
          }}>
            <CoughColdIcon />
            <span>{t("room:audio.sanofiOpella.topic.coughCold")}</span>
          </TopicButton>

          <TopicButton to={`/room/audio/${roomId}/topic/allergy`} onClick={() => {
            gaEventAudioRoomSelectTopic(t("room:audio.sanofiOpella.topic.allergy"));
          }}>
            <AllergyIcon />
            <span>{t("room:audio.sanofiOpella.topic.allergy")}</span>
          </TopicButton>

          <span className="copyright">
            <Trans className="copyright" i18nKey="room:audio.sanofiOpella.copyright" />
          </span>
        </ContentWrapper>
      </RoomPageWrapper>

      <RoomOptionsPanel
        roomId={roomId}
        isVisible={isOptionsPanelVisible}
        onClose={() => setOptionsPanelVisible(false)}
      />

      <DownloadAppModal />
    </>
  );
}

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;

  h1 {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 24px;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -0.24px;
    margin: 0;
  }

  h3 {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    margin: 0;
  }

  hr {
    width: 100%;
    background: #6d7f6e;
  }

  span {
    text-align: center;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 500;
    line-height: 110%;
    text-transform: uppercase;
  }

  .copyright {
    color: #ff78d2;
  }
`;

const TopicButton = styled(Link)`
  background: #f7efe6;
  width: 100%;
  height: 170px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    color: #042b0b;
    text-align: center;
    font-family: "Inter";
    font-size: 19px;
    font-weight: 700;
    text-transform: unset;
  }
`;

interface LastPlayedPodcast {
  slug: string;
  time: number;
  duration: number;
  _id: string;
  title: string;
  journal: {
    name: string;
  };
  medical_specialties: {
    uid: string;
  }[];
}

function ResumeListening({ onClick }: { onClick: Function }) {
  const [lastPodcast, setLastPodcast] = useState<LastPlayedPodcast | null>(
    null
  );
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { t } = useLangContext();

  useEffect(() => {
    const savedPodcast = localStorage.getItem("lastPodcast");
    if (savedPodcast) {
      const parsedPodcast = JSON.parse(savedPodcast);
      setLastPodcast(parsedPodcast);
      setBackgroundImage(getPodcastBackground(parsedPodcast.id));
    }
  }, []);

  const handleNavigate = () => {
    if (lastPodcast) {
      const timeParam = lastPodcast.time !== 0 ? `/${lastPodcast.time}` : "";
      history.push({
        pathname: `/room/audio/${roomId}/player/${lastPodcast.slug}${timeParam}`,
        state: {
          audioRoomContext: {
            topicName: AnalyticsItemList.AUDIO_ROOM_RESUME_PODCAST,
            sectionName: AnalyticsItemList.AUDIO_ROOM_RESUME_PODCAST,
          },
        }
      });
    }
    onClick && onClick(lastPodcast);
  }

  if (!lastPodcast) {
    return null;
  }

  return (
    <Wrapper>
      <span>{t("room:audio.sanofiOpella.resumeLastPodcast")}</span>
      <ResumeListeningWrapper onClick={handleNavigate}>
        <PodcastCoverImage>
          {backgroundImage && <img src={backgroundImage} />}
        </PodcastCoverImage>
        <ClampLines lines={2} className="title-text">
          {lastPodcast.title}
        </ClampLines>
        <PlayIcon />
      </ResumeListeningWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  span {
    color: #fff;
    text-align: center;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 400;
    line-height: 110%;
    text-transform: uppercase;
    margin: 0;
  }
`;

const ResumeListeningWrapper = styled.div`
  background: #f7efe6;
  padding: 13px;
  width: 100%;
  border-radius: 20px;
  box-sizing: border-box;
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 16px;

  .title-text {
    flex: 1;
    color: #042b0b;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -0.14px;
  }
`;

const PodcastCoverImage = styled.div`
  width: 40px;
  height: 40px;
  background-color: lightcoral;
  border-radius: 7px;
  overflow: hidden;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
