import { Link, useHistory } from "react-router-dom";
import { SpecialtyModel } from "../../../domains/app/app.types";
import { getItemTranslation } from "../../../domains/app/utils/getItemTranslation";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useCurrentUser } from "../../../domains/user/hooks/useCurrentUser";
import styled from "styled-components";
import { filterArrayDuplicates } from "../../../domains/app/utils/filterArrayDuplicates";
import { ReactComponent as LockSmallIcon } from "../../../assets/icons/lock-small.svg";
import { ReactComponent as PlusSmallIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as TubeIcon } from "../../../assets/icons/tube.svg";

export const SPECIALTIES_EMOJIS: { [char: string]: string } = {
  "acute-care": "🤕",
  "addiction-medicine": "🍄🚬🥃",
  "administration-and-management": "💼",
  "aerospace-medicine": "🚀",
  "allergy-and-immunology": "🤧🧫",
  anesthesiology: "💉",
  "artificial-intelligence": "🤖",
  cardiology: "🫀",
  "case-management-and-discharge-planning": "🫀",
  "complementary-and-alternative-medicine": "🌱",
  "correctional-health": "⚖️",
  "critical-care": "🤕",
  dermatology: "🫱🏻‍🫲🏾",
  "emergency-medicine": "🚑",
  endocrinology: "💉",
  epidemiology: "🦠",
  "faculty-and-teaching": "🎓",
  "family-medicine": "👨‍👩‍👧",
  "forensic-and-legal-medicine": "🕵️⚖️",
  gastroenterology: "🧑‍⚕️",
  "genitourinary-oncology": "🎗",
  geriatrics: "👴🏻",
  "hematologic-oncology": "🩸🎗️",
  hematology: "🩸",
  "home-care": "🏠",
  "hospice-and-paliative-medicine": "🏥",
  "infectious-diseases": "🦠",
  "infusion-therapy": "💧",
  "internal-medicine": "🩺",
  "lab-medicine": "🧫",
  "legal-consulting": "🧑‍⚖️",
  "managed-care": "🩺💸",
  "medical-devices": "🔌🕹️",
  "medical-genetics": "🧬",
  nephrology: "🫘",
  "neurologic-oncology": "🎗️🧠",
  neurology: "🧠",
  neurosurgery: "🧠😷",
  "non-clinical": "🧑‍💻",
  "nuclear-medicine": "☢️",
  "obstetrics-and-gynecology": "🤰",
  "occupational-medicine": "👷‍♂️👨‍💼",
  oncology: "🎗️",
  ophthalmology: "👁️",
  "orthopedic-surgery": "🦴😷",
  orthopedics: "🦴",
  otolaryngology: "👂👃🏽👀",
  pain: "🤕",
  pathology: "🧪",
  "patient-education": "🧑‍🏫🎓",
  pediatrics: "👶",
  pharmacotherapy: "💊",
  pharmacy: "💊",
  "physical-medicine-and-rehabilitation": "💪",
  "plastic-surgery": "🪡",
  "preventive-medicine": "🛡️",
  psychiatry: "🧠",
  psychology: "🧠",
  "public-health": "🌍",
  pulmonology: "🫁",
  "quality-management": "🧘‍♀️",
  radiology: "🩻",
  "radiotherapy-oncology": "🩻🎗️",
  "rare-diseases": "🦓",
  research: "🔬",
  rheumatology: "🏋️‍♀️",
  "risk-management-and-utilization-review": "☣️",
  surgery: "🪡",
  telemetry: "🧮",
  "undersea-and-hyperbaric-medicine": "🐠",
  urology: "💦",
  "wound-and-ostomy": "🩹",
  // Animal specialties
  dog: "🐶",
  cat: "🐈",
  horse: "🐴",
  bird: "🐦",
  cattle: "🐄",
  "all-other-animals": "🐑🦙🐐",
};

export default function HomeSpecialtyScroller() {
  const history = useHistory();
  const { t } = useLangContext();
  const { currentUser } = useCurrentUser();
  const mainSpecialty = currentUser.main_specialty;
  const medicalSpecialties = currentUser.medical_specialties;
  const specialties = filterArrayDuplicates(
    [mainSpecialty, ...medicalSpecialties].filter((el) => !!el),
    (a: SpecialtyModel, b: SpecialtyModel) => a.uid === b.uid
  );

  return (
    <>
      <Header>
        <div className="title">
          <TubeIcon />
          <h3>{t("home:specialties.title")}</h3>
        </div>
        <Link to="/discovery/specialties">
          <span>{t("common:action.manage")}</span>
        </Link>
      </Header>

      <HScrollContainer className="scrollbar-hidden">
        {specialties.map((specialty: SpecialtyModel) => {
          const isMainSpecialty = specialty.uid === mainSpecialty.uid;
          return (
            <SpecialtyItemContainer
              className={isMainSpecialty ? "main" : ""}
              key={specialty.uid}
              onClick={() =>
                history.push({
                  pathname: `/discovery/publications/${specialty.uid}`,
                  state: { specialty },
                })
              }
            >
              <Emoji>{SPECIALTIES_EMOJIS[specialty.uid] ?? ""}</Emoji>
              <Label>{getItemTranslation(specialty)}</Label>
              {isMainSpecialty && (
                <div className="lock">
                  <LockSmallIcon />
                </div>
              )}
            </SpecialtyItemContainer>
          );
        })}
        <Link to="/discovery/specialties">
          <SpecialtyItemContainer className="add">
            <PlusSmallIcon />
            <Label className="add">{t("home:specialties.action.add")}</Label>
          </SpecialtyItemContainer>
        </Link>
      </HScrollContainer>
    </>
  );
}

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 21px;

  .title {
    display: flex;
    align-items: center;
    gap: 6px;
  }

  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;
  }

  span {
    color: #ce0868;
  }

  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px 24px;
  display: flex;
  align-items: stretch;
  gap: 8px;
`;

const SpecialtyItemContainer = styled.div`
  position: relative;
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  background: #f7f6f2;
  margin-top: 6px; // Allows the lock icon to be seen without clipping

  &.main {
    background: #fddeeb;
  }

  &.add {
    background: #212121;
  }

  svg {
    width: 8px;
    height: 8px;
  }

  .lock {
    position: absolute;
    top: -9px;
    right: -9px;

    svg {
      width: 28px;
      height: 28px;
    }
  }
`;

const Label = styled.span`
  color: #212121;
  font-family: "Roboto";
  font-size: 14px;
  font-weight: 600;
  line-height: 110%;
  white-space: nowrap;

  &.add {
    color: #ffffff;
  }
`;

const Emoji = styled.span`
  color: #212121;
  font-family: "Roboto";
  font-size: 16px;
  font-weight: 600;
  line-height: 110%;
  white-space: nowrap;
`;
