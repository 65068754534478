import React from "react";
import ReactDOM from "react-dom";
import "./ReactotronConfig";
import * as Sentry from "@sentry/react";
import "./config/i18n";
import { Provider } from "react-redux";
import { store } from "./redux";
import App from "./App";
import "./index.css";
import "antd-mobile/dist/antd-mobile.css";

Sentry.init({
  dsn: "https://7f7c25ceef3d4dc8bb5999cf4058d9dd@o1418672.ingest.sentry.io/6769043",
  integrations: [Sentry.browserTracingIntegration()],
  tracesSampleRate: 1.0,
  beforeSend(event) {
    if (window.location.href.includes("/profile")) return event;
  },
});

console.log(window.navigator.userAgent);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
