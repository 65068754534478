import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { useRoomPreflight } from "../../domains/room/hooks/useRoomPreflight";
import { usePageScroll } from "../../domains/app/hooks/usePageScroll";
import { RoomQuizModel } from "../../domains/room/room.types";
import disableScroll from "disable-scroll";
import styled from "styled-components";
import { HeaderBoxAutoSafe, HeaderLinks, HeaderTitle, NavBack } from "../../components/app/headers/layout";
import { RoomListWrapper } from "../../components/room/layout";
import FadeIn from "react-fade-in";
import SkeletonContentCard from "../../components/profile/SkeletonContentCard";
import RoomQuizCard from "../../components/cards/RoomQuizCard";

// TODO: Replace with API call.
const HARD_CODED_QUIZES = [
  {
    label: "Questions & Answers",
    title: "Ask your questions here: Available throughout the event",
    subtitle: "",
    eventName: "Escaping from Pain - A Sanofi-Sponsored standalone event",
    template: "VkJay4qq",
    displayAt: new Date(),
    endDisplayAt: new Date(Date.now() + 1000 * 60 * 60 * 24),
  },
  {
    label: "Quiz #1",
    title: "Pre-Event Quiz",
    subtitle: "",
    eventName: "Escaping from Pain - A Sanofi-Sponsored standalone event",
    template: "DTKOlpZP",
    displayAt: new Date(),
    endDisplayAt: new Date(Date.now() + 1000 * 60 * 60 * 24),
  },
  {
    label: "Quiz #2",
    title: "Mechanism of FIRTECH",
    subtitle: "",
    eventName: "Escaping from Pain - A Sanofi-Sponsored standalone event",
    template: "hjxbRe8e",
    displayAt: new Date(),
    endDisplayAt: new Date(Date.now() + 1000 * 60 * 60 * 24),
  },
  {
    label: "Quiz #3",
    title: "Arrange in order",
    subtitle: "",
    eventName: "Escaping from Pain - A Sanofi-Sponsored standalone event",
    template: "hzhwP2Hm",
    displayAt: new Date(),
    endDisplayAt: new Date(Date.now() + 1000 * 60 * 60 * 24),
  },
  {
    label: "Quiz #4",
    title: "Select all that apply",
    subtitle: "",
    eventName: "Escaping from Pain - A Sanofi-Sponsored standalone event",
    template: "ADt6AVWg",
    displayAt: new Date(),
    endDisplayAt: new Date(Date.now() + 1000 * 60 * 60 * 24),
  },
  {
    label: "Quiz #5",
    title: "Agree or Disagree",
    subtitle: "",
    eventName: "Escaping from Pain - A Sanofi-Sponsored standalone event",
    template: "lwyxcOYW",
    displayAt: new Date(),
    endDisplayAt: new Date(Date.now() + 1000 * 60 * 60 * 24),
  },
  {
    label: "Quiz #6",
    title: "Post-Event Quiz",
    subtitle: "",
    eventName: "Escaping from Pain - A Sanofi-Sponsored standalone event",
    template: "fOGh82YQ",
    displayAt: new Date(),
    endDisplayAt: new Date(Date.now() + 1000 * 60 * 60 * 24),
  },
];

export default function RoomQuizListPage() {
  const history = useHistory();
  const { roomId } = useParams<{ roomId: string }>();
  const { t } = useLangContext();
  const { currentRoom } = useRoomContext();
  const { savePageScrollY, restorePageScrollY } = usePageScroll();
  const { isLoadingRoom, preflightRoom } = useRoomPreflight(roomId);
  const roomQuizes = HARD_CODED_QUIZES; // TEMP

  useEffect(() => {
    (async function () {
      try {
        disableScroll.off();
        restorePageScrollY();
        await preflightRoom();
      } catch (error) {
        console.error("Couldn't load room.", error);
        history.push("/discovery");
      }
    })();
  }, []);

  return (
    <Wrapper style={{ backgroundColor: currentRoom?.primaryColor ?? "" }}>
      <HeaderBoxAutoSafe>
        <HeaderLinks><NavBack /></HeaderLinks>
        <HeaderTitle>{t("room:quiz.title")}</HeaderTitle>
        <HeaderLinks />
      </HeaderBoxAutoSafe>

      <RoomListWrapper style={{ marginTop: 16, gap: 10 }}>
        {isLoadingRoom ? (
          <FadeIn key="loading">
            {[...Array(10)].map((_, index) => (
              <SkeletonContentCard key={index} />
            ))}
          </FadeIn>
        ) : (
          <>
            {roomQuizes.map((quiz: RoomQuizModel, index: number) => {
              return (
                <RoomQuizCard index={index} quiz={quiz} onclick={() => {
                  savePageScrollY();
                  history.push(`/room/quiz/${roomId}/${quiz.template}`);
                }} />
              );
            })}
          </>
        )}
      </RoomListWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  min-height: 100dvh;
`;
