import styled from "styled-components";

// TODO: Delete once removed as dependency for other pages.

export const StyledOnboarding = styled.div`
  padding: 20px 0;
  background: #fff;
  min-height: 100vh;
  max-height: 100%;
  box-sizing: border-box;

  h5 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #212121;
    margin: 0;
    padding: 0 15px;
  }

  h2 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    color: #ce0868;
    margin: 0 0 8px;
  }

  h3 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 100%;
    color: #ce0868;
  }

  .header {
    padding: 0 60px;
    margin-bottom: 24px;
    margin-top: 16px;
  }

  .selection-rows {
    padding: 30px 22px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &.popular {
      background-color: #fff9e6;
    }

    h3,
    h5 {
      text-align: left;
      margin: 0;
      padding: 0;
    }

    .row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      padding: 16px 0;
      border-bottom: 1px solid #212121;

      &:last-child {
        border-bottom: none;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 130%;
        display: flex;
        align-items: center;
        letter-spacing: 0.02em;
        color: #212121;
      }

      button {
        outline: none;
        border: 1px solid #d2dce2;
        box-shadow: none;
        background-color: #fff;
        padding: 12px 16px;
        border-radius: 100px;
        /** Font style */
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        text-align: right;
        letter-spacing: 0.02em;
        color: #212121;
        transition: all ease-out 0.3s;

        &.selected {
          background-color: #fff3ce;
          border-color: #ff8800;
          color: #ff8800;
        }
      }
    }
  }

  .content-section {
    padding: 10px 22px 50px;

    .avatar-image {
      display: block;
      margin: auto auto 16px;
      width: 200px;
      height: 200px;
      object-fit: cover;
      border-radius: 200px;
      background-color: #f9f9f9;
      border: 1px solid #d2dce2;
    }

    .upload-button {
      display: block;
      margin: auto;
      border: 1px solid #d2dce2;
      border-radius: 100px;

      & > * {
        border-radius: 100px;
        background: transparent !important;
        & > * {
          border-radius: 100px;
          background: transparent !important;
        }
      }

      .btn-text {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
        font-weight: 700;
        text-align: center;
        padding: 4px 0;
      }
    }

    .default-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      justify-content: center;
      justify-items: center;
      grid-column-gap: 12px;
      grid-row-gap: 24px;

      img {
        width: 81px;
        height: 81px;
        border-radius: 100px;
        border: 3px solid #fff;
        transition: all ease-out 0.3s;
      }

      img.picked {
        border: 3px solid #212121;
        border-radius: 100px;
      }
    }

    .tags-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      justify-content: start;
      align-items: center;
    }

    .tag {
      border: 1px solid #d2dce2;
      padding: 10px;
      border-radius: 100px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 100%;
      color: #212121;
      transition: all ease-out 0.3s;

      &.selected {
        border-color: #fff3ce;
        background-color: #fff3ce;
        color: #ff8800;
      }
    }
  }

  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 21px 22px 50px;
    background-color: #fff;
    transform: translateY(0px);
    transition: transform ease-out 0.3s;

    &.hide {
      transform: translateY(140px);
    }

    & :nth-child(2) {
      margin-top: 10px;
    }
  }

  p.empty-text {
    margin: 30px auto;
  }
`;
