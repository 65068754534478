import { atom, getDefaultStore, useAtom } from "jotai"
import { VideoModel } from "../video.types";
import { DebugLogger } from "../../app/utils/debugLogger";

const debug = new DebugLogger("Video Cache");
debug.mute();

const videoStoriesListAtom = atom<VideoModel[]>([]);
const videoStoriesTotalAtom = atom(0);

export function useVideoCache() {
  const [videoStories, setVideoStories] = useAtom(videoStoriesListAtom);
  const [totalVideoStories, setTotalVideoStories] = useAtom(videoStoriesTotalAtom);

  return {
    videoStories,
    setVideoStories: (stories: VideoModel[]) => {
      debug.log("Set Video stories cache", stories);
      setVideoStories(stories);
    },
    totalVideoStories,
    setTotalVideoStories,
    clearCache: () => setVideoStories([]),
  }
}

// NOTE: Temporary. Remove once the unique call from a class component is removed.
const store = getDefaultStore();

export const videoCacheStore = {
  videoStories: store.get(videoStoriesListAtom),
  setVideoStories: (stories: VideoModel[]) => {
    debug.log("Set Video stories cache (store)", stories);
    store.set(videoStoriesListAtom, stories);
  },
};