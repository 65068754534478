import { Component } from "react";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import {
  HeaderBoxAutoSafe,
  HeaderLinks,
  HeaderTitle,
  NavHome,
} from "../../components/app/headers/layout";
import { Link, withRouter } from "react-router-dom";
import styled from "styled-components";
import VideoCard from "../../components/VideoCard";
import VideoCard2 from "../../components/cards/VideoCard";
import Loader from "../../components/Loader";
import { t } from "i18next";
import { userApi } from "../../redux/user/user.service";
import { connect } from "react-redux";
import LoginPanel from "../../components/signup/LoginPanel";
import SpecialtyFilterPanel from "../../components/filters/SpecialtyFilterPanel";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListVideo } from "../../tools/analytics/videoAnalytics";
import { getVideoArticlesList } from "../../domains/video/endpoints/getVideoArticlesList";
import { getVideoStoriesList } from "../../domains/video/endpoints/getVideoStoriesList";
import i18n from "../../config/i18n";
import ContentFilterButton from "../../components/filters/ContentFilterButton";
import ItemList from "../../components/app/ItemList";
import VideoCardSkeleton from "../../components/skeleton/VideoCardSkeleton";
import { getStoredContentFilters } from "../../domains/app/utils/contentFilters";

class HomeVideos extends Component {
  state = {
    isLoading:
      !this.props.videos.cache.videoArticles.length &&
      !this.props.videos.cache.videoStories.length,
    isAuthentified: false,
    medical_specialties: [], // TODO: Delete (deprecated)
    specialtyFilters: getStoredContentFilters().specialtyIds ?? [],
    showDrawer: false,
    videoStories: [], // TODO: Replace with cache once this component goes functional.
  };

  async componentDidMount() {
    window.scrollTo(0, 0);

    const isAuthentified = await isUserAuthentified();
    this.setState({ isAuthentified });

    if (localStorage.getItem("homeVideosFilters")) {
      const { medical_specialties, tags } = JSON.parse(
        localStorage.getItem("homeVideosFilters")
      );
      this.setState({ medical_specialties, tags }, async () => {
        this.handleFetchVideoArticles();
        this.handleFetchVideoStories();
      });
    } else {
      this.handleFetchVideoArticles();
      this.handleFetchVideoStories();
    }

    if (isAuthentified) {
      this.props.getUser();
      this.props.getPlaylists();
    }
    this.triggerLoginPanel();
  }

  handleFetchVideoArticles = async () => {
    const { specialtyFilters } = this.state;

    const params = {
      offset: 0,
      language: i18n.resolvedLanguage,
    };

    if (specialtyFilters.length > 0) {
      params.medicalSpecialties = specialtyFilters.join(",");
    }

    await this.props
      .getVideoArticlesList({
        ...params,
        limit: 50,
      })
      .unwrap()
      .catch((error) => {
        console.error("Couldn't load video articles.", error);
      });

    this.setState({
      videos: { ...this.props.videos },
      isLoading: false,
    });

    // NOTE: Should be set with a useEffect or something, not here like this.
    gaEventViewItemListVideo({
      videos: this.props.videos.cache.videoArticles,
      listName: AnalyticsItemList.DISCOVERY_VIDEOS,
    });
  }

  handleFetchVideoStories = async () => {
    const params = {
      offset: 0,
      language: i18n.resolvedLanguage,
    };

    // NOTE: No specialty filter for video stories.

    // TODO: Replace with cache once this component goes functional.
    const { docs } = await getVideoStoriesList({
      ...params,
      limit: 20,
    });
    this.setState({ videoStories: docs });

    this.setState({
      videos: { ...this.props.videos },
      isLoading: false,
    });

    // NOTE: Should be set with a useEffect or something, not here like this.
    gaEventViewItemListVideo({
      videos: this.props.videos.cache.videoStories.slice(0, 10),
      listName: AnalyticsItemList.DISCOVERY_VIDEOS,
    });
  }

  handleChangeFilters = () => {
    this.setState({
      specialtyFilters: getStoredContentFilters().specialtyIds ?? this.state.specialtyFilters,
    }, () => this.handleFetchVideoArticles());
  }

  triggerLoginPanel = () => {
    const query = new URLSearchParams(this.props.location.search);
    const wasShared = JSON.parse(query.get("wasShared"));

    if (wasShared && !this.state.isAuthentified)
      this.setState({ showLoginPanel: true });
  };

  render() {
    const stories = this.state.videoStories.slice(0, 10);

    if (this.state.isLoading) return <Loader />;

    return (
      <PageContainer>
        <HeaderBoxAutoSafe>
          <HeaderLinks>
            <NavHome />
          </HeaderLinks>
          <HeaderTitle>{t("content:type.videos")}</HeaderTitle>
          <HeaderLinks />
        </HeaderBoxAutoSafe>

        <LoginPanel
          {...this.props}
          isOpen={this.state.showLoginPanel}
          onClose={() => this.setState({ showLoginPanel: false })}
        />

        <SpecialtyFilterPanel
          isOpen={this.state.showDrawer}
          onClose={() => this.setState({ showDrawer: false })}
          onSave={this.handleChangeFilters}
        />

        <div className="heading">
          <h5>{t("home:videos.title")}</h5>
          <Link to="/videos/stories">{t("common:action.seeAll")}</Link>
        </div>
        <HScrollContainer className="scrollbar-hidden">
          {stories.length === 0 ? (
            <VideoCardSkeleton />
          ) : (
            stories.map((story) => (
              <VideoCard
                variant="story"
                key={`${story._id}--story-homevideocard`}
                video={story}
                onClick={() => this.props.history.push({
                  pathname: `/video/story/${story.slug}`,
                  state: { story, stories },
                })}
              />
            ))
          )}
        </HScrollContainer>

        <hr style={{ margin: "30px 0" }} />

        <div className="heading">
          <h5>{t("home:videos.title2")}</h5>
          <ContentFilterButton
            active={
              this.state.tags?.length > 0 ||
              this.state.medical_specialties?.length > 0
            }
            onClick={() => this.setState({ showDrawer: true })}
          />
        </div>
        <ItemList>
          {this.props.videos.cache.videoArticles.length === 0 && (
            <p style={{ margin: "0 16px" }}>{t("common:noContent")}</p>
          )}
          {this.props.videos.cache.videoArticles?.map((video) => (
            <VideoCard2
              key={video._id + "--article-homevideocard"}
              video={video}
              {...this.props}
            />
          ))}
        </ItemList>
      </PageContainer>
    );
  }
}

function mapState(state) {
  const user = state.user;
  const videos = state.videos;

  return {
    user,
    videos,
  };
}
const mapDispatch = {
  getUser: userApi.endpoints.getUser.initiate,
  getPlaylists: userApi.endpoints.getPlaylists.initiate,
  getVideoArticlesList: getVideoArticlesList.initiate,
};

const connector = connect(mapState, mapDispatch);

export default connector(withRouter(HomeVideos));

const PageContainer = styled.div`
  min-height: 100dvh;
  display: flex;
  flex-direction: column;
  background: #f7f8fc;

  h5 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;

    span {
      color: #ce0868;
    }
  }

  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 16px 21px;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 0 24px 24px;
  display: flex;
  align-items: stretch;
  gap: 8px;
`;
