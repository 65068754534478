import styled from "styled-components";
import { RoomQuizModel } from "../../domains/room/room.types";
import {
  CardContainer,
  CardType,
  CardTitle,
} from "./layout";
import bg1 from "../../assets/images/quiz-bg/quiz-bg-1.png";
import bg2 from "../../assets/images/quiz-bg/quiz-bg-2.png";
import bg3 from "../../assets/images/quiz-bg/quiz-bg-3.png";
import bg4 from "../../assets/images/quiz-bg/quiz-bg-4.png";
import bg5 from "../../assets/images/quiz-bg/quiz-bg-5.png";
import bg6 from "../../assets/images/quiz-bg/quiz-bg-6.png";

const backgrounds = [bg1, bg2, bg3, bg4, bg5, bg6];

export default function RoomQuizCard({
  index,
  quiz,
  onclick,
}: {
  index: number;
  quiz: RoomQuizModel;
  onclick: Function;
}) {
  return (
    <Wrapper>
      <CardContainer
        backgroundSrc={backgrounds[index % backgrounds.length]}
        onClick={() => onclick()}
      >
        <CardBody>
          <div style={{ display: "flex", flexDirection: "column", gap: "6px" }}>
            <CardType>{quiz.label}</CardType>
            <CardTitle lines={3}>{quiz.title}</CardTitle>
            <div style={{ marginTop: 24, display: "flex", flexDirection: "column", gap: 24 }}>
              {quiz.subtitle && <span>{quiz.subtitle}</span>}
              {quiz.eventName && <span>{quiz.eventName}</span>}
            </div>
          </div>
        </CardBody>
      </CardContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  .card-outer-container {
    min-height: unset;
  }

  .card-color-layer {
    background: #411772;
    mix-blend-mode: hard-light;
  }

  .card-content-container {
    padding: 56px 16px;
    color: #ffffff;
    --card-primary-color: #ffffff;
    --card-secondary-color: #FFC408;
  }
`;

const CardBody = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;
