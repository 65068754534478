import React, { MouseEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { DEFAULT_LANGUAGE } from "../../config/i18n";
import styled, { keyframes } from "styled-components";
import { WELCOME_SLIDES } from "./welcomeSlides";
import FadeIn from "react-fade-in/lib/FadeIn";
import Loader from "../../components/Loader";
import { gaEventPageView } from "../../tools/analytics/analyticsEvents";
import { ReactComponent as CrossIcon } from "../../assets/icons/cross-duotone.svg";

const SLIDE_TIMEOUT = 10000;

interface Slide {
  title: React.ReactNode;
  text: React.ReactNode;
  backColor: string;
  images: {
    src: string;
    className: string;
    style?: React.CSSProperties;
  }[];
  backImage?: {
    src: string;
    className: string;
  };
  backImage2?: {
    src: string;
    className: string;
  };
  custom?: React.ReactNode;
  imageStyle?: React.CSSProperties;
}

export default function WelcomePage() {
  const [backImageLoading, setBackImageLoading] = useState<boolean>(true);
  const [imageLoading, setImageLoading] = useState<boolean>(true);
  const [slideIndex, setSlideIndex] = useState<number>(0);
  const [slideTimeout, setSlideTimeout] = useState<NodeJS.Timeout | null>(null);
  const { activeLang } = useLangContext();
  const history = useHistory();

  useEffect(() => {
    gaEventPageView();
    preloadImages();
    resetSlideTimeout();

    return () => {
      if (slideTimeout) {
        clearTimeout(slideTimeout);
      }
    };
  }, []);

  function getSafeWelcomeSlides(): Slide[] {
    return (
      WELCOME_SLIDES[activeLang as keyof typeof WELCOME_SLIDES] ??
      WELCOME_SLIDES[DEFAULT_LANGUAGE]
    );
  }

  function preloadImages() {
    const firstSlide = getSafeWelcomeSlides()[0];

    const backImage = new Image();
    if (firstSlide.backImage?.src) {
      backImage.src = firstSlide.backImage.src;
      backImage.onload = () => {
        setBackImageLoading(false);
      };
    }

    const image = new Image();
    image.src = firstSlide.images[0]?.src || "";
    image.onload = () => {
      setImageLoading(false);
    };
  }

  function resetSlideTimeout() {
    if (slideTimeout) clearTimeout(slideTimeout);
    const timeout = setTimeout(() => {
      handleSlideNext();
    }, SLIDE_TIMEOUT);
    setSlideTimeout(timeout);
  }

  function handleSlidePrev() {
    resetSlideTimeout();
    setSlideIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  }

  function handleSlideNext() {
    resetSlideTimeout();
    setSlideIndex((prevIndex) =>
      Math.min(prevIndex + 1, getSafeWelcomeSlides().length - 1)
    );
  }

  // Navigate with clicks on the side of the screen
  function handleClickSlide(e: React.MouseEvent<HTMLDivElement>) {
    if (e.clientX < document.body.clientWidth * 0.35) {
      handleSlidePrev();
    }
    if (e.clientX > document.body.clientWidth * 0.65) {
      handleSlideNext();
    }
  }

  function handleClose(e: MouseEvent) {
    e.preventDefault();
    const currentDate = new Date().toISOString().split("T")[0];
    localStorage.setItem("welcomeDoneDate", currentDate);
    history.push("/discovery");
  }

  function renderProgressSteps() {
    return (
      <ProgressSteps>
        {Array.from({ length: WELCOME_SLIDES.en.length }, (_, index) => {
          let className = "";
          if (index < slideIndex) className = "full";
          if (index === slideIndex) className = "filling";

          return <ProgressBar key={index} className={className} />;
        })}
      </ProgressSteps>
    );
  }

  function renderSlide(slide: Slide, index: number) {
    return (
      <SlideContainer
        key={index}
        onClick={handleClickSlide}
        style={{
          display: slideIndex === index ? "flex" : "none",
        }}
      >
        {slide.backImage && (
          <SlideBackImage
            className={slide.backImage.className}
            src={slide.backImage.src}
            alt=""
          />
        )}
        {slide.backImage2 && (
          <SlideBackImage
            className={slide.backImage2.className}
            src={slide.backImage2.src}
            alt=""
          />
        )}
        <FadeIn>
          <SlideTop>
            <h1>{slide.title}</h1>
            <p>{slide.text}</p>
          </SlideTop>
        </FadeIn>
        {slide.custom}
        <div
          style={{
            position: "relative",
            flex: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            overflow: "hidden", // ??
          }}
        >
          {slide.images.map((image, idx) => (
            <SlideImage
              key={idx}
              className={image.className}
              style={image.style}
              src={image.src}
              alt=""
            />
          ))}
        </div>
      </SlideContainer>
    );
  }

  if ((backImageLoading && getSafeWelcomeSlides()[0].backImage) || imageLoading)
    return <Loader />;

  return (
    <PageContainer
      style={{
        background: getSafeWelcomeSlides()[slideIndex].backColor ?? "#fff",
      }}
    >
      <nav>
        {renderProgressSteps()}
        <CrossIcon className="close-button" onClick={handleClose} />
      </nav>

      {getSafeWelcomeSlides().map((slide, index) => renderSlide(slide, index))}
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 60px 14px 10px;
  }

  .close-button {
    align-self: flex-end;
    cursor: pointer;
  }
`;

const ProgressSteps = styled.div`
  display: flex;
  gap: 2px;
`;

const fillAnimation = keyframes`
    from { max-width: 0; }
    to { max-width: 100%; }
`;

const ProgressBar = styled.div`
  flex: 1;
  height: 5px;
  border-radius: 100px;
  overflow: hidden;
  background-color: #d2dce2;
  z-index: 300;

  &::after {
    content: "";
    display: flex;
    max-width: 0;
    height: inherit;
    background-color: #ce0868;
    animation: none;
  }

  &.full {
    ::after {
      max-width: 100%;
      animation: none;
    }
  }

  &.filling {
    ::after {
      max-width: 100%;
      animation: ${fillAnimation} 10s linear;
    }
  }
`;

const SlideContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @keyframes moveUp {
    0% {
      opacity: 0;
      transform: translateY(50px);
    }

    5% {
      opacity: 1;
    }

    100% {
      transform: translateY(0px);
    }
  }
`;

const SlideTop = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 22px;
  z-index: 1;

  animation: fadeIn 0.5s ease-in-out;

  h1,
  p {
    margin: 0;
    text-align: left;
    font-family: "Inter";
    font-weight: 700;
  }

  h1 {
    font-size: 32px;
  }

  p {
    font-size: 16px;
    line-height: 20.8px;
    color: #212121;
  }

  .c-white {
    color: #ffffff;
  }
  .c-black {
    color: #212121;
  }
  .c-grey {
    color: #617985;
  }
  .c-orange {
    color: #ff8800;
  }
  .c-yellow {
    color: #eef822;
  }
  .c-lightgrey {
    color: #d2dce2;
  }
  .c-lemon {
    color: #ffc409;
  }

  .c-orange-gradient {
    background: linear-gradient(to right, #ffc408, #ff734b, #ff306e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const SlideBackImage = styled.img`
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 20%;
  width: 100%;
  opacity: 1;
  animation: moveDiagonal 6s linear alternate infinite;
  transform: translate(30px, -30px) scale(1.75);

  &.opacity-50 {
    opacity: 0.5;
  }

  &.slide-3-left {
    width: 200px;
    object-fit: contain;
    left: 0px;
    bottom: 10%;
    animation: moveUp 4s ease-out;
    transform: scale(1);

    animation-fill-mode: forwards !important;
    opacity: 0.5 !important;
  }

  &.slide-3-right {
    width: 240px;
    object-fit: contain;
    left: auto !important;
    right: -20% !important;
    bottom: -20px;
    animation: moveUp 9s ease-out;
    animation-fill-mode: forwards !important;
    transform: scale(1);
    opacity: 0.5 !important;
  }

  @keyframes moveDiagonal {
    0% {
      transform: translate(0px, 0px) scale(1.75);
    }

    5% {
    }

    100% {
      transform: translate(30px, -30px) scale(1.75);
    }
  }
`;

const SlideImage = styled.img`
  /* background-color: rgba(255, 255, 255, 0.05); */

  margin: 20px auto 0;
  width: 100%;
  z-index: 10;
  object-fit: cover;

  &.small-img {
    width: 70%;
  }

  &.small-phone {
    /* width: 50%;  */
    width: 180px;
    height: auto;
    /* max-width: 300px; */
    /* margin: 20px auto 0; */
  }

  &.side-phone {
    width: 210px;
    height: auto;
    position: absolute;
    bottom: -20px;
    /* bottom: -7%; */
    /* bottom: -25dvh; */
    z-index: 1;
  }

  &.fade-slide-up {
    animation: moveUp 5s ease-out;
  }

  &.rotate-left {
    animation: rotateLeft 5s ease-out;
    transform-origin: 100% 100%;
  }

  &.rotate-right {
    animation: rotateRight 5s ease-out;
    transform-origin: 0% 100%;
  }

  &.fade-no-repeat {
    animation-iteration-count: 1;
  }

  &.fade-slide-left {
    animation: moveLeft 3s ease-out;
  }

  &.animation-forwards {
    animation-iteration-count: 1;
    animation-fill-mode: forwards !important;
  }

  @keyframes moveLeft {
    0% {
      transform: translateX(30px);
      opacity: 1;
    }

    10% {
      opacity: 1;
    }

    100% {
      transform: translateX(0px);
    }
  }

  @keyframes rotateLeft {
    0% {
      transform: rotate(6deg) translateY(70px);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    100% {
      transform: rotate(0deg) translateY(0px);
    }
  }

  @keyframes rotateRight {
    0% {
      transform: rotate(-6deg) translateY(70px);
      opacity: 0;
    }
    5% {
      opacity: 1;
    }
    100% {
      transform: rotate(0deg) translateY(0px);
    }
  }

  @media (max-height: 740px) {
    /* max-height: max(55vh); */
  }
`;
