//
// All Android JS Functions here...
//
import { getCurrentOS } from "./utils";

// TODO: Refactor/type.

export const isNativeAndroid =
  getCurrentOS() === "android" && !!window.services;

export const androidRequestPushAuthorization = () => {
  console.log("android_push_auth");
};

export const androidGetFirebaseToken = () => {
  if (isNativeAndroid) {
    const res = JSON.parse(window.services.getFirebaseToken());
    return res.token;
  }
};

export const androidClearData = () => {
  if (isNativeAndroid) window.services?.clearData();
};

export const androidOffline = () => {
  if (isNativeAndroid) window.services?.offline();
};

export const androidShare = (content, link) => {
  if (isNativeAndroid) window.services.share(link + "\n" + content);
};

export const androidOpen = (link) => {
  if (isNativeAndroid) window.services.open(link);
};

export const androidSignIn = async () => {
  if (isNativeAndroid)
    window.services.startSignInFlow("google", "androidSignInCallback");
};

export const androidSignOut = async () => {
  if (isNativeAndroid) window.services.signOut();
};

export const androidDismissKeyboard = async () => {
  if (isNativeAndroid) window.services.dismissKeyboard();
};

export const androidRequestReview = async () => {
  if (isNativeAndroid) window.services.requestReview();
};

export const androidSetAppUrl = async (url) => {
  if (isNativeAndroid) window.services.setAppUrl(url);
};

export const androidGetAppUrl = async () => {
  if (isNativeAndroid) services.getAppUrl();
};

export const androidResetAppUrl = async () => {
  if (isNativeAndroid) services.resetAppUrl();
};
