import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { preflightUser } from "../../domains/user/utils/preflightUser";
import BottomPanel from "./panels/BottomPanel";
import CustomIcon from "../CustomIcon";
import styled from "styled-components";
import { t } from "i18next";

export default function OptionsPanel({
  isOpen,
  onClose,
  isContentSaved,
  isContentLiked,
  onSaveContent,
  onLikeContent,
  onShareContent,
}: {
  isOpen: boolean;
  onClose: () => void;
  isContentSaved: boolean;
  isContentLiked: boolean;
  onSaveContent: Function;
  onLikeContent: Function;
  onShareContent: Function;
}) {
  const history = useHistory();
  const { currentUser } = useCurrentUser();

  return (
     <BottomPanel
       isOpen={isOpen}
       onClose={() => onClose()}
     >
      <CloseButtonWrapper>
        <CustomIcon iconName="close_alt" onClick={() => onClose()} />
      </CloseButtonWrapper>

      <InnerContainer>
        <OptionRow
          className={isContentSaved ? "fill" : ""}
          onClick={async () => {
            const { isRedirected } = await preflightUser({
              history,
              onboardingMessage: t("error:notOnboarded.saveContent"),
            });
            if (isRedirected) return;
            onSaveContent();
          }}
        >
          <CustomIcon iconName={isContentSaved ? "save.fill" : "save"} />
          <div>
            <span className="label">
              {isContentSaved ? t("common:panel.options.label.modify") : t("common:panel.options.label.save")}
            </span>
            <span className="description">
              {isContentSaved
                ? t("common:panel.options.text.modify")
                : t("common:panel.options.text.save")}
            </span>
          </div>
        </OptionRow>

        <OptionRow
          className={isContentLiked ? "fill" : ""}
          onClick={() => {
            if (currentUser) {
              onLikeContent();
            } else {
              history.push("/login");
            }
          }}
        >
          <CustomIcon iconName={isContentLiked ? "heart" : "heart_outlined"} />
          <div>
            <span className="label">
              {isContentLiked ? t("common:panel.options.label.unlike") : t("common:panel.options.label.like")}
            </span>
            <span className="description">{t("common:panel.options.text.like")}</span>
          </div>
        </OptionRow>

        <OptionRow onClick={() => onShareContent()}>
          <CustomIcon iconName="share-alt" />
          <div>
            <span className="label">{t("common:panel.options.label.share")}</span>
            <span className="description">{t("common:panel.options.text.share")}</span>
          </div>
        </OptionRow>
      </InnerContainer>
     </BottomPanel>
  );
}

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;

const InnerContainer = styled.div`
  /* padding: 55px 25px; */
  padding: 55px 25px calc(55px + var(--safe-area-bottom));
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const OptionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: #212121;
  fill: #212121;
  font-family: "Inter";
  text-align: left;

  &.fill {
    color: #ff699c;
    svg path {
      fill: #ff699c;
    }
  }

  svg {
    width: 24px;
    height: 24px;
  }

  .label {
    display: block;
    font-size: 17px;
    font-weight: 600;
  }

  .description {
    display: block;
    margin-top: 4px;
    font-size: 15px;
    font-weight: 400;
  }
`;
