import { ArticleModel } from "../article/article.types";

// NOTE: Values are shared with the backend. Do not change.
export enum GPTMessageStates {
	New = "new",
	Error = "error",
	Done = "done",
}

export interface GPTConversationModel {
	id: string;
	messages: GPTMessageModel[];
}

export interface GPTMessageModel {
	fromUser: boolean;
	content: string;
	createdAt: Date;
	status: GPTMessageStates;
	sources: ArticleModel[] | null;
	id?: string;
}
