import { getApiRequestService } from "../../../services/index.services";

export async function shareCompany(companyId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/company/${companyId}/share`);
  } catch (error) {
    throw error;
  }
};
