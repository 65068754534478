import BottomNavbar from "../../components/app/BottomNavbar";
import LogoHeader from "../../components/app/headers/LogoHeader";
import styled from "styled-components";

export default function ErrorPage() {
  return (
    <Wrapper>
      <LogoHeader />

      <Container>
        <h1>404 - No fruits found</h1>
        <span>(url: {window.location.href})</span>
      </Container>

      <BottomNavbar />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  height: 100dvh;
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  box-sizing: border-box;
  padding-top: 96px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F7F8FC;
`;
