import { CSSProperties } from "react";
import toast, { Toaster } from "react-hot-toast";
import { Avatar } from "../global";
import styled, { keyframes } from "styled-components";
import JuisciLogo from "../../assets/core/icon-square.svg";

export function AppToaster() {
  return (
    <Toaster
      position="top-center"
      containerStyle={{
        zIndex: 999,
        inset: 0,
        width: "100vw",
        marginTop: "var(--safe-area-top)",
      }}
    />
  );
}

export function displayToast(message: string, type?: string, id?: string) {
  const baseDuration = 3000;
  const extraDurationPerChar = 30;
  const duration = baseDuration + message.length * extraDurationPerChar;

  const style: CSSProperties = {
    zIndex: 999,
    width: "100%",
    margin: "auto 8px",
    borderRadius: 10,
    background: "#F2F2F2",
    position: "relative",
    overflow: "hidden",
  };

  if (type === "bg-yellow") {
    style.background = "#FFAB4C";
  }

  if (type === "bg-green") {
    style.background = "#6AC38F";
  }

  if (type === "bg-orange") {
    style.background = "#FF4300";
    style.color = "#fff";
  }

  const toastId = toast(
    () => (
      <Container onClick={() => toast.dismiss(toastId)}>
        <Avatar src={JuisciLogo} height={30} />
        <span>{message}</span>
        <ProgressBar duration={duration} />
      </Container>
    ),
    {
      id,
      duration,
      style,
    }
  );
}

const progressBarSlideOut = keyframes`
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
`;

const ProgressBar = styled.div<{ duration: number }>`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  background-color: #c2c2c2;
  animation: ${progressBarSlideOut} linear ${({ duration }) => duration}ms
    forwards;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  color: #212121;
`;
