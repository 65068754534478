import { getApiRequestService } from "../../../services/index.services";

export async function renamePlaylist(playlistId: string, name: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlistId}/rename`, {
      name,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
};