import { getApiRequestService } from "../../../services/index.services";

export async function acceptConnectionRequest(connectionRequestId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/user/connection/request/accept", { connectionRequestId });
    return res.data;
  } catch (error) {
    throw error;
  }
};
