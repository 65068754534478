import styled from "styled-components";

export const PanelContainer = styled.div`
  padding: 40px 30px;
    display: flex;
    flex-direction: column;
    gap: 30px;
`;

export const PanelRow = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  color: #212121;

  svg {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }

  .text {
    display: flex;
    flex-direction: column;
    
    .label {
      font-size: 17px;
      font-weight: 600;
    }

    .description {
      font-size: 15px;
      font-weight: 400;
    }
  }
`;
