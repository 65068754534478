import { specialtiesApi } from "../specialty.api";

const api = specialtiesApi.injectEndpoints({
  endpoints: (build) => ({
    getTags: build.query<any, void>({
      query: () => {
        return "/tag";
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getTags,
} = api.endpoints;

export const {
  useLazyGetTagsQuery,
} = api;
