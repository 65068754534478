import { getApiRequestService } from "../../../services/index.services";

export async function setPassword(body: { password: string }) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post("/auth/set-password", {
      ...body,
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
