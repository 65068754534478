import { CSSProperties, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux";
import { JournalModel } from "../../../domains/journal/journal.types";
import { isUserAuthentified } from "../../../domains/user/utils/isUserAuthentified";
import { getPrioritizedJournals } from "../../../domains/journal/utils/getPrioritizedJournals";
import { memorizeJournals } from "../../../domains/journal/journals.reducer";
import { useLazyGetJournalsListQuery } from "../../../domains/journal/endpoints/getJournalsList";
import Loader from "../../Loader";
import JournalCard from "../../JournalCard";
import CustomIcon from "../../CustomIcon";
import { CustomButton } from "../../global";
import { t } from "i18next";
import styled from "styled-components";
import { ReactComponent as JournalIcon } from "../../../assets/icons/home_journal_icon.svg";
import SkeletonContentCard from "../../profile/SkeletonContentCard";

const cardStyle: CSSProperties = {
  boxSizing: "border-box",
  minWidth: 180,
  margin: 0,
  textAlign: "center",
};

export default function HomeJournalScroller() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const journals = useAppSelector((state) => state.journals.cache.list);
  const [getJournalsList] = useLazyGetJournalsListQuery();

  useEffect(() => {
    if (!journals.length) refreshJournals();
  }, []);

  async function refreshJournals() {
    await getJournalsList({
      limit: 20,
      offset: 0,
    })
      .unwrap()
      .then(async (data) => {
        const journals = (await isUserAuthentified())
          ? getPrioritizedJournals(data)
          : data.slice(0, 10);
        dispatch(memorizeJournals(journals));
      })
      .catch((error) => {
        console.error("Couldn't get journals.", error);
      });
  }

  return (
    <Wrapper>
      <Header>
        <div className='title'>
          <JournalIcon />
          <h3>{t("home:journals.title")}</h3>
        </div>
        <Link to='/search/journals'>
          <span>{t("common:action.seeAll")}</span>
        </Link>
      </Header>

      <HScrollContainer className='scrollbar-hidden'>
        {!journals.length
          ? [
              <SkeletonContentCard
                key={"skeleton-1"}
                contentFormat='journal'
              />,
              <SkeletonContentCard
                key={"skeleton-2"}
                contentFormat='journal'
              />,
            ]
          : journals
              ?.slice(0, 10)
              .map((journal: JournalModel, index: number) => {
                return (
                  <div key={`journal-${index}`} style={cardStyle}>
                    <JournalCard journal={journal} />
                  </div>
                );
              })}
        {journals.length > 0 && (
          <CardWrapper
            key='journal-invite-card'
            onClick={() => history.push("/search/journals")}
          >
            <CustomIcon iconName='invite_down_arrow' />
            <p>
              {t("home:journals.discoverMore")} <span>{t("content:type.journals")}</span>
            </p>
            <CustomButton
              style={{ fontWeight: 700, fontSize: 12 }}
              className='small pink'
            >
              {t("common:action.letsGo")}
            </CustomButton>
          </CardWrapper>
        )}
      </HScrollContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 16px 0px;
  background: #ecf0f5;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 21px 16px;
  .title {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  h3 {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;
    margin: 0;
  }
  span {
    color: #ce0868;
  }
  a {
    font-family: "Roboto";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.02em;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;

const HScrollContainer = styled.div`
  overflow-x: auto;
  padding: 6px;
  display: flex;
  align-items: stretch;
  gap: 10px;
`;

const CardWrapper = styled.div`
  box-sizing: border-box;
  min-width: 180px;
  margin: 0;
  text-align: center;
  box-shadow: 2px 2px 4px rgba(33, 33, 33, 0.1);
  border-radius: 15px;
  padding: 16px 22px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;

  span {
    color: #ce0868;
  }
`;
