import { getApiRequestService } from "../../../services/index.services";

export async function getTagsList() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/tag");
    return res.data;
  } catch (error) {
    throw error;
  }
}
