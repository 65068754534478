import { ReactNode } from "react";
import { Link } from "react-router-dom";
import SettingButton from "./SettingButton";
import { ReactComponent as RightCaretIcon } from "../../assets/icons/right-caret.svg";

export default function SettingButtonLink({
  to,
  children,
  className,
  icon,
  rightIcon,
  onClick,
}: {
  to: string | {};
  children?: ReactNode;
  className?: string;
  icon?: ReactNode;
  rightIcon?: ReactNode;
  onClick?: Function;
}) {
  const isCertificationButton = className?.includes("lemon-certified-button");

  const defaultRightIcon = (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <RightCaretIcon
        style={{ color: isCertificationButton ? "#FFAA00" : "#90A4AE" }}
      />
    </div>
  );

  return (
    <Link to={to}>
      <SettingButton
        className={className}
        icon={icon}
        onClick={onClick}
        rightIcon={rightIcon || defaultRightIcon}
      >
        {children}
      </SettingButton>
    </Link>
  );
}
