import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import ProfilePicture from "../ProfilePicture";
import styled from "styled-components";

export default function ProfileInfo() {
  const { currentUser } = useCurrentUser();

  return (
    <Container>
      <ProfilePicture
        user={currentUser}
        height={64}
        withBorder={true}
        withShadow={true}
      />
      <div
        style={{
          fontSize: "17px",
          fontFamily: "Inter",
          fontWeight: 700,
          color: "#212121",
        }}
      >
        <span style={{ fontWeight: 400 }}>{currentUser?.firstname}</span>
        <br />
        {currentUser?.lastname}
      </div>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;
