import { getApiRequestService } from "../../../services/index.services";

export async function unfollowJournal(id: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete("/user/me/journal", {
      data: { id },
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
