import { t } from "i18next";
import React, { Component } from "react";
import styled from "styled-components";
import CardImage from "../../assets/images/share_activity.svg";

class ShareActivity extends Component {
  render() {
    return (
      <StyledWrapper onClick={this.props.onClick}>
        <p>{t("profile:gamification.share.subtitle")}</p>
        <h5>{t("profile:gamification.share.title")}</h5>
        <img src={CardImage} alt='' />
      </StyledWrapper>
    );
  }
}

export default ShareActivity;

const StyledWrapper = styled.div`
  padding: 20px 36px;
  background: linear-gradient(180deg, #f42cab 0%, #f1353a 100%);
  border-radius: 15px;
  color: #fff;
  font-family: "Inter";

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 0 20px;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    margin: 8px 0;
  }

  img {
    display: block;
    margin: 5px auto 0px;
  }
`;
