import { useEffect, useState } from "react";
import { useLangContext } from "../../../domains/app/contexts/lang.context";
import { useRoomContext } from "../../../domains/app/contexts/room.context";
import { useReadyState } from "../../../domains/app/hooks/useReadyState";
import HomeCarousel from "./HomeCarousel";
import CarouselIndicator from "../../app/CarouselIndicator";
import RoomContentCard from "../../cards/RoomContentCard";
import styled from "styled-components";
import SkeletonContentCard from "../../profile/SkeletonContentCard";
import { getLastContentInUserRooms } from "../../../domains/content/endpoints/getLastContentInUserRooms";
import { AnalyticsItemList } from "../../../tools/analytics/analytics.types";
import { ReactComponent as NavRoomsBlackIcon } from "../../../assets/icons/nav/nav-rooms-black.svg";

export default function RoomsContentCarousel({
  type,
  title,
}: {
  type: "user" | "other";
  title: string;
}) {
  const { activeLang } = useLangContext();
  const { isInRoom } = useRoomContext();
  const { setAsReady, useReadyEffect } = useReadyState();
  const [list, setList] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    (async function () {
      if (!isInRoom) {
        if (list.length === 0) await loadContent();
        setAsReady();
      }
    })();
  }, [isInRoom]);

  useReadyEffect(() => {
    loadContent();
  }, [activeLang]);

  async function loadContent() {
    try {
      const params = { language: activeLang };
      const data = type == "user"
        ? await getLastContentInUserRooms(params)
        : await getLastContentInUserRooms(params); // TODO: Other route once it's done.
      setLoading(false);
      setList(data);
    } catch (error) {
      console.error("Couldn't load user rooms content.", error);
    }
  }

  if (!isLoading && list.length === 0) return null;

  return (
    <Container>
      <TopWrapper>
        <h3>
          <NavRoomsBlackIcon />
          {title}
        </h3>
        {/* <Link to={linkTo}>
          <span>{t("common:action.seeAll")}</span>
        </Link> */}
      </TopWrapper>
      <HomeCarousel
        centerMode={true}
        centerSlidePercentage={100}
        renderIndicator={(_onClickHandler: any, isSelected: boolean) => {
          return <CarouselIndicator className="red" active={isSelected} />;
        }}
      >
        {isLoading
          ? [
            <SkeletonContentCard key={0} />,
            <SkeletonContentCard key={1} />,
          ]
          :
          list.slice(0, 5).map((content, index) => (
            <SlideWrapper key={index}>
              <RoomContentCard content={content} analyticsListName={AnalyticsItemList.HOME} />
            </SlideWrapper>
          ))
        }
      </HomeCarousel>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  padding: 24px 0;
  background: var(--GRADIENTS-MAIN-Peach-2, linear-gradient(0deg, #F80 0%, #FF7FBD 100%));

  .slider-wrapper {
    box-sizing: border-box;
    padding: 0 44px 0 22px; /* TODO: Fix this mess */
  }

  .slide {
    padding: 0 !important;
    +.slide {
      margin-left: 6px;
    }
  }

  .slide > div > div {
    flex: 1;
  }

  .card-content-container {
    gap: 0 !important;
  }
`;

const SlideWrapper = styled.div`
  flex: 1;
  display: flex;
`;

const TopWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0 21px;

  h3 {
    margin: 0;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    letter-spacing: -0.00011em;
    color: #313b42;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: #313b42;
      }
    }

    span {
      color: #ce0868;
    }
  }

  a {
    font-family: "Inter";
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    letter-spacing: 0.24px;
    text-decoration-line: underline;
    color: #ce0868;
    margin: 0;
  }
`;