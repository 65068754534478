import { ArticleModel } from "../../article/article.types";

export function getArticleTypeText(article: ArticleModel) {
  return `content:articleType.${toCamelCase(article.article_type ?? "Clinical Study")}`;
}

function toCamelCase(text: string) {
  return text
    .split(/[ -/]/)
    .filter(str => !!str)
    .map((str, index) => index === 0 ? str.toLowerCase() : str[0].toUpperCase() + str.toLowerCase().slice(1))
    .join("");
}
