import { useJournalFollow } from "../domains/journal/hooks/useJournalFollow";
import { JournalModel } from "../domains/journal/journal.types";
import { pickGradient } from "../tools/utils";
import { CustomButton } from "./global";
import styled from "styled-components";
import { t } from "i18next";

export default function JournalButton({
  journal,
  onClick,
  index,
  onFollow,
}: {
  journal: JournalModel;
  index?: number;
  onClick?: Function;
  onFollow?: Function;
}) {
  const journalImage = journal.image?.url ?? journal.image ?? "";
  const { isFollowed, toggleFollow } = useJournalFollow(journal);

  return (
    <Wrapper>
      <Infos onClick={() => onClick && onClick()}>
        {journalImage?.length > 0 ? (
          <img src={journalImage} alt="" />
        ) : (
          index && (
            <div
              style={{
                height: 64,
                width: 64,
                borderRadius: "50%",
                border: "2px solid white",
                background: pickGradient(index % 5),
              }}
            ></div>
          )
        )}
        <span className="journal-name">{journal?.name}</span>
      </Infos>
      <CustomButton
        style={{
          padding: "5px 16px",
          width: "auto",
          fontSize: 14,
          background: isFollowed && "#fff3ce",
          color: isFollowed && "#ffc408",
        }}
        onClick={() => onFollow ? onFollow() : toggleFollow()}
      >
        {isFollowed ? t("common:action.unfollow") : t("common:action.follow")}
      </CustomButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 20px 15px;
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(210, 220, 226, 0) 52.08%,
    rgba(210, 220, 226, 0.5) 100%
  );
  box-shadow: 2px 2px 4px 0px #2121211a;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const Infos = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 15px;

  img {
    object-fit: contain;
    height: 64px;
    width: 64px;
  }

  .journal-name {
    line-height: 21px;
    font-size: 14;
    font-weight: 600;
    color: #212121;
  }
`;
