import { getApiRequestService } from "../../../services/index.services";

export async function unlikeContent(contentId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/content/unlike/${contentId}`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
