import { Component } from "react";
import { Col, Row } from "react-grid-system";
import styled from "styled-components";
import CustomIcon from "./CustomIcon";
import { Spacer } from "./global";
import { RemoveScroll } from "react-remove-scroll";
import { isNativeIOS } from "../tools/ios";
import BottomPanel from "./app/panels/BottomPanel";

// TODO: Rename and rework.

class CustomSheet extends Component {
  render() {
    return (
      <RemoveScroll enabled={this.props.show}>
        <BottomPanel
          isOpen={this.props.show}
          onClose={() => this.props.onChange()}
        >
          <div
            style={{
              margin: 0,
              padding: "20px 0",
              background: this.props.background ?? "#FFFFFF",
            }}
          >
            <Row align='center' justify='between' style={{ margin: 0 }}>
              <Col xs={3 / 2}>{this.props.backContent}</Col>
              <Col xs={9}>
                <h3
                  style={{
                    textAlign: "center",
                    margin: 0,
                    fontFamily: "Inter",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {this.props.title}
                </h3>
              </Col>
              <Col xs={3 / 2} onClick={this.props.onChange}>
                <div style={{ float: "right" }}>
                  <CustomIcon iconName='times' />
                </div>
              </Col>
            </Row>
          </div>

          <SheetWrapper id={this.props.bodyId}>
            <div
              style={{
                padding: 20,
                boxSizing: "border-box",
              }}
            >
              {this.props.children}
            </div>
            <Spacer height='150px' />

            {this.props.bottomContent && (
              <SheetBottom
                isNative={isNativeIOS}
                style={{ background: this.props.background ?? "white" }}
              >
                {this.props.bottomContent}
              </SheetBottom>
            )}
          </SheetWrapper>
        </BottomPanel>
      </RemoveScroll>
    );
  }
}

export default CustomSheet;

export const SheetWrapper = styled.div`
  font-family: "Inter";
  color: #212121;

  h5 {
    font-size: 14px;
    font-weight: 400;
  }
`;

export const SheetBottom = styled.div`
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  padding-bottom: ${(props) => (props.isNativeIOS ? "60px" : "30px")};
  box-shadow: -4px -4px 4px rgba(33, 33, 33, 0.07);
`;
