import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { styled } from "@mui/material/styles";

export const CustomSelect = (props) => (
  <FormControl variant='filled' sx={{ minWidth: "100%" }}>
    <InputLabel
      style={{ color: "#81939c", fontFamily: "Inter", fontWeight: 500 }}
    >
      {props.label}
    </InputLabel>
    <StyledSelect
      required={props.required !== false}
      disabled={props.disabled}
      defaultValue={props.defaultValue ?? ""}
      onChange={props.onChange}
      disableUnderline={true}
      style={{
        ...props.style,
        background: props.background ?? "white",
        borderRadius: 5,
      }}
      native
      background={props.background}
      value={props?.value}
    >
      {!!props.renderOptions ? (
        <>
          {!props.noEmptyOption && <option disabled value=''></option>}
          {props.renderOptions}
        </>
      ) : (
        <>
          {!props.noEmptyOption &&
            <option disabled value=''></option>
          }
          {props.options?.length &&
            props.options?.map((option, index) => {
              return (
                <option
                  key={
                    props.customKey
                      ? props.customKey(option)
                      : `${option + index}`
                  }
                  value={`${option}`}
                  style={{ fontFamily: "Inter", fontWeight: 500 }}
                >
                  {option}
                </option>
              );
            })}
        </>
      )}
    </StyledSelect>
  </FormControl>
);

export default CustomSelect;

const StyledSelect = styled(Select)((props) => ({
  ".MuiSelect-filled, .MuiFilledInput-root, .MuiNativeSelect-root, .MuiNativeSelect-select":
  {
    color: "#212121",
    fontFamily: "Inter",
    fontWeight: 500,
    background: props.background ?? "white",
    border: "none",
    borderRadius: "5px",
  },

  ".MuiSelect-select:focus, .MuiSelect-filled:focus, .MuiNativeSelect-root:focus, .MuiNativeSelect-select:focus":
  {
    background: props.background ?? "white",
    border: "none",
    borderRadius: "5px",
  },

  ".MuiInputBase-input": {
    backgroundColor: props.background ?? "white",
  },

  ".MuiMenuItem-root": {
    backgroundColor: props.background ?? "white",
  },
}));
