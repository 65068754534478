import { userApi } from "../../../redux/user/user.service";
import { RoomModel } from "../../room/room.types";

const api = userApi.injectEndpoints({
  endpoints: (build) => ({
    getUserRooms: build.query<RoomModel[], void>({
      query: () => {
        return "/user/rooms";
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  getUserRooms,
} = api.endpoints;

export const {
  useLazyGetUserRoomsQuery,
} = api;
