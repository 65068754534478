import { iosAudioGetCurrentTime, iosAudioGetDuration, iosAudioPause, iosAudioPlayFromUrl, iosAudioPlayResume, iosAudioSeek, iosAudioSetPlaybackRate, iosAudioStop } from "../tools/ios/audio";

// NOTE: This is a core complex component in the app.
// Do NOT touch without advising your lead first.

export class InternalAudioPlayer {
  isNativeIOS: boolean;
  onProgress: ((time: number) => void) | null;
  onEnd: (() => void) | null;
  _onTrackLoaded: (duration: number) => void;
  _webPlayer: HTMLAudioElement | null;
  _isPlaying: boolean;
  _trackDuration: number;
  _playedChunks: any[];
  _chunkStartTime: number;
  _chunkReachedTime: number;
  _progressInterval: any;

  constructor(isNativeIOS: boolean, onTrackLoaded: (duration: number) => void) {
    this.isNativeIOS = isNativeIOS;
    this.onProgress = null;
    this.onEnd = null;
    this._onTrackLoaded = onTrackLoaded;
    this._webPlayer = null;
    this._isPlaying = false;
    this._trackDuration = 0;
    this._playedChunks = [];
    this._chunkStartTime = 0;
    this._chunkReachedTime = 0;
    this._progressInterval = 0;
  }

  async setTrack(trackUrl: string) {
    if (this.isNativeIOS) {
      let success = false;
      let duration = 0;

      while (!success) {
        success = await iosAudioPlayFromUrl(trackUrl as string);
      }
      while (duration <= 0) {
        duration = await iosAudioGetDuration();
      }
      this._trackDuration = duration;
      this._onTrackLoaded && this._onTrackLoaded(duration);

      await iosAudioPause(); // NOTE: Stop autoplay.
    } else {
      const audio = new Audio(trackUrl);

      audio.addEventListener("loadedmetadata", () => {
        this._trackDuration = audio.duration;
        this._onTrackLoaded && this._onTrackLoaded(audio.duration);
      });

      audio.addEventListener("timeupdate", () => {
        this.handleProgress();
      });

      audio.addEventListener("ended", () => {
        if (this.onEnd) this.onEnd();
      });

      audio.load();
      this._webPlayer = audio;
    }
    this._playedChunks = [];
  }

  async play() {
    if (this.isNativeIOS) {
      iosAudioPlayResume();
      this._chunkStartTime = await iosAudioGetCurrentTime();
    } else {
      this._webPlayer?.play();
      if (this._webPlayer?.currentTime) {
        this._chunkStartTime = this._webPlayer.currentTime;
      }
    }

    if (this._progressInterval) clearInterval(this._progressInterval);
    this._progressInterval = setInterval(() => {
      this.handleProgress();
    }, 500);

    this._isPlaying = true;
  }

  pause() {
    this.registerPlayedChunk();
    this.isNativeIOS ? iosAudioPause() : this._webPlayer?.pause();
    if (this._progressInterval) clearInterval(this._progressInterval);

    this._isPlaying = false;
  }

  async stop() {
    this.registerPlayedChunk();
    if (this.isNativeIOS) {
      await iosAudioStop();
    } else {
      if (this._webPlayer) {
        this._webPlayer.pause();
        this._webPlayer.currentTime = 0;
        this._webPlayer.removeEventListener("loadedmetadata", () => { });
        this._webPlayer.removeEventListener("ended", () => { });
        this._webPlayer.removeEventListener("timeupdate", () => { });
        this._webPlayer = null;
      }
    }

    this._trackDuration = -1;
    if (this._progressInterval) clearInterval(this._progressInterval);

    this._isPlaying = false;
  }

  seek(time: number) {
    if (this._isPlaying && Math.abs(time - this._chunkReachedTime) >= 2) {
      this.registerPlayedChunk();
    }
    this._chunkStartTime = time;
    this._chunkReachedTime = time;

    if (this.isNativeIOS) {
      iosAudioSeek(time);
    } else {
      if (this._webPlayer) this._webPlayer.currentTime = time;
    }
  }

  setPlaybackRate(rate: number) {
    if (this.isNativeIOS) {
      iosAudioSetPlaybackRate(rate);
    } else {
      if (this._webPlayer) this._webPlayer.playbackRate = rate;
    }
  }

  async handleProgress() {
    if (this.isNativeIOS) {
      const time = await iosAudioGetCurrentTime();
      if (this.onProgress) {
        this.onProgress(time);
        this._chunkReachedTime = time;
      }
    } else {
      const time = this._webPlayer?.currentTime;
      if (time) {
        if (this.onProgress) {
          this.onProgress(time as number);
          this._chunkReachedTime = time;
        }
      }
    }
  }

  registerPlayedChunk() {
    const start = Math.floor(this._chunkStartTime);
    const end = Math.ceil(this._chunkReachedTime);
    if (start >= end) return;

    const newPlayedChunks: number[][] = [[start, end]];

    // Loop through registered chunks and check against new chunk
    for (const chunkToRecheck of this._playedChunks) {
      const [a, b] = chunkToRecheck;
      if ((a >= start && a <= end) || (b >= start && b <= end)) {
        newPlayedChunks[0] = [
          Math.min(start, a),
          Math.max(end, b),
        ];
      } else {
        newPlayedChunks.push(chunkToRecheck);
      }
    }

    this._playedChunks = newPlayedChunks;
  }
}
