import { getApiRequestService } from "../../../services/index.services";

export async function addContentToPlaylist(
  playlistId: string,
  contentId: string
) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlistId}/content`, {
      contentId,
    });

    return res.data;
  } catch (error) {
    console.error("Couldn't add content to playlist.", error);
    throw error;
  }
};