import i18n from "../../../config/i18n";
import { SupportedLanguage } from "../../../interfaces";
import { ProfessionModel } from "../../profession/profession.types";
import { SpecialtyModel, TagModel } from "../app.types";

export function getItemTranslation(
  item: ProfessionModel | SpecialtyModel | TagModel,
  lang: SupportedLanguage | string = i18n.resolvedLanguage,
) {
  return item.translations[lang as SupportedLanguage] ?? item.translations[SupportedLanguage.EN];
}
