import { t } from "i18next";
import { displayToast } from "../../../components/app/AppToast";

export async function loadMarkdown(path: string) {
  try {
    const response = await fetch(path);
    return await response.text();
  } catch (error) {
    console.error("Couldn't load markdown file", error);
    throw error;
  }
}
