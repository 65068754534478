import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { useLazyGetCurrentUserQuery } from "../../domains/user/endpoints/getCurrentUser2";
import { getUserStartingPage } from "../../domains/user/utils/getUserStartingPage";
import styled from "styled-components";
import ReactPlayer from "react-player";
import VideoData from "../../assets/videos/video-tube-to-phone.mp4";
import CustomIcon from "../../components/CustomIcon";

// TODO: Remove (unused).

export default function LoginVideoPage() {
  const history = useHistory();
  const { currentUser } = useCurrentUser();
  const [isPlaying, setPlaying] = useState(false);
  const [getCurrentUser] = useLazyGetCurrentUserQuery();
  const playerRef = useRef(null);

  useEffect(() => {
    getCurrentUser();
    
    setTimeout(() => {
      setPlaying(true);
    }, 10);
  }, []);

  async function handleNavigateNext() {
    const path = await getUserStartingPage(currentUser);
    history.push(path ?? "/foryou");
  }

  return (
    <StyledWrapper>
      <div
        className="close-icon"
        onClick={handleNavigateNext}
      >
        <CustomIcon iconName="close_alt" />
      </div>
      {/* @ts-expect-error */}
      <ReactPlayer
        ref={playerRef}
        url={VideoData}
        playing={isPlaying}
        width="100%"
        height="100%"
        playbackRate={1.75}
        playsinline={true}
        muted={true}
        onReady={(e) => {
          const player = e.getInternalPlayer();
          player.setAttribute("muted", "true");
        }}
        onEnded={handleNavigateNext}
      />
    </StyledWrapper>
  );
}

export const StyledWrapper = styled.div`
  background: #000;
  position: relative;
  height: 100dvh;
  box-sizing: border-box;
  overflow: hidden;

  .close-icon {
    position: absolute;
    top: 36px;
    right: 24px;
    z-index: 999 !important;
  }
`;
