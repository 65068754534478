import styled from "styled-components";
import { Skeleton } from "@mui/material";

export default function VideoCardSkeleton() {
  return (
    <SkeletonWrapper>
      {[1, 2].map((item) => (
        <SkeletonColumn key={item}>
          <Skeleton variant="rounded" width={200} height={166} />
          <Skeleton
            variant="rounded"
            width={200}
            height={item === 1 ? 23 : 46}
          />
        </SkeletonColumn>
      ))}
    </SkeletonWrapper>
  );
}

const SkeletonWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

const SkeletonColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
