import { getApiRequestService } from "../../../services/index.services";

export async function readAllNotifications() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/notification/read-all`);
    return res.data;
  } catch (error) {
    throw error;
  }
}
