import i18n from "../../../config/i18n";
import { getApiRequestService } from "../../../services/index.services";

export async function getProfessionsList() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/profession?language=${i18n.resolvedLanguage}`);
    return res.data;
  } catch (error) {
    throw error;
  }
};