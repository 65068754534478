import { Carousel } from "react-responsive-carousel";
import { CustomIndicators } from "../post/ImageViewer";
import { t } from "i18next";

export default function StoryImageCarousel({
  images,
  imageIndex,
  onSlideChange,
  onClick,
}: {
  images: {
    _id: string;
    url: string;
    legend: string;
    order: number;
  }[], // TODO: Image type.
  imageIndex: number;
  onSlideChange: Function;
  onClick: Function;
}) {
  const currentImage = images[imageIndex];

  return (
    <>
      <p>{!!currentImage?.legend?.length ? currentImage.legend : t("forYou:noFigureLegend")}</p>

      <div
        style={{ marginTop: 48 }}
        onClick={(e) => {
          e.stopPropagation();
          onClick();
        }}
      >
        <Carousel
          autoFocus={false}
          autoPlay={false}
          preventMovementUntilSwipeScrollTolerance
          swipeScrollTolerance={30}
          interval={1e12}
          stopOnHover={true}
          infiniteLoop={true}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          showArrows={false}
          centerMode={false}
          selectedItem={imageIndex}
          onChange={(index: number) => onSlideChange(index)}
        >
          {images?.map((image, index) => (
            <div
              key={image?._id + index}
              style={{
                width: window.innerWidth * 0.9,
                textAlign: "center",
              }}
            >
              <img
                src={image?.url}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  border: "1px solid #212121",
                }}
              />
            </div>
          ))}
        </Carousel>
      </div >

      <div style={{ display: "flex", justifyContent: "center" }}>
        {images?.map(
          (_, index) =>
            index < 14 && (
              <CustomIndicators
                key={"indicatorsImageView" + index}
                active={imageIndex === index}
                primaryColor="#CE0868"
                secondary="#21212155"
              />
            )
        )}
      </div>
    </>
  );
}
