import { useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { joinRoomByInviteCode } from "../../domains/user/endpoints/joinRoomByInviteCode";
import { displayToast } from "../../components/app/AppToast";
import Loader from "../../components/Loader";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import { isUserRegistered } from "../../domains/user/utils/isUserRegistered";
import { RoomJoinStatus } from "../../domains/user/user.types";
import { RoomModel, RoomType } from "../../domains/room/room.types";

export default function ShareCodeInvitePage() {
  const history = useHistory();
  const { inviteCode } = useParams<{ inviteCode: string }>();
  const { t } = useLangContext();

  useEffect(() => {
    (async function () {
      try {
        if (!inviteCode) {
          throw new Error("No Invite code.");
        }

        const isAuthentified = await isUserAuthentified();
        if (!isAuthentified) {
          localStorage.setItem("roomInviteCode", inviteCode);
          if (isUserRegistered()) {
            return history.push("/login");
          } else {
            return history.push("/signup");
          }
        }

        const response = await joinRoomByInviteCode(inviteCode);
        const status: RoomJoinStatus = response.status;
        const room: RoomModel = response.room;
        const roomPath = room.type === RoomType.AUDIO
          ? `/room/audio/home/${room._id}`
          : `/room/discovery/${room._id}`;

        if (status === RoomJoinStatus.Success) {
          displayToast(t("room:joinedNewRoom"));
          history.push(roomPath);
        }
        if (status === RoomJoinStatus.AlreadyJoined) {
          history.push(roomPath);
        }
        if (status === RoomJoinStatus.Error) {
          displayToast(t("error:default"));
          history.push("/discovery");
        }
      } catch (error) {
        console.error("Couldn't load QR Code invite page.", error);
        displayToast(t("error:default"));
        history.replace("/discovery");
      }
    })();
  }, []);

  return <Loader />;
}
