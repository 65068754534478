import { Modal } from "antd-mobile";
import React, { SyntheticEvent } from "react";
import Hand from "../../../assets/images/hand.svg";
import disableScroll from "disable-scroll";
import styled from "styled-components";
import { t } from "i18next";
import { CustomButton } from "../../global";

function closest(el: any, selector: any) {
  const matchesSelector =
    el.matches ||
    el.webkitMatchesSelector ||
    el.mozMatchesSelector ||
    el.msMatchesSelector;
  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    }
    el = el.parentElement;
  }
  return null;
}

interface DisclaimerProps {
  onPress: () => void;
  visible: boolean;
}

class DisclaimerModal extends React.Component<DisclaimerProps> {
  componentDidMount() {
    disableScroll.on();
  }

  onWrapTouchStart = (e: SyntheticEvent) => {
    // fix touch to scroll background page on iOS
    if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
      return;
    }
    const pNode = closest(e.target, ".am-modal-content");
    if (!pNode) {
      e.preventDefault();
    }
  };

  handlePressAccept = () => {
    this.props.onPress();
    // localStorage.setItem("acceptedRulesTakeda", "true");
  };

  render() {
    const isVisible = this.props.visible;

    return (
      <StyledModal>
        <Modal
          className="scrollbar-hidden"
          visible={isVisible}
          transparent
          maskClosable={true}
          onClose={() => this.setState({ showModal: false })}
          title={
            <StyledHeader>
              <h3>{t("common:disclaimer.title")}</h3>
              <img className="disclaimer-hand-image" src={Hand} alt="" />
            </StyledHeader>
          }
          footer={[
            {
              text: (
                <CustomButton
                  className="white small no-border"
                  style={{ width: "fit-content" }}
                >
                  {t("common:action.accept")}
                </CustomButton>
              ) as any,
              onPress: this.handlePressAccept,
            },
          ]}
          wrapProps={{ onTouchStart: this.onWrapTouchStart }}
        >
          <StyledContent className="scrollbar-hidden">
            {t("common:disclaimer.body")}
          </StyledContent>
        </Modal>
      </StyledModal>
    );
  }
}

export default DisclaimerModal;

const StyledModal = styled.div`
  .top-text {
    color: white;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: -0.02em;
    text-align: center;

    z-index: 9999;
    text-align: center;
    position: absolute;
    margin: 0 24px;
    top: calc(10dvh);
  }
`;

const StyledHeader = styled.div`
  h3 {
    font-family: Inter;
    font-size: 24px;
    font-weight: 900;
    line-height: 110%;
    letter-spacing: 0em;
    text-align: center;
    white-space: pre-line;
  }
`;

const StyledContent = styled.div`
  max-height: 60dvh;
  overflow: scroll;
  width: 100%;
  color: #212121;

  padding: 16px 0;
  white-space: pre-line;
`;
