import { Link } from "react-router-dom";
import styled from "styled-components";
import { t } from "i18next";
import { Trans } from "react-i18next";
import CustomIcon from "../../CustomIcon";

export default function GPTInviteBlock() {
  return (
    <Container>
      <span className="heading">{t("juisciGpt:cta.title")}</span>
      <span className="text">
        <Trans i18nKey="juisciGpt:cta.text" components={{ b: <b /> }} />
      </span>
      <Link to="/gpt/chat/new">
        <CustomIcon iconName={"juiscigpt-link"} />
        <span>{t("juisciGpt:cta.chatWith")}</span>
      </Link>
    </Container>
  );
}

const Container = styled.div`
  padding: 24px 22px;
  text-align: center;
  background: var(--SECONDARY-TANGY-JuisciGPT, #EEF822);
  font-family: Inter;
  color: var(--BRAND-CORE-Juisci-Black, #212121);

  .heading {
    font-size: 24px;
    font-weight: 700;
    line-height: 110%; /* 26.4px */
  }

  .text {
    display: block;
    margin: 8px 0 24px;
    font-size: 14px;
    line-height: 120%; /* 16.8px */
    letter-spacing: 0.28px;
  }

  a {
    padding: 14px 24px;
    border: none;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: var(--BRAND-CORE-White, #FFF);
    font-size: 16px;
    font-weight: 600;
    line-height: 70%; /* 11.2px */
    letter-spacing: -0.032px;
    color: var(--BRAND-CORE-Juisci-Black, #212121);
  }
`;
