import { Component } from "react";
import styled from "styled-components";
import NoContentImg from "../assets/images/illustrations/search_no_results.svg";
import { t } from "i18next";
import i18n from "../config/i18n";
import { SupportedLanguage } from "../interfaces";

class NoContent extends Component {
  render() {
    return (
      <StyledWrapper>
        <img src={NoContentImg} alt='' />
        <div className='content'>
          <p>
            {t("search:noResults.noContentFoundSpecialty")} {this.props.specialty}
          </p>
          <p>{t("search:noResults.blendFeature")}</p>
          <a
            target='_blank'
            href={
              i18n.resolvedLanguage === SupportedLanguage.FR
                ? "https://www.juisci.com/fr/mixez"
                : "https://www.juisci.com/blend"
            }
          >
            {t("search:noResults.discoverMore")}
          </a>
        </div>
      </StyledWrapper>
    );
  }
}

export default NoContent;

const StyledWrapper = styled.div`
  padding: 22px;

  img {
    display: block;
    margin: auto;
  }

  p,
  a {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: #212121;
  }

  a {
    font-weight: 700;
    margin: auto;
    display: block;
    text-decoration: underline;
  }
`;
