import { getApiRequestService } from "../../../services/index.services";

export async function sharePlaylist(playlistId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.post(`/playlist/${playlistId}/share`);
    return res.data;
  } catch (error) {
    throw error;
  }
};
