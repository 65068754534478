import { UserModel } from "../domains/user/user.types";
import { Avatar } from "./global";
import CustomIcon from "./CustomIcon";
import { useCurrentUser } from "../domains/user/hooks/useCurrentUser";
import { pickUserImage } from "../domains/image/utils/pickUserImage";
import styled from "styled-components";

export default function ProfilePicture({
  user,
  height,
  withBorder = false,
  withShadow = false,
}: {
  user?: UserModel | null;
  height: string | number;
  withBorder?: boolean;
  withShadow?: boolean;
}) {
  const { currentUser } = useCurrentUser();
  if (!user) user = currentUser;
  const heightProp = typeof height === "number" ? `${height}px` : height;

  const avatarStyle = {
    border: withBorder ? "2px solid white" : "none",
    boxShadow: withShadow ? "2px 2px 4px 0px rgba(33, 33, 33, 0.10)" : "none",
  };

  return (
    <div style={{ position: "relative", width: "min-content" }}>
      <Avatar
        src={pickUserImage(user as UserModel)}
        height={heightProp}
        style={avatarStyle}
      />
      {user?.lemonCertified && <LemonBadge />}
    </div>
  );
}

function LemonBadge() {
  return (
    <BadgeWrapper>
      <CustomIcon iconName="lemon-certified" />
    </BadgeWrapper>
  );
}

const BadgeWrapper = styled.div`
  position: absolute;
  top: -5%;
  right: -5%;
  width: 40%;
  height: 40%;

  .--custom-icon {
    width: 100%;
    height: 100%;

    svg {
      width: 100%;
      height: 100%;
    }
  }
`;
