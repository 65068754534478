declare const window: Window & typeof globalThis & { webkit: any };

export const iOSAudioHandler = window.webkit?.messageHandlers.audio;

// NOTE: Load the track for future use and start playing it.
export async function iosAudioPlayFromUrl(url: string): Promise<boolean> {
  return await iOSAudioHandler?.postMessage({ method: "play", url });
}

// NOTE: Play a track already loaded with the previous function.
export async function iosAudioPlayResume(): Promise<boolean> {
  return await iOSAudioHandler?.postMessage({ method: "play" });
}

export async function iosAudioPause(): Promise<boolean> {
  return await iOSAudioHandler?.postMessage({ method: "pause" });
}

// TODO: The following functions must change to async.

export async function iosAudioStop(): Promise<boolean> {
  return iOSAudioHandler?.postMessage({ method: "stop" });
}

// NOTE: Returns -1 if invalid.
export async function iosAudioGetDuration(): Promise<number> {
  return iOSAudioHandler?.postMessage({ method: "getDuration" });
}

// NOTE: Returns -1 if invalid.
export async function iosAudioGetCurrentTime(): Promise<number> {
  return iOSAudioHandler?.postMessage({ method: "getCurrentTime" });
}

// NOTE: Returns -1 if invalid.
export function iosAudioGetPlaybackRate(): number {
  return iOSAudioHandler?.postMessage({ method: "getPlaybackRate" });
}

export function iosAudioGetPlayerState(): "unstarted" | "playing" | "paused" {
  return iOSAudioHandler?.postMessage({ method: "getPlayerState" });
}

export function iosAudioSeek(seconds: number): boolean {
  return iOSAudioHandler?.postMessage({ method: "seek", seconds });
}

export function iosAudioSetPlaybackRate(rate: number): boolean {
  return iOSAudioHandler?.postMessage({ method: "setPlaybackRate", rate });
}
