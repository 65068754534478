import { ConversationModel } from "../../domains/messaging/messaging.types";
import { UserModel } from "../../domains/user/user.types";
import { useCurrentUser } from "../../domains/user/hooks/useCurrentUser";
import { MessageRow } from "./layout";
import ProfilePicture from "../ProfilePicture";
import ClampLines from "../app/ClampLines";
import moment from "moment";
import styled from "styled-components";
import { t } from "i18next";

export default function ConversationRow({ conversation, onClick }: {
  conversation: ConversationModel;
  onClick: Function;
}) {
  const { currentUser } = useCurrentUser();
  const otherUser = conversation.participants.find((participant: UserModel) => {
    return participant._id !== currentUser._id;
  });
  const lastMessage = conversation.messages.at(-1);

  return (
    <>
      <MessageRow onClick={() => onClick()}>
        <ProfilePicture user={otherUser} height={48} withBorder />
        <div className="details">
          <RowHeader>
            <span className="username">{otherUser?.fullname}</span>
            <span className="date">
              {lastMessage
                ? moment(new Date(lastMessage.createdAt)).calendar()
                : null
              }
            </span>
          </RowHeader>
          <ClampLines className="subline" lines={2}>
            {lastMessage
              ? lastMessage.content
              : t("No message")
            }
          </ClampLines>
        </div>
      </MessageRow>
      <hr />
    </>
  );
}

const RowHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
`;
