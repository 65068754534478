import { searchApi } from "../search.api";

interface QueryParams {
  search: string;
  limit: number;
  offset: number;
}

const api = searchApi.injectEndpoints({
  endpoints: (build) => ({
    searchUsers: build.query<any, QueryParams>({
      query: ({ search, limit, offset }) => {
        return `/user/search?term=${search}&limit=${limit}&offset=${offset}`;
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useSearchUsersQuery,
  useLazySearchUsersQuery,
} = api;
