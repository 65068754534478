import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import BottomPanel from "../app/panels/BottomPanel";
import { SheetWrapper } from "../CustomSheet";
import { PanelContainer, PanelRow } from "../app/panels/layout";
import { ReactComponent as QrCodeIcon } from "../../assets/icons/qr-code-yellow.svg";
import { ReactComponent as SendIcon } from "../../assets/icons/send-duotone.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info-circle-yellow.svg";
import { displayToast } from "../app/AppToast";
import { getRoomTranslation } from "../../domains/room/utils/getRoomTranslation";
import { useRoomContext } from "../../domains/app/contexts/room.context";
import { RoomModel } from "../../domains/room/room.types";
import { shareUrl } from "../../domains/app/utils/shareUrl";

export default function RoomOptionsPanel({
  roomId,
  isVisible,
  onClose,
}: {
  roomId: string;
  isVisible: boolean;
  onClose: Function;
}) {
  const history = useHistory();
  const { t } = useLangContext();
  const { currentRoom } = useRoomContext();

  async function shareRoom() {
    try {
      const title = getRoomTranslation(currentRoom as RoomModel);
      const url = `${window.location.origin}/room/discovery/${roomId}`;
      const text = t("room:shareRoomMessages.text", { roomName: title });
      await shareUrl(title, text, url);
      displayToast(t("room:shareRoomMessages.roomShared"), "success");
    } catch (error) {
      console.error("Couldn't share room.", error);
      displayToast(t("error:default"));
    }
  }

  return (
    <BottomPanel
      isOpen={isVisible}
      onClose={() => onClose()}
    >
      <SheetWrapper style={{ height: window.innerHeight * 0.42 }}>
        <PanelContainer>
          <PanelRow onClick={shareRoom}>
            <SendIcon />
            <div className="text">
              <span className="label">{t("room:panel.shareRoom.label")}</span>
              <span className="description">
                {t("room:panel.shareRoom.description")}
              </span>
            </div>
          </PanelRow>
          <PanelRow
            onClick={() => history.push(`/room/share/qrcode/${roomId}`)}
          >
            <QrCodeIcon />
            <div className="text">
              <span className="label">{t("room:panel.shareQrCode.label")}</span>
              <span className="description">
                {t("room:panel.shareQrCode.description")}
              </span>
            </div>
          </PanelRow>
          <PanelRow
            onClick={() => history.push(`/room/terms-privacy/${roomId}`)}
          >
            <InfoIcon />
            <div className="text">
              <span className="label">{t("room:panel.information.label")}</span>
              <span className="description">
                {t("room:panel.information.description")}
              </span>
            </div>
          </PanelRow>
        </PanelContainer>
      </SheetWrapper>
    </BottomPanel>
  );
}
