const isDevMode = process.env.NODE_ENV !== "production";

export const API_URL = !isDevMode
  ? process.env.REACT_APP_API_URL
  : process.env.REACT_APP_API_URL_STAGING;

export const SOCKET_URL = !isDevMode
  ? process.env.REACT_APP_SOCKET_URL
  : process.env.REACT_APP_SOCKET_URL_STAGING;

export const FIREBASE_VAPID_KEY = !isDevMode
  ? process.env.REACT_APP_FIREBASE_VAPID_KEY
  : process.env.REACT_APP_FIREBASE_VAPID_KEY_STAGING;

export const FIREBASE_CONFIG = !isDevMode
  ? {
    apiKey: process.env.REACT_APP_GOOGLE_KEY,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  }
  : {
    apiKey: process.env.REACT_APP_GOOGLE_KEY_STAGING,
    authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID_STAGING}.firebaseapp.com`,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID_STAGING,
    storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID_STAGING}.appspot.com`,
    messagingSenderId:
      process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID_STAGING,
    appId: process.env.REACT_APP_FIREBASE_APP_ID_STAGING,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID_STAGING,
  };
