import { t } from "i18next";
import Lottie from "lottie-react";
import styled from "styled-components";
import loadingAnimation from "../../../assets/animations/juiscigpt-chat-loading.json";

export default function GPTChatLoader() {
	return (
		<LoaderContainer>
			<Lottie
				animationData={loadingAnimation}
				className="animated-dots"
				loop={true}
				autoplay={true}
			/>
			{t("juisciGpt:loading")}
		</LoaderContainer>
	);
}

const LoaderContainer = styled.div`
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 7px;
    opacity: 0;
    animation: fadeIn ease-in 0.5s forwards;

    .animated-dots {
        width: 128px;
    }
`;
