import { getApiRequestService } from "../../../services/index.services";
import { ArticleModel } from "../article.types";
import { sortArticleImages } from "../utils/sortArticleImages";
import i18n from "../../../config/i18n";

export async function getRelatedArticles(slug: string, specialty: string) {
  try {
    const lang = i18n.resolvedLanguage;

    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(
      `article/${slug}/related?language=${lang}${
        specialty ? "&medical_specialties=" + specialty : ""
      }`
    );
    const docs = res.data.map((article: ArticleModel) => sortArticleImages(article));
    return docs;
  } catch (error) {
    console.error("Couldn't get related articles.", error);
    throw error;
  }
};