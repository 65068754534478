import { useEffect } from "react";
import { useAppSelector } from "../../redux";
import { NectarModel } from "../../domains/nectar/nectar.types";
import { useSearchNectars } from "../../domains/search/hooks/useSearchNectars";
import { AnalyticsItemList } from "../../tools/analytics/analytics.types";
import { gaEventViewItemListNectar } from "../../tools/analytics/nectarAnalytics";
import Loader from "../Loader";
import FadeIn from "react-fade-in/lib/FadeIn";
import InfiniteScroll from "react-infinite-scroll-component";
import NectarCard from "../cards/NectarCard";
import { NoResultText, SearchResultsListWrapper } from "./layout";
import { t } from "i18next";

export default function SearchResultsNectars() {
	const filtersApplied = useAppSelector((state) => state.search.filtersApplied);
	const searchValueParam = new URLSearchParams(location.search).get("value") ?? "";
	const searcher = useSearchNectars(searchValueParam);

	useEffect(() => {
		for (const list of [searcher.fallback, searcher.results]) {
			if (list.length) {
				gaEventViewItemListNectar({
					nectars: list,
					listName: AnalyticsItemList.SEARCH,
				});
			}
		}
	}, [searcher.fallback, searcher.results]);

	if (!searchValueParam && !filtersApplied) {
		if (searcher.isLoadingFallback) {
			return <Loader loaderOnly />;
		}

		return (
			<FadeIn key="fallback">
				<SearchResultsListWrapper>
					{searcher.fallback.map((nectar: NectarModel) => {
						return (
							<NectarCard
								key={nectar._id}
								nectar={nectar}
								analyticsListName={AnalyticsItemList.SEARCH}
							/>
						);
					})}
				</SearchResultsListWrapper>
			</FadeIn>
		);
	}

	if (searcher.isLoadingResults) {
		return <Loader loaderOnly />;
	}

	if (!searcher.results.length) {
		return <NoResultText>{t("common:noSearchResults")}</NoResultText >;
	}

	return (
		// NOTE: specific key to force fade when switching results
		<FadeIn key="results">
			<InfiniteScroll
				dataLength={searcher.results.length}
				next={() => searcher.searchMore()}
				hasMore={searcher.results.length < searcher.total}
				pullDownToRefresh={false}
				scrollableTarget="content-ref"
				loader={null}
			>
				<SearchResultsListWrapper>
					{searcher.results.map((nectar: NectarModel) => {
						return (
							<NectarCard
								key={nectar._id}
								nectar={nectar}
								analyticsListName={AnalyticsItemList.SEARCH}
							/>
						);
					})}
					{searcher.isLoadingResults && <Loader loaderOnly />}
				</SearchResultsListWrapper>
			</InfiniteScroll>
		</FadeIn>
	);
}