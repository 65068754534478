import { ReactChildren, ReactNode } from "react";
import ContentMarkdown from "../../ContentMarkdown";
import styled from "styled-components";
import { t } from "i18next";
import { ReactComponent as AnswerIcon } from "../../../assets/icons/gpt/gpt-answer-tube.svg";
import { ReactComponent as CopyIcon } from "../../../assets/icons/gpt/gpt-copy.svg";

export default function GPTChatAnswer({ children, isTyping, onCopy }: {
	children: ReactNode | ReactChildren;
	isTyping?: boolean;
	onCopy?: Function;
}) {
	return (
		<>
			<Container>
				<Heading>
					<AnswerIcon />
					{t("common:answer")}
				</Heading>
				<Content
					className={isTyping ? "typing" : ""}
					children={children}
				/>
			</Container>

			{onCopy &&
				<ActionContainer>
					<CopyButton onClick={() => onCopy && onCopy()}>
						<CopyIcon />
						{t("juisciGpt:chat.action.copyAnswer")}
					</CopyButton>
				</ActionContainer>
			}
		</>
	);
}

const Container = styled.div`
	padding: 0px 16px;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const Heading = styled.div`
	display: inline-flex;
	align-items: center;
	gap: 8px;

	color: #212121;
	font-family: Inter;
	font-size: 21px;
	font-style: normal;
	font-weight: 500;
	line-height: 26px; /* 123.81% */
	letter-spacing: -0.21px;
`;

const Content = styled(ContentMarkdown)`
	color: #212121;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 26px; /* 162.5% */
	letter-spacing: -0.16px;

	&.typing {
		> *:last-child::after {
			content: "";
			display: inline-block;
			width: 12px;
			height: 12px;
			margin-left: 8px;
			background-color: #ff8800;
			border-radius: 50%;
			animation: blink 0.85s infinite;
		}

		@keyframes blink {
			0% { opacity: 1; transform: scale(1); }
			50% { opacity: 0.5; transform: scale(0.65); }
			100% { opacity: 1; transform: scale(1); }
		}
	}

	p {
		margin: 0;
	}

	ul, ol {
		margin: 5px 0;
		padding-inline-start: 16px;
	}

	ul {
		text-indent: -4px;
	}

	li::marker {
		color: var(--secondarytangy-raspberry-1, #CE0868);
	}

	.local-link {
		font-family: Inter;
		font-size: 14px;
		font-weight: 700;
		line-height: 20px;
		text-decoration: underline;
	}
`;

const ActionContainer = styled.div`
	padding: 0 16px 24px;
`;

const CopyButton = styled.button`
	padding: 12px 16px;
	display: flex;
	align-items: center;
	gap: 10px;
	border: 1px solid var(--SECONDARY-GREY-SHADES-Bluegrey-3, #D2DCE2);
	border-radius: 100px;
	background: var(--BRAND-CORE-Juisci-Black, #212121);
	color: var(--BRAND-CORE-White, #FFF);
	font-family: Inter;
	font-size: 16px;
	font-weight: 700;
	line-height: 100%; /* 16px */
	letter-spacing: 0.16px;
`;