import { ChangeEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { SupportedLanguage } from "../../interfaces";
import { isUserAuthentified } from "../../domains/user/utils/isUserAuthentified";
import SaveHeader from "../../components/app/headers/SaveHeader";
import Loader from "../../components/Loader";
import CustomSelect from "../../components/customSelect";
import { iosSetBackgroundColor, isNativeIOS } from "../../tools/ios";
import styled from "styled-components";
import { displayToast } from "../../components/app/AppToast";

export default function SettingsEditLanguagePage() {
  const history = useHistory();
  const { activeLang, changeActiveLang, t } = useLangContext();
  const [isLoading, setLoading] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState<SupportedLanguage>(activeLang);
  const langOptions: { [key in SupportedLanguage]?: unknown } = {
    [SupportedLanguage.EN]: t("common:lang:english"),
    [SupportedLanguage.FR]: t("common:lang:french"),
    // [SupportedLanguage.IT]: t("common:lang:italian"),
    // [SupportedLanguage.ES]: t("common:lang:spanish"),
  };

  useEffect(() => {
    (async function () {
      const isAuthentified = await isUserAuthentified();
      if (!isAuthentified) return history.replace("/discovery");

      if (isNativeIOS) iosSetBackgroundColor("#FFFFFF");
      setLoading(false);
    })();
  }, []);

  async function handleChangeLang(e: ChangeEvent<HTMLSelectElement>) {
    const lang = Object.keys(langOptions)[
      Object.values(langOptions).findIndex(value => value === e.target.value)
    ];
    setSelectedLanguage(lang as SupportedLanguage);
  }

  async function handleSubmit() {
    try {
      history.goBack();
      changeActiveLang(selectedLanguage);
      displayToast(t("toast:success.settingsUpdated"));
    } catch (error) {
      console.error("Couldn't edit language.", error);
      displayToast(t("error:default"));
    }
  }

  return (
    <>
      <SaveHeader
        title={t("settings:settingsPage.options.editLanguage")}
        saveReady={selectedLanguage !== activeLang}
        onSaveClick={handleSubmit}
      />
      {isLoading
        ? <Loader />
        : (
          <Container>
            <CustomSelect
              noEmptyOption
              defaultValue={langOptions[activeLang] ?? null}
              options={Object.values(langOptions)}
              label={t("common:language")}
              onChange={handleChangeLang}
            />
          </Container>
        )
      }
    </>
  );
}

const Container = styled.div`
  padding: 30px;
`;
