import { getApiRequestService } from "../../../services/index.services";

export async function getRoomSharingQrCode(roomId: string) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get(`/room/sharing-qr-code?room=${roomId}&format=webp`, {
      responseType: "blob",
    });
    return res.data;
  } catch (error) {
    throw error;
  }
}
