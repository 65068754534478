import { getApiRequestService } from "../../../services/index.services";

export async function getDiscoveryNotifications() {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.get("/notification/discovery");
    return res.data;
  } catch (error) {
    throw error;
  }
}
