import { getApiRequestService } from "../../../services/index.services";

export async function removeArticleFromPlaylist(playlistId: string, slug: any) {
  try {
    const apiInstance = await getApiRequestService();
    const res = await apiInstance.delete(`/playlist/${playlistId}/article`, {
      data: { slug },
    });
    return res.data;
  } catch (error) {
    console.error("Couldn't remove article from playlist.", error);
    throw error;
  }
};