import { useEffect, useRef, useState } from "react";
import { useLangContext } from "../../domains/app/contexts/lang.context";
import { SpecialtyModel, TagModel } from "../../domains/app/app.types";
import { getItemTranslation } from "../../domains/app/utils/getItemTranslation";
import { getStoredContentFilters, storeContentFilters } from "../../domains/app/utils/contentFilters";
import { getSpecialtyTags } from "../../domains/app/endpoints/getSpecialtyTags";
import { FilterPanelActionBox, FilterPanelContent, FilterPanelFooter, FilterPanelHeader, LeftDrawer, SelectableItem } from "./layout";
import CustomIcon from "../CustomIcon";
import CustomSearchInput from "../CustomSearchInput";
import CustomRadio from "../CustomRadio";
import Fuse from "fuse.js";
import AppButton from "../app/AppButton";

export default function SubSpecialtyFilterPanel({
  specialty,
  isOpen,
  onClose,
  onSave,
  // NOTE: Used to force a set of subspecialties instead of the ones from the defined specialty
  subSpecialties,
}: {
  specialty: SpecialtyModel;
  isOpen: boolean;
  onClose: Function;
  onSave: Function;
  subSpecialties?: TagModel[];
}) {
  const { activeLang, t } = useLangContext();
  const [searchValue, setSearchValue] = useState("");
  const [fetchedSubSpecialties, setFetchedSubSpecialties] = useState<TagModel[]>(subSpecialties ?? []);
  const [selectedSubSpecialtyIds, setSubSelectedSpecialtyIds] = useState<string[]>([]);
  const [memSubSelectedSpecialtyIds, setMemSubSelectedSpecialtyIds] = useState<string[]>([]);
  const isAllSelected = selectedSubSpecialtyIds.length === fetchedSubSpecialties.length;
  const [visibleItems, setVisibleItems] = useState(fetchedSubSpecialties);
  const sortedItems = [...visibleItems]
    ?.sort((a: TagModel, b: TagModel) => {
      return getItemTranslation(a).localeCompare(getItemTranslation(b));
    });
  const scrollerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    (async function () {
      try {
        if (!subSpecialties) {
          const tags = await getSpecialtyTags(specialty.uid);
          setFetchedSubSpecialties(tags);
          setVisibleItems(tags);
        }
      } catch (error) {
        console.error("Couldn't fetch subspecialties.", error);
      }
    })();
  }, [])

  useEffect(() => {
    if (isOpen) {
      if (getStoredContentFilters()) {
        setSubSelectedSpecialtyIds(getStoredContentFilters().subSpecialtyIds ?? []);
      }
      setMemSubSelectedSpecialtyIds(selectedSubSpecialtyIds);
    } else {
      setTimeout(() => {
        setSearchValue("");
      }, 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (searchValue) {
      const fuse = new Fuse(fetchedSubSpecialties, {
        includeScore: true,
        threshold: 0.25,
        keys: [`translations.${activeLang}`],
      });
      const searchResults = fuse.search(searchValue).map((res: any) => res.item);
      setVisibleItems(searchResults);
    } else {
      setVisibleItems(fetchedSubSpecialties);
    }
  }, [searchValue]);

  function handleToggleSelect(tag: TagModel) {
    if (selectedSubSpecialtyIds.includes(tag._id)) {
      setSubSelectedSpecialtyIds(selectedSubSpecialtyIds.filter((id) => id !== tag._id));
    } else {
      setSubSelectedSpecialtyIds([...selectedSubSpecialtyIds, tag._id]);
      scrollerRef.current?.scrollTo({ top: 0, behavior: "smooth" });
    }
  }

  function handleSelectAll() {
    setSubSelectedSpecialtyIds(fetchedSubSpecialties.map((tag: TagModel) => tag._id));
  }

  function handleUnselectAll() {
    setSubSelectedSpecialtyIds([]);
  }

  function handleCloseWithoutSaving() {
    onClose();
    setTimeout(() => {
      setSubSelectedSpecialtyIds(memSubSelectedSpecialtyIds);
    }, 100);
  }

  function handleSubmit() {
    const subSpecialties = selectedSubSpecialtyIds.map((id) => {
      return fetchedSubSpecialties.find((tag: TagModel) => tag._id === id);
    });
    storeContentFilters({
      specialtyIds: selectedSubSpecialtyIds.length > 0 ? [specialty._id] : [],
      specialtyUids: selectedSubSpecialtyIds.length > 0 ? [specialty.uid] : [],
      subSpecialtyIds: selectedSubSpecialtyIds,
      subSpecialtyUids: subSpecialties.map(tag => tag?.uid ?? "").filter(element => !!element),
    });
    onSave();
    onClose();
  }

  return (
    <LeftDrawer isOpen={isOpen}>

      <FilterPanelHeader>
        <CustomIcon
          className="close-button"
          iconName="close_alt"
          color="#313B42"
          onClick={handleCloseWithoutSaving}
        />
      </FilterPanelHeader>

      <FilterPanelContent ref={scrollerRef}>
        <span className="info">{t("content:filterBy.subspecialtiesWithin")} <b>{getItemTranslation(specialty)}</b></span>

        <CustomSearchInput
          placeholder={t("common:action.search")}
          onSearch={(value: string) => setSearchValue(value)}
          onClear={() => {
            setSearchValue("");
          }}
        />

        {searchValue.length === 0 && (
          <div>
            <span className="heading">{t("common:default")}</span>

            <FilterPanelActionBox>
              <CustomRadio
                onChange={handleSelectAll}
                checked={isAllSelected}
                title={t("common:action.selectAll")}
                textStyle={{
                  fontFamily: "Inter",
                  fontSize: 14,
                  fontWeight: 700,
                  width: "max-content",
                }}
              />

              <div
                className={`clear-all ${selectedSubSpecialtyIds.length > 0 ? "active" : ""}`}
                onClick={handleUnselectAll}
              >
                <CustomIcon iconName="times-radio" />
                {t("common:action.clearAll")}
              </div>
            </FilterPanelActionBox>
          </div>
        )}

        <hr style={{ margin: "16px 0" }} />

        <span className="heading">{t("common:subspecialties")}</span>

        {[
          ...sortedItems.filter((tag: TagModel) => selectedSubSpecialtyIds.includes(tag._id)),
          ...sortedItems.filter((tag: TagModel) => !selectedSubSpecialtyIds.includes(tag._id)),
        ].map((tag: TagModel) => {
          const isSelected = selectedSubSpecialtyIds.includes(tag._id);
          return (
            <SelectableItem key={tag._id} className={isSelected ? "selected" : ""}>
              <span>{getItemTranslation(tag)}</span>
              <button onClick={() => handleToggleSelect(tag)}>
                {isSelected ? t("common:action.unselect") : t("common:action.select")}
              </button>
            </SelectableItem>
          );
        })}

      </FilterPanelContent>

      <FilterPanelFooter>
        <AppButton className="xl" onClick={handleSubmit}>
          {t("common:panel.options.label.save")}
        </AppButton>
      </FilterPanelFooter>
    </LeftDrawer >
  );
}
