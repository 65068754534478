import { Link } from "react-router-dom";
import { useAppDispatch } from "../../redux";
import { resetFilters } from "../../domains/search/search.reducer";
import FadeIn from "react-fade-in/lib/FadeIn";
import CustomIcon from "../CustomIcon";
import styled from "styled-components";
import { t } from "i18next";

export default function SearchCategoryList() {
  return (
    <FadeIn delay={20} transitionDuration={200}>
      <CategoryLink
        to={`/search/articles`}
        label={t("content:type.publications")}
        iconName="paperscroll" iconWidth={20} iconHeight={20}
      />

      <CategoryLink
        to={`/search/journals`}
        label={t("content:type.journals")}
        iconName="documents" iconWidth={22} iconHeight={22}
      />

      <CategoryLink
        to={`/search/playlists`}
        label={t("content:type.playlists")}
        iconName="playlist" iconWidth={16} iconHeight={16}
      />

      <CategoryLink
        to={`/search/users`}
        label={t("common:users")}
        iconName="user" iconWidth={18} iconHeight={18}
      />

      <CategoryLink
        to={`/search/videos`}
        label={t("content:type.videos")}
        iconName="video-player" iconWidth={21} iconHeight={20}
      />

      <CategoryLink
        to={`/search/nectars`}
        label={t("content:type.nectarsOfScience")}
        iconName="nectar-drop-search" iconWidth={21} iconHeight={20}
      />
    </FadeIn>
  );
}

// TODO: Rework
function CategoryLink({
  to,
  label,
  iconName,
  iconWidth = 16,
  iconHeight = 16,
}: {
  to: string;
  label: string;
  iconName: string;
  iconWidth: number;
  iconHeight: number;
}) {
  const dispatch = useAppDispatch();

  return (
    <Link to={to} onClick={() => dispatch(resetFilters())}>
      <EntryRow>
        <div className='inner-container'>
          <CustomIcon
            iconName={iconName}
            color='#212121'
            width={iconWidth}
            height={iconHeight}
          />
          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
            <span className="category-title">{label}</span>
          </div>
          <CustomIcon iconName='external' className='external-icon' />
        </div>
      </EntryRow>
    </Link>
  );
}

const EntryRow = styled.div`
  .inner-container {
    margin: 0 22px;
    padding: 16px 0;
    display: grid;
    grid-template-columns: 0.4fr 3fr 0.3fr;
    grid-template-rows: 1fr;
    grid-template-areas: ". . .";
    align-items: center;
    border-bottom: 1px solid rgba(210, 220, 226, 1);
  }

  .external-icon {
    text-align: right;
  }
`;
