
import { SpecialtyModel } from "../../domains/app/app.types";
import { ArticleModel } from "../../domains/article/article.types";
import { AnalyticsItemList, AnalyticsItemVariant } from "./analytics.types";
import { gaEventSelectItem, gaEventViewItem, gaEventViewItemList } from "./analyticsEvents";
import { gaPushEvent } from "./gaPushEvent";

// TODO: update search
// TODO: add audio_room_like
// TODO: add audio_room_share
// TODO: add audio_room_save

enum Event {
  SELECT_TOPIC = "select_topic",
  AUDIO_ROOM_PLAYED = "audio_room_played",
  AUDIO_ROOM_RESUMED = "audio_room_resumed",
  AUDIO_ROOM_PROGRESS = "audio_room_progress",
  ACCESS_ORIGINAL_PUBLICATION = "access_original_publication",
}

interface AudioRoomItemPayload {
  // NOTE: Can be a real podcast or something similar
  // like the data stored to resume the last podcast.
  podcast: ArticleModel | any; // TODO: Type podcast.
  topicName: string;
  sectionName: string;
  durationMin: number;
  percentage?: number,
  publicationUrl?: string,
}

interface AudioRoomItemListPayload {
  podcasts: ArticleModel[] | any[]; // TODO: Type podcast.
  topicName: string;
  sectionName: string;
  durationMin: number;
}

function convertPodcastToItemData(payload: AudioRoomItemPayload) {
  const { podcast, topicName, sectionName, durationMin } = payload;
  return {
    item_id: podcast._id ?? podcast.id,
    audio_room_name: sectionName,
    audio_room_journal: podcast.journal.name,
    audio_room_topic: topicName,
    item_variant: AnalyticsItemVariant.PODCASTS,
    item_list_name: AnalyticsItemList.AUDIO_ROOM_LIST,
    audio_room_duration: durationMin,
    podcast_name: podcast.title,
    audio_room_category: podcast.medical_specialties?.map((spe: SpecialtyModel) => {
      return spe.uid;
    }).join(",") ?? "",
  };
}

export function gaEventSelectItemPodcast(payload: AudioRoomItemPayload) {
  gaEventSelectItem(convertPodcastToItemData(payload));
}

export function gaEventViewItemPodcast(payload: AudioRoomItemPayload) {
  gaEventViewItem(convertPodcastToItemData(payload));
}

export async function gaEventViewItemListPodcast(payload: AudioRoomItemListPayload) {
  const podcastsWithDuration = [];
  for (const podcast of payload.podcasts) {
    // NOTE: This is a temporary hack to get the duration on a list
    // until the backend provides it.
    let duration = 0
    if (podcast.speech?.url) {
      duration = await new Promise<number>((resolve) => {
        const dummyAudio = new Audio(podcast.speech?.url);
        dummyAudio.addEventListener("loadedmetadata", () => {
          resolve(dummyAudio.duration);
        });
      });
    }
    podcastsWithDuration.push(
      convertPodcastToItemData({
        podcast,
        topicName: payload.topicName,
        sectionName: payload.sectionName,
        durationMin: Math.floor(duration as number / 60),
      })
    );
  }

  gaEventViewItemList(podcastsWithDuration);

  // NOTE: This is what the final code should look like.
  // gaEventViewItemList(payload.podcasts.map(async (podcast) => {
  //   return convertPodcastToItemData({
  //     podcast,
  //     topicName: payload.topicName,
  //     sectionName: payload.sectionName,
  //     durationMin: payload.durationMin,
  //   });
  // }));
}

export function gaEventAudioRoomSelectTopic(topicName: string) {
  gaPushEvent(
    Event.SELECT_TOPIC,
    {
      topic_name: topicName,
    },
  );
}

export function gaEventAudioRoomPlayPodcast(payload: AudioRoomItemPayload) {
  gaPushEvent(
    Event.AUDIO_ROOM_PLAYED,
    {
      is_public: true,
      ecommerce: {
        items: [convertPodcastToItemData(payload)],
      },
    },
  );
}

export function gaEventAudioRoomResumePodcast(payload: AudioRoomItemPayload) {
  gaPushEvent(
    Event.AUDIO_ROOM_RESUMED,
    {
      is_public: true,
      ecommerce: {
        items: [convertPodcastToItemData(payload)],
      },
    },
  );
}

export function gaEventPodcastAudioProgress(payload: AudioRoomItemPayload) {
  gaPushEvent(
    Event.AUDIO_ROOM_PROGRESS,
    {
      is_public: true,
      percentage: payload.percentage,
      ecommerce: {
        items: [convertPodcastToItemData(payload)],
      },
    },
  );
}

export function gaEventAudioPodcastAccessOriginalPublication(payload: AudioRoomItemPayload) {
  gaPushEvent(
    Event.ACCESS_ORIGINAL_PUBLICATION,
    {
      is_public: true,
      publication_url: payload.publicationUrl,
      ecommerce: {
        items: [convertPodcastToItemData(payload)],
      },
    },
  );
}
