import styled from "styled-components";

export const PolicyTextStyle = styled.div`
  color: #212121;
  font-family: "Inter";

  & :nth-child(2) {
    margin-top: 30px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 20px;
  }

  ol {
    padding-left: 20px;
  }

  ul {
    padding-left: 15px;
  }

  li {
    font-size: 13px;
    font-weight: 300;
    margin-bottom: 15px;
  }

  b {
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 300;
  }

  a {
    color: #ff8800;
    font-weight: 500;
  }

  th,
  td {
    border: 1px solid #212121;
    font-size: 12px;
  }

  td {
    padding: 12px;
    box-sizing: border-box;
  }

  th {
    padding: 8px 0;
  }
`;
